import React from 'react';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import './alert-confirm.css';

export default function AlertConfirm(props) {
  const {close, afterSubmitMethod, details} = props;
  const {text, yes, no} = details;

  return (
    <>
      <div className="alert-stop-container">
        <div className="alert-stop-text">
          {text}
          {/* Automatic periodic measurement of vital parameters will stop */}
        </div>
        <div className="alert-stop-button-group">
          <OutlinePrimaryButton
            label={yes}
            click={() => {
              close();
              afterSubmitMethod();
            }}
          />

          <PrimaryButton label={no} classes="alert-stop-button" click={close} />
        </div>
      </div>
    </>
  );
}
