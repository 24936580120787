import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAddMealBody,
  getAllMealsBody,
  getDeleteMealBody,
  getEditMealBody,
  getListMealBody,
} from '../body/meal';
import {getHeadersWithKeyAndToken, URL} from '../config';
import {httpPostRequest} from '../httpHandler';

export const addMeal = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAddMealBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const listMeal = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getListMealBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const deleteMeal = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getDeleteMealBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const editMeal = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getEditMealBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getAllMealsInRange = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAllMealsBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
