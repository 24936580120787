import {headers, URL} from './config';
import {httpPostRequest} from './httpHandler';

export const getMasterData = (type) => {
  const body = {dataType: type};
  return httpPostRequest(
    URL[URL.currentENV].masterData,
    headers.onlyType,
    JSON.stringify(body)
  );
};
