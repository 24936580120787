import {Skeleton} from '@material-ui/lab';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import health from '../../assets//images/overview/health.png';
import meal from '../../assets//images/overview/meal.png';
import arrowRight from '../../assets/icons/common/arrow-right.svg';
import steps from '../../assets/icons/overview/steps.svg';
import {HealthParameterType} from '../../common/config';
import {
  checkIfTokenPresent,
  getLocalStorage,
  setLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getLatestHealthValues,
  getLatestStepValue,
} from '../../rest-api/home-api/overview';
import {getProfileInfo} from '../../rest-api/home-api/profile';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DashLayout from '../dash-layout/dash-layout';
import './home-page.css';
import {getIconWithColor} from './icon-handler';
import {getWatchSettings} from '../../rest-api/home-api/settings';
import {getBloodGlucose, GLUCOSE_UNIT_MGDL} from '../../utils/glucose';

const EMPTY_BLOOD_GLUCOSE = {value: '', unit: GLUCOSE_UNIT_MGDL};

export default function HomePage() {
  const history = useHistory();
  const [bloodGlucose, setBloodGlucose] = useState(EMPTY_BLOOD_GLUCOSE);
  const [bloodPressure, setBloodPressure] = useState('');
  const [heartRate, setHeartRate] = useState('');
  const [respirationRate, setRespirationRate] = useState('');
  const [oxygenSaturation, setOxygenSaturation] = useState('');
  const [stepCount, setStepCount] = useState('');
  const [stepGoal, setStepGoal] = useState(5000);
  const [measuredTime, setMeasuredTime] = useState('');

  const redirectToShareData = () => {
    history.push(routesPath.SHARE_DATA);
  };

  const CardWithImage = (props) => {
    const {image, label, action, id} = props;
    return (
      <>
        <div
          id={'card_' + id}
          className="overview-card-image-container card-hover c-pointer"
          onClick={action}
          style={{backgroundImage: `url(${image})`}}
        >
          <div className="overview-card-image-label">
            {label} <img src={arrowRight} alt="arrow-right" />
          </div>
        </div>
      </>
    );
  };

  const OverviewCard = (props) => {
    const {obj} = props;
    const {icon, id, value, unit, label, action} = obj;
    console.log('Label with value: ', label, value);
    return (
      <div
        id={'card_' + id}
        className="overview-card card-hover c-pointer"
        onClick={() => action()}
      >
        <div className="overview-icon">
          <img src={icon} alt={'icon-' + icon} />
        </div>
        <div className="overview-card-details">
          {value === '' ? (
            <Skeleton
              variant="text"
              style={{fontSize: 'xx-large', width: '50%'}}
            />
          ) : (
            <>
              <span className="details-value">
                {value !== null ? value : '__ '}
              </span>{' '}
              {unit}
            </>
          )}
        </div>
        <div className="overview-card-label-container">
          <div className="overview-card-label-value">{label}</div>
          <img src={arrowRight} alt="arrow-right" />
        </div>
      </div>
    );
  };

  const cardObjectListUpper = [
    {
      icon: getIconWithColor(HealthParameterType.BLOOD_GLUCOSE, bloodGlucose),
      value: bloodGlucose.value,
      unit: bloodGlucose.unit,
      label: 'blood glucose',
      id: 'blood_glucose',
      action: () => history.push(routesPath.BLOOD_GLUCOSE),
    },
    {
      icon: getIconWithColor(HealthParameterType.HEART_RATE, heartRate),
      value: heartRate,
      unit: 'bpm',
      label: 'heart rate',
      id: 'heart_rate',
      action: () => history.push(routesPath.HEART_RATE),
    },
    {
      icon: getIconWithColor(HealthParameterType.BLOOD_PRESSURE, bloodPressure),
      value: bloodPressure,
      unit: 'mmHg',
      label: 'Blood Pressure',
      id: 'blood_pressure',
      action: () => history.push(routesPath.BLOOD_PRESSURE),
    },
  ];

  const cardObjectListLower = [
    {
      icon: getIconWithColor(
        HealthParameterType.RESPIRATION_RATE,
        respirationRate
      ),
      value: respirationRate,
      unit: 'brpm',
      label: 'Respiration Rate',
      id: 'respiration_rate',
      action: () => history.push(routesPath.RESPIRATION_RATE),
    },
    {
      icon: getIconWithColor(
        HealthParameterType.OXYGEN_SATURATION,
        oxygenSaturation
      ),
      value: oxygenSaturation,
      unit: '%',
      label: 'Oxygen Saturation',
      id: 'oxygen_saturation',
      action: () => history.push(routesPath.OXYGEN_SATURATION),
    },
    {
      icon: steps,
      value: stepCount,
      unit: ((stepCount / stepGoal) * 100).toFixed() + '% of goal ' + stepGoal,
      label: 'Steps Walked',
      id: 'steps_walked',
      action: () => history.push(routesPath.STEPS_WALKED),
    },
  ];

  const cardImageObjectList = [
    {
      image: meal,
      label: 'Meals',
      id: 'meal',
      action: () => history.push(routesPath.MEALS),
    },
    {
      image: health,
      label: 'Health Symptoms',
      id: 'health_symptoms',
      action: () => history.push(routesPath.HEALTH_SYMPTOMS),
    },
  ];

  const getDataIfPresent = (data) => {
    return data || data === 0 ? data : null;
  };

  const initWithData = () => {
    console.log('initWithData function called.........................');
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId,
    };
    console.log('promise all is calling.......................');
    Promise.all([
      getLatestHealthValues(body),
      getLatestStepValue(body),
      getProfileInfo(),
      getWatchSettings(body),
    ])
      .then(([res, stepData, profileInfo, watchSettings]) => {
        console.log('promise all response.......................');
        if (res.data.fetchLatestData.statusCode === 200) {
          const latestData = res.data.fetchLatestData.body.data;
          if (latestData.length > 0) {
            const {SPO2, RR, HR, SBP, DBP, Glucose, Time} = JSON.parse(
              latestData[latestData.length - 1].data
            );

            setBloodGlucose(
              getBloodGlucose(
                getDataIfPresent(Glucose),
                watchSettings?.data?.getSettings?.body?.data?.cgmUnit
              )
            );

            if (getDataIfPresent(DBP) || getDataIfPresent(SBP)) {
              setBloodPressure(SBP + '/' + DBP);
            } else {
              setBloodPressure(null);
            }
            setHeartRate(getDataIfPresent(HR));
            setRespirationRate(getDataIfPresent(RR));
            setOxygenSaturation(getDataIfPresent(SPO2));
            const time = new Date(Number(latestData[0].ts));
            const gmtDateTime = moment.utc(Number(Time));
            const local = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');
            console.log('Time with timezone: ', local);
            setMeasuredTime(
              `Time: ${moment(time).format('DD-MM-YYYY HH:mm:ss')}`
            );
          } else {
            throw new Error('NO_DATA');
          }
        } else {
          throw new Error('NO_DATA');
        }
        if (stepData.data.getLatestStepCount.statusCode === 200) {
          if (stepData.data.getLatestStepCount.body.data !== null) {
            setStepCount(stepData.data.getLatestStepCount.body.data.count);
          } else {
            setStepCount(null);
          }
        } else {
          throw new Error('NO_STEP');
        }
        if (
          profileInfo.data.getUserAllInfo.statusCode === 200 &&
          profileInfo.data.getUserAllInfo.body.data.stepGoal
        ) {
          setStepGoal(profileInfo.data.getUserAllInfo.body.data.stepGoal);
        }
        if (
          profileInfo.data.getUserAllInfo.statusCode === 200 &&
          profileInfo.data.getUserAllInfo.body.data.deviceMSN
        ) {
          setLocalStorage(
            localStorageKey.DEVICE_MSN,
            profileInfo.data.getUserAllInfo.body.data.deviceMSN
          );
        }
      })
      .catch((err) => {
        if (err.message === 'NO_DATA') {
          setBloodGlucose(EMPTY_BLOOD_GLUCOSE);
          setBloodPressure(null);
          setHeartRate(null);
          setRespirationRate(null);
          setOxygenSaturation(null);
          setStepCount(null);
        }
        if (err.message === 'NO_STEP') {
          setStepCount(null);
        }
      });
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }

    initWithData();
  }, []);
  return (
    <>
      <DashLayout refreshData={initWithData} loggedIn={true}>
        <div className="home-body">
          <div className="home-header-container">
            <div className="overview-title">Health Overview</div>
            <PrimaryButton
              inputId="btn_share_data"
              classes="overview-button"
              label="Share data"
              click={() => redirectToShareData()}
            />
          </div>
          <div className="overview-card-container">
            {cardObjectListUpper.map((value, i) => (
              <OverviewCard key={i} obj={value} />
            ))}
          </div>
          <div className="overview-card-container">
            {cardObjectListLower.map((value, i) => (
              <OverviewCard key={i} obj={value} />
            ))}
          </div>
          <div className="overview-card-images">
            {cardImageObjectList.map((value, i) => (
              <CardWithImage
                id={value.id}
                image={value.image}
                label={value.label}
                action={value.action}
                key={i}
              />
            ))}
          </div>
          <div style={{color: 'grey'}}>{measuredTime}</div>
        </div>
      </DashLayout>
    </>
  );
}
