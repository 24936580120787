import {createSlice} from '@reduxjs/toolkit';

const initialState = {data: null};

const watchSettings = createSlice({
  name: 'watchSettings',
  initialState,
  reducers: {
    saveWatchSettings(state, {payload}) {
      state.data = payload;
    },
  },
});

export const {saveWatchSettings} = watchSettings.actions;

export default watchSettings.reducer;
