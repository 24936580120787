import {
  getSubscriptionDetailsBody,
  getSubscriptionURLBody,
} from '../body/chargebee';
import {
  getHeadersWithKey,
  getHeadersWithKeyAndToken,
  headers,
  URL,
} from '../config';
import {httpGetRequest, httpPostRequest} from '../httpHandler';

export const getSubscriptionDetails = (apiKey, token, data) => {
  const body = getSubscriptionDetailsBody(data);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apiKey, token),
    body
  );
};

export const getSubscriptionURL = (apiKey, token, priceId, userId) => {
  const body = getSubscriptionURLBody(priceId, userId);
  console.log(body);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apiKey, token),
    body
  );
};
