import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Spinner from 'react-spinner-material';
import Calender from '../../assets/icons/common/calendar.svg';
import MealMediumIcon from '../../assets/icons/common/meal-medium.svg';
import Trash from '../../assets/icons/common/trash-blue.svg';
import {months} from '../../common/config';
import {
  checkIfTokenPresent,
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {deleteMeal, listMeal} from '../../rest-api/home-api/meal';
import {getMasterData} from '../../rest-api/masterData';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import CardWithHeader from '../common/card-with-header/card-with-header';
import Modal, {modalType} from '../common/modal/modal';
import SuccessMessageSnackbar from '../common/success/success';
import {PlainTextBox} from '../common/textboxes/plain-textbox/plain-textbox';
import DashLayout from '../dash-layout/dash-layout';
import Drawer from '../drawer/drawer';
import MealDropdown from './meal-dropdown';
import './meal.css';

export default function Meal() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [dateValue, setDateValue] = useState(new Date());
  const [isDatePickerOpen, openDatePicker] = useState(false);
  const [isAddMealOpened, openAddMeal] = useState(false);
  const [successMessage, setSuccessful] = useState('');
  const [sizeList, setSizeList] = useState([]);
  const [meal, setMeal] = useState('');
  const [isEditOpen, openEdit] = useState(false);
  const [editObject, setEditObject] = useState({});
  const [mealsInAction, setMealsInAction] = useState([]);
  const [mealIdToDelete, setMealIdToDelete] = useState('');
  const userId = getLocalStorage(localStorageKey.USERID);
  const [isConfirmDeleteOpened, openConfirmDelete] = useState(false);

  const updateDateToCurrentTime = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    dateValue.setHours(currentHour, currentMinute);
  };
  const setTodayAsDate = () => {
    getAllMeal(new Date());
  };

  const Loader = () => (
    <div className="meal-loader">
      <Spinner color="#1a74d4" stroke={4} />
    </div>
  );

  const getMealSizes = () => {
    getMasterData('getMealSize')
      .then((res) => {
        if (res) {
          if (res.statusCode === 200) {
            setSizeList(res.body.data.master_data_list);
          } else if (res.statusCode === 303) {
            deleteAllLocalStorage();
            history.push(routesPath.SIGN_IN);
          }
        } else {
          const message = `meal size is not found`;
          throw new Error(message);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  };

  const NoMeal = () => (
    <div className="no-meal-found">There are no meals to show for this day</div>
  );

  const Content = () => {
    return (
      <>
        {meal === '' ? (
          <Loader />
        ) : (
          <>
            {meal.length > 0 ? (
              <>
                {meal.map((obj, i) => (
                  <MealList obj={obj} key={i} index={i} />
                ))}
              </>
            ) : (
              <NoMeal />
            )}
          </>
        )}
      </>
    );
  };
  const Header = () => {
    return (
      <>
        <div className="meal-header-item">
          <div style={{display: 'flex', width: '50%'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableFuture
                open={isDatePickerOpen}
                value={dateValue}
                onChange={(val) => getAllMeal(val)}
                onClose={() => openDatePicker(false)}
                animateYearScrolling
                TextFieldComponent={() => (
                  <img
                    className="c-pointer"
                    src={Calender}
                    alt="calendar"
                    onClick={() => openDatePicker(!isDatePickerOpen)}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
            <div style={{marginLeft: '5%'}}>
              {months[dateValue.getMonth()]} {dateValue.getDate()}
            </div>
          </div>
          <div
            className="meal-underline c-pointer"
            onClick={() => setTodayAsDate()}
          >
            Today
          </div>
        </div>
      </>
    );
  };

  const getAllMeal = (val) => {
    setDateValue(val);
    const timestamp = new Date(val);
    const body = {
      userId: userId,
      timestamp: timestamp.setHours(0, 0, 0, 0),
    };
    console.log('after: ', val);
    setMeal('');
    listMeal(body)
      .then((res) => {
        if (res.data.mealList.statusCode === 200) {
          setMeal(Array.from(res.data.mealList.body.data));
        }
        console.log(meal);
      })
      .catch((e) => console.error(e));
  };

  const getTimeFormat = (timestamp) => {
    const date = new Date(Number(timestamp));
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (
      ('0' + (hours > 12 ? hours - 12 : hours)).slice(-2) +
      ' : ' +
      ('0' + minutes).slice(-2) +
      (hours > 12 ? ' pm' : ' am')
    );
  };

  const deleteConfirm = (mealId) => {
    setMealIdToDelete(mealId);
    openConfirmDelete(true);
  };

  const deleteItem = () => {
    let temp = Array.from(mealsInAction);
    temp.push(mealIdToDelete);
    setSuccessful('');
    setMealsInAction(Array.from(temp));
    const body = {
      userId: userId,
      mealId: mealIdToDelete,
    };
    deleteMeal(body).then((res) => {
      const {message} = JSON.parse(res.data.deleteMeal.body);
      if (res.data.deleteMeal.statusCode === 200) {
        setSuccessful(message);
        setMeal(meal.filter((m) => m.mealId !== mealIdToDelete));
      }
      setMealsInAction(temp.filter((t) => t !== mealIdToDelete));
    });
  };

  const MealList = (props) => {
    const {obj, index} = props;
    const {mealId, timestamp, details, mealSizeId} = obj;

    return (
      <>
        <div
          className="meal-list-container"
          style={index === meal.length - 1 ? {border: 0} : {}}
        >
          <>
            {mealsInAction.includes(mealId) ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <div className="meal-underline">{getTimeFormat(timestamp)}</div>
                <div className="meal-list-body">
                  {sizeList.length > 0 && (
                    <MealDropdown
                      sizeList={sizeList}
                      size={Number(mealSizeId)}
                      isIconHidden={true}
                    />
                  )}
                  <PlainTextBox
                    value={details}
                    label="No notes for this meal."
                    classes="meal-text-box"
                  />
                </div>
                <div className="d-flex-space-between">
                  <i
                    className="fa fa-pencil edit-meal"
                    onClick={() => {
                      setEditObject(obj);
                      openEdit(true);
                    }}
                  />
                  <img
                    src={Trash}
                    style={{height: 28}}
                    alt="trash-blue"
                    className="c-pointer"
                    onClick={() => deleteConfirm(mealId)}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </>
    );
  };

  const alertConfirmDetails = {
    text: 'Are you sure you want to delete this meal permanently?',
    yes: 'Yes',
    no: 'No',
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    getMealSizes();
    getAllMeal(new Date());
  }, []);

  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      <Modal
        details={alertConfirmDetails}
        type={modalType.ALERT_CONFIRM}
        isOpened={isConfirmDeleteOpened}
        close={() => openConfirmDelete(false)}
        afterSubmitMethod={() => deleteItem()}
      />

      <Modal
        afterSubmitMethod={(value) => {
          setSuccessful(value);
          getAllMeal(dateValue);
        }}
        type={modalType.ADD_MEAL}
        params={{selectedDate: dateValue}}
        close={() => openAddMeal(false)}
        isOpened={isAddMealOpened}
      />

      <Modal
        afterSubmitMethod={(value) => {
          setSuccessful(value);
          getAllMeal(dateValue);
        }}
        type={modalType.EDIT_MEAL}
        params={{object: editObject}}
        close={() => openEdit(false)}
        isOpened={isEditOpen}
      />
      <DashLayout
        bodyClass="drawer-body"
        loggedIn={true}
        children={
          <Drawer
            children={
              <>
                <div className="meal-container">
                  <div className="meal-header">
                    <div className="meal-header-label">
                      <img
                        src={MealMediumIcon}
                        alt="meal-medium-icon"
                        className="meal-icon"
                      />
                      <span style={{marginTop: '5%'}}>Meals</span>
                    </div>
                    <PrimaryButton
                      classes="add-meal-button"
                      label="Add a meal"
                      click={() => {
                        updateDateToCurrentTime();
                        openAddMeal(true);
                      }}
                    />
                  </div>
                  <CardWithHeader Header={Header} Content={Content} />
                </div>
              </>
            }
          />
        }
      />
    </>
  );
}
