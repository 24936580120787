import {UPDATE_DOT_SIGNLE_LINE_WITH_HIGH_LOW} from '../actionTypes';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DOT_SIGNLE_LINE_WITH_HIGH_LOW:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
}
