/* eslint-disable no-use-before-define */
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './searchableDropdown.css';
import {CountryListJson} from '../../../../config/countries';

export default function SearchableDropdown(props) {
  const {value, changeValue, width} = props;
  return (
    <>
      <Autocomplete
        id="combo-box-demo"
        className="searchable-dropdown"
        options={CountryListJson}
        onChange={(e, value) => {
          changeValue(value);
        }}
        value={value}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        style={{width: width}}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Search ..."
            variant="outlined"
          />
        )}
      />
    </>
  );
}
