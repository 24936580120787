import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import OKICON from '../../assets/icons/common/ok.svg';
import Steps from '../../assets/icons/steps/steps.svg';
import {months} from '../../common/config';
import {
  checkIfTokenPresent,
  deleteAllLocalStorage,
} from '../../common/localStorageHandler';
import {getSum} from '../../fake-data-generator/common';
import {
  generateStepMonthData,
  getAreaDailyFooterText,
  getBarDailyFooterText,
  getBarFooterTextInRange,
} from '../../fake-data-generator/steps-walked';
import {getProfileInfo} from '../../rest-api/home-api/profile';
import {routesPath} from '../../router';
import {
  getStepMonthBarData,
  getStepsBarData,
  getStepsWeekBarData,
  getStepYearBarData,
} from '../../steps-data-generator/steps-data-generator';
import CardWithHeader from '../common/card-with-header/card-with-header';
import PlainSwitch from '../common/switches/plain-switch';
import DashLayout from '../dash-layout/dash-layout';
import Drawer from '../drawer/drawer';
import AreaChart from '../graphs/areaChart';
import BarChart from '../graphs/barchart';
import {Loader} from '../health-component/health-common';
import './steps-walked.css';

const timeTypeList = ['Day', 'Week', 'Month', 'Year'];
const headerTimeLabel = ['hour', 'day', 'day', 'month'];

export default function StepWalked() {
  const history = useHistory();
  const [timeType, setTimeType] = useState(timeTypeList[0]);
  const [stepGoal, setStepGoal] = useState(5000);
  const [generatedData, setGeneratedData] = useState('');
  const [previousDayData, setPreviousDayData] = useState('');
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const changeTimeAndUpdate = (back) => {
    let numberOfDaysToGoBack = 0;
    switch (timeType) {
      case timeTypeList[0]:
        numberOfDaysToGoBack = 1;
        break;
      case timeTypeList[1]:
        numberOfDaysToGoBack = 7;
        break;
      case timeTypeList[2]:
        numberOfDaysToGoBack = 30;
        break;
      case timeTypeList[3]:
        numberOfDaysToGoBack = 365;
        break;
      default:
        break;
    }
    if (back) {
      endDate.setDate(new Date(endDate).getDate() - numberOfDaysToGoBack);
    } else {
      endDate.setDate(new Date(endDate).getDate() + numberOfDaysToGoBack);
    }
    getGeneratedData(timeType);
  };

  const getGeneratedData = (tab) => {
    setGeneratedData('');
    switch (tab) {
      case timeTypeList[0]:
        setStartDate(new Date(endDate));
        const prevDate = new Date(endDate);
        prevDate.setDate(endDate.getDate() - 1);
        Promise.all([
          getStepsBarData(endDate, false),
          getStepsBarData(prevDate, false),
        ]).then(([stepsData, prevData]) => {
          setPreviousDayData(prevData);
          setGeneratedData(stepsData);
          console.log('Previous data : ', prevData);
        });
        break;
      case timeTypeList[1]:
        startDate.setDate(endDate.getDate() - 6);
        setStartDate(new Date(startDate));
        getStepsWeekBarData(endDate, false).then((stepsData) => {
          setGeneratedData(stepsData);
        });
        break;
      case timeTypeList[2]:
        startDate.setDate(endDate.getDate() - 29);
        setStartDate(new Date(startDate));
        getStepMonthBarData(endDate, false).then((stepsData) => {
          setGeneratedData(stepsData);
        });
        break;
      case timeTypeList[3]:
        startDate.setDate(endDate.getDate() - 365);
        setStartDate(new Date(startDate));
        getStepYearBarData(endDate, false).then((stepsData) => {
          setGeneratedData(stepsData);
        });
        break;
      default:
        setGeneratedData(generateStepMonthData(endDate));
        break;
    }
  };

  const switchTab = (tab) => {
    endDate.setDate(new Date().getDate());
    startDate.setFullYear(new Date().getFullYear());
    startDate.setMonth(new Date().getMonth());
    startDate.setDate(new Date().getDate());
    setEndDate(new Date());
    setStartDate(new Date());
    setTimeType(tab);
    getGeneratedData(tab);
  };

  const GraphHeader = (props) => {
    const {value, text} = props;
    return (
      <div className="steps-bar-header">
        <div className="steps-bar-value">{value}</div>
        <div className="steps-bar-text">{text}</div>
      </div>
    );
  };

  const GraphLower = (props) => {
    const {text} = props;
    return (
      <div className="graph-lower-container">
        <img src={OKICON} alt="ok-icon" />
        <div className="graph-lower-container-text">{text}</div>
      </div>
    );
  };

  const getHowManyTimesItReached = () => {
    const allValues = generatedData.barData.map((b) => b.value);
    let reachedCount = 0;
    allValues.forEach((value) => {
      if (value >= stepGoal) {
        reachedCount++;
      }
    });
    return reachedCount;
  };

  const compareToPrevData = () => {
    const prevData = previousDayData.barData.map((b) => b.value);
    const currentDayData = generatedData.barData.map((b) => b.value);
    const prevSum = getSum(prevData);
    const currentDaySum = getSum(currentDayData);
    return currentDaySum > prevSum ? currentDaySum - prevSum : 0;
  };

  const getFooterText = () => {
    switch (timeType) {
      case timeTypeList[0]:
        return getBarDailyFooterText(generatedData.barData.map((b) => b.value));
      case timeTypeList[1]:
        return getBarFooterTextInRange(
          stepGoal,
          getHowManyTimesItReached(),
          'in this week'
        );
      case timeTypeList[2]:
        return getBarFooterTextInRange(
          stepGoal,
          getHowManyTimesItReached(),
          'in this month'
        );
      case timeTypeList[3]:
        return getBarFooterTextInRange(
          stepGoal,
          getHowManyTimesItReached(),
          'in this year'
        );

      default:
        break;
    }
  };

  const Content = () => {
    return (
      <>
        {generatedData !== '' ? (
          <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
            <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
              <GraphLower text={getFooterText()} />
              <BarChart data={generatedData} />
              <GraphHeader
                value={generatedData.average}
                text={
                  'average steps per ' +
                  headerTimeLabel[timeTypeList.indexOf(timeType)]
                }
              />
            </div>
            {timeType === timeTypeList[0] && (
              <div style={{borderBottom: '2px solid grey'}}>
                <GraphHeader
                  value={generatedData.sum}
                  text={'steps of ' + (stepGoal ? stepGoal : 5000) + ' goal'}
                />
                <AreaChart data={generatedData} />
                <GraphLower
                  text={getAreaDailyFooterText(
                    compareToPrevData(),
                    endDate.toDateString() === new Date().toDateString()
                  )}
                />
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  };

  const getHeaderDate = () => {
    if (timeType === timeTypeList[0]) {
      const date = new Date(endDate);
      date.setDate(endDate.getDate() + 1);
      if (endDate.toDateString() === new Date().toDateString()) {
        return 'Today';
      } else if (date.toDateString() === new Date().toDateString()) {
        return 'Yesterday';
      }
      return (
        months[endDate.getMonth()].substr(0, 3) +
        ' ' +
        endDate.getDate() +
        ', ' +
        endDate.getFullYear()
      );
    }
    return (
      months[startDate.getMonth()].substr(0, 3) +
      ' ' +
      startDate.getDate() +
      ', ' +
      startDate.getFullYear() +
      ' - ' +
      months[endDate.getMonth()].substr(0, 3) +
      ' ' +
      endDate.getDate() +
      ', ' +
      endDate.getFullYear()
    );
  };
  const Header = () => {
    return (
      <>
        <div className="meal-header-item steps-header-item">
          <div className="steps-walked-header">
            <div className="steps-header-left">
              <i
                className="fa fa-angle-left"
                id="btn_back"
                onClick={() => changeTimeAndUpdate(true)}
              />
              <span className="steps-header-text">{getHeaderDate()}</span>
              {new Date(endDate).toDateString() !==
                new Date().toDateString() && (
                <i
                  className="fa fa-angle-right"
                  id="btn_front"
                  onClick={() => changeTimeAndUpdate(false)}
                />
              )}
            </div>
            <div className="steps-header-right">
              <PlainSwitch
                switchId="switch_steps"
                classes="steps-switch"
                value={timeType}
                changeValue={switchTab}
                switches={timeTypeList}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    switchTab(timeTypeList[0]);
    getProfileInfo()
      .then((res) => {
        const {statusCode, body} = res.data.getUserAllInfo;
        if (statusCode === 200) {
          const {stepGoal} = body.data;
          setStepGoal(stepGoal ? stepGoal : 5000);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        }
      })
      .catch((e) => console.error('Error in profile: ', e));
  }, []);

  return (
    <>
      <DashLayout
        bodyClass="drawer-body"
        loggedIn={true}
        children={
          <Drawer
            children={
              <>
                <div className="meal-container">
                  <div className="meal-header">
                    <div className="steps-header-label">
                      <img src={Steps} alt="steps-icon" className="meal-icon" />
                      <span style={{marginTop: '2%'}}>Steps Walked</span>
                    </div>
                  </div>
                  <CardWithHeader
                    headerClass="steps-card-header"
                    Header={Header}
                    Content={Content}
                  />
                </div>
              </>
            }
          />
        }
      />
    </>
  );
}
