export const getAverage = (array) => {
  let sum = 0;
  if (array.length === 0) {
    return 0;
  }
  array.forEach((value) => {
    sum += value;
  });
  return Math.floor(sum / array.length);
};

export const getHighestLowest = (array) => {
  let highest = array[0] ? array[0] : 0;
  let lowest = array[0] ? array[0] : 0;

  array.forEach((value) => {
    if (highest < value) {
      highest = value;
    }
    if (lowest > value) {
      lowest = value;
    }
  });
  return {
    highest: Math.floor(highest),
    lowest: Math.floor(lowest),
  };
};

export const alphabeticallySorting = (items, fieldName) => {
  if (items && items.length && fieldName && fieldName != '') {
    let sortedItems = items.sort(function (a, b) {
      var nameA = a[fieldName].toUpperCase(); // ignore upper and lowercase
      var nameB = b[fieldName].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return sortedItems;
  } else {
    return [];
  }
};
