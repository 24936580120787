import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import trashIcon from '../../assets/icons/common/trash-blue.svg';
import {isValidEmail} from '../../common/emailHandler';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {deleteContact, editContact} from '../../rest-api/home-api/contact';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import ErrorMessage from '../common/errors/error-message';
import Modal, {modalType} from '../common/modal/modal';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';
import './edit-contact.css';

export default function EditContact(props) {
  const {close, afterSubmitMethod, details} = props;
  const [name, setName] = useState('');
  const [contactType, setContactType] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmiting, setSubmiting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isConfirmDeleteOpened, openConfirmDelete] = useState(false);
  const history = useHistory();

  const editContactHandler = (e) => {
    e.preventDefault();
    setSubmiting(true);
    setErrorMessage('');
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId: userId,
      name: name,
      type: contactType,
      emailId: email,
      phone: phone,
      contactId: details.id,
    };
    editContact(body)
      .then((res) => {
        const {statusCode, body} = res.data.editContact;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          close();
          afterSubmitMethod(message);
          // use to update contact list
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const deleteContactHandler = (e) => {
    setDeleting(true);
    setErrorMessage('');
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId: userId,
      contactId: details.id,
    };
    deleteContact(body)
      .then((res) => {
        const {statusCode, body} = res.data.deleteContact;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          close();
          afterSubmitMethod(message);
          // use to update contact list
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const isSubmitDisable = () => {
    return !(
      name.trim().length &&
      contactType.trim().length &&
      email.trim().length &&
      isValidEmail(email)
    );
  };

  const alertConfirmDetails = {
    text: 'Are you sure you want to delete this contact permanently?',
    yes: 'Yes',
    no: 'No',
  };

  const updatePhone = (value) => {
    if (/^[0-9]+$|^$/g.test(value)) {
      setPhone(value);
    }
  };

  useEffect(() => {
    const {name, emailId, phone, typeId} = details;
    setName(name);
    setEmail(emailId);
    setPhone(phone);
    setContactType(String(typeId));
  }, []);
  return (
    <>
      <Modal
        details={alertConfirmDetails}
        type={modalType.ALERT_CONFIRM}
        isOpened={isConfirmDeleteOpened}
        close={() => openConfirmDelete(false)}
        afterSubmitMethod={(e) => deleteContactHandler(e)}
      />
      <div className="add-contact-container">
        <div className="add-contact-header">
          <div className="add-contact-header-text">Edit a contact</div>
          <i
            className="fa fa-close"
            onClick={close}
            style={{color: 'dodgerblue', cursor: 'pointer'}}
          />
        </div>
        {(isSubmiting || isDeleting) && <LinearProgress indeterminate={true} />}
        <div
          className={
            isSubmiting || isDeleting ? 'pre-authentication-disable-div' : ''
          }
        >
          <div className="edit-info">Edit {name}'s details</div>
          <form onSubmit={editContactHandler}>
            <div className="add-contact-body">
              <div className="add-contact-text-box">
                <PlainTextBoxWithLabel
                  label="Full Name"
                  classes="add-contact-text-box-input"
                  value={name}
                  changeValue={setName}
                />
              </div>
              <div className="add-contact-text-box">
                <div>
                  <b>Type</b>
                </div>
                <DropDown
                  dataType="getContactType"
                  value={contactType}
                  messageClass="add-contact-text-box-input"
                  changeValue={setContactType}
                  classes="add-contact-downdown"
                />
              </div>
              <div className="add-contact-text-box">
                <EmailTextBoxWithLabel
                  purpose="contact"
                  label="Email Address"
                  value=""
                  classes="add-contact-text-box-input"
                  value={email}
                  changeValue={setEmail}
                />
              </div>
              <div className="add-contact-text-box">
                <PlainTextBoxWithLabel
                  isOptional={true}
                  label="Phone Number"
                  classes="add-contact-text-box-input"
                  value={phone}
                  changeValue={updatePhone}
                />
              </div>
              <span className="mt-1">
                Standard message and data rates apply.
              </span>
              <div className="mt-1 contact-delete-container">
                <img
                  src={trashIcon}
                  alt="trash"
                  style={{cursor: 'pointer'}}
                  onClick={() => openConfirmDelete(true)}
                />
                <span
                  className="delete-text-blue"
                  onClick={() => openConfirmDelete(true)}
                >
                  {isDeleting ? 'Deleting contact ...' : 'Delete this contact'}
                </span>
              </div>
              <div className="add-contact-text-box">
                <PrimaryButton
                  disable={isSubmitDisable()}
                  labelInAction={isSubmiting ? 'Saving...' : 'Save Changes'}
                  isInAction={isSubmiting || isDeleting}
                  label="Save Changes"
                  classes="add-contact-downdown"
                  click={editContactHandler}
                />
              </div>
              {errorMessage.length > 0 && (
                <ErrorMessage
                  classes="add-contact-text-box-input"
                  message={errorMessage}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
