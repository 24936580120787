import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  checkIfTokenPresent,
  getLocalStorage,
  localStorageKey,
  setLocalStorage,
} from '../../common/localStorageHandler';
import {
  getWatchSettings,
  saveWatchSettings,
} from '../../rest-api/home-api/settings';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import Modal, {modalType} from '../common/modal/modal';
import SuccessMessageSnackbar from '../common/success/success';
import MaterialSwitch from '../common/switches/material-switch/material-switch';
import DashLayout from '../dash-layout/dash-layout';
import './settings.css';
import VitalParameter, {VITAL_PARAMS_FIELD} from './vital-parameter';
import {MGDL_ID} from '../../utils/glucose';

export default function Settings() {
  const [isOpenedAddFeedback, openAddFeedback] = useState(false);
  const [isAlertStopOpened, openAlertStop] = useState(false);
  const [selectedItemForOff, selectItemForOff] = useState('');
  const [successMessage, setSuccessful] = useState('');
  const initAutoMeasure = getLocalStorage(
    localStorageKey.SETTINGS.AUTO_MEASURE
  );
  const initTrack = getLocalStorage(
    localStorageKey.SETTINGS.TRACK_WHILE_SLEEPING
  );
  const initAdaptive = getLocalStorage(
    localStorageKey.SETTINGS.ADAPTIVE_POWER_SAVING
  );
  const initAutoMeasureInterval = Number(
    getLocalStorage(localStorageKey.SETTINGS.AUTO_MEASURE_INTERVAL)
  );
  const initDeviceMSN = getLocalStorage(localStorageKey.DEVICE_MSN)
    ? getLocalStorage(localStorageKey.DEVICE_MSN)
    : 'Not Connected';
  const [deviceMSN, setDeviceMSN] = useState(initDeviceMSN);
  const [autoMeasure, setAutoMeasure] = useState(false);
  const [track, setTrack] = useState(initTrack === 'true');
  const [adaptive, setAdaptive] = useState(initAdaptive === 'true');
  const [autoMeasureInterval, setAutoMeasureInterval] = useState(5);
  const [weatherUnitValue, setWeatherUnitValue] = useState(2);
  const [bloodGlucoseUnitValue, setBloodGlucoseUnitValue] = useState(MGDL_ID);
  const [extractedItem, toogleExtractItem] = useState('');
  const history = useHistory();
  const userId = getLocalStorage(localStorageKey.USERID);

  const getAboutConfig = (deviceMSN) => {
    const aboutConfig = [
      {
        label: 'App version',
        value: 'Version 1.1',
      },
      {
        label: 'Connected watch',
        value: deviceMSN,
      },
    ];
    return aboutConfig;
  };

  const toogleChangeSettings = (type) => {
    switch (type) {
      case 'auto-measure':
        setAutoMeasure(!autoMeasure);
        console.log('AutoMeasureIntervale: ', autoMeasureInterval);
        saveSettings({
          autoMeasureInterval: autoMeasureInterval,
          autoMeasure: !autoMeasure,
        });
        // setLocalStorage(localStorageKey.SETTINGS.AUTO_MEASURE, !autoMeasure)
        break;
      case 'track':
        setTrack(!track);
        setLocalStorage(localStorageKey.SETTINGS.TRACK_WHILE_SLEEPING, !track);
        break;
      case 'adaptive':
        setAdaptive(!adaptive);
        setLocalStorage(
          localStorageKey.SETTINGS.ADAPTIVE_POWER_SAVING,
          !adaptive
        );
        break;

      default:
        break;
    }
  };

  const changeSettings = (type, value) => {
    if (value) {
      selectItemForOff(type);
      openAlertStop(true);
    } else {
      toogleChangeSettings(type);
    }
  };

  const AboutItem = (props) => {
    const {label, value} = props;
    return (
      <div className="about-item">
        <div className="about-label">{label}</div>
        <div className="about-value">{value}</div>
      </div>
    );
  };

  const About = () => (
    <div>
      {getAboutConfig(deviceMSN).map((obj, i) => (
        <AboutItem label={obj.label} value={obj.value} key={i} />
      ))}
    </div>
  );
  const changeExtractionOfItem = (value) => {
    if (extractedItem === value) {
      toogleExtractItem('');
    } else {
      toogleExtractItem(value);
    }
  };

  const changeAutoMeasureInterval = (value) => {
    setAutoMeasureInterval(value);
    console.log('Value: ', value);
    setLocalStorage(localStorageKey.SETTINGS.AUTO_MEASURE_INTERVAL, value);
    saveSettings({autoMeasure: autoMeasure, autoMeasureInterval: value});
  };

  const changeWeatherUnitValue = (value) => {
    setWeatherUnitValue(value);
    setLocalStorage(localStorageKey.SETTINGS.WEATHER_UNIT, value);
  };

  const changeBloodGlucoseUnitValue = (value) => {
    setBloodGlucoseUnitValue(value);
  };

  const alertConfirmDetails = {
    text: 'Automatic periodic measurement of vital parameters will stop',
    yes: 'Ok',
    no: 'Cancel',
  };

  // const Loader = () => (
  //     <div className="meal-loader">
  //         <Spinner color="#1a74d4" stroke={4} />
  //     </div>
  // )

  const saveSettings = ({autoMeasureInterval, autoMeasure}) => {
    const body = {
      userId: userId,
      autoMeasure: autoMeasure,
      autoMeasureFrequency:
        autoMeasureInterval === '' ? 5 : autoMeasureInterval,
    };
    saveWatchSettings(body).then((res) => {
      if (res.data.saveSettings.statusCode !== 200) {
        initSettings();
      }
    });
  };
  const initSettings = () => {
    getWatchSettings({userId: userId}).then((res) => {
      if (res.data.getSettings.statusCode === 200) {
        const data = res.data.getSettings.body.data;
        const {autoMeasure, autoMeasureFrequency, cgmUnit} = data;
        setAutoMeasure(autoMeasure);
        setAutoMeasureInterval(autoMeasureFrequency);
        setBloodGlucoseUnitValue(cgmUnit);
      }
    });
  };
  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    initSettings();
  }, []);

  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      <Modal
        details={alertConfirmDetails}
        afterSubmitMethod={() => toogleChangeSettings(selectedItemForOff)}
        type={modalType.ALERT_CONFIRM}
        close={() => openAlertStop(false)}
        isOpened={isAlertStopOpened}
      />
      <Modal
        afterSubmitMethod={setSuccessful}
        type={modalType.ADD_FEEDBACK}
        close={() => openAddFeedback(false)}
        isOpened={isOpenedAddFeedback}
      />
      <DashLayout
        bodyClass="settings-body"
        loggedIn={true}
        children={
          <div className="settings-body-container">
            <div className="settings-header">
              <div className="settings-header-text">Settings</div>
              <PrimaryButton
                classes="feedback-button"
                label="Provide Feedback"
                click={() => openAddFeedback(true)}
              />
            </div>
            <div className="settings-container">
              <div className="settings-sub-container overview-card">
                <div className="settings-watch-header">Watch Settings</div>
                <div className="settings-item">
                  <div className="settings-headers-with-switch">
                    <span>Auto-Measure</span>
                    <MaterialSwitch
                      checked={autoMeasure}
                      handleChange={() =>
                        changeSettings('auto-measure', autoMeasure)
                      }
                    />
                  </div>
                  <div className="settings-desc">
                    When Auto-Measure is ON, the watch keeps on measuring vital
                    parameters without any user involvement at an interval set
                    by the user below.
                  </div>
                  <div style={{marginTop: '1%', fontWeight: 'bold'}}>
                    Auto-Measure Interval
                  </div>
                  <DropDown
                    isDisabled={!autoMeasure}
                    dataType="auto-measure"
                    classes="auto-measure-interval"
                    value={autoMeasureInterval}
                    changeValue={changeAutoMeasureInterval}
                  />
                  <div style={{marginTop: '1%', fontWeight: 'bold'}}>
                    Blood Glucose Unit
                  </div>
                  <DropDown
                    dataType="blood-glucose-unit"
                    classes="auto-measure-interval"
                    value={bloodGlucoseUnitValue}
                    changeValue={changeBloodGlucoseUnitValue}
                  />
                  <div style={{marginTop: '1%', fontWeight: 'bold'}}>
                    Weather Unit
                  </div>
                  <DropDown
                    dataType="weather-unit"
                    classes="auto-measure-interval"
                    value={weatherUnitValue}
                    changeValue={changeWeatherUnitValue}
                  />
                </div>
                {/* <div className="settings-item">
                                <div className="settings-headers-with-switch">
                                    <span>
                                        Track while sleeping
                                    </span>
                                    <MaterialSwitch checked={track} handleChange={() => changeSettings('track', track)} />
                                </div>
                                <div className="settings-desc">
                                    Continuous recording of vital parameters during sleep.
                                </div>
                            </div>
                            <div className="settings-item">
                                <div className="settings-headers-with-switch">
                                    <span>
                                        Adaptive power saving
                                    </span>
                                    <MaterialSwitch checked={adaptive} handleChange={() => changeSettings('adaptive', adaptive)} />
                                </div>
                                <div className="settings-desc">
                                    Adapt to an auto-measurement interval based on vital health.
                                </div>
                            </div> */}
                <div className="settings-item" style={{border: 0}}>
                  <div style={{fontWeight: 'bold'}}>Firmware updates</div>
                  <div className="settings-desc">
                    Firmware updates take place automatically at midnight when
                    your device is charging while paired with your mobile phone
                  </div>
                </div>
                {/* <div className="settings-item">
                                <div className="d-flex-space-between">
                                    <div style={{ fontWeight: 'bold' }}>
                                        Device ID#
                                    </div>
                                    <div className="settings-link-button">
                                        Forget device
                                    </div>
                                </div>
                            </div>
                            <div className="settings-link-button" style={{ margin: '2%' }}>
                                Connect new device
                            </div> */}
              </div>

              <div
                className="settings-sub-container overview-card"
                style={{padding: 0}}
              >
                <div
                  className="settings-right-item"
                  onClick={() => changeExtractionOfItem('vital')}
                >
                  <div className="d-flex-space-between">
                    <div style={{fontWeight: 'bold'}}>
                      Vital Parameter Bounds
                    </div>
                    {extractedItem === 'vital' ? (
                      <i className="fa fa-minus" />
                    ) : (
                      <i className="fa fa-plus" />
                    )}
                  </div>
                </div>

                {extractedItem === 'vital' &&
                  Object.keys(VITAL_PARAMS_FIELD).map((key) => (
                    <div className="m-2">
                      <VitalParameter field={VITAL_PARAMS_FIELD[key]} />
                    </div>
                  ))}
                <div
                  className="settings-right-item"
                  onClick={() => changeExtractionOfItem('about')}
                >
                  <div className="d-flex-space-between">
                    <div style={{fontWeight: 'bold'}}>About</div>
                    {extractedItem === 'about' ? (
                      <i className="fa fa-minus" />
                    ) : (
                      <i className="fa fa-plus" />
                    )}
                  </div>
                </div>
                {extractedItem === 'about' && <About />}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
