import {Divider} from '@material-ui/core';
import {useEffect, useState} from 'react';
import INFOICON from '../../assets/icons/common/info.svg';
import OKICON from '../../assets/icons/common/ok.svg';
import {HealthParameterType} from '../../common/config';
import {generateBloodGlucoseBarData} from '../../fake-data-generator/bloodglucoseOther';
import {TIME_TYPE} from '../../fake-data-generator/common';
import {generateHealthAverageByHourData} from '../../health-data-generator/health-multiday-data';
import BarChart from '../graphs/barchart';
import {
  DotDetailsContainer,
  DotDetailsContainerWithHighLow,
} from '../graphs/common';
import ComplexLine from '../graphs/complexLine';
import MultiLineAreaChart from '../graphs/multiLineWithAreaChart';
import {Loader, NoDataContainer} from './health-common';

const OtherOkContainer = (props) => {
  const {inRangeDays, timeType} = props;
  const moreThanPrev = timeType === TIME_TYPE.WEEK ? 1 : 7;

  return (
    <div className="graph-lower-container blood-glucose-time-in-container">
      <img src={OKICON} alt="ok-icon" width={24} height={24} />
      <span style={{marginLeft: '1%', marginRight: '1%'}}>
        {inRangeDays} day(s){' '}
      </span>
      <span style={{fontWeight: '500'}}>
        {' '}
        above 70% benchmark, {moreThanPrev} more than last{' '}
        {String(timeType).toLowerCase()}{' '}
      </span>
    </div>
  );
};

export const OtherSummary = (props) => {
  const {data, setOpenTimeRangeBound, timeType, currentDate, healthType} =
    props;
  const [dailyAverage, setDailyAverage] = useState('');
  const type = String(timeType).toLowerCase();
  const menuItem = [
    {
      value: 0,
      label: `Previous 4 ${type}s`,
    },
    {
      value: 1,
      label: `Previous 3 ${type}s`,
    },
    {
      value: 2,
      label: `Previous 2 ${type}s`,
    },
    {
      value: 3,
      label: `Previous ${type}`,
    },
  ];
  useEffect(() => {
    generateHealthAverageByHourData(
      new Date(currentDate),
      data.noOfDay,
      healthType,
      false
    ).then((res) => {
      console.log('Average health data: ', res);
      setDailyAverage(res);
    });
  }, []);

  const barData = generateBloodGlucoseBarData(data, timeType, healthType);
  const barDataSys = generateBloodGlucoseBarData(data, timeType, healthType, 0);
  const barDataDia = generateBloodGlucoseBarData(data, timeType, healthType, 1);
  let countData = 0;

  data?.multiDaysData
    ?.map((day) => day.eachDayData)
    .forEach((data) => {
      countData += data.length;
    });
  return (
    <>
      {data !== '' ? (
        <>
          {countData !== 0 ? (
            <>
              <ComplexLine data={data} />
              <DotDetailsContainer hasDay={true} type={healthType} />
              <Divider className="thick-black-divider" />
              {healthType === HealthParameterType.BLOOD_PRESSURE ? (
                <>
                  <div className="time-in-range-week">
                    <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                      {' '}
                      Systolic
                    </span>
                  </div>
                  <OtherOkContainer
                    inRangeDays={barDataSys.inRange}
                    timeType={timeType}
                  />
                  <BarChart data={barDataSys} id="sys" />
                  <Divider className="thick-black-divider" />
                  <div className="time-in-range-week">
                    <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                      {' '}
                      Diastolic
                    </span>
                  </div>
                  <OtherOkContainer
                    inRangeDays={barDataDia.inRange}
                    timeType={timeType}
                  />
                  <BarChart data={barDataDia} id="dia" />
                </>
              ) : (
                <>
                  <div className="time-in-range-week">
                    <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                      {' '}
                      % Time in range
                    </span>
                    {healthType === HealthParameterType.BLOOD_GLUCOSE && (
                      <img
                        className="c-pointer"
                        onClick={() => setOpenTimeRangeBound(true)}
                        src={INFOICON}
                        alt="info-icon"
                        width={24}
                        height={24}
                      />
                    )}
                  </div>
                  <OtherOkContainer
                    inRangeDays={barData.inRange}
                    timeType={timeType}
                  />
                  <BarChart data={barData} />
                </>
              )}
              <Divider className="thick-black-divider" />
              <div style={{fontWeight: 'bold', margin: '1%'}}>
                Daily averages by hour
              </div>
              {dailyAverage !== '' ? (
                <>
                  <MultiLineAreaChart
                    id="_multi"
                    timeType={type}
                    data={dailyAverage}
                    menuItem={menuItem}
                  />
                  <DotDetailsContainerWithHighLow type={healthType} />
                </>
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <NoDataContainer />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
