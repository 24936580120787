import {defaultConvertFunction} from './health-common';

export const getGraphUpperLines = (
  range,
  unit,
  convertFunction = defaultConvertFunction
) => {
  return [
    {
      yValue: range[0],
      label: convertFunction(range[0], unit),
      isDotted: true,
    },
    {
      yValue: (range[0] + range[1]) / 2,
      label: convertFunction((range[0] + range[1]) / 2, unit),
      isDotted: false,
    },
    {
      yValue: range[1],
      label: convertFunction(range[1], unit),
      isDotted: true,
    },
  ];
};

export const getGraphLowerLines = () => {
  return [
    {
      yValue: 0,
      label: 'Meals',
      isDotted: false,
    },
    {
      yValue: 10,
      label: 'Fasting',
      isDotted: false,
    },
    {
      yValue: 15,
      label: 'Activity',
      isDotted: false,
    },
    {
      yValue: 30,
      label: '',
      isDotted: false,
    },
  ];
};
