export const httpPostRequest = (url, headers, body) => {
  return fetch(url, {
    method: 'post',
    headers: headers,
    body: body,
  }).then((res) => res.json());
};

export const httpGetRequest = (url, headers) => {
  return fetch(url, {
    method: 'get',
    headers: headers,
  }).then((res) => res.json());
};
