export const getContactsBody = (userId) => {
  const body = {
    query:
      'query contact($userId:Int!){getContact(userId:$userId){statusCode body{success message data{id name emailId phone type typeId}}}}',
    variables: {
      userId: userId,
    },
    operationName: 'contact',
  };
  return JSON.stringify(body);
};

export const getAddContactBody = (object) => {
  const {name, emailId, phone, type, userId} = object;

  const body = {
    query:
      'mutation add($userId:Int,$name:String,$emailId:String,$phone:String,$type:Int){addContact(userId:$userId,name:$name,emailId:$emailId,phone:$phone,type:$type){statusCode body}}',
    variables: {
      userId: userId,
      name: name,
      emailId: emailId,
      phone: phone,
      type: type,
    },
    operationName: 'add',
  };
  return JSON.stringify(body);
};

export const getEditContactBody = (object) => {
  const {name, emailId, phone, type, userId, contactId} = object;

  const body = {
    query:
      'mutation edit($userId:Int,$contactId:Int,$name:String,$emailId:String,$phone:String,$type:Int){editContact(userId:$userId,contactId:$contactId,name:$name,emailId:$emailId,phone:$phone,type:$type){statusCode body}}',
    variables: {
      userId: userId,
      contactId: contactId,
      name: name,
      emailId: emailId,
      phone: phone,
      type: type,
    },
    operationName: 'edit',
  };
  return JSON.stringify(body);
};

export const getDeleteContactBody = (object) => {
  const {userId, contactId} = object;
  const body = {
    query:
      'mutation delete($userId:Int!,$contactId:Int!){deleteContact(userId:$userId,contactId:$contactId){statusCode body}}',
    variables: {
      userId: userId,
      contactId: contactId,
    },
    operationName: 'delete',
  };
  return JSON.stringify(body);
};
