export const getProfileInfoBody = (userId) => {
  const body = {
    query:
      'query user($userId:Int!){getUserAllInfo(userId:$userId){statusCode body{success data{emailId firstName lastName state personalInfo stepGoal deviceId deviceMSN}}}}',
    variables: {
      userId: userId,
    },
  };
  return JSON.stringify(body);
};

export const getProfilePicBody = (object) => {
  const {userId, imgData} = object;
  const body = {
    userId: userId,
    imgData: imgData,
  };

  return JSON.stringify(body);
};

export const getEditAccountInfoBody = (object) => {
  const {userId, firstName, lastName} = object;
  const body = {
    query:
      'mutation MyMutation($firstName: String, $lastName: String, $userId: ID) {editAdminUser(firstName: $firstName, lastName: $lastName, userId: $userId) {body statusCode}}',
    variables: {
      firstName: firstName,
      lastName: lastName,
      userId: userId,
    },
  };

  return JSON.stringify(body);
};

export const getUpdatePasswordBody = (object) => {
  const {userId, newPassword, oldPassword} = object;
  const body = {
    query:
      'mutation MyMutation($oldPassword: String, $newPassword: String, $userId: Int) {resetPassword(newPassword: $newPassword, oldPassword: $oldPassword, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      newPassword: newPassword,
      oldPassword: oldPassword,
    },
  };
  return JSON.stringify(body);
};

export const getUpdateStepGoalBody = (object) => {
  const {userId, stepGoal} = object;
  const body = {
    query:
      'mutation create($userId:Int,$stepGoals:Int){addStepGoals(userId:$userId,stepGoals:$stepGoals){statusCode body}}',
    variables: {
      userId: userId,
      stepGoals: stepGoal,
    },
  };
  return JSON.stringify(body);
};

export const getBloodGroupBody = (userId) => {
  const body = {
    query:
      'query bloodGroup($userId:Int!){getBloodGroup(userId:$userId){statusCode body{success message data{id value}}}}',
    variables: {
      userId: userId,
    },
    operationName: 'bloodGroup',
  };
  return JSON.stringify(body);
};

export const getKnownDiseaseBody = (userId) => {
  const body = {
    query:
      'query disease($userId:Int!){getKnownDisease(userId:$userId){statusCode body{success message data{id value others}}}}',
    variables: {
      userId: userId,
    },
    operationName: 'disease',
  };
  return JSON.stringify(body);
};

export const getAddBloodGroupBody = (object) => {
  const {userId, bloodGroup} = object;
  const body = {
    query:
      'mutation create($userId:Int,$bloodGroup:Int){addBloodGroup(userId:$userId,bloodGroup:$bloodGroup){statusCode body}}',
    variables: {
      userId: userId,
      bloodGroup: bloodGroup,
    },
  };
  return JSON.stringify(body);
};

export const getAddKnownDiseaseBody = (object) => {
  const {userId, knownDisease, otherDisease} = object;
  const body = {
    query:
      'mutation create($userId:Int,$knownDisease:[Int],$otherDisease:String){addKnownDisease(userId:$userId,knownDisease:$knownDisease,otherDisease:$otherDisease){statusCode body}}',
    variables: {
      userId: userId,
      knownDisease: knownDisease,
      otherDisease: otherDisease,
    },
  };
  return JSON.stringify(body);
};

export const getUpdatePersonalInfoBody = (obj) => {
  const {userId, countryCode, ...restProps} = obj;
  const body = {
    query:
      'mutation MyMutation($birthDay: String, $country: String, $ethnicityId: ID, $gender: String, $height: String, $patientId: ID, $unitOfMeasurement: String, $weight: String, $skinToneId: ID) {' +
      '     editUser(birthDay: $birthDay, country: $country, ethnicityId: $ethnicityId, gender: $gender, height: $height, patientId: $patientId, unitOfMeasurement: $unitOfMeasurement, weight: $weight, skinToneId: $skinToneId) {body statusCode}}',
    variables: {
      country: countryCode,
      ...restProps,
    },
  };
  return JSON.stringify(body);
};
