import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as d3 from 'd3';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import SMALL_HIGHEST_ICON from '../../assets/icons/graph/small-highest.svg';
import SMALL_LOWEST_ICON from '../../assets/icons/graph/small-lowest.svg';
import {HealthParameterType} from '../../common/config';
import {capitalizeFirstLetter} from '../../common/stringHandler';
import {
  UPDATE_DOT_SIGNLE_LINE_WITH_HIGH_LOW,
  UPDATE_DOT_WITH_HIGH_LOW,
} from '../../redux/actionTypes';
import {getSvgWidth} from './common';
import appendSingleTooltip from './singleTooltip';

export default function MultiLineAreaChart(props) {
  const height = window.screen.height;
  const {data, menuItem, timeType, id, isSingleLine} = props;
  const {
    xLabels,
    graphLow,
    graphHigh,
    complexScatterY,
    graphLines,
    previousLinesData,
    yLabel,
    requestedDate,
    currentLine,
    type,
  } = data;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(menuItem[0]);
  const svgHeight = height * 0.42;
  const marginLeft = 10;
  const max = xLabels.length;

  const chartId = 'multi-line-area-chart' + (id ? id : '');
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (obj) => {
    if (obj !== null) {
      setSelectedMenu(obj);
      hideOrDisplayLines(obj.value);
    }

    setAnchorEl(null);
  };

  const hideOrDisplayLines = (noOflineToshow) => {
    const numberOfLines = previousLinesData.length;
    for (let i = 0; i < numberOfLines; i++) {
      d3.select('#complex-scatter-line' + i).style('display', 'none');
    }
    for (let i = 0; i < numberOfLines - noOflineToshow; i++) {
      d3.select('#complex-scatter-line' + i).style('display', 'block');
    }
  };

  const y = d3
    .scaleLinear()
    .domain([30, 200])
    .range([height * 0.4, 0]);

  const dispatch = useDispatch();

  const findTheClosest = (value, data) => {
    const xValues = data.map((a) => a.x);
    const closest = xValues.reduce(function (prev, curr) {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
    console.log('The closest point of multi line: ', closest);
    return closest;
  };

  const createLabel = (svg, value, i) => {
    svg
      .append('text')
      .text(value)
      .attr('x', getScaleXValue(getSvgWidth(svg), i))
      .attr('y', svgHeight - 2)
      .style('font-size', 'small');
  };

  const createMarginTopLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createMarginBottomLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', svgHeight)
      .attr('y2', svgHeight)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createGraphLineParrelToX = (svg, yValue, isDotted) => {
    svg
      .append('line')
      .attr('x1', getScaleXValue(getSvgWidth(svg), 0))
      .attr('x2', getScaleXValue(getSvgWidth(svg), max))
      .attr('y1', y(yValue))
      .attr('y2', y(yValue))
      .attr('stroke-width', 0.4)
      .style('stroke-dasharray', isDotted ? '5,5' : '')
      .style('stroke', isDotted ? '#f86363' : 'black')
      .attr('shape-rendering', 'crispEdges');
  };

  const createLineText = (svg, yValue, label, xOffset) => {
    svg
      .append('text')
      .text(label)
      .attr('x', getScaleXValue(getSvgWidth(svg), 0) - xOffset)
      .attr('y', y(yValue) + 2)
      .style('font-size', 'small')
      .style('text-align', 'flex-end');
  };

  const createGraphUpperLine = (svg, obj) => {
    const {yValue, label, isDotted} = obj;
    createGraphLineParrelToX(svg, complexScatterY(yValue), isDotted);
    createLineText(svg, complexScatterY(yValue), label, marginLeft * 2);
  };

  const getScaleXValue = (width, data) => {
    const x = d3
      .scaleLinear()
      .domain([0, max])
      .range([width * 0.1, width * 0.98]);
    return x(data);
  };

  const getScaleXValueReverse = (width, data) => {
    const x = d3
      .scaleLinear()
      .domain([0, max])
      .range([width * 0.1, width * 0.98]);
    return x.invert(data);
  };

  const valueline = (value, width, index) => {
    const line = d3
      .line()
      .x(function (d) {
        return getScaleXValue(width, d.x);
      })
      .y(function (d) {
        return y(
          complexScatterY(
            type === HealthParameterType.BLOOD_PRESSURE ? d.y[index] : d.y
          )
        );
      });

    return line(value);
  };

  const createMultiStandardLine = (svg, data, index, tooltipLowerText) => {
    const numberOfLines = previousLinesData.length;
    const line = svg
      .append('g')
      .data(data)
      .attr('id', 'complex-scatter-line' + index);

    line
      .append('path')
      .attr('id', 'complex-scatter-upper-line' + index)
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg), 0));

    line
      .append('path')
      .attr('id', 'complex-scatter-lower-line' + index)
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg), 1));

    line
      .on('mouseover', (e, d) => {
        d3.select('#complex-scatter-upper-line' + index)
          .style('stroke', '#1a74d4')
          .attr('stroke-width', 2);
        d3.select('#complex-scatter-lower-line' + index)
          .style('stroke', '#1a74d4')
          .attr('stroke-width', 2);
        const xValue = findTheClosest(
          getScaleXValueReverse(getSvgWidth(svg), d3.pointer(e)[0]),
          data
        );
        console.log(
          'Line data in mouseover: ',
          data.filter((d) => d.x === xValue)[0]
        );
        const dataObject = data.filter((d) => d.x === xValue)[0];
        appendSingleTooltip({
          svg: svg,
          texts: {
            upper: capitalizeFirstLetter(timeType) + ' of',
            lower: tooltipLowerText,
          },
          cordinates: {
            x: getScaleXValue(getSvgWidth(svg), dataObject.x),
            y: y(complexScatterY(dataObject.y[0])),
          },
        });
      })
      .on('mouseout', () => {
        d3.select('#single-tooltip').remove();
        for (let i = 0; i < numberOfLines; i++) {
          d3.select('#complex-scatter-upper-line' + i)
            .style('stroke', 'gray')
            .attr('stroke-width', 1.5);
          d3.select('#complex-scatter-lower-line' + i)
            .style('stroke', 'gray')
            .attr('stroke-width', 1.5);
        }
      });

    return line;
  };

  const createStandardLine = (svg, data, index, tooltipLowerText) => {
    const numberOfLines = previousLinesData.length;
    const line = svg
      .append('path')
      .datum(data)
      .attr('id', 'complex-scatter-line' + index)
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg), 0))
      .on('mouseover', (e, d) => {
        d3.select('#complex-scatter-line' + index)
          .style('stroke', '#1a74d4')
          .attr('stroke-width', 2);
        const xValue = findTheClosest(
          getScaleXValueReverse(getSvgWidth(svg), d3.pointer(e)[0]),
          d
        );
        const data = d.filter((data) => data.x === xValue)[0];
        appendSingleTooltip({
          svg: svg,
          texts: {
            upper: capitalizeFirstLetter(timeType) + ' of',
            lower: tooltipLowerText,
          },
          cordinates: {
            x: getScaleXValue(getSvgWidth(svg), data.x),
            y: y(complexScatterY(data.y)),
          },
        });
      })
      .on('mouseout', () => {
        d3.select('#single-tooltip').remove();
        for (let i = 0; i < numberOfLines; i++) {
          d3.select('#complex-scatter-line' + i)
            .style('stroke', 'gray')
            .attr('stroke-width', 1.5);
        }
      });

    return line;
  };

  const createTooltip = (svg, data) => {
    const g = svg
      .selectAll('circle')
      .attr('id', '#multi-line-tooltip')
      .data(data)
      .enter()
      .append('g')
      .style('z-index', 999)
      .style('display', 'none');

    g.append('line')
      .attr('x1', (d, i) => Number(getScaleXValue(getSvgWidth(svg), d.x)))
      .attr('x2', (d, i) => Number(getScaleXValue(getSvgWidth(svg), d.x)))
      .attr('y1', y(30))
      .attr('y2', y(200))
      .attr('stroke-width', 2)
      .style('stroke', '#1a74d4')
      .style('z-index', -1)
      .attr('shape-rendering', 'crispEdges');

    if (type === HealthParameterType.BLOOD_PRESSURE) {
      g.append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
        .style('fill', 'none')
        .style('stroke', '#1a74d4')
        .style('stroke-width', 2)
        .attr('r', 8)
        .attr('display', (d, i) => (d.y[0] > graphHigh ? 'none' : 'block'));

      g.append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
        .style('fill', 'none')
        .style('stroke', '#1a74d4')
        .style('stroke-width', 2)
        .attr('r', 8)
        .attr('display', (d, i) => (d.y[1] < graphLow ? 'none' : 'block'));

      g.append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .style('z-index', 1)
        .attr('stroke', 'grey')
        .attr('fill', 'white')
        .style('height', 20)
        .style('width', 40)
        .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 20)
        .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 40);

      g.append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .style('z-index', 1)
        .attr('stroke', 'grey')
        .attr('fill', 'white')
        .style('height', 20)
        .style('width', 40)
        .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 20)
        .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 20);

      g.append('text')
        .text('SYS')
        .style('font-weight', 'bold')
        .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 12)
        .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 25)
        .attr('display', (d, i) => (d.y[0] > graphHigh ? 'none' : 'block'));

      g.append('text')
        .text('DIA')
        .style('font-weight', 'bold')
        .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 12)
        .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 35)
        .attr('display', (d, i) => (d.y[1] < graphLow ? 'none' : 'block'));
    } else {
      g.append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y)))
        .style('fill', 'none')
        .style('stroke', '#1a74d4')
        .style('stroke-width', 2)
        .attr('r', 8);
    }

    if (isSingleLine) {
      if (type === HealthParameterType.BLOOD_PRESSURE) {
        g.append('circle')
          .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
          .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
          .style('fill', (d, i) => d.color[0])
          .style('stroke', (d, i) => d.stroke[0])
          .attr('r', 4)
          .attr('display', (d, i) => (d.y[0] < graphHigh ? 'none' : 'block'));

        g.append('circle')
          .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
          .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
          .style('fill', (d, i) => d.color[1])
          .style('stroke', (d, i) => d.stroke[1])
          .attr('r', 4)
          .attr('display', (d, i) => (d.y[1] < graphLow ? 'none' : 'block'));
      } else {
        g.append('circle')
          .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
          .attr('cy', (d, i) => y(complexScatterY(d.y)))
          .style('fill', (d, i) => d.color)
          .style('stroke', (d, i) => d.stroke)
          .attr('r', 4);
      }
    }

    g.append('image')
      .attr('xlink:href', SMALL_HIGHEST_ICON)
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 5)
      .attr('y', (d, i) => y(complexScatterY(d.highest)) - 5);

    g.append('image')
      .attr('xlink:href', SMALL_LOWEST_ICON)
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 5)
      .attr('y', (d, i) => y(complexScatterY(d.lowest)) - 5);
    return g;
  };

  const filledArea = (value, width) => {
    const area = d3
      .area()
      .x(function (d) {
        return getScaleXValue(width, d.x);
      })
      .y0((d) => y(complexScatterY(d.highest)))
      .y1(function (d) {
        return y(complexScatterY(d.lowest));
      });
    return area(value);
  };

  const createAreaOfHighLow = (svg, data) => {
    svg
      .append('path')
      .datum(data)
      .style('fill', 'grey')
      .style('opacity', 0.2)
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', filledArea(data, getSvgWidth(svg)));
  };

  const createMultiLineWithCircle = (svg, data) => {
    const line = svg
      .append('g')
      .datum(data)
      .attr('id', 'multi-line-area-current-line');

    line
      .append('path')
      .attr('stroke', 'black')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg), 0));

    line
      .append('path')
      .attr('stroke', 'black')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg), 1));

    if (!isSingleLine) {
      line
        .append('g')
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
        .style('fill', (d, i) => d.color[0])
        .style('stroke', (d, i) => d.stroke[0])
        .attr('r', 4)
        .style('cursor', 'pointer')
        .style('display', (d, i) =>
          d.y[1] > graphLow && d.y[0] < graphHigh ? '' : 'none'
        );

      line
        .append('g')
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
        .style('fill', (d, i) => d.color[1])
        .style('stroke', (d, i) => d.stroke[1])
        .attr('r', 4)
        .style('cursor', 'pointer')
        .style('display', (d, i) =>
          d.y[1] > graphLow && d.y[0] < graphHigh ? '' : 'none'
        );
    }
    return line;
  };

  const createLineWithCircle = (svg, data) => {
    const line = svg
      .append('path')
      .datum(data)
      .attr('id', 'multi-line-area-current-line')
      .attr('stroke', 'black')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRectMulti")')
      .attr('d', valueline(data, getSvgWidth(svg)));

    if (!isSingleLine) {
      svg
        .append('g')
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
        .attr('cy', (d, i) => y(complexScatterY(d.y)))
        .style('fill', (d, i) => d.color)
        .style('stroke', (d, i) => d.stroke)
        .attr('r', 4)
        .style('cursor', 'pointer')
        .style('display', (d, i) =>
          d.y > graphLow && d.y < graphHigh ? '' : 'none'
        );
    }
    return line;
  };

  const drawMultiLineAreaChart = () => {
    d3.select('#' + chartId + '-svg').remove();

    const svg = d3
      .select('#' + chartId)
      .append('svg')
      .attr('id', chartId + '-svg')
      .attr('width', '100%')
      .attr('height', svgHeight);

    svg
      .append('clipPath')
      .attr('id', 'clipRectMulti')
      .append('rect')
      .attr('x', 0)
      .attr('y', y(complexScatterY(graphHigh)))
      .attr('width', '100%')
      .attr(
        'height',
        y(complexScatterY(graphLow)) - y(complexScatterY(graphHigh))
      );
    createMarginTopLine(svg);
    createMarginBottomLine(svg);

    xLabels.forEach((d, i) => {
      createLabel(svg, d, i);
    });

    graphLines.forEach((obj) => {
      createGraphUpperLine(svg, obj);
    });
    createAreaOfHighLow(svg, currentLine?.hourData);

    if (!isSingleLine) {
      previousLinesData.forEach((previousLine, i) => {
        console.log('Previous line data: ', i, previousLine);
        if (type === HealthParameterType.BLOOD_PRESSURE) {
          createMultiStandardLine(
            svg,
            previousLine?.hourData,
            i,
            previousLine.tooltipLowerText
          );
        } else {
          createStandardLine(
            svg,
            previousLine?.hourData,
            i,
            previousLine.tooltipLowerText
          );
        }
      });
    }
    let line = '';
    let tooltip = '';
    const filteredMultiData = currentLine?.hourData.filter(
      (data) => data.y[1] > graphLow && data.y[1] < graphHigh
    );
    const filteredData = currentLine?.hourData.filter(
      (data) => data.y > graphLow && data.y < graphHigh
    );
    if (isSingleLine) {
      line =
        type === HealthParameterType.BLOOD_PRESSURE
          ? createMultiLineWithCircle(svg, currentLine?.hourData)
          : createLineWithCircle(svg, currentLine?.hourData);
      tooltip = createTooltip(
        svg,
        type === HealthParameterType.BLOOD_PRESSURE
          ? filteredMultiData
          : filteredData
      );
    } else {
      tooltip = createTooltip(
        svg,
        type === HealthParameterType.BLOOD_PRESSURE
          ? filteredMultiData
          : filteredData
      );
      line =
        type === HealthParameterType.BLOOD_PRESSURE
          ? createMultiLineWithCircle(svg, currentLine?.hourData)
          : createLineWithCircle(svg, currentLine?.hourData);
    }

    line.on('mouseover', (e, data) => {
      const xValue = findTheClosest(
        getScaleXValueReverse(getSvgWidth(svg), d3.pointer(e)[0]),
        currentLine?.hourData
      );
      const selectedDotWithHighLow = data.filter((d) => d.x === xValue)[0];
      if (isSingleLine) {
        dispatch({
          type: UPDATE_DOT_SIGNLE_LINE_WITH_HIGH_LOW,
          payload: selectedDotWithHighLow,
        });
      } else {
        dispatch({
          type: UPDATE_DOT_WITH_HIGH_LOW,
          payload: selectedDotWithHighLow,
        });
      }

      tooltip
        .filter((c) => {
          return c.x === xValue;
        })
        .style('display', 'block');
      tooltip
        .filter((c) => {
          return c.x !== xValue;
        })
        .style('display', 'none');
    });
    if (isSingleLine) {
      dispatch({
        type: UPDATE_DOT_SIGNLE_LINE_WITH_HIGH_LOW,
        payload: currentLine?.hourData[currentLine?.hourData.length - 1],
      });
    } else {
      dispatch({
        type: UPDATE_DOT_WITH_HIGH_LOW,
        payload: currentLine?.hourData[currentLine?.hourData.length - 1],
      });
    }
    tooltip
      .filter((c) => {
        return (
          c.x === currentLine?.hourData[currentLine?.hourData.length - 1].x
        );
      })
      .style('display', 'block');
    svg
      .append('text')
      .text(yLabel)
      .attr('x', '2%')
      .attr('y', y(130))
      .style('writing-mode', 'vertical-rl')
      .style('font-weight', 'bold');
  };

  if (!d3.select('#' + chartId + '-svg').empty()) {
    d3.select(window).on('resize.svg' + chartId, () => {
      drawMultiLineAreaChart(false);
      hideOrDisplayLines(selectedMenu.value);
    });
  }

  useEffect(() => {
    drawMultiLineAreaChart(true);
  }, []);

  return (
    <>
      {!isSingleLine && (
        <div className="d-flex w-100">
          <div className="graph-line" style={{borderColor: 'black'}}></div>
          <span className="mlr-1">This {timeType}</span>
          <div className="graph-line" style={{borderColor: '#8c969b'}}></div>
          <div className="mlr-1 d-flex" onClick={openMenu}>
            <span className="c-pointer">{selectedMenu.label}&nbsp;</span>
            <i className="fa fa-angle-down" style={{marginTop: '3%'}} />
          </div>
        </div>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleCloseMenu(null)}
      >
        {menuItem.map((obj) => (
          <MenuItem
            selected={obj.value === selectedMenu.value}
            onClick={() => handleCloseMenu(obj)}
          >
            {obj.label}
          </MenuItem>
        ))}
      </Menu>

      <div id={chartId} style={{width: '100%', marginTop: '0.5%'}}></div>
    </>
  );
}
