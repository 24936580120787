import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {isValidEmail} from '../../common/emailHandler';
import {
  checkIfTokenPresent,
  deleteAllLocalStorage,
} from '../../common/localStorageHandler';
import {STORE_SIGN_IN_INFO} from '../../redux/actionTypes';
import {doLogin, getUserState} from '../../rest-api/user-api/sign-in';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import ErrorMessage from '../common/errors/error-message';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import PasswordTextBoxWithLabel from '../common/textboxes/password-textbox/password-textbox';
import {navigateToPage} from './tools';

export default function SignIn(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSigning, setSigning] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let signInData = {
    apiKeyGpl: '',
    apiKeyEureka: '',
    jwtToken: '',
    userId: '',
    firstName: '',
    lastName: '',
  };

  const checkIfSubmitDisable = () => {
    if (email.trim().length && password.trim().length && isValidEmail(email)) {
      return false;
    }
    return true;
  };

  const doSignIn = (event) => {
    setSigning(true);
    setErrorMessage('');
    if (event) event.preventDefault();
    doLogin(email, password)
      .then((res) => {
        console.log('Response: ', res);
        if (res === null) {
          throw new Error('SERVER_ERROR');
        }
        if (res.statusCode === 200) {
          const {
            token,
            apiKeyGPL,
            apiKeyEureka,
            userId,
            profilePicUrl,
            fuseBillCustomerToken,
            firstName,
            lastName,
            emailId,
          } = res.data;
          if (!apiKeyGPL) {
            throw new Error('Api key unavailable');
          }

          signInData = {
            apiKeyGpl: apiKeyGPL,
            apiKeyEureka: apiKeyEureka,
            jwtToken: token,
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            profileUrl: profilePicUrl,
            emailId: emailId,
            fuseBillCustomerToken,
          };
          dispatch({
            type: STORE_SIGN_IN_INFO,
            payload: signInData,
          });
          return [userId, apiKeyEureka, token];
        } else {
          const {message} = JSON.parse(res.body);
          throw new Error(message);
        }
      })
      .then(([userId, apiKeyEureka, token]) =>
        getUserState(userId, apiKeyEureka, token)
      )
      .then((res) => {
        console.log('user State : ', res);
        const response = res.data.getUserState;
        if (response.statusCode === 200) {
          navigateToPage(response.body.data.userState, signInData, history);
        } else {
          const {message} = JSON.parse(res.body);
          throw new Error(message);
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.message === 'SERVER_ERROR') {
          doLogin();
        }
        setErrorMessage(e.message);
      })
      .then(() => {
        setSigning(false);
      });
  };

  useEffect(() => {
    if (checkIfTokenPresent()) {
      history.push(routesPath.HOME_PAGE);
    } else {
      deleteAllLocalStorage();
    }
  }, [history]);

  return (
    <>
      <PreAuthContainer
        children={
          <>
            <div className="pre-authentication-header">
              Sign in to your {process.env.REACT_APP_WEBSITE_NAME} account
            </div>
            {isSigning && (
              <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
            )}
            <div className={isSigning ? 'pre-authentication-disable-div' : ''}>
              <form onSubmit={doSignIn}>
                <div className="pre-authentication-textbox-container">
                  <EmailTextBoxWithLabel
                    inputId="text-email"
                    label="Email"
                    value={email}
                    changeValue={setEmail}
                    isDisabled={isSigning}
                  />
                </div>
                <div className="pre-authentication-textbox-container">
                  <PasswordTextBoxWithLabel
                    inputId="text-password"
                    label="Password"
                    value={password}
                    changeValue={setPassword}
                    isDisabled={isSigning}
                  />
                </div>

                <div className="pre-authentication-forget-password">
                  <Link
                    to="/forgot-password"
                    className="no-underline"
                    id="btn_forget_password"
                  >
                    <u
                      className="font-rajdhani"
                      style={{cursor: 'pointer', fontSize: 'medium'}}
                    >
                      <b>Forget Password?</b>
                    </u>
                  </Link>
                </div>
                <div className="pre-authentication-button-container">
                  <PrimaryButton
                    inputId="btn_sign_in"
                    label="Sign in"
                    labelInAction="Signing in..."
                    isInAction={isSigning}
                    disable={checkIfSubmitDisable()}
                    click={doSignIn}
                  />
                </div>
              </form>
              <div className="signup-switch-container font-rajdhani">
                Don't have an account?{' '}
                <Link id="btn_switch_to_sign_up" to="/create-account">
                  <u className="signup-link">Sign up</u>
                </Link>
              </div>
            </div>
            {errorMessage.length > 0 && <ErrorMessage message={errorMessage} />}
          </>
        }
      />
    </>
  );
}
