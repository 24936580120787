export const MGDL_ID = 1;
export const MMOL_ID = 0;

export const GLUCOSE_UNIT_MMOL = 'mmol/l';
export const GLUCOSE_UNIT_MGDL = 'mg/dL';

export function convertToMmOl(val) {
  return Math.round((val / 18) * 10) / 10;
}

/**
 * @mgDlValue - number value
 * @cgmUnit - should be of IDS: MGDL_ID /MMOL_ID
 */
export function getBloodGlucose(mgDlValue, cgmUnit) {
  const unitLabel = cgmUnit === MGDL_ID ? GLUCOSE_UNIT_MGDL : GLUCOSE_UNIT_MMOL;
  const value = cgmUnit === MGDL_ID ? mgDlValue : convertToMmOl(mgDlValue);
  return {value, unit: unitLabel};
}

export function getBloodGlucoseValue(mgDlValue, cgmUnit) {
  return cgmUnit === MGDL_ID ? mgDlValue : convertToMmOl(mgDlValue);
}

export function getBloodGlucoseLabel(cgmUnit) {
  return cgmUnit === MGDL_ID ? GLUCOSE_UNIT_MGDL : GLUCOSE_UNIT_MMOL;
}
