import React from 'react';
import './drawer.css';
import ArrowBack from '../../assets/icons/drawer/arrow.svg';
import BloodGlucose from '../../assets/icons/drawer/blood-glucose.svg';
import HealthSymptoms from '../../assets/icons/drawer/health-symptoms.svg';
import BloodGlucoseActive from '../../assets/icons/drawer/blood-glucose-black.svg';
import HeartRateActive from '../../assets/icons/drawer/rate-black.svg';
import HealthSymptomsActive from '../../assets/icons/drawer/health-symptoms-active.svg';
import MealActive from '../../assets/icons/drawer/meal-black.svg';
import StepActive from '../../assets/icons/drawer/steps-black.svg';
import Oxygen from '../../assets/icons/drawer/oxygen.svg';
import OxygenActive from '../../assets/icons/drawer/oxygen-black.svg';
import Pulse from '../../assets/icons/drawer/pulse.svg';
import PulseActive from '../../assets/icons/drawer/pulse-black.svg';
import Rate from '../../assets/icons/drawer/rate.svg';
import Respiration from '../../assets/icons/drawer/respiration.svg';
import RespirationActive from '../../assets/icons/drawer/respiration-black.svg';
import Steps from '../../assets/icons/drawer/steps.svg';
import Meal from '../../assets/icons/drawer/meal.svg';
import {useHistory} from 'react-router-dom';
import {routesPath} from '../../router';

export default function Drawer(props) {
  const {children} = props;
  const history = useHistory();
  const checkIfActive = (path) => {
    return history.location.pathname === path;
  };

  const drawerItem = [
    {
      label: 'Blood Glucose',
      icon: BloodGlucose,
      path: routesPath.BLOOD_GLUCOSE,
      activeIcon: BloodGlucoseActive,
    },
    {
      label: 'Heart Rate',
      icon: Rate,
      path: routesPath.HEART_RATE,
      activeIcon: HeartRateActive,
    },
    {
      label: 'Blood Pressure',
      icon: Pulse,
      path: routesPath.BLOOD_PRESSURE,
      activeIcon: PulseActive,
    },
    {
      label: 'Respiration Rate',
      icon: Respiration,
      path: routesPath.RESPIRATION_RATE,
      activeIcon: RespirationActive,
    },
    {
      label: 'Oxygen Saturation',
      icon: Oxygen,
      path: routesPath.OXYGEN_SATURATION,
      activeIcon: OxygenActive,
    },
    {
      label: 'Steps Walked',
      icon: Steps,
      activeIcon: StepActive,
      path: routesPath.STEPS_WALKED,
    },
    {
      label: 'Meals',
      icon: Meal,
      activeIcon: MealActive,
      path: routesPath.MEALS,
    },
    {
      label: 'Health Symptoms',
      icon: HealthSymptoms,
      activeIcon: HealthSymptomsActive,
      path: routesPath.HEALTH_SYMPTOMS,
    },
  ];

  const DrawerItem = (props) => {
    const {obj} = props;
    const {label, icon, path, activeIcon} = obj;
    return (
      <>
        <div
          className={
            (checkIfActive(path) ? 'drawer-item-active' : '') + ' drawer-item'
          }
          onClick={() => history.push(path)}
        >
          <img
            src={checkIfActive(path) ? activeIcon : icon}
            alt="icons"
            style={{marginLeft: '15%'}}
          />
          <div className="drawer-item-text">{label}</div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="left-side-drawer">
        <div
          className="drawer-item drawer-dashboard"
          onClick={() => history.push(routesPath.HOME_PAGE)}
        >
          <img src={ArrowBack} alt="icons" style={{marginLeft: '15%'}} />
          <div className="drawer-item-text">Dashboard</div>
        </div>
        {drawerItem.map((obj, i) => (
          <DrawerItem key={i} obj={obj} />
        ))}
      </div>
      <div className="drawer-children">{children}</div>
    </>
  );
}
