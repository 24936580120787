import {Snackbar} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import './success.css';

export default function SuccessMessageSnackbar(props) {
  const {message} = props;
  const [isShown, setShown] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShown(false);
    }, 3000);
  }, []);
  return (
    <>
      {isShown && (
        <div className="success-message">
          <i className="fa fa-check" style={{marginRight: '2%'}}></i>
          {message}
        </div>
      )}
    </>
  );
}

export function MatSuccessSnackBar(props) {
  const {message, open, close} = props;
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={close}>
        <div className="success-message">
          <i className="fa fa-check" style={{marginRight: '2%'}}></i>
          {message}
        </div>
      </Snackbar>
    </>
  );
}
