import React, {useEffect, useState} from 'react';
import './dropdown.css';
import {getMasterData} from '../../../rest-api/masterData';
import {deleteAllLocalStorage} from '../../../common/localStorageHandler';
import {useHistory} from 'react-router-dom';
import {routesPath} from '../../../router';
import MealLight from '../../../assets/icons/meal/meal-light-black.svg';
import MealModerate from '../../../assets/icons/meal/meal-moderate-black.svg';
import MealHeavy from '../../../assets/icons/meal/meal-heavy-black.svg';
import {
  GLUCOSE_UNIT_MGDL,
  GLUCOSE_UNIT_MMOL,
  MGDL_ID,
  MMOL_ID,
} from '../../../utils/glucose';

const getIconWithLabel = (size) => {
  switch (size) {
    case 1:
      return MealLight;
    case 2:
      return MealModerate;
    case 3:
      return MealHeavy;
    default:
      break;
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export default function DropDown(props) {
  const {
    dataType,
    inputId,
    value,
    category,
    changeValue,
    classes,
    messageClass,
    isArrowHidden,
    isDisabled,
  } = props;
  const [optionList, setOptionList] = useState([]);
  const [errorMesage, setErrorMesage] = useState('');
  const history = useHistory();
  const id =
    'dropdown_' +
    String(category ? category : dataType)
      .split(' ')
      .join('_')
      .toLowerCase();

  const generateObjectList = (from, to, itr) => {
    let list = [];
    for (let i = from; i <= to; i += itr) {
      list.push({id: i, value: numberWithCommas(i)});
    }
    return list;
  };

  const autoMeasureIntervalList = [
    {
      id: 5,
      value: '5 min',
    },
    {
      id: 10,
      value: '10 min',
    },
    {
      id: 15,
      value: '15 min',
    },
    {
      id: 20,
      value: '20 min',
    },
    {
      id: 30,
      value: '30 min',
    },
    {
      id: 60,
      value: '60 min',
    },
  ];

  const weatherUnitList = [
    {
      id: 1,
      value: 'Imperial (Fahrenheit - Mile)',
    },
    {
      id: 2,
      value: 'Metric (Celsius - Kilometer)',
    },
  ];

  const bloodGlucoseUnitList = [
    {
      id: MGDL_ID,
      value: GLUCOSE_UNIT_MGDL,
    },
    {
      id: MMOL_ID,
      value: GLUCOSE_UNIT_MMOL,
    },
  ];

  const heightInchList = () => {
    let list = [];
    for (let i = 0; i <= 11; i++) {
      list.push({id: i < 10 ? `0${i}` : i, value: i});
    }
    return list;
  };

  const getClass = () => {
    return (
      (classes ? classes : '') +
      (isArrowHidden ? ' hide-select-dropdown' : '') +
      ' text-box-container'
    );
  };

  const handleChange = (e) => {
    changeValue(e.target.value);
  };
  useEffect(() => {
    switch (dataType) {
      case 'height':
        if (category === 'foot') {
          setOptionList(generateObjectList(0, 10, 1));
        } else if (category === 'inch') {
          setOptionList(heightInchList);
        }
        break;
      case 'steps':
        setOptionList(generateObjectList(1000, 25000, 1000));
        break;
      case 'auto-measure':
        setOptionList(autoMeasureIntervalList);
        break;
      case 'weather-unit':
        setOptionList(weatherUnitList);
        break;
      case 'blood-glucose-unit':
        setOptionList(bloodGlucoseUnitList);
        break;
      default:
        getMasterData(dataType)
          .then((res) => {
            if (res) {
              if (res.statusCode === 200) {
                setOptionList(res.body.data.master_data_list);
              } else if (res.statusCode === 303) {
                deleteAllLocalStorage();
                history.push(routesPath.SIGN_IN);
              }
            } else {
              const message = `${dataType} is not found`;
              throw new Error(message);
            }
          })
          // .then(() => {
          //     if (dataType === 'getMealSize') {
          //         optionList.forEach((opt) => {
          //             opt.value = () => (
          //                 <>
          //                     {getIconWithLabel(opt.id)}
          //                 </>
          //             )
          //         })
          //     }
          // })
          .catch((e) => {
            setErrorMesage(e.message);
          });
        break;
    }
  }, []);

  const Loader = () => (
    <>
      {errorMesage.length > 0 ? (
        <div className={(messageClass ? messageClass : '') + ' error-message'}>
          {errorMesage}
        </div>
      ) : (
        <div
          className={
            (messageClass ? messageClass : '') + ' text-box-container loader'
          }
        >
          Loading ...
        </div>
      )}
    </>
  );

  return (
    <>
      {optionList.length > 0 ? (
        <div>
          <select
            disabled={isDisabled ? isDisabled : false}
            id={inputId ? inputId : id}
            onChange={handleChange}
            value={value}
            className={getClass()}
          >
            {dataType !== 'height' && dataType !== 'steps' && (
              <option value="" disabled selected>
                Select one
              </option>
            )}
            {optionList.map((obj, i) => (
              <option key={i} value={obj.id}>
                {obj.value}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
