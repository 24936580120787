import {HealthParameterType} from '../common/config';
import {getGlucoseUnit} from '../redux/watchSettings/richActions';
import {getBloodGlucoseValue} from '../utils/glucose';
import {useEffect, useState} from 'react';

export const defaultConvertFunction = (value) => value;

const HealthParamMap = {
  [HealthParameterType.BLOOD_GLUCOSE]: 'Glucose',
  [HealthParameterType.HEART_RATE]: 'HR',
  [HealthParameterType.RESPIRATION_RATE]: 'RR',
  [HealthParameterType.OXYGEN_SATURATION]: 'SPO2',
  [HealthParameterType.BLOOD_PRESSURE]: ['SBP', 'DBP'],
};

const INITIAL_VALE = {
  param: '',
  converter: defaultConvertFunction,
  unitLabel: '',
  range: [0, 1],
  diff: 1,
};

export const getHealthConfigParam = (type) => {
  return HealthParamMap[type];
};

export const getHealthConfig = async (type) => {
  const defaultParams = {
    param: getHealthConfigParam(type),
    converter: defaultConvertFunction,
  };
  switch (type) {
    case HealthParameterType.BLOOD_GLUCOSE:
      const {unitLabel, unit} = await getGlucoseUnit();
      return {
        ...defaultParams,
        range: [70, 180],
        diff: 10,
        unitLabel,
        unit,
        converter: getBloodGlucoseValue,
      };
    case HealthParameterType.HEART_RATE:
      return {
        ...defaultParams,
        unitLabel: 'bpm',
        range: [40, 130],
        diff: 8,
      };
    case HealthParameterType.RESPIRATION_RATE:
      return {
        ...defaultParams,
        unitLabel: 'brpm',
        range: [10, 30],
        diff: 2,
      };
    case HealthParameterType.OXYGEN_SATURATION:
      return {
        ...defaultParams,
        unitLabel: '%',
        range: [85, 100],
        diff: 1,
      };
    case HealthParameterType.BLOOD_PRESSURE:
      return {
        ...defaultParams,
        unitLabel: 'mmHG',
        range: [50, 180],
        diff: 11,
      };
    default:
      break;
  }
};

export const useHealthConfig = (type) => {
  const [result, setResult] = useState(INITIAL_VALE);
  useEffect(async () => {
    const res = await getHealthConfig(type);
    setResult(res);
  }, [type]);

  return result;
};

export const getHealthRange = (type) => {
  switch (type) {
    case HealthParameterType.HEART_RATE:
      return [50, 110];
    case HealthParameterType.RESPIRATION_RATE:
      return [12, 20];
    case HealthParameterType.OXYGEN_SATURATION:
      return [88, 95];
    case HealthParameterType.BLOOD_PRESSURE:
      return [65, 130];
    default:
      break;
  }
};
