import React from 'react';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MealHeavy from '../../assets/icons/meal/meal-heavy-black.svg';
import MealLight from '../../assets/icons/meal/meal-light-black.svg';
import MealModerate from '../../assets/icons/meal/meal-moderate-black.svg';

const getIcon = (size) => {
  switch (size) {
    case 1:
      return MealLight;
    case 2:
      return MealModerate;
    case 3:
      return MealHeavy;
    default:
      break;
  }
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    marginLeft: '9%',
    width: '85%',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '2%',
    display: 'flex',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      backgroundColor: 'white',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function MealDropdown(props) {
  // const classes = useStyles();

  const {sizeList, size, isIconHidden} = props;
  return (
    <Select
      IconComponent={() => isIconHidden && <></>}
      value={size}
      input={<BootstrapInput />}
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      style={{width: '95%', marginLeft: '0.5%'}}
      // value={age}
      // onChange={handleChange}
    >
      {sizeList.map((obj, i) => (
        <MenuItem key={i} value={obj.id}>
          <img
            src={getIcon(obj.id)}
            alt="meal-light"
            style={{marginRight: '2%'}}
          />
          {obj.value}
        </MenuItem>
      ))}
    </Select>
  );
}
