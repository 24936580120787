export const getDataInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getFormattedHour = (hour) => {
  const hh =
    hour > 12 && hour < 24 ? hour - 12 : hour === 0 || hour === 24 ? 12 : hour;
  const ampm = hour >= 12 && hour < 24 ? 'pm' : 'am';
  return hh + ' ' + ampm;
};

export const getFormattedHourWithMinute = (time) => {
  const hour = Math.floor(time);
  const minutes = Math.floor(60 * (time - hour));
  const hh = (
    '0' +
    (hour > 12 && hour < 24 ? hour - 12 : hour === 0 || hour === 24 ? 12 : hour)
  ).slice(-2);
  const mm = ('0' + minutes).slice(-2);
  const ampm = hour >= 12 && hour < 24 ? 'pm' : 'am';
  return hh + ' : ' + mm + ' ' + ampm;
};

export const getHourAndMinuteFromTime = (time) => {
  const d = new Date(time);
  const hour = d.getHours();
  const hh = (
    '0' +
    (hour > 12 && hour < 24 ? hour - 12 : hour === 0 || hour === 24 ? 12 : hour)
  ).slice(-2);
  const mm = ('0' + d.getMinutes()).slice(-2);
  const ampm = hour >= 12 && hour < 24 ? 'pm' : 'am';
  return hh + ' : ' + mm + ' ' + ampm;
};
export const getAvg = (array) => {
  let data = Array.from(array);
  let sum = 0;
  data.forEach((d) => {
    sum += d;
  });
  return Math.floor(sum / data.length);
};

export const getSum = (array) => {
  let data = Array.from(array);
  let sum = 0;
  data.forEach((d) => {
    sum += d;
  });
  return Math.floor(sum);
};

export const TIME_TYPE = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};
export const stepsAreaColors = ['#1a74d4', '#ffffff'];
