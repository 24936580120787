import React from 'react';
import './outline-primary.css';

export default function OutlinePrimaryButton(props) {
  const {label, classes, inputId, labelInAction, isInAction, disable, click} =
    props;
  const id = 'btn_' + String(label).split(' ').join('_').toLowerCase();

  return (
    <>
      <button
        id={inputId ? inputId : id}
        className={'outline-primary-button ' + classes}
        onClick={click}
        type="submit"
        disabled={disable || isInAction}
      >
        {isInAction ? labelInAction : label}
      </button>
    </>
  );
}
