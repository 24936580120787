import {HealthParameterType, months, weekDay} from '../common/config';
import {getSingleDayData} from './bloodGlucose';
import {
  getDataInRange,
  getFormattedHour,
  TIME_TYPE,
} from '../fake-data-generator/common';

export const generateComplexGlucoseLineData = (date, typeOfTime) => {
  const requestedDate = new Date(date);
  let noOfDay = 0;
  if (typeOfTime === TIME_TYPE.WEEK) {
    noOfDay = 7;
  } else if (typeOfTime === TIME_TYPE.MONTH) {
    noOfDay = 30;
  } else if (typeOfTime === TIME_TYPE.YEAR) {
    const endDate = new Date(requestedDate);
    const startDate = new Date(requestedDate);
    startDate.setDate(new Date(startDate).getDate() - 29);
    let monthData = [];
    for (let i = 0; i < 12; i++) {
      let multiDaysData = [];
      const dateOfDay = new Date(endDate);
      for (let i = 0; i < 30; i++) {
        multiDaysData.push(getSingleDayData(dateOfDay, 2));
        dateOfDay.setDate(new Date(dateOfDay).getDate() - 1);
      }
      monthData.push({
        start: new Date(startDate),
        end: new Date(endDate),
        multiDaysData: multiDaysData,
      });
      endDate.setDate(new Date(endDate).getDate() - 30);
      startDate.setDate(new Date(startDate).getDate() - 29);
    }
    return monthData;
  }

  let multiDaysData = [];
  let xLabels = [];
  for (let i = 0; i < noOfDay; i++) {
    multiDaysData.push(getSingleDayData(requestedDate, 2));
    if (typeOfTime === TIME_TYPE.WEEK) {
      xLabels.push(weekDay[requestedDate.getDay()]);
    } else if (typeOfTime === TIME_TYPE.MONTH) {
      multiDaysData.forEach((eachDay) => {
        eachDay.activity = '';
        // eachDay.meals = [];
        eachDay.fastingLine = '';
      });
      if ([0, 9, 19, 29].includes(i)) {
        xLabels.push(
          months[requestedDate.getMonth()].substr(0, 3) +
            ' ' +
            requestedDate.getDate()
        );
      } else {
        xLabels.push('');
      }
    }
    requestedDate.setDate(requestedDate.getDate() - 1);
  }
  const grapghUpperLines = [
    {
      yValue: 70,
      label: 70,
      isDotted: true,
    },
    {
      yValue: 125,
      label: 125,
      isDotted: false,
    },
    {
      yValue: 180,
      label: 180,
      isDotted: true,
    },
  ];

  const graphLowerLines = [
    {
      yValue: 0,
      label: 'Meals',
      isDotted: false,
    },
    {
      yValue: 10,
      label: 'Fasting',
      isDotted: false,
    },
    {
      yValue: 15,
      label: 'Activity',
      isDotted: false,
    },
    {
      yValue: 30,
      label: '',
      isDotted: false,
    },
  ];

  return {
    requestedDate: requestedDate,
    yLabel: 'mg/dL',
    timeType: typeOfTime,
    noOfDay: noOfDay,
    xLabels: xLabels.reverse(),
    multiDaysData: multiDaysData.reverse(),
    graphUpperLines: grapghUpperLines,
    graphLowerLines: graphLowerLines,
    scatterLow: 70,
    scatterHigh: 180,
  };
};

export const generateBloodGlucoseBarData = (data, typeOfTime, type, index) => {
  let barColors = ['#2ee48a', '#98c361', '#f05f5f'];
  let barData = [];
  let toolTipObjects = [];
  let max = 100;
  const {multiDaysData, scatterHigh, scatterLow} = data;
  let yAxislabels = [
    {
      value: 100,
      yAxisPercentage: 1,
    },
    {
      value: 30,
      yAxisPercentage: 0.3,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  multiDaysData?.forEach((multiDays, i) => {
    const {eachDayData, date} = multiDays;
    let timeInRange = 0;
    eachDayData.forEach((e) => {
      const actualValue =
        type === HealthParameterType.BLOOD_PRESSURE ? e.y[index] : e.y;
      if (actualValue > scatterLow && actualValue <= scatterHigh) {
        timeInRange++;
      }
    });
    const value = Math.floor((100 * timeInRange) / eachDayData.length);
    let requestedDate = new Date(date);
    let label = '';
    if (typeOfTime === TIME_TYPE.WEEK) {
      label = weekDay[requestedDate.getDay()];
    } else if (typeOfTime === TIME_TYPE.MONTH && [0, 9, 19, 29].includes(i)) {
      label =
        months[requestedDate.getMonth()].substr(0, 3) +
        ' ' +
        requestedDate.getDate();
    }
    barData.push({
      value: value,
      label: label,
    });
    toolTipObjects.push({
      value: value + '%',
      upper: '',
      lower: 'of time in range',
    });
  });

  const howManyInrange = barData.filter((b) => b.value > 70).length;

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    inRange: howManyInrange,
    footerText: '',
    yLabel: '% time in range',
  };
};

export const generateBloodGlucoseAverageByHourData = (date, noOfDay) => {
  const requestedDate = new Date(date);
  let xLabels = [];
  let currentLineData = [];
  let previousLinesData = [];
  const graphLines = [
    {
      yValue: 60,
      label: '',
      isDotted: false,
    },
    {
      yValue: 70,
      label: 70,
      isDotted: true,
    },
    {
      yValue: 125,
      label: 125,
      isDotted: false,
    },
    {
      yValue: 180,
      label: 180,
      isDotted: true,
    },
  ];
  for (let i = 0; i <= 24; i++) {
    if ([0, 24, 12].includes(i)) {
      xLabels.push(getFormattedHour(i));
    } else {
      xLabels.push('');
    }
  }
  const startDate = new Date(requestedDate);
  startDate.setDate(requestedDate.getDate() - (noOfDay - 1));
  let hourData = getSingleDayData(startDate, 1).eachDayData;
  hourData.forEach((data) => {
    const diff = getDataInRange(10, 15);
    data.highest = data.y + diff;
    data.lowest = data.y - diff;
  });
  currentLineData = {
    start: startDate,
    end: requestedDate,
    hourData: hourData,
  };
  for (let i = 0; i < 4; i++) {
    const start = new Date(requestedDate);
    start.setDate(start.getDate() - i * noOfDay);
    const end = new Date(start);
    end.setDate(end.getDate() - (noOfDay - 1));
    const startMonth = months[new Date(start).getMonth()].substr(0, 3);
    const endMonth = months[new Date(end).getMonth()].substr(0, 3);
    const startDate = new Date(start).getDate();
    const endDate = new Date(end).getDate();
    const tooltipLowerText =
      endMonth + ' ' + endDate + ' - ' + startMonth + ' ' + startDate;
    let hourDataOfPrev = [];
    hourData.forEach((data) => {
      const diff = getDataInRange(5, 10);
      if (i < 2) {
        hourDataOfPrev.push({
          x: data.x,
          y: data.y + diff * (i + 1),
        });
      } else {
        hourDataOfPrev.push({
          x: data.x,
          y: data.y - (diff * (i + 1)) / 2,
        });
      }
    });
    previousLinesData.push({
      start: startDate,
      end: endDate,
      tooltipLowerText: tooltipLowerText,
      hourData: hourDataOfPrev,
    });
  }
  return {
    requestedDate: requestedDate,
    yLabel: 'mg/dL',
    xLabels: xLabels,
    currentLine: currentLineData,
    previousLinesData: previousLinesData,
    graphLines: graphLines,
    graphHigh: 70,
    graphLow: 180,
  };
};
