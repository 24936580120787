import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import ForgotPassword from './components/forget-password/forget-password';
import HomePage from './components/home-page/home-page';
import PersonalInfo from './components/personal-info/personal-info';
import ShareData from './components/share-data/share-data';
import Subscription from './components/subscription/subscription';
import CreditCardForm from './components/plan/subscription';
import PlanRegistration from './components/plan/registration';
import SignIn from './components/signin/signin';
import SignUp from './components/signup/signup';
import TermsOfServices from './components/terms-services/terms-services';
import VerifyAccount from './components/verify-account/verify-account';
import Profile from './components/profile/profile';
import Settings from './components/settings/settings';
import Meal from './components/meals/meal';
import HealthSymptoms from './components/health-symptoms/health-symptoms';
import StepsWalked from './components/steps-walked/steps-walked';
import BloodGlucose from './components/health-component/health-component';
import BloodGlucoses from './components/blood-glucoses/blood-glucose';
import HeartRate from './components/heart-rate/heart-rate';
import RespirationRate from './components/respiration-rate/respiration-rate';
import OxygenSaturation from './components/oxygen-saturation/oxygen-saturation';
import BloodPressure from './components/blood-pressure/blood-pressure';
import AutoLogin from './components/autoLogin/autoLogin';

export const routesPath = {
  VERIFY_ACCOUNT: '/verify-account',
  FORGOT_PASSWORD: '/forgot-password',
  TERMS_OF_SERVICES: '/terms-of-services',
  PERSONAL_INFO: '/personal-information',
  HOME_PAGE: '/home',
  SHARE_DATA: '/share-data',
  CREATE_ACCOUNT: '/create-account',
  SIGN_IN: '/sign-in',
  SUBSCRIPTION: '/subscription',
  CREDIT_CARD_FORM: '/payment',
  PLAN_REGISTRATION: '/plan-registration',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  MEALS: '/meals',
  HEALTH_SYMPTOMS: '/health-symptoms',
  STEPS_WALKED: '/steps-walked',
  BLOOD_GLUCOSE: '/blood-glucose',
  HEART_RATE: '/heart-rate',
  RESPIRATION_RATE: '/respiration-rate',
  OXYGEN_SATURATION: '/oxygen-saturation',
  BLOOD_PRESSURE: '/blood-pressure',
  AUTO_LOGIN: '/autoLogin',
};
export default function Routers() {
  return (
    <Router>
      <Switch>
        <>
          <Route exact path="/">
            <Redirect to={routesPath.SIGN_IN} />
          </Route>
          <Route path={routesPath.BLOOD_PRESSURE} component={BloodPressure} />
          <Route
            path={routesPath.OXYGEN_SATURATION}
            component={OxygenSaturation}
          />
          <Route
            path={routesPath.RESPIRATION_RATE}
            component={RespirationRate}
          />
          <Route path={routesPath.BLOOD_GLUCOSE} component={BloodGlucoses} />
          <Route path={routesPath.HEART_RATE} component={HeartRate} />
          <Route path={routesPath.STEPS_WALKED} component={StepsWalked} />
          <Route path={routesPath.HEALTH_SYMPTOMS} component={HealthSymptoms} />
          <Route path={routesPath.MEALS} component={Meal} />
          <Route path={routesPath.SETTINGS} component={Settings} />
          <Route path={routesPath.PROFILE} exact component={Profile} />
          <Route
            path={routesPath.VERIFY_ACCOUNT}
            exact
            component={VerifyAccount}
          />
          <Route
            path={routesPath.SUBSCRIPTION}
            exact
            component={Subscription}
          />
          <Route
            path={routesPath.CREDIT_CARD_FORM}
            exact
            component={CreditCardForm}
          />
          <Route
            path={routesPath.PLAN_REGISTRATION}
            exact
            component={PlanRegistration}
          />
          <Route
            path={routesPath.FORGOT_PASSWORD}
            exact
            component={ForgotPassword}
          />
          <Route
            path={routesPath.TERMS_OF_SERVICES}
            exact
            component={TermsOfServices}
          />
          <Route path={routesPath.PERSONAL_INFO} component={PersonalInfo} />
          <Route path={routesPath.SHARE_DATA} component={ShareData} />
          <Route path={routesPath.HOME_PAGE} exact component={HomePage} />
          <Route path={routesPath.CREATE_ACCOUNT} exact component={SignUp} />
          <Route path={routesPath.SIGN_IN} component={SignIn} />
          <Route path={routesPath.AUTO_LOGIN} component={AutoLogin} />
        </>
      </Switch>
    </Router>
  );
}
