import {STORE_SIGN_IN_INFO} from '../actionTypes';

const initialState = {
  apiKeyGpl: '',
  apiKeyEureka: '',
  jwtToken: '',
  userId: '',
  firstName: '',
  lastName: '',
  profileUrl: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_SIGN_IN_INFO:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
}
