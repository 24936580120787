import React, {useEffect} from 'react';
import PlainSwitch from '../common/switches/plain-switch';
import DropDown from '../common/drop-downs/dropdown';
import PlainTextboxWithFixedPlaceholder from '../common/textboxes/plain-textbox-fixed-placeholder/plain-textbox-fixed-placeholder';
import {HeightDataType} from '../../common/config';
import {heightMeterValidator} from './height-validator';
import {
  convertFeetToMeter,
  formatInches,
  meterToFeetDotInchesFormatter,
  metricToImperial,
} from '../../common/metricsTool';

const HeightPicker = ({
  heightType,
  setHeightType,
  heightFT,
  setHeightFT,
  heightIN,
  setHeightIN,
  heightM,
  setHeightM,
  containerClassName,
  plainSwitchClasses, // undefined
  dropdownContainerClass,
  dropdownItemClass,
  metricInputClass,
  metricInputErrorClass,
}) => {
  const {errorMessage, valid} = heightMeterValidator(heightM, heightType);
  useEffect(() => {
    if (heightType === HeightDataType.METER) {
      if (!heightFT && !heightIN) {
        const [feet, inches] = metricToImperial(heightM);
        setHeightFT(feet);
        setHeightIN(formatInches(inches));
      }
      return;
    }
    if (!heightM) {
      setHeightM(convertFeetToMeter(heightFT, heightIN));
    }
  }, [heightType]);

  const onFeetChange = (value) => {
    setHeightFT(value);
    setHeightM(convertFeetToMeter(value, heightIN));
    console.log(
      'convertFeetToMeter(value, heightIN): ',
      convertFeetToMeter(value, heightIN)
    );
  };

  const onInchesChange = (value) => {
    setHeightIN(value);
    setHeightM(convertFeetToMeter(heightFT, value));
    console.log(
      'convertFeetToMeter(value, heightIN): ',
      convertFeetToMeter(heightFT, value)
    );
  };

  const onMetersChange = (value) => {
    setHeightM(value);
    const [feet, inches] = metricToImperial(value);
    setHeightFT(feet);
    setHeightIN(formatInches(inches));
    console.log('feet, inches: ', feet, inches);
  };

  return (
    <div className={containerClassName}>
      <div>
        <b>Height</b>
      </div>
      <PlainSwitch
        switchId="div_height"
        classes={plainSwitchClasses}
        value={heightType}
        changeValue={setHeightType}
        switches={[HeightDataType.FEET, HeightDataType.METER]}
      />
      {heightType === HeightDataType.FEET ? (
        <div className={dropdownContainerClass}>
          <div className={dropdownItemClass}>
            <DropDown
              inputId="dropdown_feet"
              value={heightFT}
              dataType="height"
              changeValue={onFeetChange}
              category="foot"
            />
          </div>
          <div className={dropdownItemClass}>
            <DropDown
              inputId="dropdown_inch"
              value={heightIN}
              dataType="height"
              changeValue={onInchesChange}
              category="inch"
            />
          </div>
        </div>
      ) : (
        <PlainTextboxWithFixedPlaceholder
          inputId="textbox_weight"
          classes={valid ? metricInputClass : metricInputErrorClass}
          maxLength={4}
          label={heightType}
          value={heightM}
          changeValue={onMetersChange}
        />
      )}
      {!valid && <p style={{color: 'red'}}>{errorMessage}</p>}
    </div>
  );
};
export default HeightPicker;
