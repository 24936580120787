import Spinner from 'react-spinner-material';
import {HealthParameterType} from '../../common/config';
import BloodGlucoseIcon from '../../assets/icons/blood-glucose/orange_drop.svg';
import HeartIcon from '../../assets/icons/overview/heart.svg';
import {Alert} from '@material-ui/lab';

export const Loader = () => (
  <div className="meal-loader">
    <Spinner color="#1a74d4" stroke={4} />
  </div>
);

export const NoDataContainer = () => (
  <div className="blood-glucose-no-data">
    <Alert icon={false} severity="info">
      No data is available
    </Alert>
  </div>
);
export const getTitle = (type) => {
  switch (type) {
    case HealthParameterType.BLOOD_GLUCOSE:
      return 'Blood Glucose';
    case HealthParameterType.BLOOD_PRESSURE:
      return 'Blood Pressure';
    case HealthParameterType.HEART_RATE:
      return 'Heart Rate';
    case HealthParameterType.RESPIRATION_RATE:
      return 'Respiration Rate';
    case HealthParameterType.OXYGEN_SATURATION:
      return 'Oxygen Saturation';
    default:
      break;
  }
};

export const getHeaderIcon = (type) => {
  switch (type) {
    case HealthParameterType.BLOOD_GLUCOSE:
      return BloodGlucoseIcon;
    // case HealthParameterType.HEART_RATE:
    //     return HeartIcon
    default:
      return BloodGlucoseIcon;
  }
};
