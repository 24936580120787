export const getSubscriptionDetailsBody = (data) => {
  const body = {
    query: `query MyQuery($purpose: String = "get", $userId: Int, $subscriptionId: String, $customerId: String) {getSubscriptionDetails(purpose: $purpose, userId: $userId, subscriptionId: $subscriptionId, customerId: $customerId) {statusCode body {data isSubscribed planId message success}}}`,
    variables: data,
  };
  return JSON.stringify(body);
};

export const getSubscriptionURLBody = (priceId, userId) => {
  const body = {
    query:
      'query MyQuery($itemPriceId: String, $userId: Int) {getSubscriptionURL(itemPriceId: $itemPriceId, userId: $userId) {statusCode body {message success url}}}',
    variables: {
      itemPriceId: priceId,
      userId,
    },
  };
  return JSON.stringify(body);
};
