/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const Colors = {
  white: '#ffffff',
  black: '#000000',
  transparent: 'rgba(0,0,0,0)',
  ButtonColor: '#1A74D3',
  selectedColor: '#1A74D3',
  red: 'red',
  blue: '#1A74D3',
  gray: '#7C7C7C',
  lightGray: '#999',
  secondLightGray: '#E1EAED',
  iconColor: '#0d6bd7',
  borderColor: '#D2D3D6',
  checkBoxBorderColor: '#c8c8c8',
  homeBackground: '#F2FBFF',
  yellowAlert: '#FFF572',
  yellowAlertBorder: '#fdf9d7',
  redAlert: '#F86362',
  redAlertBorder: '#FEE0E0',
  orangeAlert: '#FFA42C',
  orangeAlertBorder: '#FEEED5',
};

export default Colors;
