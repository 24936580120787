export const getSignInBody = (email, password) => {
  const body = {
    email: email.trim(),
    password: password.trim(),
  };
  return JSON.stringify(body);
};

export const getSignUpBody = (email, firstName, lastName, password) => {
  const body = {
    query:
      'mutation create($email: String,$password: String,$firstName: String,$lastName: String,$deviceMSN: String){signup(email:$email,password:$password,firstName:$firstName,lastName:$lastName,deviceMSN:$deviceMSN){statusCode body}}',
    variables: {
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password: password.trim(),
      deviceMSN: 'mok9',
    },
  };
  return JSON.stringify(body);
};

export const getUserStateBody = (userId) => {
  const body = {
    query:
      'query state{getUserState(userCredId:' +
      userId +
      '){statusCode body{success message data{userState}}}}',
    variables: null,
    operationName: 'state',
  };
  return JSON.stringify(body);
};

export const getTermsOfServiceBody = (userId) => {
  const body = {
    query:
      'mutation create($userCredId:Int,$hipaaTerms:String,$termsOfService:String){setUserTerms(userCredId:$userCredId,hipaaTerms:$hipaaTerms,termsOfService:$termsOfService){statusCode body}}',
    variables: {
      userCredId: userId,
      hipaaTerms: 'WEB',
      termsOfService: 'WEB',
    },
  };
  return JSON.stringify(body);
};

export const getForgetPasswordBody = (emailId) => {
  const body = {
    query:
      'mutation password{forgetPassword(emailId:"' +
      emailId +
      '"){statusCode body}}',
    variables: null,
    operationName: 'password',
  };
  return JSON.stringify(body);
};

export const getAddOtherInfoBody = (obj) => {
  const {userId, countryCode, ...restProps} = obj;
  const body = {
    query:
      'mutation create($userCredId:Int,$birthDay:String,$gender:String,$height:Float,$weight:Float,$ethnicityId:Int,$country:String,$unitOfMeasurement:String,$skinToneId:Int){' +
      ' addUserInfo(userCredId:$userCredId,birthDay:$birthDay,gender:$gender,height:$height,weight:$weight,ethnicityId:$ethnicityId,country:$country,unitOfMeasurement:$unitOfMeasurement,skinToneId:$skinToneId){' +
      ' statusCode body}}',
    variables: {
      userCredId: userId,
      country: countryCode,
      ...restProps,
    },
  };
  return JSON.stringify(body);
};
