import * as d3 from 'd3';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import StepsIcon from '../../assets/icons/blood-glucose/steps.svg';
import {HealthParameterType} from '../../common/config';
import {TIME_TYPE} from '../../fake-data-generator/common';
import {UPDATE_DATE, UPDATE_DOT} from '../../redux/actionTypes';
import {getMealIcon, getSvgWidth} from './common';

export default function ComplexLine(props) {
  const height = window.screen.height;
  const {data} = props;
  const {
    xLabels,
    scatterLow,
    complexScatterY,
    scatterHigh,
    multiDaysData,
    graphUpperLines,
    graphLowerLines,
    yLabel,
    requestedDate,
    noOfDay,
    timeType,
    diffToAxis,
    type,
  } = data;
  const svgHeight = height * 0.4;
  const marginLeft = 10;
  const max = xLabels.length;
  const y = d3
    .scaleLinear()
    .domain([0, 200])
    .range([height * 0.38, 0]);
  const dispatch = useDispatch();

  const findTheClosest = (value, data) => {
    const xValues = data.map((a) => a.x);
    const closest = xValues.reduce(function (prev, curr) {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
    return closest;
  };

  const getXInOneSlot = (width, data, i) => {
    const slot = (width * 0.9 * 0.98) / noOfDay;
    const marginLeft = width * 0.1;
    const x = d3
      .scaleLinear()
      .domain([0, 24])
      .range([marginLeft + slot * i, marginLeft + (i + 1) * slot]);
    return x(data);
  };

  const createLabel = (svg, value, i) => {
    svg
      .append('text')
      .text(value)
      .attr('x', getScaleXValue(getSvgWidth(svg), i))
      .attr('y', svgHeight - 2)
      .style('font-size', 'small');
  };
  const appendImage = (svg, src, xCord, yCord, i) => {
    svg
      .append('image')
      .attr('xlink:href', src)
      .attr('x', getXInOneSlot(getSvgWidth(svg), xCord, i))
      .attr('y', y(yCord));
  };

  const createFastingDashLine = (svg, x1, x2, yValue, height) => {
    const inc = (x2 - x1) / 8;
    for (let i = x1; i < x2 && x2 > i + inc / 2; i = i + inc) {
      svg
        .append('line')
        .attr('x1', i)
        .attr('x2', i + inc)
        .attr('y1', y(yValue - height + 1))
        .attr('y2', y(yValue))
        .attr('stroke-width', 1)
        .attr('stroke', 'grey');
    }
  };

  const createMarginTopLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createMarginBottomLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', svgHeight)
      .attr('y2', svgHeight)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createFastingLine = (svg, x1, x2, yValue, height) => {
    svg
      .append('rect')
      .attr('x', x1)
      .attr('y', y(yValue))
      .attr('fill', '#d0e7ff')
      .style('width', x2 - x1)
      .style('height', height);
    createFastingDashLine(svg, x1, x2, yValue, height);
  };

  const appendVerticalLine = (svg, x, y1, y2, xOffset, color, i) => {
    svg
      .append('line')
      .attr('x1', Number(getXInOneSlot(getSvgWidth(svg), x, i)) + xOffset)
      .attr('x2', Number(getXInOneSlot(getSvgWidth(svg), x, i)) + xOffset)
      .attr('y1', y(y1))
      .attr('y2', y(y2))
      .attr('stroke-width', 0.5)
      .style('stroke', color)
      .attr('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '5,5');
  };

  const appendMeal = (svg, obj, i) => {
    const {x, type, yValue} = obj;
    const lastHour = requestedDate.getHours();
    if (x !== lastHour + 1) {
      const src = getMealIcon(type);
      appendImage(svg, src, x, yValue, i);
      appendVerticalLine(svg, x, 8, 200, 8, 'grey', i);
    }
  };

  const appendActivityDiv = (svg, xValue, width, yValue, height, i) => {
    svg
      .append('rect')
      .attr('x', getXInOneSlot(getSvgWidth(svg), xValue, i))
      .attr('y', y(yValue))
      .attr('fill', '#d0e7ff')
      .attr('rx', height / 2)
      .style('width', width)
      .style('height', height);
  };
  const appendActivityImage = (svg, xCord, yCord, width, i) => {
    svg
      .append('image')
      .attr('xlink:href', StepsIcon)
      .attr('x', Number(getXInOneSlot(getSvgWidth(svg), xCord, i)) + width / 2)
      .attr('y', y(yCord) + 2);
  };

  const createAlertBar = (svg, y1, y2, x, up, max) => {
    svg
      .append('rect')
      .attr('x', getXOfLine(getSvgWidth(svg), x, max) - 2.5)
      .attr('y', y1)
      .attr('rx', 2)
      .attr('ry', 2)
      .style('height', y2 - y1)
      .style('width', 5)
      .style('fill', up ? 'url(#gradUpper)' : 'url(#gradLower)');
  };

  const appendActivity = (svg, activity, i) => {
    const {start, end, yValue, height} = activity;
    appendActivityDiv(svg, start, 32, yValue, height, i);
    appendActivityImage(svg, start, yValue, 16, i);
    // appendActivityDiv(svg, end, 32, yValue, height, i);
    // appendActivityImage(svg, end, yValue, 16, i)
  };
  const createGraphLineParrelToX = (svg, yValue, isDotted) => {
    svg
      .append('line')
      .attr('x1', getScaleXValue(getSvgWidth(svg), 0))
      .attr('x2', getScaleXValue(getSvgWidth(svg), max))
      .attr('y1', y(yValue))
      .attr('y2', y(yValue))
      .attr('stroke-width', 0.4)
      .style('stroke-dasharray', isDotted ? '5,5' : '')
      .style('stroke', isDotted ? '#f86363' : 'black')
      .attr('shape-rendering', 'crispEdges');
  };

  const createLineText = (svg, yValue, label, xOffset) => {
    svg
      .append('text')
      .text(label)
      .attr('x', getScaleXValue(getSvgWidth(svg), 0) - xOffset)
      .attr('y', y(yValue) + 2)
      .style('font-size', 'small')
      .style('text-align', 'flex-end');
  };

  const createGraphUpperLine = (svg, obj) => {
    const {yValue, label, isDotted} = obj;
    createGraphLineParrelToX(svg, complexScatterY(yValue), isDotted);
    createLineText(svg, complexScatterY(yValue), label, marginLeft * 2);
  };

  const createGraphLowerLine = (svg, obj, i) => {
    const {yValue, label, isDotted} = obj;
    let yOffset = 2;
    if (i !== graphLowerLines.length - 1 && i !== 0)
      yOffset = Math.floor(
        (graphLowerLines[i].yValue + graphLowerLines[i + 1].yValue) / 2
      );
    createGraphLineParrelToX(svg, yValue, isDotted);
    createLineText(svg, yOffset, label, marginLeft * 5);
  };

  const getScaleXValue = (width, data) => {
    const x = d3
      .scaleLinear()
      .domain([0, max])
      .range([width * 0.1, width * 0.98]);
    return x(data);
  };

  const getXOfLine = (width, data, max) => {
    const x = d3
      .scaleLinear()
      .domain([0, noOfDay])
      .range([width * 0.1, width * 0.98]);
    return x(data);
  };

  const getReverseXOfLine = (width, data, max) => {
    const x = d3
      .scaleLinear()
      .domain([0, noOfDay])
      .range([width * 0.1, width * 0.98]);
    return x.invert(data);
  };

  const valueline = (value, width, index) => {
    const line = d3
      .line()
      .x(function (d) {
        return getXOfLine(width, d.x, noOfDay);
      })
      .y(function (d) {
        return y(
          complexScatterY(
            type === HealthParameterType.BLOOD_PRESSURE ? d.y[index] : d.y
          )
        );
      });

    return line(value);
  };

  const createMultiStandardLine = (svg, data, max) => {
    svg
      .append('clipPath')
      .attr('id', 'clipRect')
      .append('rect')
      .attr('x', 0)
      .attr('y', y(complexScatterY(scatterHigh)))
      .attr('width', '100%')
      .attr(
        'height',
        y(complexScatterY(scatterLow)) - y(complexScatterY(scatterHigh))
      );
    const line = svg.append('g').attr('id', 'complex-scatter-line').datum(data);

    line
      .append('path')
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRect")')
      .attr('d', valueline(data, getSvgWidth(svg), 0));
    line
      .append('path')
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRect")')
      .attr('d', valueline(data, getSvgWidth(svg), 1));
    return line;
  };

  const createStandardLine = (svg, data, max) => {
    svg
      .append('clipPath')
      .attr('id', 'clipRect')
      .append('rect')
      .attr('x', 0)
      .attr('y', y(complexScatterY(scatterHigh)))
      .attr('width', '100%')
      .attr(
        'height',
        y(complexScatterY(scatterLow)) - y(complexScatterY(scatterHigh))
      );
    const line = svg
      .append('path')
      .datum(data)
      .attr('id', 'complex-scatter-line')
      .attr('stroke', 'gray')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('clip-path', 'url("#clipRect")')
      .attr('d', valueline(data, getSvgWidth(svg), 0));
    return line;
  };

  const createMultiTooltip = (svg, data, max) => {
    const g = svg
      .selectAll('circle')
      .attr('id', '#complex-line-tooltip')
      .data(data)
      .enter()
      .append('g')
      .style('z-index', 1)
      .style('display', 'none');

    g.append('line')
      .attr('x1', (d, i) => Number(getXOfLine(getSvgWidth(svg), d.x, max)))
      .attr('x2', (d, i) => Number(getXOfLine(getSvgWidth(svg), d.x, max)))
      .attr('y1', y(30))
      .attr('y2', y(200))
      .attr('stroke-width', 2)
      .style('stroke', '#1a74d4')
      .style('z-index', -1)
      .attr('shape-rendering', 'crispEdges')
      .style('display', '');

    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .attr('display', (d, i) =>
        d.y[0] > scatterHigh || d.y[0] < scatterLow ? 'none' : 'block'
      );

    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
      .style('fill', (d, i) => d.color[0])
      .style('stroke', (d, i) => d.stroke[0])
      .attr('r', 4)
      .style('cursor', 'pointer')
      .attr('display', (d, i) =>
        d.y[0] > scatterHigh || d.y[0] < scatterLow ? 'none' : 'block'
      );

    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));

    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
      .style('fill', (d, i) => d.color[1])
      .style('stroke', (d, i) => d.stroke[1])
      .attr('r', 4)
      .style('cursor', 'pointer')
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));

    g.append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .style('z-index', 1)
      .attr('stroke', 'grey')
      .attr('fill', 'white')
      .style('height', 20)
      .style('width', 40)
      .attr('x', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max) - 20)
      .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 40)
      .attr('display', (d, i) =>
        d.y[0] > scatterHigh || d.y[0] < scatterLow ? 'none' : 'block'
      );

    g.append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .style('z-index', 1)
      .attr('stroke', 'grey')
      .attr('fill', 'white')
      .style('height', 20)
      .style('width', 40)
      .attr('x', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max) - 20)
      .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 20)
      .attr('display', (d, i) =>
        d.y[1] > scatterHigh || d.y[1] < scatterLow ? 'none' : 'block'
      );

    g.append('text')
      .text('SYS')
      .style('font-weight', 'bold')
      .attr('x', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max) - 12)
      .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 25)
      .attr('display', (d, i) =>
        d.y[0] > scatterHigh || d.y[0] < scatterLow ? 'none' : 'block'
      );

    g.append('text')
      .text('DIA')
      .style('font-weight', 'bold')
      .attr('x', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max) - 12)
      .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 35)
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));

    return g;
  };

  const createTooltip = (svg, data, max) => {
    const g = svg
      .selectAll('circle')
      .attr('id', '#complex-line-tooltip')
      .data(data)
      .enter()
      .append('g')
      .style('z-index', 1)
      .style('display', 'none');
    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y)))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .style('display', '');

    g.append('line')
      .attr('x1', (d, i) => Number(getXOfLine(getSvgWidth(svg), d.x, max)))
      .attr('x2', (d, i) => Number(getXOfLine(getSvgWidth(svg), d.x, max)))
      .attr('y1', y(30))
      .attr('y2', y(200))
      .attr('stroke-width', 2)
      .style('stroke', '#1a74d4')
      .style('z-index', -1)
      .attr('shape-rendering', 'crispEdges')
      .style('display', '');

    g.append('circle')
      .attr('cx', (d, i) => getXOfLine(getSvgWidth(svg), d.x, max))
      .attr('cy', (d, i) => y(complexScatterY(d.y)))
      .style('fill', (d, i) => d.color)
      .style('stroke', (d, i) => d.stroke)
      .attr('r', 4)
      .style('cursor', 'pointer')
      .style('display', '');
    return g;
  };

  const drawComplexLineChart = () => {
    d3.select('#complex-line-svg').remove();

    const svg = d3
      .select('#complex-line-chart')
      .append('svg')
      .attr('id', 'complex-line-svg')
      .attr('width', '100%')
      .attr('height', svgHeight);

    createMarginTopLine(svg);
    createMarginBottomLine(svg);

    const gradLower = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradLower')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    const gradUpper = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradUpper')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradLower
      .selectAll('stop')
      .data(['#f86363', '#ffe6e6'])
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (2 - 1)) + '%';
      });

    gradUpper
      .selectAll('stop')
      .data(['#ffe6e6', '#f86363'])
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (2 - 1)) + '%';
      });
    xLabels.forEach((d, i) => {
      createLabel(svg, d, i);
    });

    graphLowerLines.forEach((obj, i) => {
      createGraphLowerLine(svg, obj, i);
    });

    graphUpperLines.forEach((obj) => {
      createGraphUpperLine(svg, obj);
    });

    const width = getSvgWidth(svg);
    let maxValue = 0;
    let generateLineData = [];
    multiDaysData.forEach((d, i) => {
      const {fastingLine, meals, activity, eachDayData} = d;
      const x1 = getXInOneSlot(width, 0, i);
      const x2 = getXInOneSlot(width, fastingLine.end, i);
      if (timeType === TIME_TYPE.WEEK) {
        meals.forEach((meal) => {
          appendMeal(svg, meal, i);
        });
      }
      eachDayData.forEach((data, index) => {
        let val = i + data.x / 24;
        const obj = {
          x: val,
          y: data.y,
          date: data.date,
          color: data.color,
          stroke: data.stroke,
        };
        if (index !== 24) {
          generateLineData.push(obj);
        }
        if (
          i === multiDaysData.length - 1 &&
          index === eachDayData.length - 1
        ) {
          maxValue = 24 * 7;
        }
      });
      if (fastingLine !== '') {
        createFastingLine(svg, x1, x2, fastingLine.yValue, fastingLine.height);
      }
      if (activity !== '') {
        appendActivity(svg, activity, i);
      }
    });
    console.log('GeneratedLine data: ', generateLineData);
    const dataInRange = generateLineData.filter(
      (d) =>
        (type === HealthParameterType.BLOOD_PRESSURE ? d.y[1] : d.y) >
          scatterLow &&
        (type === HealthParameterType.BLOOD_PRESSURE ? d.y[0] : d.y) <
          scatterHigh
    );
    const line =
      type === HealthParameterType.BLOOD_PRESSURE
        ? createMultiStandardLine(svg, generateLineData, maxValue)
        : createStandardLine(svg, generateLineData, maxValue);
    const tooltip =
      type === HealthParameterType.BLOOD_PRESSURE
        ? createMultiTooltip(svg, dataInRange, maxValue)
        : createTooltip(svg, dataInRange, maxValue);

    line.on('mouseover', (e, data) => {
      const xValue = findTheClosest(
        getReverseXOfLine(getSvgWidth(svg), d3.pointer(e)[0], maxValue),
        dataInRange
      );
      dispatch({
        type: UPDATE_DATE,
        payload: multiDaysData[Math.floor(xValue)].date,
      });
      dispatch({
        type: UPDATE_DOT,
        payload: data.filter((d) => d.x === xValue)[0],
      });
      tooltip
        .filter((c) => {
          return c.x === xValue;
        })
        .style('display', 'block');
      tooltip
        .filter((c) => {
          return c.x !== xValue;
        })
        .style('display', 'none');
    });

    dispatch({
      type: UPDATE_DATE,
      payload: multiDaysData[multiDaysData.length - 1].date,
    });
    dispatch({
      type: UPDATE_DOT,
      payload: generateLineData[generateLineData.length - 1],
    });

    tooltip
      .filter((c) => {
        return c === generateLineData[generateLineData.length - 1];
      })
      .style('display', 'block');
    generateLineData.forEach((data) => {
      if (data.y < scatterLow) {
        createAlertBar(
          svg,
          y(complexScatterY(scatterLow)),
          y(complexScatterY(scatterLow - diffToAxis)),
          Math.floor(data.x),
          false
        );
      }
      if (data.y > scatterHigh) {
        createAlertBar(
          svg,
          y(complexScatterY(scatterHigh + diffToAxis)),
          y(complexScatterY(scatterHigh)),
          Math.floor(data.x),
          true
        );
      }
    });
    svg
      .append('text')
      .text(yLabel)
      .attr('x', '2%')
      .attr('y', y(120))
      .style('writing-mode', 'vertical-rl')
      .style('font-weight', 'bold');
  };

  if (!d3.select('#complex-line-svg').empty()) {
    d3.select(window).on('resize.svg', () => drawComplexLineChart(false));
  }
  useEffect(() => {
    drawComplexLineChart(true);
  }, []);

  return (
    <>
      <div
        id="complex-line-chart"
        style={{width: '100%', marginTop: '0.5%'}}
      ></div>
    </>
  );
}
