export const getStepsCountBody = (object) => {
  const {startTS, endTS, userId} = object;

  const body = {
    query:
      'query MyQuery($endTs: String, $startTs: String, $userId: Int) {getStepCount(endTs: $endTs, startTs: $startTs, userId: $userId) {statusCode body {message success data {count ts}}}}',
    variables: {
      userId: userId,
      startTs: startTS,
      endTs: endTS,
    },
  };
  return JSON.stringify(body);
};
