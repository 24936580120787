import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {deleteAllLocalStorage} from '../../common/localStorageHandler';
import {acceptTermsOfService} from '../../rest-api/user-api/sign-up';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import TermsBody from './terms-body';
import './terms-services.css';

export default function TermsOfServices(props) {
  const [isAccepting, setAccepting] = useState(false);
  const history = useHistory();
  const state = props.location.state;
  const signInData = useSelector((state) => state.signIn);

  const getBottomClass = () => {
    if (isMobile) {
      return 'terms-button-group-mobile';
    }
    return 'terms-button-group';
  };

  const onCancel = () => {
    deleteAllLocalStorage();
    history.push(routesPath.SIGN_IN);
  };

  const agreeTerms = () => {
    setAccepting(true);
    acceptTermsOfService(
      signInData.userId,
      signInData.apiKeyEureka,
      signInData.jwtToken
    )
      .then((res) => {
        if (res.data.setUserTerms.statusCode === 200) {
          history.push(routesPath.PERSONAL_INFO, {allow: true});
        } else {
          console.error('error in response: ', res);
        }
      })
      .catch((e) => console.error(e))
      .then(() => setAccepting(false));
  };

  useEffect(() => {
    // check if the component is allowed for rendering or not
    if (!state || !state.allow || signInData.userId === '') {
      history.push(routesPath.SIGN_IN);
    }
  }, [state, history]);

  return (
    <>
      <div className="terms-container">
        <PreAuthContainer>
          <TermsBody />
        </PreAuthContainer>

        <div className="fixed-bottom">
          <div className={getBottomClass()}>
            <OutlinePrimaryButton
              inputId="btn_cancel"
              label="Cancel"
              click={onCancel}
              disable={isAccepting}
            />
            <PrimaryButton
              inputId="btn_agree"
              label="Agree"
              classes="agree-button"
              disable={false}
              click={agreeTerms}
              labelInAction="Accepting..."
              isInAction={isAccepting}
            />
          </div>
        </div>
      </div>
    </>
  );
}
