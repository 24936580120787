import React from 'react';
import AddContact from '../../add-contact/add-contact';
import AddFeedback from '../../add-feedback/add-feedback';
import AlertConfirm from '../../alert-confirm/alert-confirm';
import HealthBound from '../../health-component/health-bound';
import TimeRange from '../../health-component/time-range-details';
import EditContact from '../../edit-contact/edit-contact';
import AddHealth from '../../health-symptoms/add-health';
import EditHealth from '../../health-symptoms/edit-health';
import AddMeal from '../../meals/add-meal';
import EditMeal from '../../meals/edit-meal';
import './modal.css';

export const modalType = {
  ADD_CONTACT: 'add-contact',
  EDIT_CONTACT: 'edit-contact',
  ADD_FEEDBACK: 'add-feedback',
  ALERT_CONFIRM: 'alert-confirm',
  ADD_MEAL: 'add-meal',
  EDIT_MEAL: 'edit-meal',
  ADD_HEALTH: 'add-health',
  EDIT_HEALTH: 'edit-health',
  BLOOD_GLUCOSE_BOUND: 'blood-glucose-bound',
  TIME_IN_RANGE: 'time-in-range',
};
export default function Modal(props) {
  const {isOpened, close, type, afterSubmitMethod, details, params} = props;

  const getModalContent = () => {
    switch (type) {
      case modalType.ADD_CONTACT:
        return (
          <AddContact close={close} afterSubmitMethod={afterSubmitMethod} />
        );
      case modalType.EDIT_CONTACT:
        return (
          <EditContact
            details={details}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.ADD_FEEDBACK:
        return (
          <AddFeedback close={close} afterSubmitMethod={afterSubmitMethod} />
        );
      case modalType.ALERT_CONFIRM:
        return (
          <AlertConfirm
            close={close}
            afterSubmitMethod={afterSubmitMethod}
            details={details}
          />
        );
      case modalType.ADD_MEAL:
        return (
          <AddMeal
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.EDIT_MEAL:
        return (
          <EditMeal
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.ADD_HEALTH:
        return (
          <AddHealth
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.EDIT_HEALTH:
        return (
          <EditHealth
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.BLOOD_GLUCOSE_BOUND:
        return (
          <HealthBound
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      case modalType.TIME_IN_RANGE:
        return (
          <TimeRange
            params={params}
            close={close}
            afterSubmitMethod={afterSubmitMethod}
          />
        );
      default:
        break;
    }
  };

  return <>{isOpened && <div className="modal">{getModalContent()}</div>}</>;
}
