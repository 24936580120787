import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {masterDataType} from '../../common/config';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getBloodGroup,
  getKnownDisease,
  updateBloodGroup,
  updateKnownDisease,
} from '../../rest-api/home-api/profile';
import {getMasterData} from '../../rest-api/masterData';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import CheckBoxWithLabel from '../common/check-boxes/check-box';
import DropDown from '../common/drop-downs/dropdown';
import ErrorMessage from '../common/errors/error-message';
import SuccessMessageSnackbar from '../common/success/success';
import PlainTextBoxWithLabel, {
  PlainTextBox,
} from '../common/textboxes/plain-textbox/plain-textbox';

export default function MedicalInfo() {
  const history = useHistory();
  const [successMessage, setSuccessful] = useState('');
  const [selectedKnownDiseaseList, updateKnownDiseaseList] = useState([]);
  const [knownDiseaseList, setKnownDiseaseList] = useState([]);
  const [otherDiseaseText, setOtherDisease] = useState('');
  const [errorMessageKnownDisease, setErrorMessageKnownDisease] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [isUpdating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toogleKnownDiseaseList = (value) => {
    if (selectedKnownDiseaseList.includes(value)) {
      updateKnownDiseaseList(
        selectedKnownDiseaseList.filter((s) => s !== value)
      );
    } else {
      updateKnownDiseaseList([...selectedKnownDiseaseList, value]);
    }
  };

  const getKnownDiseaseList = () => {
    getMasterData(masterDataType.KNOWNDISEASELIST)
      .then((res) => {
        if (res) {
          if (res.statusCode === 200) {
            // logic to push other as last item
            const diseaseList = res.body.data.master_data_list;
            let list = diseaseList.filter((v) => v.id !== 7);
            list.push(diseaseList.filter((v) => v.id === 7)[0]);
            setKnownDiseaseList(list);
          } else if (res.statusCode === 303) {
            deleteAllLocalStorage();
            history.push(routesPath.SIGN_IN);
          }
        } else {
          const message = `bloodgrouplist is not found`;
          throw new Error(message);
        }
      })
      .catch((e) => {
        setErrorMessageKnownDisease(e.message);
      });
  };

  const getUserBloodGroup = () => {
    getBloodGroup()
      .then((res) => {
        const {statusCode, body} = res.data.getBloodGroup;
        if (statusCode === 200) {
          setBloodGroup(body.data.id);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        }
      })
      .catch((e) => setErrorMessage(e.message));
  };

  const getUserKnownDisease = () => {
    getKnownDisease()
      .then((res) => {
        const {statusCode, body} = res.data.getKnownDisease;
        if (statusCode === 200) {
          const otherDisease = body.data.filter((b) => b.id === 7)[0];
          if (otherDisease) {
            setOtherDisease(otherDisease.others);
          }
          updateKnownDiseaseList(body.data.map((b) => b.id));
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        }
      })
      .catch((e) => setErrorMessage(e.message));
  };

  const updateMedicalInfo = () => {
    setUpdating(true);
    setErrorMessage('');
    setSuccessful('');
    const userId = getLocalStorage(localStorageKey.USERID);
    const knownDiseaseBody = {
      userId: userId,
      knownDisease: selectedKnownDiseaseList,
      otherDisease: otherDiseaseText,
    };
    updateBloodGroup(bloodGroup)
      .then((res) => {
        const {statusCode, body} = res.data.addBloodGroup;
        const {message} = JSON.parse(body);
        if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else if (statusCode !== 200) {
          throw new Error(message);
        }
      })
      .then(() => updateKnownDisease(knownDiseaseBody))
      .then((res) => {
        const {statusCode, body} = res.data.addKnownDisease;
        const {message} = JSON.parse(body);
        if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else if (statusCode !== 200) {
          throw new Error(message);
        }
      })
      .then(() =>
        setSuccessful('Your medical information has been updated successfully.')
      )
      .catch((e) => setErrorMessage(e.message))
      .then(() => setUpdating(false));
  };

  const isSubmitDisable = () => {
    return !(
      bloodGroup &&
      selectedKnownDiseaseList.length &&
      (selectedKnownDiseaseList.includes(7) ? otherDiseaseText.length : true)
    );
  };

  useEffect(() => {
    getKnownDiseaseList();
    getUserBloodGroup();
    getUserKnownDisease();
  }, []);
  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      {isUpdating && <LinearProgress indeterminate={true} />}
      <div className={isUpdating ? 'pre-authentication-disable-div' : ''}>
        <div className="lower-item-info-container">
          <div className="mt-1">
            <div>
              <b>Blood Type</b>
            </div>
            <DropDown
              inputId="dropdown_blood_type"
              value={bloodGroup}
              changeValue={setBloodGroup}
              dataType={masterDataType.BLOODGROUPLIST}
            />
          </div>

          <div className="mt-1">
            <div className="profile-name-text">Known Diseases</div>
            {errorMessageKnownDisease.length > 0 ? (
              <ErrorMessage
                classes="known-disease-loader"
                message={errorMessageKnownDisease}
              />
            ) : (
              <>
                {knownDiseaseList.length > 0 ? (
                  <>
                    {knownDiseaseList.map((obj) => (
                      <CheckBoxWithLabel
                        label={obj.value}
                        value={obj.id}
                        click={toogleKnownDiseaseList}
                        checked={selectedKnownDiseaseList.includes(obj.id)}
                      />
                    ))}
                    {selectedKnownDiseaseList.includes(7) && (
                      <PlainTextBox
                        inputId="textbox_other_disease"
                        value={otherDiseaseText}
                        changeValue={setOtherDisease}
                        classes="profile-other-disease-text-box"
                        label="Please type other diseases"
                      />
                    )}
                  </>
                ) : (
                  <div className="known-disease-loader">
                    Loading disease ...
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mt-2">
            <PrimaryButton
              inputId="btn_update"
              label="Save changes "
              labelInAction="Saving..."
              isInAction={isUpdating}
              disable={isSubmitDisable()}
              click={updateMedicalInfo}
            />
          </div>
        </div>
        {errorMessage.length > 0 && (
          <ErrorMessage message={errorMessage} classes="medical-info-error" />
        )}
      </div>
    </>
  );
}
