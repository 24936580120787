import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import editIcon from '../../assets/icons/common/edit.svg';
import profileIcon from '../../assets/icons/common/profile.svg';
import {deleteAllLocalStorage} from '../../common/localStorageHandler';
import {getContacts} from '../../rest-api/home-api/contact';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import ErrorMessage from '../common/errors/error-message';
import {alphabeticallySorting} from '../../common/calculation';

export default function EditContactInfo(props) {
  const [contactList, setContactList] = useState('');
  const history = useHistory();
  const [errorMessage, setErrorMesssage] = useState('');
  const {openContact, openEditContact} = props;

  const updateContactList = () => {
    getContacts()
      .then((res) => {
        const statusCode = res.data.getContact.statusCode;
        if (statusCode === 200) {
          const contactList = alphabeticallySorting(
            res.data.getContact.body.data,
            'name'
          );
          setContactList(contactList);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          const {message} = res.data.getContact.body.message;
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMesssage(e.message));
  };

  const ContactBox = (props) => {
    const {contact} = props;
    const {type, name} = contact;
    return (
      <>
        <div className="contact-box-container" style={{width: 'unset'}}>
          <div className="profile-contact-sub-container">
            <div className="profile-icon">
              <img src={profileIcon} alt="profile" />
            </div>
            <div className="profile-contact-data">
              <div className="profile-name">{name}</div>
              <div>({type})</div>
            </div>
          </div>
          <div
            className="profile-edit-icon"
            onClick={() => openEditContact(contact)}
          >
            <img src={editIcon} alt="edit" />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    updateContactList();
  }, []);

  return (
    <>
      <div className="profile-add-contact-container">
        <div className="profile-add-contact-header">
          Who do you want to share data with?
        </div>
        <div className="mt-1">You can share via email, text, or both.</div>
        {errorMessage.length > 0 ? (
          <ErrorMessage classes="known-disease-loader" message={errorMessage} />
        ) : (
          <>
            {contactList !== '' ? (
              <>
                {contactList.length > 0 ? (
                  <>
                    {contactList.map((obj, i) => (
                      <ContactBox key={i} contact={obj} />
                    ))}
                  </>
                ) : (
                  <>
                    <div
                      className="contact-box-container no-contact"
                      style={{width: 'unset'}}
                    >
                      No contact available
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="known-disease-loader">
                Loading contact list ...
              </div>
            )}
          </>
        )}

        <div className="mt-2">
          <PrimaryButton
            inputId={'id'}
            label="Add New Contact"
            labelInAction="Adding New Contact..."
            isInAction={false}
            disable={contactList === ''}
            click={openContact}
          />
        </div>
      </div>
    </>
  );
}
