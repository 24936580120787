export const getAddFeedbackBody = (obj) => {
  const {userId, message} = obj;
  const body = {
    query:
      'mutation MyMutation($userId: Int, $phoneModel: String, $osVersion: String, $message: String) {addFeedback(message: $message, osVersion: $osVersion, phoneModel: $phoneModel, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      message: message,
    },
  };
  return JSON.stringify(body);
};

export const getWatchSettingsBody = (obj) => {
  const {userId} = obj;
  const body = {
    query:
      'query MyQuery($userId: Int) {getSettings(userId: $userId) {statusCode body {message success data {autoMeasure autoMeasureFrequency cgmUnit updationDate}}}}',
    variables: {
      userId: userId,
    },
  };

  return JSON.stringify(body);
};

export const saveWatchSettingsBody = (obj) => {
  const {userId, autoMeasure, autoMeasureFrequency} = obj;
  const body = {
    query:
      'mutation MyMutation($userId: Int, $cgmUnit: Int, $autoMeasureFrequency: Int, $autoMeasure: Boolean = false) {saveSettings(autoMeasure: $autoMeasure, autoMeasureFrequency: $autoMeasureFrequency, cgmUnit: $cgmUnit, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      cgmUnit: 5,
      autoMeasure: autoMeasure,
      autoMeasureFrequency: autoMeasureFrequency,
    },
  };

  return JSON.stringify(body);
};
