import {HealthParameterType} from '../../common/config';
import {
  color,
  diastolicBloodPressureConf,
  fastingBarData,
  heartRateConf,
  oxygenSaturationConf,
  respirationRateConf,
  systolicBloodPressureConf,
} from '../settings/vital-config';
import DROP_GREEN from '../../assets/icons/overview/drop.svg';
import DROP_GREY from '../../assets/icons/overview/drop-grey.svg';
import DROP_RED from '../../assets/icons/overview/drop-red.svg';
import DROP_ORANGE from '../../assets/icons/overview/drop-orange.svg';
import DROP_YELLOW from '../../assets/icons/overview/drop-yellow.svg';
import HEART_GREEN from '../../assets/icons/overview/heart.svg';
import HEART_GREY from '../../assets/icons/overview/heart-grey.svg';
import HEART_RED from '../../assets/icons/overview/heart-red.svg';
import HEART_ORANGE from '../../assets/icons/overview/heart-orange.svg';
import HEART_YELLOW from '../../assets/icons/overview/heart-yellow.svg';
import HEART_WAITING_GREEN from '../../assets/icons/overview/heart-waiting.svg';
import HEART_WAITING_GREY from '../../assets/icons/overview/heart-waiting-grey.svg';
import HEART_WAITING_RED from '../../assets/icons/overview/heart-waiting-red.svg';
import HEART_WAITING_ORANGE from '../../assets/icons/overview/heart-waiting-orange.svg';
import HEART_WAITING_YELLOW from '../../assets/icons/overview/heart-waiting-yellow.svg';
import KIDNEY_GREEN from '../../assets/icons/overview/kidney.svg';
import KIDNEY_GREY from '../../assets/icons/overview/kidney-grey.svg';
import KIDNEY_RED from '../../assets/icons/overview/kidney-red.svg';
import KIDNEY_ORANGE from '../../assets/icons/overview/kidney-orange.svg';
import KIDNEY_YELLOW from '../../assets/icons/overview/kidney-yellow.svg';
import OXYGEN_SATURATION_GREEN from '../../assets/icons/overview/oxygen.svg';
import OXYGEN_SATURATION_GREY from '../../assets/icons/overview/oxygen-grey.svg';
import OXYGEN_SATURATION_RED from '../../assets/icons/overview/oxygen-red.svg';
import OXYGEN_SATURATION_ORANGE from '../../assets/icons/overview/oxygen-orange.svg';
import OXYGEN_SATURATION_YELLOW from '../../assets/icons/overview/oxygen-yellow.svg';

export const getIconWithColor = (type, value) => {
  switch (type) {
    case HealthParameterType.BLOOD_GLUCOSE:
      const configBloodGlucose = fastingBarData.filter(
        (f) => value >= f.range[0] && value <= f.range[1]
      )[0];
      if (!configBloodGlucose) {
        return DROP_GREY;
      }
      switch (configBloodGlucose.color) {
        case color.red:
          return DROP_RED;
        case color.orange:
          return DROP_ORANGE;
        case color.yellow:
          return DROP_YELLOW;
        case color.green:
          return DROP_GREEN;
        default:
          return DROP_GREY;
      }
    case HealthParameterType.HEART_RATE:
      const configHeartRate = heartRateConf.filter(
        (f) => value >= f.range[0] && value <= f.range[1]
      )[0];
      if (!configHeartRate) {
        return HEART_GREY;
      }
      switch (configHeartRate.color) {
        case color.red:
          return HEART_RED;
        case color.orange:
          return HEART_ORANGE;
        case color.yellow:
          return HEART_YELLOW;
        case color.green:
          return HEART_GREEN;
        default:
          return HEART_GREY;
      }
    case HealthParameterType.BLOOD_PRESSURE:
      const orderHighToLow = [
        color.red,
        color.orange,
        color.yellow,
        color.green,
      ];
      const sysValue = String(value).split('/')[0];
      const diasValue = String(value).split('/')[1];
      const configBloodPressureSys = systolicBloodPressureConf.filter(
        (f) => sysValue >= f.range[0] && sysValue <= f.range[1]
      )[0];
      const configBloodPressureDia = diastolicBloodPressureConf.filter(
        (f) => diasValue >= f.range[0] && diasValue <= f.range[1]
      )[0];
      if (!configBloodPressureSys || !configBloodPressureDia) {
        return HEART_WAITING_GREY;
      }
      const sysIndex = orderHighToLow.indexOf(configBloodPressureSys.color);
      const diasIndex = orderHighToLow.indexOf(configBloodPressureDia.color);
      let greaterIndex = 0;
      if (sysIndex > diasIndex) {
        greaterIndex = diasIndex;
      } else {
        greaterIndex = sysIndex;
      }
      const configColor = orderHighToLow[greaterIndex];
      switch (configColor) {
        case color.red:
          return HEART_WAITING_RED;
        case color.orange:
          return HEART_WAITING_ORANGE;
        case color.yellow:
          return HEART_WAITING_YELLOW;
        case color.green:
          return HEART_WAITING_GREEN;
        default:
          return HEART_WAITING_GREY;
      }
    case HealthParameterType.RESPIRATION_RATE:
      const configRespirationRate = respirationRateConf.filter(
        (f) => value >= f.range[0] && value <= f.range[1]
      )[0];
      if (!configRespirationRate) {
        return KIDNEY_GREY;
      }
      switch (configRespirationRate.color) {
        case color.red:
          return KIDNEY_RED;
        case color.orange:
          return KIDNEY_ORANGE;
        case color.yellow:
          return KIDNEY_YELLOW;
        case color.green:
          return KIDNEY_GREEN;
        default:
          return KIDNEY_GREY;
      }
    case HealthParameterType.OXYGEN_SATURATION:
      const configOxygenSaturation = oxygenSaturationConf.filter(
        (f) => value >= f.range[0] && value <= f.range[1]
      )[0];
      console.log('Oxygen config: ', value, configOxygenSaturation);
      if (!configOxygenSaturation) {
        if (value > 100) {
          return OXYGEN_SATURATION_RED;
        } else return OXYGEN_SATURATION_GREY;
      } else {
        switch (configOxygenSaturation.color) {
          case color.red:
            return OXYGEN_SATURATION_RED;
          case color.orange:
            return OXYGEN_SATURATION_ORANGE;
          case color.yellow:
            return OXYGEN_SATURATION_YELLOW;
          case color.green:
            return OXYGEN_SATURATION_GREEN;
          default:
            return OXYGEN_SATURATION_GREY;
        }
      }

    default:
      break;
  }
};
