import {getAverage, getHighestLowest} from '../common/calculation';
import {HealthParameterType, months, weekDay} from '../common/config';
import {getLocalStorage, localStorageKey} from '../common/localStorageHandler';
import {
  diastolicBloodPressureConf,
  fastingBarData,
  heartRateConf,
  mealBarAfter,
  mealBarWithin,
  oxygenSaturationConf,
  respirationRateConf,
  systolicBloodPressureConf,
} from '../components/settings/vital-config';
import {
  generateBloodGlucoseAverageByHourData,
  generateComplexGlucoseLineData,
} from '../fake-data-generator/bloodglucoseOther';
import {
  getDataInRange,
  getFormattedHour,
  TIME_TYPE,
} from '../fake-data-generator/common';
import {getAllMealsInRange} from '../rest-api/home-api/meal';
import {getAllHealthValues} from '../rest-api/home-api/overview';
import {getHealthConfig, getHealthConfigParam} from './health-common';
import {complexScatterY, USERSTATE} from './health-day-data';
import {getGraphLowerLines, getGraphUpperLines} from './utils';
import {getBloodGlucoseValue} from '../utils/glucose';

export const getMultiDayData = (startDate, endDate, type) => {
  console.log('multi day data');
  const startTime = new Date(startDate);
  startTime.setHours(0, 0, 0, 0);
  const endTime = new Date(endDate);
  endTime.setHours(23, 59, 0, 0);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  const meals = [];
  const param = getHealthConfigParam(type);
  const getUserState = (hour) => {
    let state = USERSTATE.MEALSAFTER;
    if (meals[0] && hour < meals[0].x) {
      state = USERSTATE.FAST;
    }
    meals.forEach((meal) => {
      if (hour >= Number(meal.x) && hour <= Number(meal.x) + 2)
        state = USERSTATE.MEALSWITHIN;
    });

    return state;
  };

  const getVitalConf = (userstate, type) => {
    if (type === HealthParameterType.BLOOD_GLUCOSE) {
      switch (userstate) {
        case USERSTATE.FAST:
          return fastingBarData;
        case USERSTATE.MEALSWITHIN:
          return mealBarWithin;
        case USERSTATE.MEALSAFTER:
          return mealBarAfter;
        default:
          break;
      }
    }
    switch (type) {
      case HealthParameterType.HEART_RATE:
        return heartRateConf;
      case HealthParameterType.OXYGEN_SATURATION:
        return oxygenSaturationConf;
      case HealthParameterType.RESPIRATION_RATE:
        return respirationRateConf;
      case HealthParameterType.BLOOD_PRESSURE:
        return [systolicBloodPressureConf, diastolicBloodPressureConf];
      default:
        return [];
    }
  };

  const getColorAndRange = (value, userState) => {
    let color = '';
    let stroke = '';
    const conf = getVitalConf(userState, type);
    if (type !== HealthParameterType.BLOOD_PRESSURE) {
      conf.forEach((data) => {
        const {range} = data;
        if (range[0] <= value && range[1] >= value) {
          color = data.color;
          stroke = data.borderColor;
        }
      });
    } else {
      color = [];
      stroke = [];
      conf[0].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[0] && range[1] >= value[0]) {
          color[0] = data.color;
          stroke[0] = data.borderColor;
        }
      });
      conf[1].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[1] && range[1] >= value[1]) {
          color[1] = data.color;
          stroke[1] = data.borderColor;
        }
      });
    }

    return {
      color: color,
      stroke: stroke,
    };
  };
  let multiDaysData = [];
  let fastingLine = [];

  return Promise.all([
    getAllHealthValues(body),
    type === HealthParameterType.BLOOD_GLUCOSE
      ? getAllMealsInRange(body)
      : {data: []},
  ]).then(([healthData, meals]) => {
    const {fetchData} = healthData.data;
    const {getMealListInRange} = meals.data;
    let allMeals = [];
    if (
      type === HealthParameterType.BLOOD_GLUCOSE &&
      getMealListInRange.statusCode === 200
    ) {
      allMeals = getMealListInRange.body.data;
    }
    if (fetchData?.statusCode === 200) {
      const allData = fetchData.body.data;
      for (
        let d = new Date(startTime);
        d < new Date(endTime);
        d.setDate(d.getDate() + 1)
      ) {
        let singleDayData = allData.filter(
          (response) =>
            d.toDateString() === new Date(Number(response.ts)).toDateString()
        );
        let singleDayMeals = allMeals.filter(
          (response) =>
            d.toDateString() ===
            new Date(Number(response.timestamp)).toDateString()
        );
        let eachDayData = [];
        let meals = [];
        singleDayMeals.forEach((meal, i) => {
          meals.push({
            type: String(meal.mealSize).toUpperCase(),
            x: d.getHours() + d.getMinutes() / 60,
            date: Number(meal.timestamp),
            yValue: 10,
          });
          if (i === 0) {
            fastingLine = {
              end: d.getHours() + d.getMinutes() / 60,
              yValue: 14,
              height: 5,
            };
          }
        });
        singleDayData.forEach((response) => {
          const data = JSON.parse(response.data);

          const y =
            type === HealthParameterType.BLOOD_PRESSURE
              ? [data[param[0]], data[param[1]]]
              : data[param];

          const date = new Date(Number(response.ts));
          const x = date.getHours() + date.getMinutes() / 60;
          const userState = getUserState(Math.floor(x));
          const colorConf = getColorAndRange(y, userState);
          if (typeof y === 'number') {
            eachDayData.push({
              x: x,
              date: date,
              y: y,
              color: colorConf.color,
              stroke: colorConf.stroke,
            });
          } else if (Array.isArray(y)) {
            if (y[0] && y[1]) {
              eachDayData.push({
                x: x,
                date: date,
                y: y,
                color: colorConf.color,
                stroke: colorConf.stroke,
              });
            }
          }
        });
        multiDaysData.push({
          date: new Date(d),
          eachDayData: eachDayData,
          activity: {
            start: getDataInRange(5, 7),
            end: getDataInRange(10, 12),
            yValue: 29,
            height: 20,
          },
          fastingLine: fastingLine,
          meals: meals,
        });
      }
    }
    return multiDaysData;
  });
};

export const generateComplexHealthLineData = async (
  date,
  typeOfTime,
  type,
  isFake
) => {
  if (isFake) {
    return Promise.resolve(generateComplexGlucoseLineData(date, typeOfTime));
  }

  const {range, diff, unitLabel, unit, converter} = await getHealthConfig(type);
  const requestedDate = new Date(date);
  let noOfDay = 0;
  if (typeOfTime === TIME_TYPE.WEEK) {
    noOfDay = 7;
  } else if (typeOfTime === TIME_TYPE.MONTH) {
    noOfDay = 30;
  } else if (typeOfTime === TIME_TYPE.YEAR) {
    noOfDay = 365;
  }
  const startDate = new Date(requestedDate);
  startDate.setDate(requestedDate.getDate() - noOfDay + 1);
  let multiDaysData = [];
  let xLabels = [];

  return getMultiDayData(startDate, requestedDate, type)
    .then((all) => {
      multiDaysData = all;
    })
    .then(() => {
      const grapghUpperLines = getGraphUpperLines(range, unit, converter);
      const graphLowerLines = getGraphLowerLines();

      for (let i = 0; i < noOfDay; i++) {
        if (typeOfTime === TIME_TYPE.WEEK) {
          xLabels.push(weekDay[requestedDate.getDay()]);
        } else if (
          typeOfTime === TIME_TYPE.MONTH ||
          typeOfTime === TIME_TYPE.YEAR
        ) {
          multiDaysData.forEach((eachDay) => {
            eachDay.activity = '';
            eachDay.fastingLine = '';
          });
          if ([0, 9, 19, 29].includes(i)) {
            xLabels.push(
              months[requestedDate.getMonth()].substr(0, 3) +
                ' ' +
                requestedDate.getDate()
            );
          } else {
            xLabels.push('');
          }
        }
        requestedDate.setDate(requestedDate.getDate() - 1);
      }

      return {
        requestedDate: requestedDate,
        yLabel: unitLabel,
        timeType: typeOfTime,
        noOfDay: noOfDay,
        xLabels: xLabels.reverse(),
        multiDaysData: multiDaysData,
        graphUpperLines: grapghUpperLines,
        graphLowerLines: graphLowerLines,
        scatterLow: range[0],
        scatterHigh: range[1],
        complexScatterY: complexScatterY(range, diff),
        type: type,
      };
    });
};

export const generateHealthAverageByHourData = async (
  date,
  noOfDay,
  type,
  isFake
) => {
  if (isFake) {
    return Promise.resolve(
      generateBloodGlucoseAverageByHourData(date, noOfDay)
    );
  }
  const {param, range, diff, unitLabel, unit, converter} =
    await getHealthConfig(type);

  const getVitalConf = (type) => {
    switch (type) {
      case HealthParameterType.BLOOD_GLUCOSE:
        return fastingBarData;
      case HealthParameterType.HEART_RATE:
        return heartRateConf;
      case HealthParameterType.OXYGEN_SATURATION:
        return oxygenSaturationConf;
      case HealthParameterType.RESPIRATION_RATE:
        return respirationRateConf;
      case HealthParameterType.BLOOD_PRESSURE:
        return [systolicBloodPressureConf, diastolicBloodPressureConf];
      default:
        return [];
    }
  };

  const getColorAndRange = (value) => {
    let color = '';
    let stroke = '';
    const conf = getVitalConf(type);
    if (type !== HealthParameterType.BLOOD_PRESSURE) {
      conf.forEach((data) => {
        const {range} = data;
        if (range[0] <= value && range[1] >= value) {
          color = data.color;
          stroke = data.borderColor;
        }
      });
    } else {
      color = [];
      stroke = [];
      conf[0].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[0] && range[1] >= value[0]) {
          color[0] = data.color;
          stroke[0] = data.borderColor;
        }
      });
      conf[1].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[1] && range[1] >= value[1]) {
          color[1] = data.color;
          stroke[1] = data.borderColor;
        }
      });
    }

    return {
      color: color,
      stroke: stroke,
    };
  };

  const requestedDate = new Date(date);
  const startTime = new Date(date);
  startTime.setDate(startTime.getDate() - noOfDay * 5 + 1);
  startTime.setHours(0, 0, 0, 0);
  const endTime = new Date(date);
  endTime.setHours(23, 59);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  console.log('Start and end ts: ', startTime, endTime);
  let currentLineData = [];
  let previousLinesData = [];

  return getAllHealthValues(body).then((healthData) => {
    const {fetchData} = healthData.data;
    console.log('Fetch data1: ', fetchData);
    if (fetchData?.statusCode === 200) {
      const allData = fetchData.body.data;
      for (let i = 0; i < 5; i++) {
        const startDate = new Date(startTime);
        startDate.setDate(startDate.getDate() + i * noOfDay);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + noOfDay - 1);
        const startMonth = months[new Date(startDate).getMonth()].substr(0, 3);
        const endMonth = months[new Date(endDate).getMonth()].substr(0, 3);
        const tooltipLowerText =
          startMonth +
          ' ' +
          startDate.getDate() +
          ' - ' +
          endMonth +
          ' ' +
          endDate.getDate();
        let hourData = [];
        const dataOfTheWeek = allData.filter((response) => {
          const date = new Date(Number(response.ts));
          return (
            date.getTime() >= startDate.getTime() &&
            date.getTime() <= endDate.getTime()
          );
        });
        for (let i = 0; i < 24; i++) {
          const healthData = dataOfTheWeek.filter(
            (eachData) => i === new Date(Number(eachData.ts)).getHours()
          );
          const yValues =
            type === HealthParameterType.BLOOD_PRESSURE
              ? [
                  healthData.map(
                    (eachData) => JSON.parse(eachData.data)[param[0]]
                  ),
                  healthData.map(
                    (eachData) => JSON.parse(eachData.data)[param[1]]
                  ),
                ]
              : healthData.map((eachData) => JSON.parse(eachData.data)[param]);
          // console.log("Yvalues in average:", yValues[0].filter((d) => d !== undefined))
          // const yValues = healthData.map((eachData) => JSON.parse(eachData.data)[param]);
          const average =
            type === HealthParameterType.BLOOD_PRESSURE
              ? [
                  getAverage(yValues[0].filter((d) => d !== undefined)),
                  getAverage(yValues[1].filter((d) => d !== undefined)),
                ]
              : getAverage(yValues);
          const highLow =
            type === HealthParameterType.BLOOD_PRESSURE
              ? [
                  getHighestLowest(yValues[0].filter((d) => d !== undefined)),
                  getHighestLowest(yValues[1].filter((d) => d !== undefined)),
                ]
              : getHighestLowest(yValues);
          const colorConf = getColorAndRange(average);
          hourData[i] = {
            x: i,
            y: average ? average : 0,
            color: colorConf.color,
            stroke: colorConf.stroke,
            highest:
              type === HealthParameterType.BLOOD_PRESSURE
                ? highLow[0].highest
                : highLow.highest,
            lowest:
              type === HealthParameterType.BLOOD_PRESSURE
                ? highLow[1].lowest
                : highLow.lowest,
          };
        }
        previousLinesData.push({
          start: startDate,
          end: endDate,
          tooltipLowerText: tooltipLowerText,
          hourData: hourData,
        });
      }
    }

    currentLineData = previousLinesData[previousLinesData.length - 1];
    console.log('COMPLEX SCATTER CURRENTLINE: ', currentLineData);
    let xLabels = [];
    const graphLines = [
      {
        yValue: range[0] - 10,
        label: converter(range[0] - 10, unit),
        isDotted: false,
      },
      ...getGraphUpperLines(range, unit, converter),
    ];
    for (let i = 0; i <= 24; i++) {
      if ([0, 24, 12].includes(i)) {
        xLabels.push(getFormattedHour(i));
      } else {
        xLabels.push('');
      }
    }
    return {
      requestedDate: requestedDate,
      yLabel: unitLabel,
      xLabels: xLabels,
      currentLine: currentLineData,
      previousLinesData: previousLinesData,
      graphLines: graphLines,
      graphLow: range[0],
      graphHigh: range[1],
      type: type,
      complexScatterY: complexScatterY(range, diff),
    };
  });
};
