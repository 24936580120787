import {
  getForgetPasswordBody,
  getSignInBody,
  getUserStateBody,
} from '../body/userModule';
import {
  getHeadersWithKey,
  getHeadersWithKeyAndToken,
  headers,
  URL,
} from '../config';
import {httpGetRequest, httpPostRequest} from '../httpHandler';

export const doLogin = (email, password) => {
  const body = getSignInBody(email, password);
  return httpPostRequest(URL[URL.currentENV].login, headers.onlyType, body);
};

export const doLoginWithToken = (email, passwordToken) => {
  const body = JSON.stringify({email, passwordToken});
  return httpPostRequest(URL[URL.currentENV].login, headers.onlyType, body);
};

export const getUserState = (userId, apiKey, token) => {
  const body = getUserStateBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apiKey, token),
    body
  );
};

export const forgetPassword = (emailId) => {
  const body = getForgetPasswordBody(emailId);
  return httpGetRequest(URL[URL.currentENV].getApiKey, {})
    .then((res) => {
      if (res.statusCode === 200) {
        return getHeadersWithKey(res.data.apiKeyGPL);
      } else {
        throw new Error('Key not found');
      }
    })
    .then((header) =>
      httpPostRequest(URL[URL.currentENV].graphQlAdmin, header, body)
    );
};
