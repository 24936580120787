import {months, weekDay} from '../common/config';
import {getAvg, getDataInRange, getFormattedHour, getSum} from './common';

export const getAreaDailyFooterText = (moreStep, isToday) => {
  return `You’ve taken ${moreStep} more step(s) than ${
    isToday ? 'yesterday' : 'previous day'
  }.`;
};

export const getBarDailyFooterText = (data) => {
  let higestValue = 0;
  data.forEach((value) => {
    if (value > higestValue) {
      higestValue = value;
    }
  });
  const index = data.indexOf(higestValue);
  return `Your most steps ${higestValue} were between ${getFormattedHour(
    index
  )} and ${getFormattedHour(index + 1)}.`;
};

export const getBarFooterTextInRange = (goal, times, lastText) => {
  return `You reached your goal of ${
    goal ? goal : 5000
  } step(s) ${times} time(s) ${lastText}.`;
};
export const generateStepDayData = (date) => {
  const requestedDate = new Date(date);
  let lastHour = requestedDate.getHours();

  if (
    lastHour === 0 ||
    requestedDate.toDateString() !== new Date().toDateString()
  ) {
    lastHour = 24;
  } else {
    lastHour += 1;
  }
  let barColors = ['#1a74d4', '#3794fc'];
  let barData = [];
  let barLabels = [];
  let toolTipObjects = [];
  let min = getDataInRange(30, 60);
  let max = getDataInRange(500, 600);
  let yAxislabels = [
    {
      value: max + 50,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  for (let i = 0; i < lastHour; i++) {
    const value = getDataInRange(min, max);
    barData.push({
      value: value,
      label:
        i === 0
          ? '12 am'
          : i === lastHour - 1
          ? '< ' + getFormattedHour(lastHour)
          : '',
    });
    toolTipObjects.push({
      value: value,
      upper: 'steps',
      lower:
        i === 0
          ? '12am - 1am'
          : getFormattedHour(i) + ' - ' + getFormattedHour(i + 1),
    });
    barLabels.push(
      i === 0
        ? '12 am'
        : i === lastHour - 1
        ? '< ' + getFormattedHour(lastHour)
        : ''
    );
  }

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max + 50,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    average: getAvg(barData.map((b) => b.value)),
    sum: getSum(barData.map((b) => b.value)),
  };
};

export const generateStepWeekData = (date) => {
  const requestedDate = new Date(date);
  const currentDay = requestedDate.getDay();
  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];
  let max = getDataInRange(7000, 9000);
  let yAxislabels = [
    {
      value: max + 500,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  for (let i = 0; i < 7; i++) {
    let date = new Date(requestedDate);
    date.setDate(date.getDate() - 6 + i);
    const label =
      weekDay[
        currentDay + 1 + i < 7 ? currentDay + 1 + i : (currentDay + i + 1) % 7
      ];
    const value = getDataInRange(4000, max);
    barData.push({
      value: value,
      label: label,
    });
    toolTipObjects.push({
      value: value,
      upper: 'steps',
      lower:
        months[date.getMonth()].substring(0, 3) +
        ' ' +
        date.getDate() +
        ', ' +
        date.getFullYear(),
    });
  }

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max + 500,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    average: getAvg(barData.map((b) => b.value)),
    sum: getSum(barData.map((b) => b.value)),
    footerText: '',
  };
};

export const generateStepMonthData = (date) => {
  const requestedDate = new Date(date);
  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];
  let max = getDataInRange(7000, 9000);
  let yAxislabels = [
    {
      value: max + 500,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  for (let i = 0; i < 30; i++) {
    let date = new Date(requestedDate);
    date.setDate(date.getDate() - 29 + i);
    const value = getDataInRange(4000, max);
    barData.push({
      value: value,
      label:
        (i % 7 === 0 && i !== 28) || i === 29
          ? months[date.getMonth()].substring(0, 3) + ' ' + date.getDate()
          : '',
    });
    toolTipObjects.push({
      value: value,
      upper: 'steps',
      lower: months[date.getMonth()] + ' ' + date.getDate(),
    });
  }

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max + 500,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    average: getAvg(barData.map((b) => b.value)),
    sum: getSum(barData.map((b) => b.value)),
    footerText: '',
  };
};

export const generateStepYearData = (date) => {
  const requestedDate = new Date(date);
  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];
  let max = getDataInRange(8000, 12000);
  let yAxislabels = [
    {
      value: max + 500,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  for (let i = 0; i < 12; i++) {
    let date = new Date(requestedDate);
    const value = getDataInRange(2500, max);
    date.setMonth(date.getMonth() - 11 + i);
    barData.push({
      value: value,
      label:
        i === 0 || i === 11
          ? months[date.getMonth()].substring(0, 3) +
            ' ' +
            Number(date.getFullYear())
          : '',
      tooltipLower: months[date.getMonth()] + ' ' + Number(date.getFullYear()),
    });
    toolTipObjects.push({
      value: value,
      upper: 'steps',
      lower: months[date.getMonth()] + ' ' + Number(date.getFullYear()),
    });
  }

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max + 500,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    average: getAvg(barData.map((b) => b.value)),
    sum: getSum(barData.map((b) => b.value)),
    footerText: '',
  };
};

export const generateStepsAreaData = (barObject) => {
  const {barData, toolTipObjects} = barObject;
  let areaGraphData = [];
  const barValues = barData.map((b) => b.value);
  const barLabels = barData.map((b) => b.label);
  let toolTipObjectsArray = [];

  const xAxisDistance = 90 / barValues.length;

  let sum = 0;
  barData.forEach((data, i) => {
    sum += data.value;
    areaGraphData.push({
      x: i * xAxisDistance + 10,
      y: sum,
    });
    toolTipObjectsArray.push({
      value: sum,
      upper: 'steps',
      lower: toolTipObjects[i].lower.split('-')[0],
    });
  });
  const maxValue = areaGraphData[areaGraphData.length - 1].y;
  const max = maxValue !== 0 ? maxValue : 50;
  let yAxislabels = [
    {
      value: max,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];
  console.log('YAXISLABELs: ', yAxislabels);
  return {
    areaGraphData: areaGraphData,
    yAxislabels: yAxislabels,
    yLabels: barLabels,
    maxValue: max,
    toolTipObjects: toolTipObjectsArray,
  };
};
