import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import LinearProgress from '@material/react-linear-progress';
import React, {useState} from 'react';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {addSymptom} from '../../rest-api/home-api/health';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import ErrorMessage from '../common/errors/error-message';
import TextArea from '../common/textarea/textarea';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';

export default function AddHealth(props) {
  const {close, afterSubmitMethod, params} = props;
  const [isSubmiting, setSubmiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTimePickerOpened, openTimePicker] = useState(false);
  const [selectedTime, setTime] = useState(params.selectedDate);
  const [symptomType, setSymptomType] = useState('');
  const [details, setDetails] = useState('');

  const addNewSymptom = (e) => {
    e.preventDefault();
    setSubmiting(true);
    setErrorMessage('');
    afterSubmitMethod('');

    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId: userId,
      symptomType: symptomType,
      timestamp: selectedTime.getTime(),
      details: details,
    };

    addSymptom(body)
      .then((res) => {
        const {message} = JSON.parse(res.data.addSymptoms.body);
        if (res.data.addSymptoms.statusCode === 200) {
          close();
          afterSubmitMethod(message);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const getTime = () => {
    if (selectedTime.getHours() > 12) {
      return (
        ('0' + (selectedTime.getHours() - 12)).slice(-2) +
        ' : ' +
        ('0' + selectedTime.getMinutes()).slice(-2) +
        ' PM'
      );
    } else {
      return (
        ('0' + selectedTime.getHours()).slice(-2) +
        ' : ' +
        (' 0' + selectedTime.getMinutes()).slice(-2) +
        ' AM'
      );
    }
  };

  const changeTime = (value) => {
    setErrorMessage('');
    const currentTimeStamp = new Date().getTime();
    if (currentTimeStamp < value.getTime()) {
      setErrorMessage("You can't enter future time");
    } else {
      setTime(value);
    }
  };

  return (
    <>
      <div className="add-contact-container">
        <div className="add-contact-header">
          <div className="add-contact-header-text">Add Symptom</div>
          <i
            className="fa fa-close"
            onClick={close}
            style={{color: 'dodgerblue', cursor: 'pointer'}}
          />
        </div>
        {isSubmiting && <LinearProgress indeterminate={true} />}
        <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
          <form onSubmit={addNewSymptom}>
            <div className="add-contact-body">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  label="12 hours"
                  value={selectedTime}
                  open={isTimePickerOpened}
                  onClose={() => openTimePicker(false)}
                  onChange={changeTime}
                  TextFieldComponent={() => (
                    <div className="mt-1">
                      <PlainTextBoxWithLabel
                        classes="add-meal-textbox"
                        value={getTime()}
                        label="Time"
                        click={() => openTimePicker(true)}
                      />
                    </div>
                  )}
                />
              </MuiPickersUtilsProvider>

              <div className="mt-1">
                <div className="add-health-header-text">Symptom</div>
                <DropDown
                  dataType="getSymptomsType"
                  value={symptomType}
                  changeValue={setSymptomType}
                  messageClass="add-meal-textbox"
                  classes="symptom-dropdown"
                />
              </div>
              <div className="textarea-label">Details</div>
              <div style={{width: '97%'}}>
                <TextArea
                  value={details}
                  changeValue={setDetails}
                  placeholder="Add notes"
                />
              </div>
              <div className="add-contact-text-box">
                <PrimaryButton
                  disable={symptomType === ''}
                  labelInAction="Adding ..."
                  isInAction={isSubmiting}
                  label="Add"
                  classes="add-contact-downdown"
                  click={addNewSymptom}
                />
              </div>
              {errorMessage.length > 0 && (
                <ErrorMessage
                  classes="add-contact-text-box-input"
                  message={errorMessage}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
