export const color = {
  red: '#f86363',
  orange: '#ffa42c',
  yellow: '#fff573',
  green: '#2ee48a',
};

export const borderColor = {
  red: '#b42f2f',
  orange: '#c67001',
  yellow: '#998f00',
  green: '#108d4e',
};

export const fastingBarData = [
  {
    color: color.red,
    value: 60,
    range: [0, 60],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 65,
    range: [60, 65],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 70,
    range: [65, 70],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 100,
    range: [70, 100],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 125,
    range: [100, 125],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 350,
    range: [125, 350],
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    range: [350, 400],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const mealBarWithin = [
  {
    color: color.red,
    value: 60,
    range: [0, 60],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 65,
    range: [60, 65],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 90,
    range: [65, 90],
    borderColor: borderColor.yellow,
    width: '7%',
  },
  {
    color: color.green,
    value: 190,
    range: [90, 190],
    borderColor: borderColor.green,
    width: '43%',
  },
  {
    color: color.yellow,
    value: 230,
    range: [190, 230],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.orange,
    value: 350,
    range: [230, 350],
    borderColor: borderColor.orange,
    width: '10%',
  },
  {
    color: color.red,
    value: 0,
    range: [350, 400],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const mealBarAfter = [
  {
    color: color.red,
    value: 60,
    range: [0, 60],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 65,
    range: [60, 65],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 90,
    range: [65, 90],
    borderColor: borderColor.yellow,
    width: '15%',
  },
  {
    color: color.green,
    value: 140,
    range: [90, 140],
    borderColor: borderColor.green,
    width: '18%',
  },
  {
    color: color.yellow,
    value: 200,
    range: [140, 200],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 350,
    range: [200, 350],
    borderColor: borderColor.orange,
    width: '15%',
  },
  {
    color: color.red,
    value: 0,
    range: [350, 400],
    borderColor: borderColor.red,
    width: '13%',
  },
];

const systolicBloodPressure = [
  {
    color: color.red,
    value: 80,
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 85,
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 90,
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 130,
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 140,
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 180,
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    borderColor: borderColor.red,
    width: '10%',
  },
];

const diastolicBloodPressure = [
  {
    color: color.red,
    value: 50,
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 55,
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 60,
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 90,
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 100,
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 110,
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const heartRateConf = [
  {
    color: color.red,
    value: 40,
    range: [0, 40],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 50,
    range: [41, 50],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 60,
    range: [51, 60],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 100,
    range: [61, 100],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 110,
    range: [101, 110],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 130,
    range: [111, 130],
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    range: [131, 1000],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const respirationRateConf = [
  {
    color: color.red,
    value: 10,
    range: [0, 10],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 11,
    range: [11, 11],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 12,
    range: [12, 12],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 20,
    range: [13, 20],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 22,
    range: [21, 22],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 30,
    range: [23, 30],
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    range: [30, 1000],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const oxygenSaturationConf = [
  {
    color: color.green,
    value: 100,
    range: [95, 100],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 95,
    range: [90, 95],
    borderColor: borderColor.yellow,
    width: '25%',
  },
  {
    color: color.orange,
    value: 90,
    range: [85, 90],
    borderColor: borderColor.orange,
    width: '25%',
  },
  {
    color: color.red,
    value: 85,
    range: [0, 85],
    borderColor: borderColor.red,
    width: '25%',
  },
];

export const systolicBloodPressureConf = [
  {
    color: color.red,
    value: 80,
    range: [0, 80],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 85,
    range: [81, 85],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 90,
    range: [86, 90],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 130,
    range: [91, 130],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 140,
    range: [131, 140],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 180,
    range: [141, 180],
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    range: [181, 1000],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const diastolicBloodPressureConf = [
  {
    color: color.red,
    value: 50,
    range: [0, 50],
    borderColor: borderColor.red,
    width: '25%',
  },
  {
    color: color.orange,
    value: 55,
    range: [51, 55],
    borderColor: borderColor.orange,
    width: '5%',
  },
  {
    color: color.yellow,
    value: 60,
    range: [56, 60],
    borderColor: borderColor.yellow,
    width: '5%',
  },
  {
    color: color.green,
    value: 90,
    range: [61, 90],
    borderColor: borderColor.green,
    width: '25%',
  },
  {
    color: color.yellow,
    value: 100,
    range: [91, 100],
    borderColor: borderColor.yellow,
    width: '10%',
  },
  {
    color: color.orange,
    value: 110,
    range: [101, 110],
    borderColor: borderColor.orange,
    width: '20%',
  },
  {
    color: color.red,
    value: 0,
    range: [111, 1000],
    borderColor: borderColor.red,
    width: '10%',
  },
];

export const bloodGlucose = [
  {
    label: 'Fasting',
    data: fastingBarData,
  },
  {
    label: 'After meal (within 2 hours)',
    data: mealBarWithin,
  },
  {
    label: 'After meal (after 2 hours)',
    data: mealBarAfter,
  },
];

export const bloodPressure = [
  {
    label: 'Systolic Blood Pressure',
    data: systolicBloodPressure,
  },
  {
    label: 'Diastolic Blood Pressure',
    data: diastolicBloodPressure,
  },
];

export const heartRate = [
  {
    label: 'Heart Rate',
    data: heartRateConf,
  },
];

export const respirationRate = [
  {
    label: 'Respiration Rate',
    data: respirationRateConf,
  },
];

export const oxygenSaturation = [
  {
    label: 'O2 Saturation',
    data: oxygenSaturationConf,
  },
];
