import {months, weekDay} from '../common/config';
import {getLocalStorage, localStorageKey} from '../common/localStorageHandler';
import {
  getAvg,
  getDataInRange,
  getFormattedHour,
  getSum,
} from '../fake-data-generator/common';
import {
  generateStepDayData,
  generateStepMonthData,
  generateStepWeekData,
  generateStepYearData,
} from '../fake-data-generator/steps-walked';
import {getStepsCount} from '../rest-api/home-api/steps';

export const getStepsBarData = (date, isFake) => {
  if (isFake) {
    return Promise.resolve(generateStepDayData(date));
  }

  const requestedDate = new Date(date);
  let lastHour = requestedDate.getHours();
  let barColors = ['#1a74d4', '#3794fc'];
  let barData = [];
  let toolTipObjects = [];

  if (
    lastHour === 0 ||
    requestedDate.toDateString() !== new Date().toDateString()
  ) {
    lastHour = 24;
  } else {
    lastHour += 1;
  }

  const startTime = new Date(date);
  const endTime = new Date(date);
  startTime.setHours(0, 0, 0);
  endTime.setHours(23, 59, 59);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  return getStepsCount(body)
    .then((res) => {
      const allStepsData = res.data.getStepCount.body.data;
      for (let i = 0; i < lastHour; i++) {
        const dataOfTheHour = allStepsData.filter((data) => {
          return new Date(Number(data.ts)).getHours() === i;
        });
        let count = 0;
        dataOfTheHour.forEach((hourData) => {
          count += hourData.count;
        });
        barData.push({
          value: count,
          label:
            i === 0
              ? '12 am'
              : i === lastHour - 1
              ? '< ' + getFormattedHour(lastHour)
              : '',
        });
        toolTipObjects.push({
          value: count,
          upper: 'steps',
          lower:
            i === 0
              ? '12am - 1am'
              : getFormattedHour(i) + ' - ' + getFormattedHour(i + 1),
        });
      }
    })
    .then(() => {
      let max = Math.max(...barData.map((b) => b.value));
      if (max === 0) {
        max = 50;
      }
      let yAxislabels = [
        {
          value: max,
          yAxisPercentage: 1,
        },
        {
          value: Math.floor(max / 2),
          yAxisPercentage: 0.5,
        },
        {
          value: 0,
          yAxisPercentage: 0.05,
        },
      ];
      return {
        barColors: barColors,
        barData: barData,
        maxValue: max,
        toolTipObjects: toolTipObjects,
        yAxislabels: yAxislabels,
        average: getAvg(barData.map((b) => b.value)),
        sum: getSum(barData.map((b) => b.value)),
      };
    });
};

export const getStepsWeekBarData = (date, isFake) => {
  if (isFake) {
    return Promise.resolve(generateStepWeekData(date));
  }

  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];

  const startTime = new Date(date);
  const endTime = new Date(date);
  startTime.setDate(startTime.getDate() - 6);
  startTime.setHours(0, 0, 0);
  endTime.setHours(23, 59, 59);
  const requestedDate = new Date(date);
  const currentDay = requestedDate.getDay();
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  return getStepsCount(body)
    .then((res) => {
      const allStepsData = res.data.getStepCount.body.data;
      for (let i = 0; i < 7; i++) {
        const date = new Date(requestedDate);
        date.setDate(date.getDate() - 6 + i);
        const dataOfTheDay = allStepsData.filter((data) => {
          return new Date(Number(data.ts)).getDate() === date.getDate();
        });
        let count = 0;
        dataOfTheDay.forEach((hourData) => {
          count += hourData.count;
        });
        const label =
          weekDay[
            currentDay + 1 + i < 7
              ? currentDay + 1 + i
              : (currentDay + i + 1) % 7
          ];
        const value = count;
        barData.push({
          value: value,
          label: label,
        });
        toolTipObjects.push({
          value: value,
          upper: 'steps',
          lower:
            months[date.getMonth()].substring(0, 3) +
            ' ' +
            date.getDate() +
            ', ' +
            date.getFullYear(),
        });
      }
    })
    .then(() => {
      let max = Math.max(...barData.map((b) => b.value));
      if (max === 0) {
        max = 50;
      }
      let yAxislabels = [
        {
          value: max,
          yAxisPercentage: 1,
        },
        {
          value: Math.floor(max / 2),
          yAxisPercentage: 0.5,
        },
        {
          value: 0,
          yAxisPercentage: 0.05,
        },
      ];
      return {
        barColors: barColors,
        barData: barData,
        maxValue: max,
        toolTipObjects: toolTipObjects,
        yAxislabels: yAxislabels,
        average: getAvg(barData.map((b) => b.value)),
        sum: getSum(barData.map((b) => b.value)),
      };
    });
};

export const getStepMonthBarData = (date, isFake) => {
  if (isFake) {
    return Promise.resolve(generateStepMonthData(date));
  }

  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];

  const startTime = new Date(date);
  const endTime = new Date(date);
  startTime.setDate(startTime.getDate() - 29);
  startTime.setHours(0, 0, 0);
  endTime.setHours(23, 59, 59);
  const requestedDate = new Date(date);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  return getStepsCount(body)
    .then((res) => {
      const allStepsData = res.data.getStepCount.body.data;
      for (let i = 0; i < 30; i++) {
        const date = new Date(requestedDate);
        date.setDate(date.getDate() - 29 + i);
        const dataOfTheDay = allStepsData.filter((data) => {
          return new Date(Number(data.ts)).getDate() === date.getDate();
        });
        let count = 0;
        dataOfTheDay.forEach((hourData) => {
          count += hourData.count;
        });
        const label =
          (i % 7 === 0 && i !== 28) || i === 29
            ? months[date.getMonth()].substring(0, 3) + ' ' + date.getDate()
            : '';
        const value = count;
        barData.push({
          value: value,
          label: label,
        });
        toolTipObjects.push({
          value: value,
          upper: 'steps',
          lower: months[date.getMonth()] + ' ' + date.getDate(),
        });
      }
    })
    .then(() => {
      let max = Math.max(...barData.map((b) => b.value));
      if (max === 0) {
        max = 50;
      }
      let yAxislabels = [
        {
          value: max,
          yAxisPercentage: 1,
        },
        {
          value: Math.floor(max / 2),
          yAxisPercentage: 0.5,
        },
        {
          value: 0,
          yAxisPercentage: 0.05,
        },
      ];
      return {
        barColors: barColors,
        barData: barData,
        maxValue: max,
        toolTipObjects: toolTipObjects,
        yAxislabels: yAxislabels,
        average: getAvg(barData.map((b) => b.value)),
        sum: getSum(barData.map((b) => b.value)),
      };
    });
};

export const getStepYearBarData = (date, isFake) => {
  if (isFake) {
    return Promise.resolve(generateStepYearData(date));
  }

  let barColors = ['#00a2c7', '#00d0ff'];
  let barData = [];
  let toolTipObjects = [];

  const startTime = new Date(date);
  const endTime = new Date(date);
  startTime.setDate(startTime.getDate() - 365);
  startTime.setHours(0, 0, 0);
  endTime.setHours(23, 59, 59);
  const requestedDate = new Date(date);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  return getStepsCount(body)
    .then((res) => {
      const allStepsData = res.data.getStepCount.body.data;
      for (let i = 0; i <= 12; i++) {
        const date = new Date(requestedDate);
        date.setMonth(date.getMonth() - i);
        const dataOfTheMonth = allStepsData.filter(
          (data) =>
            new Date(Number(data.ts)).getMonth() === date.getMonth() &&
            new Date(Number(data.ts)).getFullYear() === date.getFullYear()
        );
        let count = 0;
        dataOfTheMonth.forEach((hourData) => {
          count += hourData.count;
        });
        const label =
          i === 0 || i === 12
            ? months[date.getMonth()].substring(0, 3) +
              ' ' +
              Number(date.getFullYear())
            : '';
        const value = count;
        barData.push({
          value: value,
          label: label,
          tooltipLower:
            months[date.getMonth()] + ' ' + Number(date.getFullYear()),
        });
        toolTipObjects.push({
          value: value,
          upper: 'steps',
          lower: months[date.getMonth()] + ' ' + Number(date.getFullYear()),
        });
      }
    })
    .then(() => {
      let max = Math.max(...barData.map((b) => b.value));
      if (max === 0) {
        max = 50;
      }
      let yAxislabels = [
        {
          value: max,
          yAxisPercentage: 1,
        },
        {
          value: Math.floor(max / 2),
          yAxisPercentage: 0.5,
        },
        {
          value: 0,
          yAxisPercentage: 0.05,
        },
      ];
      return {
        barColors: barColors,
        barData: barData.reverse(),
        maxValue: max,
        toolTipObjects: toolTipObjects.reverse(),
        yAxislabels: yAxislabels,
        average: getAvg(barData.map((b) => b.value)),
        sum: getSum(barData.map((b) => b.value)),
      };
    });
};

export const generateStepsAreaData = (barObject) => {
  console.log('Barobject', barObject);
  const {barData, toolTipObjects} = barObject;
  let areaGraphData = [];
  const barValues = barData.map((b) => b.value);
  const barLabels = barData.map((b) => b.label);
  let toolTipObjectsArray = [];

  const xAxisDistance = 90 / barValues.length;

  let sum = 0;
  barData.forEach((data, i) => {
    sum += data.value;
    areaGraphData.push({
      x: i * xAxisDistance + 10,
      y: sum,
    });
    toolTipObjectsArray.push({
      value: sum,
      upper: 'steps',
      lower: toolTipObjects[i].lower.split('-')[0],
    });
  });

  const max = areaGraphData[areaGraphData.length - 1].y;
  let yAxislabels = [
    {
      value: max,
      yAxisPercentage: 1,
    },
    {
      value: Math.floor(max / 2),
      yAxisPercentage: 0.5,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];
  return {
    areaGraphData: areaGraphData,
    yAxislabels: yAxislabels,
    yLabels: barLabels,
    toolTipObjects: toolTipObjectsArray,
  };
};
