import LinearProgress from '@material/react-linear-progress';
import React, {useState} from 'react';
import {isValidEmail} from '../../common/emailHandler';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {addContact} from '../../rest-api/home-api/contact';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import ErrorMessage from '../common/errors/error-message';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';
import './add-contact.css';

export default function AddContact(props) {
  const {close, afterSubmitMethod} = props;
  const [name, setName] = useState('');
  const [contactType, setContactType] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmiting, setSubmiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addNewContact = (e) => {
    e.preventDefault();
    setSubmiting(true);
    setErrorMessage('');
    const userId = getLocalStorage(localStorageKey.USERID);
    console.log('userID: ', userId);
    const body = {
      userId: userId,
      name: name,
      type: contactType,
      emailId: email,
      phone: phone,
    };
    addContact(body)
      .then((res) => {
        console.log('got response ', res);
        const {message} = JSON.parse(res.data.addContact.body);
        if (res.data.addContact.statusCode === 200) {
          close();
          afterSubmitMethod(message);
          // use to update contact list
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const isSubmitDisable = () => {
    return !(
      name.trim().length &&
      contactType.trim().length &&
      email.trim().length &&
      isValidEmail(email)
    );
  };

  const updatePhone = (value) => {
    if (/^[0-9]+$|^$/g.test(value)) {
      setPhone(value);
    }
  };

  return (
    <>
      <div className="add-contact-container">
        <div className="add-contact-header">
          <div className="add-contact-header-text">Add a contact</div>
          <i
            className="fa fa-close"
            onClick={close}
            style={{color: 'dodgerblue', cursor: 'pointer'}}
          />
        </div>
        {isSubmiting && <LinearProgress indeterminate={true} />}
        <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
          <form onSubmit={addNewContact}>
            <div className="add-contact-body">
              <div className="add-contact-text-box">
                <PlainTextBoxWithLabel
                  label="Full Name"
                  classes="add-contact-text-box-input"
                  value={name}
                  changeValue={setName}
                />
              </div>
              <div className="add-contact-text-box">
                <div>
                  <b>Type</b>
                </div>
                <DropDown
                  value={contactType}
                  dataType="getContactType"
                  messageClass="add-contact-text-box-input"
                  changeValue={setContactType}
                  classes="add-contact-downdown"
                />
              </div>
              <div className="add-contact-text-box">
                <EmailTextBoxWithLabel
                  purpose="contact"
                  label="Email Address"
                  value=""
                  classes="add-contact-text-box-input"
                  value={email}
                  changeValue={setEmail}
                />
              </div>
              <div className="add-contact-text-box">
                <PlainTextBoxWithLabel
                  isOptional={true}
                  label="Phone Number"
                  classes="add-contact-text-box-input"
                  value={phone}
                  changeValue={updatePhone}
                />
              </div>
              <span className="mt-1">
                Standard message and data rates apply.
              </span>
              <div className="add-contact-text-box">
                <PrimaryButton
                  disable={isSubmitDisable()}
                  labelInAction="Adding contact ..."
                  isInAction={isSubmiting}
                  label="Add contact"
                  classes="add-contact-downdown"
                  click={addNewContact}
                />
              </div>
              {errorMessage.length > 0 && (
                <ErrorMessage
                  classes="add-contact-text-box-input"
                  message={errorMessage}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
