import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {routesPath} from '../../router';
import './subscription.css';

export default function Subscription() {
  const history = useHistory();
  useEffect(() => {
    if (!getLocalStorage(localStorageKey.JWTTOKEN)) {
      history.push(routesPath.SIGN_IN);
    }
  }, []);

  let url = `https://trialforlifeplus.mybillsystem.com/ManagedPortal/Home?token=${getLocalStorage(
    localStorageKey.FUSEBILLCUSTOMERTOKEN
  )}`;
  return (
    <>
      {/* <iframe src="http://lifeplusweb.s3-website-us-east-1.amazonaws.com/dashboard" height="540" width="1350" title="description"></iframe> */}
      <iframe src={url} height="540" width="1350" title="description"></iframe>
    </>
  );
}
