export const getAddMealBody = (object) => {
  const {size, timestamp, details, userId} = object;

  const body = {
    query:
      'mutation MyMutation($userId: Int, $timestamp: String, $size: Int, $notTakenMeal: Boolean, $details: String) {addMeals(details: $details, notTakenMeal: $notTakenMeal, size: $size, timestamp: $timestamp, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      size: size,
      timestamp: timestamp,
      details: details,
      notTakenMeal: false,
    },
  };
  return JSON.stringify(body);
};

export const getListMealBody = (object) => {
  const {timestamp, userId} = object;

  const body = {
    query:
      'query MyQuery($timestamp: String, $userId: Int) {mealList(timestamp: $timestamp, userId: $userId) {statusCode body {message success data {creationDate details mealId mealSize mealSizeId timestamp updationDate notTakenMeal}}}}',
    variables: {
      userId: userId,
      timestamp: timestamp,
    },
  };
  return JSON.stringify(body);
};

export const getDeleteMealBody = (object) => {
  const {mealId, userId} = object;

  const body = {
    query:
      'mutation MyMutation($mealId: Int, $userId: Int) {deleteMeal(mealId: $mealId, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      mealId: mealId,
    },
  };
  return JSON.stringify(body);
};

export const getEditMealBody = (object) => {
  const {size, timestamp, details, mealId, userId} = object;

  const body = {
    query:
      'mutation MyMutation($userId: Int, $timestamp: String, $size: Int, $notTakenMeal: Boolean, $mealId: Int, $details: String) {editMeals(details: $details, mealId: $mealId, notTakenMeal: $notTakenMeal, size: $size, timestamp: $timestamp, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      mealId: mealId,
      timestamp: timestamp,
      size: size,
      details: details,
      notTakenMeal: false,
    },
  };
  return JSON.stringify(body);
};

export const getAllMealsBody = (object) => {
  const {startTS, endTS, userId} = object;

  const body = {
    query:
      'query MyQuery($userId: Int, $startTs: String, $endTs: String) {getMealListInRange(endTs: $endTs, startTs: $startTs, userId: $userId) {statusCode body {message success data {creationDate details isActive mealId mealSize mealSizeId patientId timestamp updationDate notTakenMeal}}}}',
    variables: {
      userId: userId,
      startTs: startTS,
      endTs: endTS,
    },
  };
  return JSON.stringify(body);
};
