import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import profileIcon from '../../assets/icons/common/profile.svg';
import {
  checkIfTokenPresent,
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {getContacts} from '../../rest-api/home-api/contact';
import {shareData} from '../../rest-api/home-api/shareData';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import ErrorMessage from '../common/errors/error-message';
import Modal, {modalType} from '../common/modal/modal';
import RadioButtonGroups from '../common/radio-buttons/radio-buttons';
import SuccessMessageSnackbar from '../common/success/success';
import TextArea from '../common/textarea/textarea';
import DashLayout from '../dash-layout/dash-layout';
import './share-data.css';

export default function ShareData(props) {
  const history = useHistory();
  const [details, setDetails] = useState('');
  const [range, setRange] = useState('latest');
  const [contactList, setContactList] = useState([]);
  const [selectedContactEmails, setSelectedContactEmails] = useState([]);
  const [successMessage, setSuccessful] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSending, setSending] = useState(false);
  const [isOpenedAddContact, openAddContact] = useState(false);
  const [isOpenConfirm, openConfirm] = useState(false);

  const updateContactList = () => {
    console.log('Update list');
    getContacts()
      .then((res) => {
        const statusCode = res.data.getContact.statusCode;
        if (statusCode === 200) {
          const contactList = res.data.getContact.body.data;
          setContactList(contactList);
          setSelectedContactEmails(contactList.map((c) => c.emailId));
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          const {message} = res.data.getContact.body.message;
          throw new Error(message);
        }
      })
      .catch((e) => console.error(e.message));
  };

  const afterAddingContact = (message) => {
    updateContactList();
    setSuccessful(message);
  };
  const rangeObjectList = [
    {
      value: 'latest',
      label: 'Only the latest measurement',
    },
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: '1w',
      label: 'This week',
    },
    {
      value: '1m',
      label: 'This month',
    },
    {
      value: '3m',
      label: '3 months',
    },
    {
      value: '6m',
      label: '6 months',
    },
    {
      value: '1y',
      label: '1 year',
    },
  ];

  const backToHome = () => {
    history.push(routesPath.HOME_PAGE);
  };

  const toogleEmailIdSelection = (emailId) => {
    if (selectedContactEmails.includes(emailId)) {
      setSelectedContactEmails(
        selectedContactEmails.filter((e) => e !== emailId)
      );
    } else {
      setSelectedContactEmails([...selectedContactEmails, emailId]);
    }
  };

  const doShareData = () => {
    const userId = getLocalStorage(localStorageKey.USERID);

    const body = {
      userId: userId,
      contacts: selectedContactEmails,
      details: details,
      range: range,
    };
    setSending(true);
    setSuccessful('');
    setErrorMessage('');
    shareData(body)
      .then((res) => {
        const {message} = JSON.parse(res.data.shareData.body);
        console.log('Message: ', message);
        if (res.data.shareData.statusCode === 200) {
          setSuccessful(message);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      })
      .then(() => setSending(false));
  };

  const ContactBox = (props) => {
    const {contact} = props;
    const {type, name, emailId} = contact;
    return (
      <>
        <div className="contact-box-container">
          <div className="contact-box-left-container">
            <input
              type="checkbox"
              style={{cursor: 'pointer'}}
              checked={selectedContactEmails.includes(emailId)}
              onChange={() => toogleEmailIdSelection(emailId)}
            />
          </div>
          <div className="contact-box-right-container">
            <div className="profile-icon">
              <img src={profileIcon} alt="profile" />
            </div>
            <div className="profile-data">
              <div className="profile-name">{name}</div>
              <div>({type})</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const alertConfirmDetails = {
    text: 'Are you sure you want to share the data?',
    yes: 'Yes',
    no: 'No',
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    updateContactList();
  }, [history]);

  return (
    <>
      <div style={isOpenedAddContact ? {overflow: 'hidden'} : {}}>
        <Modal
          afterSubmitMethod={afterAddingContact}
          type={modalType.ADD_CONTACT}
          close={() => openAddContact(false)}
          isOpened={isOpenedAddContact}
        />
        {successMessage.length > 0 && (
          <SuccessMessageSnackbar message={successMessage} />
        )}
        <Modal
          details={alertConfirmDetails}
          afterSubmitMethod={doShareData}
          type={modalType.ALERT_CONFIRM}
          close={() => openConfirm(false)}
          isOpened={isOpenConfirm}
        />

        <DashLayout
          loggedIn={true}
          bodyClass="share-data-body"
          children={
            <>
              <div className="share-data-top-header-container">
                <div className="share-data-top-header-text">
                  Share your data
                </div>
                <OutlinePrimaryButton
                  disable={isSending}
                  label="Cancel"
                  classes="share-data-button"
                  click={backToHome}
                />
              </div>
              {isSending && (
                <LinearProgress
                  className="w-90"
                  style={{marginTop: '2%'}}
                  indeterminate={true}
                />
              )}
              <div className="overview-card w-90 card-overflow">
                <div
                  className={isSending ? 'pre-authentication-disable-div' : ''}
                >
                  <div className="share-data-body-container">
                    <div className="share-data-container">
                      <div className="share-data-sub-container">
                        <div className="share-data-heading">
                          Share data for:
                        </div>
                      </div>

                      <div className="share-data-sub-container">
                        <div className="radio-buttons">
                          <RadioButtonGroups
                            objectList={rangeObjectList}
                            selectedValue={range}
                            changeValue={setRange}
                          />
                        </div>
                        <div className="share-data-label">Details</div>
                        <TextArea
                          value={details}
                          changeValue={setDetails}
                          placeholder="Add notes"
                        />
                      </div>
                    </div>

                    <div className="share-data-container">
                      <div className="share-data-sub-container">
                        <div className="share-data-heading">
                          Who do you want to share data with?
                        </div>
                        <div>You can share via email, text, or both.</div>
                      </div>

                      <div className="share-data-sub-container">
                        {contactList.length > 0 ? (
                          contactList.map((obj, i) => (
                            <ContactBox key={i} contact={obj} />
                          ))
                        ) : (
                          <div
                            className="contact-box-container no-contact"
                            style={{width: 'unset'}}
                          >
                            No contact available
                          </div>
                        )}
                        <div
                          className="link-button"
                          onClick={() => {
                            openAddContact(true);
                            setSuccessful('');
                          }}
                        >
                          <u>+ Add new contact</u>
                        </div>
                      </div>
                    </div>

                    <div className="share-data-button-container">
                      <PrimaryButton
                        classes="share-data-button"
                        label="Send"
                        labelInAction="Sending ..."
                        isInAction={isSending}
                        disable={!selectedContactEmails.length}
                        click={() => openConfirm(true)}
                      />
                    </div>
                    {errorMessage.length > 0 && (
                      <ErrorMessage classes="w-100" message={errorMessage} />
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
