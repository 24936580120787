import React from 'react';

export default function TimeRange(props) {
  const {close} = props;

  return (
    <div className="time-range-container">
      <div className="add-contact-header" style={{padding: '2%'}}>
        <div className="add-contact-header-text">Time in range</div>
        <i
          className="fa fa-close"
          onClick={close}
          style={{color: 'dodgerblue', cursor: 'pointer'}}
        />
      </div>
      <div style={{padding: '2%'}}>
        “Time-in-Range” (TIR) is the percentage of time that a person spends
        with their blood glucose levels in a target range.
        <br />
        <br />
        The range will vary depending on the person, but general guidelines
        suggest starting with a range of 70 to 180 mg/dl.
        <br />
        <br />
        For people with type 1 or type 2 diabetes, experts recommend aiming for
        at least 70% of time in range each day.
      </div>
    </div>
  );
}
