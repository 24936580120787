import React, {useState} from 'react';
import PlainSwitch from '../common/switches/plain-switch';
import PlainTextboxWithFixedPlaceholder from '../common/textboxes/plain-textbox-fixed-placeholder/plain-textbox-fixed-placeholder';
import {getWeightByUnits} from './tools';
import {KG, POUND} from '../../hooks/measurement';

const testValue = (value) => {
  return value <= 500 && /^[0-9]+$|^$/g.test(value);
};

const WeightPicker = ({
  weightType,
  setWeightType,
  kilograms,
  setKilograms,
  pounds,
  setPounds,
}) => {
  const onWeightChange = (value) => {
    if (!testValue(value)) return;
    if (weightType == POUND) {
      setPounds(value);
      setKilograms(getWeightByUnits(value, weightType, KG));
      return;
    }
    setKilograms(value);
    setPounds(getWeightByUnits(value, weightType, POUND));
  };

  return (
    <>
      <PlainSwitch
        key="weight-switch"
        switchId="switch_weight"
        value={weightType}
        changeValue={setWeightType}
        switches={['lb', 'kg']}
      />
      <PlainTextboxWithFixedPlaceholder
        key="weight-input"
        label={weightType}
        value={weightType == POUND ? pounds : kilograms}
        changeValue={onWeightChange}
        type="number"
      />
    </>
  );
};

export default WeightPicker;
