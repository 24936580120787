import React from 'react';
import './plain-textbox-fixed-placeholder.css';

export default function PlainTextboxWithFixedPlaceholder(props) {
  const {label, value, inputId, classes, changeValue, type, maxLength, onBlur} =
    props;
  const id = 'textbox_' + String(label).split(' ').join('_').toLowerCase();

  const getClassForField = () => {
    return 'fixed-placeholder-text-box-container ' + (classes ? classes : '');
  };

  return (
    <div className={getClassForField()}>
      <input
        id={inputId ? inputId : id}
        type={type}
        onBlur={onBlur}
        maxLength={maxLength}
        className="fixed-placeholder-field"
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
      />
      <span className="fixed-placeholder-text">{label}</span>
    </div>
  );
}
