import INFOICON from '../../assets/icons/common/info.svg';
import OKICON from '../../assets/icons/common/ok.svg';
import {HealthParameterType} from '../../common/config';
import {DotDetailsContainer} from '../graphs/common';
import ComplexScatter from '../graphs/complexScatter';
import {Loader, NoDataContainer} from './health-common';

const getHowManyDotsInRange = (generatedData) => {
  console.log('Generated data : ', generatedData);
  const {scatterData, scatterLow, scatterHigh, type} = generatedData;
  const totalData = scatterData.length;
  let dataInRange = 0;
  scatterData.forEach((s) => {
    if (type === HealthParameterType.BLOOD_PRESSURE) {
      if (s.y[0] < scatterHigh && s.y[1] > scatterLow) {
        dataInRange++;
      }
    }
    if (s.y > scatterLow && s.y < scatterHigh) {
      dataInRange++;
    }
  });
  return Math.floor((dataInRange / totalData) * 100);
};

export const TimeInRangeContainer = (props) => {
  const {generatedData, setOpenTimeRangeBound, healthType} = props;
  return (
    <div className="graph-lower-container blood-glucose-time-in-container">
      {getHowManyDotsInRange(generatedData) > 70 && (
        <img src={OKICON} alt="ok-icon" width={24} height={24} />
      )}
      <div className="graph-lower-container-text blood-glucose-time-in-text">
        {getHowManyDotsInRange(generatedData)}%
        <span style={{fontWeight: 500}}> time in range</span>
      </div>
      {healthType === HealthParameterType.BLOOD_GLUCOSE && (
        <img
          className="c-pointer"
          onClick={() => setOpenTimeRangeBound(true)}
          src={INFOICON}
          alt="info-icon"
          width={24}
          height={24}
        />
      )}
    </div>
  );
};

export const DaySummary = (props) => {
  const {data, setOpenTimeRangeBound, healthType} = props;
  return (
    <>
      {data !== '' ? (
        <>
          {data.scatterData?.length > 0 ? (
            <>
              <TimeInRangeContainer
                generatedData={data}
                setOpenTimeRangeBound={setOpenTimeRangeBound}
                healthType={healthType}
              />
              <ComplexScatter data={data} />
              <DotDetailsContainer hasDay={false} type={healthType} />
            </>
          ) : (
            <NoDataContainer />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
