import {Divider} from '@material-ui/core';
import {useSelector} from 'react-redux';
import HIGHEST_ICON from '../../assets/icons/graph/large-highest.svg';
import LOWEST_ICON from '../../assets/icons/graph/large-lowest.svg';
import MealHeavy from '../../assets/icons/meal/meal-heavy.svg';
import MealLight from '../../assets/icons/meal/meal-light.svg';
import MealModerate from '../../assets/icons/meal/meal-moderate.svg';
import {MEAL_TYPE} from '../../common/config';
import {
  getFormattedHourWithMinute,
  getHourAndMinuteFromTime,
} from '../../fake-data-generator/common';
import {useHealthConfig} from '../../health-data-generator/health-common';

export const getSvgWidth = (svg) => {
  if (!svg.empty()) {
    return svg.style('width').replace('px', '');
  }
  return 0;
};

export const getMealIcon = (type) => {
  switch (type) {
    case MEAL_TYPE.LIGHT:
      return MealLight;
    case MEAL_TYPE.MODERATE:
      return MealModerate;
    case MEAL_TYPE.HEAVY:
      return MealHeavy;
    default:
      return null;
  }
};

export const DotDetailsContainerWithHighLow = (props) => {
  const {isSingleLine, type} = props;
  const {unitLabel, unit, converter} = useHealthConfig(type);
  const selectedDotWithHighLow = useSelector((state) =>
    isSingleLine
      ? state.selectedDotSingleLineWithHighLow
      : state.selectedDotWithHighLow
  );
  const {x, y, color, stroke, highest, lowest} = selectedDotWithHighLow;

  return (
    <div className="dot-details-container">
      <div className="dot-details-sub-container">
        <div className="dot-details-text">
          Average at <br />
          <span style={{fontWeight: 'bold'}}>
            {getFormattedHourWithMinute(x)}
          </span>
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="dot-details-sub-container">
        <div className="outer-line-blue-circle">
          <div
            className="contained-circle"
            style={{
              backgroundColor: Array.isArray(y) ? color[0] : color,
              borderColor: Array.isArray(y) ? stroke[0] : stroke,
            }}
          ></div>
        </div>
        <div className="dot-details-text">
          {/* <span style={{ fontWeight: 'bold' }}> */}
          Average
          <br />
          {/* </span> */}
          <span className="dot-details-value">
            {Array.isArray(y)
              ? converter(Math.floor(y[0]), unit) +
                '/' +
                converter(Math.floor(y[1]), unit)
              : converter(Math.floor(y), unit)}
          </span>
          <span>{unitLabel}</span>
        </div>
      </div>
      <Divider orientation="vertical" flexItem />

      <div className="dot-details-sub-container">
        <img src={HIGHEST_ICON} alt="icon_highest" />
        <div className="dot-details-text">
          {/* <span style={{ fontWeight: 'bold' }}> */}
          Highest
          <br />
          {/* </span> */}
          <span className="dot-details-value">
            {converter(Math.floor(highest), unit)}
          </span>
          {unitLabel}
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="dot-details-sub-container">
        <img src={LOWEST_ICON} alt="icon_highest" />
        <div className="dot-details-text">
          {/* <span style={{ fontWeight: 'bold' }}> */}
          Lowest
          <br />
          {/* </span> */}
          <span className="dot-details-value">
            {converter(Math.floor(lowest), unit)}
          </span>
          {unitLabel}
        </div>
      </div>
    </div>
  );
};

export const DotDetailsContainer = (props) => {
  const {hasDay, type} = props;
  const {unitLabel, unit, converter} = useHealthConfig(type);
  const selectedDot = useSelector((state) => state.selectedDot);
  const selectedDate = useSelector((state) => state.selectedDate);
  const {date, y, color, stroke} = selectedDot;

  const time = getHourAndMinuteFromTime(date);
  if (!converter) {
    return null;
  }
  return (
    <div className="dot-details-container">
      <div className="dot-details-left-container">
        <div className="outer-line-blue-circle">
          <div
            className="contained-circle"
            style={{
              backgroundColor: Array.isArray(y) ? color[0] : color,
              borderColor: Array.isArray(y) ? stroke[0] : stroke,
            }}
          ></div>
        </div>
        <div className="dot-details-text">
          Reading at <br />
          <span style={{fontWeight: 'bold'}}>
            {time}
            <br />
            {hasDay
              ? String(selectedDate).split(
                  new Date(selectedDate).getFullYear()
                )[0]
              : ''}
          </span>
        </div>
      </div>

      <div className="dot-details-right-container">
        {hasDay && <br />}
        <div
          className="triangle"
          style={{borderBottomColor: Array.isArray(y) ? stroke[0] : stroke}}
        >
          <div
            className="inner-triangle"
            style={{borderBottomColor: Array.isArray(y) ? color[0] : color}}
          ></div>
        </div>
        <div className="dot-details-text">
          <span className="dot-details-value">
            {Array.isArray(y)
              ? converter(Math.floor(y[0]), unit) +
                '/' +
                converter(Math.floor(y[1]), unit)
              : converter(Math.floor(y), unit)}
          </span>
          {unitLabel}
        </div>
      </div>
    </div>
  );
};
