import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useHistory} from 'react-router-dom';
import arrowBelow from '../../assets/icons/common/arrow-below.svg';
import bellIcons from '../../assets/icons/header/bell-alerts.svg';
import fileIcon from '../../assets/icons/header/file.png';
import profileBlue from '../../assets/icons/header/profile-blue.svg';
import settings from '../../assets/icons/header/settings.svg';
import watchConnected from '../../assets/icons/header/watch-connected.svg';
import logo from '../../assets/logo.svg';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {routesPath} from '../../router';
import './dash-layout.css';
import ProfileDropdown, {checkIfDrawerPresent} from './profile-dropdown';
import {URL} from '../../rest-api/config';

export default function DashLayout(props) {
  const history = useHistory();
  const {
    children,
    loggedIn,
    bodyClass,
    refreshData,
    centerContent = false,
  } = props;
  const [isProfileOpenDropdown, openProfileDropdown] = useState(false);
  const profileName = getLocalStorage(localStorageKey.FIRST_NAME);
  const profileUrl = getLocalStorage(localStorageKey.PROFILE_PIC_URL);

  const Footer = () => (
    <div className="dash-footer">
      <div className="footer-container">
        <div className="footer-text">
          © Copyright LifePlus, Inc. All Rights Reserved.
        </div>
        <div className="footer-text align-last">
          Terms of Service<span className="space-footer-text">|</span>Privacy
          Policy
        </div>
      </div>
    </div>
  );

  const Icon = (props) => {
    const {icon, id, action, isActive} = props;
    return (
      <>
        <div className={isActive ? 'header-icons-active' : ''}>
          <img
            id={'btn_' + id}
            className="home-header-icon"
            onClick={action}
            src={icon}
            alt={icon}
          />
        </div>
      </>
    );
  };

  const HeaderIconsContainer = () => {
    let iconArr;
    if (URL.currentENV === 'PRODUCTION') {
      iconArr = iconList.filter((item) => item.id !== 'file');
    } else {
      iconArr = iconList;
    }
    return (
      <>
        <div className="header-icons-container-multiple">
          {iconArr.map((value, i) => (
            <Icon
              key={i}
              id={value.id}
              icon={value.icon}
              action={value.action}
              isActive={value.isActive}
            />
          ))}
          <div
            id="dropdown_profile"
            className="profile-icon-container"
            onClick={() => openProfileDropdown(!isProfileOpenDropdown)}
          >
            <div>
              {profileUrl !== 'null' ? (
                <img
                  src={profileUrl}
                  alt="profile"
                  style={{borderRadius: '50%', paddingRight: '1%'}}
                  width={32}
                  height={32}
                />
              ) : (
                <img className="home-header-icon" src={profileBlue} />
              )}
            </div>
            <span className="profile-info"> Hi, {profileName}</span>
            <span className="arrow-below">
              <img src={arrowBelow} alt="arrow-below" />
            </span>
          </div>
        </div>
      </>
    );
  };

  const getClassOfMainBody = () => {
    return 'dash-body-container ' + (loggedIn ? 'post-authenticate-body' : '');
  };

  const getClassOfBody = () => {
    const resultClasses = ['dash-body'];
    if (centerContent) resultClasses.push('dash-body-centered');
    if (bodyClass) resultClasses.push(bodyClass);
    return resultClasses.join(' ');
  };
  const checkIfIconActive = (pathname) => {
    if (pathname === history.location.pathname) {
      console.log('pathname: ', pathname);
      console.log('location: ', history.location.pathname);
      return true;
    }
    return false;
  };

  const iconList = [
    {
      icon: fileIcon,
      id: 'file',
      action: () => {
        history.push(routesPath.PLAN_REGISTRATION);
      },
      isActive: checkIfIconActive(routesPath.PLAN_REGISTRATION),
    },
    {
      icon: bellIcons,
      id: 'notification',
      action: () => {},
      isActive: false,
    },
    {
      icon: settings,
      id: 'settings',
      action: () => {
        checkIfIconActive(routesPath.SETTINGS);
        history.push(routesPath.SETTINGS);
      },
      isActive: checkIfIconActive(routesPath.SETTINGS),
    },
    {
      icon: watchConnected,
      id: 'watch_connected',
      action: () => {},
      isActive: false,
    },
    // {
    //     icon: profileBlue,
    //     action: () => openProfileDropdown(!isProfileOpenDropdown)
    // }
  ];

  return (
    <>
      <div className={getClassOfMainBody()}>
        <div
          className="dash-header-title"
          style={
            checkIfDrawerPresent(history.location.pathname)
              ? {borderBottom: '1px solid lightgrey'}
              : {}
          }
        >
          <div className="header-container">
            <div className="header-icons-container">
              <img
                id="img_home"
                src={logo}
                alt="logo-lifeplus"
                className="c-pointer"
                onClick={() => {
                  if (history.location.pathname === routesPath.HOME_PAGE) {
                    refreshData();
                  } else {
                    history.push(routesPath.HOME_PAGE);
                  }
                }}
              />
            </div>
            {loggedIn && (
              <>
                <HeaderIconsContainer />
              </>
            )}
          </div>
        </div>
        <div
          className={getClassOfBody()}
          style={isMobile ? {paddingTop: '10%'} : {}}
        >
          <ProfileDropdown
            isVisible={isProfileOpenDropdown}
            hide={() => {
              openProfileDropdown(false);
            }}
          />
          {children}
        </div>
        {loggedIn && <Footer />}
      </div>
    </>
  );
}
