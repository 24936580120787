import React from 'react';
import './card-with-header.css';

export default function CardWithHeader(props) {
  const {Header, Content, headerClass} = props;
  return (
    <>
      <div className="card-container">
        <div className={(headerClass ? headerClass : '') + ' card-header'}>
          <Header />
        </div>
        <div className="card-content">
          <Content />
        </div>
      </div>
    </>
  );
}
