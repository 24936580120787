import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ErrorMessage from '../common/errors/error-message';
import styled from 'styled-components';
import DashLayout from '../dash-layout/dash-layout';
import sideImage from '../../assets/watch-image.png';
import {doLoginWithToken, getUserState} from '../../rest-api/user-api/sign-in';
import {STORE_SIGN_IN_INFO} from '../../redux/actionTypes';
import {navigateToPage} from '../signin/tools';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const MainImage = styled.img`
  margin-top: 4vh;
  max-height: 70%;
  height: auto;
`;

export default function AutoLogin(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const search = useLocation().search;
  const history = useHistory();

  const processLogin = async (search) => {
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get('email');
    const passwordToken = searchParams.get('token');

    try {
      const res = await doLoginWithToken(email, passwordToken);

      if (res === null) {
        throw new Error('SERVER_ERROR');
      }
      if (res.statusCode !== 200) {
        const {message} = JSON.parse(res.body);
        throw new Error(message);
      }
      const {
        token,
        apiKeyGPL,
        apiKeyEureka,
        userId,
        profilePicUrl,
        fuseBillCustomerToken,
        firstName,
        lastName,
        emailId,
      } = res.data;
      if (!apiKeyGPL) {
        throw new Error('Api key unavailable');
      }

      const signInData = {
        apiKeyGpl: apiKeyGPL,
        apiKeyEureka: apiKeyEureka,
        jwtToken: token,
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        profileUrl: profilePicUrl,
        emailId: emailId,
        fuseBillCustomerToken,
      };
      dispatch({
        type: STORE_SIGN_IN_INFO,
        payload: signInData,
      });

      const resUserState = await getUserState(userId, apiKeyEureka, token);
      const response = resUserState.data.getUserState;
      if (response.statusCode === 200) {
        navigateToPage(response.body.data.userState, signInData, history, true);
      } else {
        const {message} = JSON.parse(res.body);
        throw new Error(message);
      }
    } catch (error) {
      console.warn(error);
      setErrorMessage('Error: ' + error.message);
    }
  };

  useEffect(() => {
    if (!search) {
      return;
    }
    processLogin(search);
  }, [search]);

  return (
    <DashLayout loggedIn={false} centerContent>
      <MainContainer>
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <>
            <div className="pre-authentication-header">
              Please wait, we will redirect you soon.
            </div>
            <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
          </>
        )}
        <MainImage src={sideImage} alt="watch-image" />
      </MainContainer>
    </DashLayout>
  );
}
