import React, {useEffect, useRef, useState} from 'react';
import DashLayout from '../dash-layout/dash-layout';
import './profile.css';
import profileAddAvatar from '../../assets/icons/profile/add-avatar.svg';
import {
  checkIfTokenPresent,
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
  setLocalStorage,
} from '../../common/localStorageHandler';
import AccountInfo from './account-info';
import SubscriptionInfo from './subscription-info';
import EditPersonalInfo from './personal-info';
import MedicalInfo from './medical-info';
import EditContactInfo from './contact-info';
import Modal, {modalType} from '../common/modal/modal';
import SuccessMessageSnackbar from '../common/success/success';
import {useHistory} from 'react-router-dom';
import {routesPath} from '../../router';
import {
  getProfileInfo,
  updateStepGoal,
  uploadProfilePic,
} from '../../rest-api/home-api/profile';
import {getSubscriptionDetails} from '../../rest-api/chargebee-api/plans';
import Spinner from 'react-spinner-material';
import DropDown from '../common/drop-downs/dropdown';

export default function Profile() {
  const [successMessage, setSuccessful] = useState('');
  const [isOpenedAddContact, openAddContact] = useState(false);
  const [isOpenedEditContact, openEditContact] = useState(false);
  const [editContactDetails, setEditContactDetails] = useState({});
  const inputFile = useRef(null);
  const profileUrl = getLocalStorage(localStorageKey.PROFILE_PIC_URL);
  const profileName =
    getLocalStorage(localStorageKey.FIRST_NAME) +
    ' ' +
    getLocalStorage(localStorageKey.LAST_NAME);
  const [extractedItem, setExtractedItem] = useState('');
  const [profilePic, setProfilePic] = useState(profileUrl);
  const [accountInfo, setAccountInfo] = useState({});
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [stepGoal, setStepGoal] = useState('');
  const [personalInfo, setPersonalInfo] = useState({});
  const [isSettingGoal, settingGoal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  const history = useHistory();

  const profileItems = [
    {
      name: 'Account Information',
      type: 'account',
    },
    {
      name: 'Subscription Information',
      type: 'subscription',
    },
    {
      name: 'Personal Information',
      type: 'personal',
    },
    {
      name: 'Medical Information',
      type: 'medical',
    },
    {
      name: 'Contacts',
      type: 'contacts',
    },
  ];

  const getSubscriptionPage = () => {
    const userId = parseInt(getLocalStorage(localStorageKey.USERID));
    const jwtToken = getLocalStorage(localStorageKey.JWTTOKEN);
    const apiKeyEureka = getLocalStorage(localStorageKey.APIKEYEUREKA);
    setSubscriptionStatus(false);
    setSubscriptionInfo({});
    getSubscriptionDetails(apiKeyEureka, jwtToken, {
      userId: userId,
      purpose: 'details',
    })
      .then((res) => {
        console.log('success : ', res);
        if (res.data && res.data.getSubscriptionDetails.statusCode == 200) {
          if (res.data.getSubscriptionDetails.body.isSubscribed) {
            console.log('Subscription Found');
            setSubscriptionInfo(
              JSON.parse(res.data.getSubscriptionDetails.body.data)
            );
            setSubscriptionStatus(true);
          } else {
            console.log('No Subscription Found');
          }
        } else if (res.data) {
          // setErrorMessage(res.data.getSubscriptionDetails.body.message);
        } else {
          // setErrorMessage(res.errors);
        }
      })
      .catch((err) => {
        console.log('Error : ', err);
        // setErrorMessage('Server faced an internal Error');
      });
  };

  const loadProfilePic = () => {
    inputFile.current.click();
  };

  const sendProfilePic = (el) => {
    const userId = getLocalStorage(localStorageKey.USERID);
    let file = el.target.files[0];
    let reader = new FileReader();
    setProfilePic('');
    setSuccessful('');
    reader.onloadend = function () {
      const body = {
        userId: userId,
        imgData: reader.result,
      };
      uploadProfilePic(body)
        .then((res) => {
          const data = JSON.parse(res.body);
          console.log('Body data: ', data);

          const {statusCode, body} = data;

          if (statusCode === 200) {
            console.log('Body: ', body.url);
            setLocalStorage(
              localStorageKey.PROFILE_PIC_URL,
              body.url.replace('https', 'http')
            );
            setSuccessful(body.message);
            setProfilePic(body.url.replace('https', 'http'));
          }
        })
        .catch((e) => console.error(e));
    };
    reader.readAsDataURL(file);
  };

  const toogleExtraction = (value) => {
    if (value === extractedItem) {
      setExtractedItem('');
    } else {
      setExtractedItem(value);
    }
  };

  const editContact = (object) => {
    openEditContact(true);
    setEditContactDetails(object);
  };

  const updateGoal = (value) => {
    setSuccessful('');
    settingGoal(true);
    updateStepGoal(value)
      .then((res) => {
        const {statusCode, body} = res.data.addStepGoals;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          setSuccessful(message);
          setStepGoal(value);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => console.error(e.message))
      .then(() => settingGoal(false));
  };
  const getLowerItem = (type) => {
    switch (type) {
      case 'account':
        return (
          <>
            {accountInfo.firstName.length > 0 && (
              <AccountInfo
                getInfoAndSetFields={getInfoAndSetFields}
                details={accountInfo}
                setSuccessful={setSuccessful}
              />
            )}
          </>
        );
      case 'subscription':
        return (
          <>
            <SubscriptionInfo
              subscriptionStatus={subscriptionStatus}
              subscriptionInfo={subscriptionInfo}
            />
            {/* {accountInfo.firstName.length > 0 && <AccountInfo getInfoAndSetFields={getInfoAndSetFields} details={accountInfo} setSuccessful={setSuccessful} />} */}
          </>
        );
      case 'personal':
        return (
          <>
            {personalInfo.DOB && (
              <EditPersonalInfo
                getInfoAndSetFields={getInfoAndSetFields}
                details={personalInfo}
              />
            )}
          </>
        );
      case 'medical':
        return <MedicalInfo getInfoAndSetFields={getInfoAndSetFields} />;
      case 'contacts':
        return (
          <EditContactInfo
            getInfoAndSetFields={getInfoAndSetFields}
            openEditContact={(obj) => editContact(obj)}
            openContact={() => openAddContact(true)}
          />
        );
      default:
        break;
    }
  };
  const isDetailsAvailable = (type) => {
    switch (type) {
      case 'account':
        return accountInfo.firstName !== undefined;
      case 'personal':
        return personalInfo.DOB !== undefined;
      default:
        return true;
    }
  };
  const ProfileItem = (props) => {
    const {object} = props;
    const {name, type} = object;
    return (
      <>
        <div
          className="profile-item-container"
          style={isDetailsAvailable(type) ? {} : {cursor: 'not-allowed'}}
          id={'div_' + type}
          onClick={() =>
            isDetailsAvailable(type) ? toogleExtraction(type) : {}
          }
        >
          <div className="profile-item-text">{name}</div>
          {isDetailsAvailable(type) ? (
            <i
              className={type === extractedItem ? 'fa fa-minus' : 'fa fa-plus'}
            />
          ) : (
            <Spinner color="#1a74d4" stroke={2} radius={16} />
          )}
        </div>
        {type === extractedItem && getLowerItem(type)}
      </>
    );
  };

  const getInfoAndSetFields = () => {
    getProfileInfo()
      .then((res) => {
        const {statusCode, body} = res.data.getUserAllInfo;
        if (statusCode === 200) {
          const {emailId, firstName, lastName, stepGoal, personalInfo} =
            body.data;
          setLocalStorage(localStorageKey.FIRST_NAME, firstName);
          setLocalStorage(localStorageKey.LAST_NAME, lastName);
          setAccountInfo({
            emailId: emailId,
            firstName: firstName,
            lastName: lastName,
          });
          setPersonalInfo(JSON.parse(personalInfo));
          setStepGoal(stepGoal);
          // setProfileName(firstName + ' ' + lastName);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        }
      })
      .catch((e) => console.error('Error in profile: ', e));
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    getInfoAndSetFields();
    getSubscriptionPage();
  }, [history]);

  return (
    <>
      <div style={isOpenedAddContact ? {overflow: 'hidden'} : {}}>
        {successMessage.length > 0 && (
          <SuccessMessageSnackbar message={successMessage} />
        )}

        <Modal
          afterSubmitMethod={(message) => setSuccessful(message)}
          type={modalType.ADD_CONTACT}
          close={() => {
            setSuccessful('');
            openAddContact(false);
          }}
          isOpened={isOpenedAddContact}
        />

        <Modal
          afterSubmitMethod={(message) => setSuccessful(message)}
          type={modalType.EDIT_CONTACT}
          close={() => {
            setSuccessful('');
            openEditContact(false);
          }}
          isOpened={isOpenedEditContact}
          details={editContactDetails}
        />

        <DashLayout
          bodyClass="profile-body"
          loggedIn={true}
          children={
            <>
              <div className="profile-header">My profile</div>
              <div className="profile-container">
                <div className="profile-sub-container overview-card">
                  <div className="profile-add-picture">
                    <>
                      {profilePic === '' ? (
                        <div className="profile-loader">
                          <Spinner color="#1a74d4" stroke={2} />
                        </div>
                      ) : (
                        <div>
                          {profilePic !== 'null' ? (
                            <div
                              onClick={loadProfilePic}
                              id="btn-upload-profile-pic"
                            >
                              <img
                                src={profilePic}
                                alt="image"
                                className="profile-image c-pointer"
                              />
                              <i className="fa fa-plus-circle profile-plus-icon" />
                            </div>
                          ) : (
                            <>
                              <img
                                id="btn-upload-profile-pic"
                                onClick={loadProfilePic}
                                className="c-pointer"
                                src={profileAddAvatar}
                                alt="profile-avatar"
                                onClick={loadProfilePic}
                              />
                            </>
                          )}
                          <input
                            onClick={(event) => (event.target.value = null)}
                            type="file"
                            id="file"
                            ref={inputFile}
                            accept="image/*"
                            style={{display: 'none'}}
                            onChange={sendProfilePic}
                          />
                        </div>
                      )}
                    </>
                    <div className="profile-name-text">{profileName}</div>
                  </div>

                  <div className="profile-daily-step">
                    <div>
                      <b>Daily step goals</b>
                    </div>
                    {isSettingGoal ? (
                      <div className="change-step-goal">
                        <span style={{marginTop: '1%'}}>
                          Changing step goal ...
                        </span>
                        <Spinner color="#1a74d4" stroke={2} radius={30} />
                      </div>
                    ) : (
                      <DropDown
                        inputId="dropdown-step-goal"
                        classes="step-goal-dropdown"
                        value={stepGoal}
                        changeValue={updateGoal}
                        dataType="steps"
                      />
                    )}
                  </div>
                </div>
                <div className="profile-sub-container overview-card">
                  {profileItems.map((obj, i) => (
                    <ProfileItem object={obj} key={i} />
                  ))}
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
