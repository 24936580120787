import {HealthParameterType, months} from '../common/config';
import {getHealthConfig} from './health-common';

export const generateHealthBarYearData = async (data, type, index) => {
  let barColors = ['#2ee48a', '#98c361', '#f05f5f'];
  const {multiDaysData} = data;
  const startDate = multiDaysData
    ? new Date(multiDaysData[0].date)
    : new Date();
  const endDate = multiDaysData
    ? new Date(multiDaysData[multiDaysData.length - 1].date)
    : new Date();
  let barData = [];
  const {range} = await getHealthConfig(type);
  let toolTipObjects = [];
  let max = 100;
  let yAxislabels = [
    {
      value: 100,
      yAxisPercentage: 1,
    },
    {
      value: 30,
      yAxisPercentage: 0.3,
    },
    {
      value: 0,
      yAxisPercentage: 0.05,
    },
  ];

  for (
    let start = new Date(startDate);
    start <= endDate ||
    (start.getMonth() <= endDate.getMonth() &&
      start.getFullYear() <= endDate.getFullYear());
    start.setMonth(start.getMonth() + 1)
  ) {
    let dataOfThisMonth = [];
    let valueInRange = 0;
    let howManyData = 0;
    if (multiDaysData) {
      dataOfThisMonth = multiDaysData.filter(
        (m) =>
          new Date(m.date).getMonth() === start.getMonth() &&
          new Date(m.date).getFullYear() === start.getFullYear()
      );
      dataOfThisMonth
        .map((data) => data.eachDayData)
        .forEach((singleData) => {
          singleData.forEach((data) => {
            howManyData++;
            if (type === HealthParameterType.BLOOD_PRESSURE) {
              valueInRange +=
                data.y[index] > range[0] && data.y[index] < range[1] ? 1 : 0;
            } else {
              valueInRange += data.y > range[0] && data.y < range[1] ? 1 : 0;
            }
          });
        });
    }
    const value =
      valueInRange === 0 ? 0 : Math.floor((valueInRange / howManyData) * 100);
    barData.push({
      value: value,
      label:
        start.toDateString() === startDate.toDateString() ||
        (start.getFullYear() === endDate.getFullYear() &&
          start.getMonth() === endDate.getMonth())
          ? months[start.getMonth()].substring(0, 3) +
            ' ' +
            Number(start.getFullYear())
          : '',
      tooltipLower:
        months[start.getMonth()] + ' ' + Number(start.getFullYear()),
    });
    toolTipObjects.push({
      value: value + '%',
      upper: '',
      lower: 'of time in range',
    });
  }

  const howManyInrange = barData.filter((b) => b.value > range[0]).length;

  return {
    barColors: barColors,
    barData: barData,
    maxValue: max,
    toolTipObjects: toolTipObjects,
    yAxislabels: yAxislabels,
    inRange: howManyInrange,
    footerText: '',
    yLabel: '% time in range',
  };
};
