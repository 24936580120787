import React from 'react';
import {isMobile} from 'react-device-detect';
import sideImage from '../../../assets/watch-image.png';
import DashLayout from '../../dash-layout/dash-layout';
import './pre-authentication-container.css';

export default function PreAuthContainer(props) {
  const {children, centerContent = true} = props;
  return (
    <DashLayout loggedIn={false} centerContent={centerContent}>
      <div className="pre-authentication-container">
        {!isMobile && (
          <div className="pre-authentication-image-container">
            <div className="side-image">
              <img
                src={sideImage}
                width="50%"
                height="100%"
                alt="watch-image"
              />
            </div>
          </div>
        )}
        <div
          className="pre-authentication-body-container"
          style={isMobile ? {width: '100%', marginLeft: '10%'} : {}}
        >
          {children}
        </div>
      </div>
    </DashLayout>
  );
}
