export const masterDataType = {
  ETHNICITYLIST: 'EthnicityList',
  GENDERLIST: 'getGender',
  BLOODGROUPLIST: 'BloodGroupList',
  KNOWNDISEASELIST: 'KnownDieasesList',
};
export const MEAL_TYPE = {
  LIGHT: 'LIGHT',
  MODERATE: 'MODERATE',
  HEAVY: 'HEAVY',
};

export const HealthParameterType = {
  BLOOD_GLUCOSE: 'BLOOD_GLUCOSE',
  BLOOD_PRESSURE: 'BLOOD_PRESSURE',
  HEART_RATE: 'HEART_RATE',
  RESPIRATION_RATE: 'RESPIRATION_RATE',
  OXYGEN_SATURATION: 'OXYGEN_SATURATION',
};

export const HeightDataType = {
  FEET: 'Feet',
  METER: 'Meter',
};

export const weekDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
