import React, {useState} from 'react';
import './password-textbox.css';

export default function PasswordTextBoxWithLabel(props) {
  const {label, inputId, value, changeValue, classes} = props;
  const [isVisible, setVisible] = useState(false);
  const id = String(label).split(' ').join('_').toLowerCase();

  return (
    <>
      <div className="font-rajdhani">
        <b>{label}</b>
      </div>
      <div
        className={(classes ? classes : '') + ' password-text-box-container'}
      >
        <input
          id={inputId ? inputId : id}
          type={isVisible ? 'type' : 'password'}
          className="password-field"
          placeholder={label}
          value={value}
          onChange={(e) => {
            changeValue(e.target.value);
          }}
        />

        <i
          id="btn_eye_"
          className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}
          style={{color: 'dodgerblue', cursor: 'pointer', marginTop: '1%'}}
          onClick={() => setVisible(!isVisible)}
        />
      </div>
    </>
  );
}
