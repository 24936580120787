import React from 'react';
import './radio-button.css';

export default function RadioButtonGroups(props) {
  const {objectList, selectedValue, changeValue} = props;
  const RadioButton = (props) => {
    const {label, value} = props;
    return (
      <div className="radio-button-container">
        <input
          className="radio-button"
          name="radio"
          type="radio"
          value={value}
          checked={selectedValue === value}
          onChange={(e) => changeValue(e.target.value)}
        />
        <span className="radio-label">{label}</span>
      </div>
    );
  };
  return (
    <>
      {objectList.map((obj, i) => (
        <RadioButton label={obj.label} value={obj.value} key={i} />
      ))}
    </>
  );
}
