import LinearProgress from '@material/react-linear-progress';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {masterDataType, HeightDataType} from '../../common/config';
import {
  errorMessageOfDay,
  errorMessageOfMonth,
  errorMessageOfYear,
} from '../../common/dateHandler';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {CountryListJson} from '../../config/countries';
import {updatePersonalInfo} from '../../rest-api/home-api/profile';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import SearchableDropdown from '../common/drop-downs/searchableDropdown/searchableDropdown';
import ErrorMessage from '../common/errors/error-message';
import SuccessMessageSnackbar from '../common/success/success';
import {PlainTextBox} from '../common/textboxes/plain-textbox/plain-textbox';
import {
  convertFeetToMeter,
  formatToFeetDotInches,
} from '../../common/metricsTool';
import HeightPicker from '../height-picker/height-picker';
import {isHeightValid} from '../height-picker/height-validator';
import SkinTonePicker, {
  isValidSkinToneId,
  SKIN_TONE_DUMMY_ID,
} from '../common/skintone-picker/skin-tone-picker';
import WeightPicker from '../weight-picker/weight-picker';
import {useMeasurement} from '../../hooks/measurement';
import {useWeight} from '../weight-picker/hooks';

export default function EditPersonalInfo(props) {
  const {details, getInfoAndSetFields} = props;
  const {
    DOB,
    country,
    ethnicityId,
    skinToneId = SKIN_TONE_DUMMY_ID,
    gender,
    height,
    unitOfMeasurement: initialUnitsOfMeasurement,
    weight,
    patientId,
  } = details;

  const feet = String(height).split('.')[0];
  const inch = String(height).split('.')[1];
  const [dayOfDOB, setDayOfDOB] = useState(String(DOB).split('-')[2]);
  const [monthOfDOB, setMonthOfDOB] = useState(String(DOB).split('-')[1]);
  const [yearOfDOB, setYearOfDOB] = useState(String(DOB).split('-')[0]);
  const [enthnicity, setEthnicity] = useState(String(ethnicityId));
  const [editGender, setGender] = useState(gender);
  const [heightFT, setHeightFT] = useState(feet);
  const [heightIN, setHeightIN] = useState(inch ? inch : 0);
  const [heightM, setHeightM] = useState(convertFeetToMeter(feet, inch));

  const {
    heightType,
    setHeightType,
    weightType,
    setWeightType,
    unitsOfMeasurement,
  } = useMeasurement(initialUnitsOfMeasurement);

  const weightParams = useWeight(weight, weightType);

  const [errorMessage, setErrorMessage] = useState('');
  const [editcountry, setCountry] = useState(
    CountryListJson.filter((c) => c.code === country)[0]
  );
  const [isSubmiting, setSubmiting] = useState(false);
  const [successMessage, setSuccessful] = useState('');
  const [skinTone, setSkinTone] = useState(skinToneId);
  const history = useHistory();
  const formatBirthDay = () => {
    return yearOfDOB + '-' + monthOfDOB + '-' + dayOfDOB;
  };

  const updateNumberValue = (value, maxLength, handler) => {
    if (value.length <= maxLength && /^\d*$/.test(value)) handler(value);
  };

  const checkIfSubmitDisabled = () => {
    return !(
      dayOfDOB.trim().length &&
      monthOfDOB.trim().length &&
      yearOfDOB.trim().length &&
      enthnicity.trim().length &&
      isValidSkinToneId(skinTone) &&
      editGender.trim().length &&
      isHeightValid(heightM, heightType, heightFT) &&
      weightParams?.resultWeight?.trim().length &&
      !errorMessageOfDay(dayOfDOB, monthOfDOB, yearOfDOB).length &&
      !errorMessageOfMonth(monthOfDOB, yearOfDOB).length &&
      !errorMessageOfYear(yearOfDOB).length &&
      editcountry
    );
  };

  const ErrorText = (props) => {
    return (
      <div className="invalid-message">
        {props.message.length > 0 && (
          <i
            className="fa fa-exclamation-triangle"
            style={{marginRight: '2%'}}
          ></i>
        )}
        {props.message}
      </div>
    );
  };

  const doUpdatePersonalInfo = (e) => {
    e.preventDefault();

    setSubmiting(true);
    setErrorMessage('');
    setSuccessful('');
    const userId = getLocalStorage(localStorageKey.USERID);

    const object = {
      userId: Number(userId),
      patientId: Number(patientId),
      birthDay: formatBirthDay(),
      gender: editGender,
      height: formatToFeetDotInches(heightM, heightFT, heightIN, heightType),
      weight: String(weightParams.resultWeight),
      ethnicityId: Number(enthnicity),
      countryCode: editcountry.code,
      unitOfMeasurement: unitsOfMeasurement,
      skinToneId: skinTone,
    };
    updatePersonalInfo(object)
      .then((res) => {
        const {statusCode, body} = res.data.editUser;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          setSuccessful(message);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false))
      .then(() => getInfoAndSetFields());
  };

  const dobFieldsObject = [
    {
      label: 'dd',
      value: dayOfDOB,
      handler: setDayOfDOB,
      maxLenght: 2,
      errorMessage: errorMessageOfDay(dayOfDOB, monthOfDOB, yearOfDOB),
    },
    {
      label: 'mm',
      value: monthOfDOB,
      handler: setMonthOfDOB,
      maxLenght: 2,
      errorMessage: errorMessageOfMonth(monthOfDOB, yearOfDOB),
    },
    {
      label: 'yyyy',
      value: yearOfDOB,
      handler: setYearOfDOB,
      maxLenght: 4,
      errorMessage: errorMessageOfYear(yearOfDOB),
    },
  ];

  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      {isSubmiting && (
        <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
      )}
      <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
        <div className="lower-item-info-container">
          <form onSubmit={() => {}} style={{marginBottom: '2%'}}>
            <div className="mt-1">
              <div>
                <b> Date of birth</b>
              </div>
              <div className="multi-textboxes">
                {dobFieldsObject.map((obj, i) => (
                  <PlainTextBox
                    inputId={'textbox_' + obj.label}
                    classes="dob-sub-textfield"
                    label={obj.label}
                    value={obj.value}
                    errorMessage={obj.errorMessage}
                    changeValue={(value) =>
                      updateNumberValue(value, obj.maxLenght, obj.handler)
                    }
                    type="number"
                    maxLength={obj.maxLength}
                  />
                ))}
              </div>
              <div className="multi-textboxes">
                {dobFieldsObject.map((obj, i) => (
                  <>
                    <ErrorText message={obj.errorMessage} />
                  </>
                ))}
              </div>
            </div>
            <div className="mt-1">
              <div>
                <b>Ethnicity</b>
              </div>
              <DropDown
                inputId="dropdown_ethnicity"
                value={enthnicity}
                dataType={masterDataType.ETHNICITYLIST}
                changeValue={setEthnicity}
              />
            </div>

            <div className="mt-1">
              <div>
                <b>Skin tone</b>
              </div>
              <SkinTonePicker selectedId={skinTone} setSkinTone={setSkinTone} />
            </div>

            <div className="mt-1">
              <div>
                <b>Gender</b>
              </div>
              <DropDown
                inputId="dropdown_gender"
                value={editGender}
                dataType={masterDataType.GENDERLIST}
                changeValue={setGender}
              />
            </div>

            <HeightPicker
              {...{
                heightType,
                setHeightType,
                heightFT,
                setHeightFT,
                heightIN,
                setHeightIN,
                heightM,
                setHeightM,
                containerClassName: 'mt-1',
                plainSwitchClasses: '',
                dropdownContainerClass: 'multi-textboxes',
                dropdownItemClass: 'multi-dropdown-item',
                metricInputClass: '',
                metricInputErrorClass: 'lower-item-error-text-box',
              }}
            />
            <div className="mt-1">
              <div>
                <b>Weight</b>
              </div>
              <WeightPicker {...{weightType, setWeightType, ...weightParams}} />
            </div>
            <div className="mt-1">
              <div>
                <b>Country</b>
              </div>
              <SearchableDropdown
                value={editcountry}
                changeValue={setCountry}
              />
            </div>
            <div className="pre-authentication-button-container mt-2">
              <PrimaryButton
                inputId="btn_update"
                label="Save changes "
                labelInAction="Saving..."
                isInAction={isSubmiting}
                disable={checkIfSubmitDisabled()}
                click={doUpdatePersonalInfo}
              />
            </div>
          </form>
        </div>
      </div>
      {errorMessage.length > 0 && (
        <ErrorMessage message={errorMessage} classes="personal-info-error" />
      )}
    </>
  );
}
