import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {isValidEmail} from '../../common/emailHandler';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import PasswordTextBoxWithLabel from '../common/textboxes/password-textbox/password-textbox';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';
import './signup.css';
import {doCreateAccount} from '../../rest-api/user-api/sign-up';
import LinearProgress from '@material/react-linear-progress';
import ErrorMessage, {
  InvalidPasswordMessage,
} from '../common/errors/error-message';
import {isPasswordValid} from '../../common/passwordHandler';
import {routesPath} from '../../router';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';

export default function SignUp(props) {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreating, setCreating] = useState(false);

  const checkIfSubmitDisable = () => {
    if (
      email.trim().length &&
      password.trim().length &&
      firstName.trim().length &&
      lastName.trim().length &&
      isValidEmail(email) &&
      isPasswordValid(password)
    ) {
      return false;
    }
    return true;
  };

  // const checkIfPasswordInvalid = () => {
  //     if (password.trim().length > 0) {
  //         return !isPasswordValid(password)
  //     }
  //     return false
  // }

  const createAccount = (event) => {
    if (event) event.preventDefault();
    setCreating(true);
    setErrorMessage('');

    doCreateAccount(email, firstName, lastName, password)
      .then((res) => {
        console.log('Response: ', res);
        if (res.data.signup === null) {
          throw new Error('SERVER_ERROR');
        }
        if (res.data.signup.statusCode === 200) {
          history.push(routesPath.VERIFY_ACCOUNT, {allow: true});
        } else {
          const {message} = JSON.parse(res.data.signup.body);
          setErrorMessage(message);
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.message === 'SERVER_ERROR') {
          createAccount();
        }
        setErrorMessage(e.message);
      })
      .then(() => {
        setCreating(false);
      });
  };

  useEffect(() => {
    const getUserState = getLocalStorage(localStorageKey.USERSTATE);
    if (Number(getUserState) === 3) {
      history.push(routesPath.HOME_PAGE);
    } else {
      deleteAllLocalStorage();
    }
  }, [history]);

  return (
    <>
      <PreAuthContainer
        children={
          <>
            <Link
              to={routesPath.SIGN_IN}
              className="no-underline"
              id="btn_back"
            >
              <div className="back-button font-rajdhani">
                <i className="fa fa-arrow-left" />{' '}
                <span style={{marginLeft: '1vw'}}>Back</span>
              </div>
            </Link>
            <div className="pre-authentication-header">Create your account</div>
            {isCreating && (
              <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
            )}
            <div className={isCreating ? 'pre-authentication-disable-div' : ''}>
              <form onSubmit={(e) => createAccount(e)}>
                <div className="pre-authentication-textbox-container">
                  <PlainTextBoxWithLabel
                    inputId="text_first_name"
                    label="First Name"
                    value={firstName}
                    changeValue={setFirstName}
                  />
                </div>
                <div className="pre-authentication-textbox-container">
                  <PlainTextBoxWithLabel
                    inputId="text_last_name"
                    label="Last Name"
                    value={lastName}
                    changeValue={setLastName}
                  />
                </div>
                <div className="pre-authentication-textbox-container">
                  <EmailTextBoxWithLabel
                    inputId="text_email"
                    label="Email"
                    value={email}
                    changeValue={setEmail}
                  />
                </div>
                <div className="pre-authentication-textbox-container">
                  <PasswordTextBoxWithLabel
                    inputId="text_password"
                    label="Password"
                    value={password}
                    changeValue={setPassword}
                  />
                  {!isPasswordValid(password) && <InvalidPasswordMessage />}
                </div>
                <div className="signup-button-container">
                  <PrimaryButton
                    inputId="btn_continue"
                    label="Continue"
                    labelInAction="Creating account..."
                    isInAction={isCreating}
                    disable={checkIfSubmitDisable()}
                    click={createAccount}
                  />
                </div>
              </form>
            </div>
            {errorMessage.length > 0 && <ErrorMessage message={errorMessage} />}
          </>
        }
      />
    </>
  );
}
