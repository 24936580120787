export const getShareDataBody = (object) => {
  const {range, contacts, details, userId} = object;

  const body = {
    query:
      'mutation share($userId: Int,$range: String,$contacts: [String],$details: String){shareData(userId:$userId,range:$range,contacts:$contacts,details:$details){statusCode body}}',
    variables: {
      userId: userId,
      range: range,
      contacts: contacts,
      details: details,
    },
    operationName: 'share',
  };
  return JSON.stringify(body);
};
