import LinearProgress from '@material/react-linear-progress';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {isPasswordValid} from '../../common/passwordHandler';
import {
  updateAccountInfo,
  updatePassword,
} from '../../rest-api/home-api/profile';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import ErrorMessage, {
  InvalidPasswordMessage,
} from '../common/errors/error-message';
import SuccessMessageSnackbar from '../common/success/success';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import PasswordTextBoxWithLabel from '../common/textboxes/password-textbox/password-textbox';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';

export default function AccountInfo(props) {
  const {details, getInfoAndSetFields} = props;
  const [successMessage, setSuccessful] = useState('');
  const {emailId, firstName, lastName} = details;
  const [errorMessage, setErrorMessage] = useState('');
  const userId = getLocalStorage(localStorageKey.USERID);
  const [isUpdatingInfo, setUpdatingInfo] = useState(false);
  const [isResetingPassword, setResetingPassword] = useState(false);

  const [firstname, setFirstName] = useState(firstName);
  const [lastname, setLastName] = useState(lastName);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const history = useHistory();

  const updateAccInfo = (e) => {
    e.preventDefault();
    setUpdatingInfo(true);
    setErrorMessage('');
    setSuccessful('');
    const body = {
      userId: userId,
      firstName: firstname,
      lastName: lastname,
    };
    updateAccountInfo(body)
      .then((res) => {
        const {statusCode, body} = res.data.editAdminUser;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          setSuccessful('User updated successfully');
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setUpdatingInfo(false))
      .then(() => getInfoAndSetFields());
  };

  const doUpdatePassword = () => {
    setResetingPassword(true);
    setErrorMessage('');
    setSuccessful('');
    const body = {
      userId: userId,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    updatePassword(body)
      .then((res) => {
        const {statusCode, body} = res.data.resetPassword;
        const {message} = JSON.parse(body);
        if (statusCode === 200) {
          setSuccessful(message);
          setTimeout(() => {
            deleteAllLocalStorage();
            history.push(routesPath.SIGN_IN);
          }, 1000);
        } else if (statusCode === 303) {
          deleteAllLocalStorage();
          history.push(routesPath.SIGN_IN);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setResetingPassword(false));
  };

  const isDisableUpdateInfoButton = () => {
    if (!(firstname.trim().length && lastname.trim().length)) {
      return true;
    } else if (
      getLocalStorage(localStorageKey.FIRST_NAME) == firstname &&
      getLocalStorage(localStorageKey.LAST_NAME) == lastname
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDisableUpdatePassButton = () => {
    return !(
      currentPassword.trim().length &&
      newPassword.trim().length &&
      isPasswordValid(newPassword)
    );
  };

  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      {(isUpdatingInfo || isResetingPassword) && (
        <LinearProgress indeterminate={true} />
      )}
      <div
        className={
          isUpdatingInfo || isResetingPassword
            ? 'pre-authentication-disable-div'
            : ''
        }
      >
        <div className="lower-item-info-container">
          <div className="mt-1">
            <PlainTextBoxWithLabel
              inputId="textbox_first_name"
              label="First Name"
              value={firstname}
              changeValue={setFirstName}
            />
          </div>
          <div className="mt-1">
            <PlainTextBoxWithLabel
              inputId="textbox_last_name"
              label="Last Name"
              value={lastname}
              changeValue={setLastName}
            />
          </div>
          <div className="mt-1">
            <EmailTextBoxWithLabel
              inputId="textbox_email"
              label="Email"
              disable={true}
              value={emailId}
            />
          </div>
          <div className="mt-1">
            <PasswordTextBoxWithLabel
              inputId="textbox_current_password"
              label="Current Password"
              value={currentPassword}
              changeValue={setCurrentPassword}
            />
          </div>
          <div className="mt-1">
            <PasswordTextBoxWithLabel
              inputId="textbox_new_password"
              label="New Password"
              value={newPassword}
              changeValue={setNewPassword}
            />
            {!isPasswordValid(newPassword) && <InvalidPasswordMessage />}
          </div>
          <div className="mt-2 account-info-button-container">
            <PrimaryButton
              inputId="btn_update_password"
              label="Update Password"
              disable={isDisableUpdatePassButton()}
              labelInAction="Updating Password ..."
              isInAction={isResetingPassword}
              classes="account-info-button"
              click={doUpdatePassword}
            />

            <OutlinePrimaryButton
              inputId="btn_update_scc_info"
              label="Update Acc Info"
              disable={isDisableUpdateInfoButton()}
              labelInAction="Updating Acc Info ..."
              isInAction={isUpdatingInfo}
              classes="account-info-button"
              click={updateAccInfo}
            />
          </div>
          {errorMessage.length > 0 && <ErrorMessage message={errorMessage} />}
        </div>
      </div>
    </>
  );
}
