import {
  localStorageKey,
  setLocalStorage,
  setSettingsDataIntoLocalstorage,
} from '../../common/localStorageHandler';
import {URL} from '../../rest-api/config';
import {routesPath} from '../../router';

const setLocalStorageData = (signInData) => {
  const {
    jwtToken,
    apiKeyGpl,
    apiKeyEureka,
    userId,
    firstName,
    lastName,
    profileUrl,
    fuseBillCustomerToken,
    emailId,
  } = signInData;
  setLocalStorage(localStorageKey.JWTTOKEN, jwtToken);
  setLocalStorage(localStorageKey.APIKEYGPL, apiKeyGpl);
  setLocalStorage(localStorageKey.APIKEYEUREKA, apiKeyEureka);
  setLocalStorage(localStorageKey.USERID, userId);
  setLocalStorage(localStorageKey.FIRST_NAME, firstName);
  setLocalStorage(localStorageKey.LAST_NAME, lastName);
  setLocalStorage(localStorageKey.EMAIL_ID, emailId);
  if (profileUrl && profileUrl !== 'null') {
    setLocalStorage(
      localStorageKey.PROFILE_PIC_URL,
      URL[URL.currentENV].profileUrl + profileUrl
    );
  } else {
    setLocalStorage(localStorageKey.PROFILE_PIC_URL, profileUrl);
  }
  setLocalStorage(localStorageKey.FUSEBILLCUSTOMERTOKEN, fuseBillCustomerToken);
  setSettingsDataIntoLocalstorage();
};
export const navigateToPage = (
  userState,
  signInData,
  history,
  replaceCurrentUrl
) => {
  let navigateTo = history.push;
  if (replaceCurrentUrl) {
    navigateTo = history.replace;
  }

  switch (userState) {
    case 1:
      navigateTo(routesPath.TERMS_OF_SERVICES, {allow: true});
      break;
    case 2:
      navigateTo(routesPath.PERSONAL_INFO, {allow: true});
      break;
    case 3:
      setLocalStorageData(signInData);
      navigateTo(routesPath.HOME_PAGE);
      break;
    default:
      break;
  }
};
