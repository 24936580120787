import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {useHistory} from 'react-router-dom';
import {deleteAllLocalStorage} from '../../common/localStorageHandler';
import {routesPath} from '../../router';

export function checkIfDrawerPresent(path) {
  const routesHaveDrawer = [
    routesPath.MEALS,
    routesPath.HEALTH_SYMPTOMS,
    routesPath.BLOOD_GLUCOSE,
    routesPath.STEPS_WALKED,
    routesPath.HEART_RATE,
    routesPath.RESPIRATION_RATE,
    routesPath.OXYGEN_SATURATION,
    routesPath.BLOOD_PRESSURE,
  ];
  return routesHaveDrawer.includes(path);
}
export default function ProfileDropdown(props) {
  const {isVisible, hide} = props;
  const history = useHistory();

  const doLogout = () => {
    deleteAllLocalStorage();
    history.push(routesPath.SIGN_IN);
  };
  const listItemsObject = [
    {
      label: 'Profile',
      id: 'profile',
      action: () => history.push(routesPath.PROFILE),
    },
    {
      label: 'Log out',
      id: 'log_out',
      action: doLogout,
    },
  ];
  const ListItem = (props) => {
    const {label, action, id} = props;
    return (
      <div className="profile-dropdown-item" id={'btn_' + id} onClick={action}>
        {label}
      </div>
    );
  };

  return (
    <>
      {isVisible && (
        <OutsideClickHandler onOutsideClick={hide}>
          <div
            className="profile-dropdown-container"
            style={
              checkIfDrawerPresent(history.location.pathname)
                ? {marginLeft: '77vw'}
                : {}
            }
          >
            {listItemsObject.map((obj, i) => (
              <ListItem
                key={i}
                id={obj.id}
                label={obj.label}
                action={obj.action}
              />
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </>
  );
}
