import React from 'react';
import Colors from '../../../theme/colors';
import styled from '@emotion/styled';
import {css} from '@emotion/react';

export const SKIN_TONE_DUMMY_ID = -1;

const SKIN_TONES = [
  {id: 1, color: '#F0E5CA'},
  {id: 2, color: '#E1BE92'},
  {id: 3, color: '#DEBD98'},
  {id: 4, color: '#B1926A'},
  {id: 5, color: '#835533'},
  {id: 6, color: '#4D3F39'},
];

export const isValidSkinToneId = (id) =>
  0 <= SKIN_TONES.findIndex((skinToneItem) => skinToneItem.id == id);

const Row = styled.div`
  display: flex;
  flex-direction: 'row';
  marginbottom: 10px;
  justify-content: space-between;
`;

const getBackgroundColor = (props) => css`
  background-color: ${props.color};
`;

const slectedStyle = (props) => css`
  border: ${props.selected ? 'solid 3px ' + Colors.selectedColor : 'unset'};
  margin: ${props.selected ? '0px' : '3px'};
`;

const ColorSquare = styled.div`
  width: 36px;
  height: 36px;
  ${getBackgroundColor}
  ${slectedStyle}
`;

/**
 *
 * @param selectedId id of selected skin tone (to highlight)
 * @param setSkinTone function for setting action skin tone
 * @return {JSX.Element}
 * @constructor
 */
const SkinTonePicker = ({selectedId, setSkinTone}) => {
  return (
    <Row>
      {SKIN_TONES.map((skinTone) => (
        <ColorSquare
          data-testid={skinTone.id}
          key={skinTone.id}
          color={skinTone.color}
          selected={skinTone.id == selectedId}
          onClick={() => {
            setSkinTone?.(skinTone.id);
          }}
        />
      ))}
    </Row>
  );
};

export default SkinTonePicker;
