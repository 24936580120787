export const getAddSymptomBody = (object) => {
  const {symptomType, timestamp, details, userId} = object;

  const body = {
    query:
      'mutation MyMutation($userId: Int, $type: Int, $timestamp: String, $details: String) {addSymptoms(details: $details, timestamp: $timestamp, type: $type, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      type: symptomType,
      timestamp: timestamp,
      details: details,
    },
  };
  return JSON.stringify(body);
};

export const getListSymptomBody = (object) => {
  const {timestamp, userId} = object;

  const body = {
    query:
      'query MyQuery($timestamp: String, $userId: Int) {symptomsList(timestamp: $timestamp, userId: $userId) {statusCode body {message success data {creationDate details symptomsId symptomsType symptomsTypeId timestamp updationDate}}}}',
    variables: {
      userId: userId,
      timestamp: timestamp,
    },
  };
  return JSON.stringify(body);
};

export const getEditSymptomBody = (object) => {
  const {symptomsId, userId, timestamp, symptomType, details} = object;

  const body = {
    query:
      'mutation MyMutation($userId: Int,$timestamp: String, $type: Int, $symptomsId: Int, $details: String) {editSymptoms(details: $details, symptomsId: $symptomsId, type: $type, userId: $userId,timestamp:$timestamp) {body statusCode}}',
    variables: {
      userId: userId,
      symptomsId: symptomsId,
      type: symptomType,
      timestamp: timestamp,
      details: details,
    },
  };

  return JSON.stringify(body);
};

export const getDeleteSymptomBody = (object) => {
  const {symptomsId, userId} = object;

  const body = {
    query:
      'mutation MyMutation($symptomsId: Int, $userId: Int) {deleteSymptoms(symptomsId: $symptomsId, userId: $userId) {body statusCode}}',
    variables: {
      userId: userId,
      symptomsId: symptomsId,
    },
  };

  return JSON.stringify(body);
};
