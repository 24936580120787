import React from 'react';
import './button-primary.css';

export default function PrimaryButton(props) {
  const {label, inputId, labelInAction, isInAction, disable, classes, click} =
    props;
  const id = 'btn_' + String(label).split(' ').join('_').toLowerCase();

  return (
    <>
      <button
        id={inputId ? inputId : id}
        className={'primary-button ' + classes}
        onClick={click}
        type="submit"
        disabled={disable || isInAction}
      >
        {isInAction ? labelInAction : label}
      </button>
    </>
  );
}
