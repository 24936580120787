import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAddContactBody,
  getContactsBody,
  getDeleteContactBody,
  getEditContactBody,
} from '../body/contact';
import {getHeadersWithKeyAndToken, URL} from '../config';
import {httpPostRequest} from '../httpHandler';

export const getContacts = () => {
  const userId = getLocalStorage(localStorageKey.USERID);
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);

  const body = getContactsBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};

export const addContact = (object) => {
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);

  const body = getAddContactBody(object);

  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};

export const editContact = (object) => {
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);

  const body = getEditContactBody(object);

  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};

export const deleteContact = (object) => {
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);

  const body = getDeleteContactBody(object);

  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};
