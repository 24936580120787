export default function appendSingleTooltip(obj) {
  const {svg, texts, cordinates} = obj;
  const {x, y} = cordinates;
  const {upper, lower} = texts;
  const rectOffset = {
    x: 50,
    y: 90,
  };

  const rectHeight = 50;
  const rectWidth = 120;

  const measureRectX = (xValue) => {
    const svgWidth = Number(svg.style('width').replace('px', ''));
    if (xValue < rectHeight) {
      return Number(xValue) - rectOffset.x / 2;
    }
    if (Number(xValue) + rectWidth / 2 < svgWidth) {
      return Number(xValue) - rectOffset.x - 10;
    } else {
      return Number(xValue) - rectOffset.x * 2;
    }
  };

  const measureRectY = (yValue) => {
    if (Number(yValue - rectOffset.y) < 0) {
      return yValue + rectOffset.y / 4;
    } else {
      return yValue - rectOffset.y;
    }
  };

  // tooltip
  const tooltip = svg
    .append('g')
    .attr('id', 'single-tooltip')
    .style('z-index', 1);

  //outer circle
  tooltip
    .append('circle')
    .attr('cx', x)
    .attr('cy', y)
    .style('stroke', '#1a74d4')
    .style('fill', 'white')
    .attr('r', 6);

  //inner circle
  tooltip
    .append('circle')
    .attr('cx', x)
    .attr('cy', y)
    .style('fill', '#1a74d4')
    .attr('r', 3);

  tooltip
    .append('rect')
    .attr('rx', 4)
    .attr('ry', 4)
    .style('z-index', 1)
    .attr('stroke', 'grey')
    .attr('fill', 'white')
    .style('height', rectHeight)
    .style('width', rectWidth)
    .attr('x', measureRectX(x))
    .attr('y', measureRectY(y));

  tooltip
    .append('text')
    .text(upper)
    .attr('x', measureRectX(x) + 10)
    .attr('y', measureRectY(y) + 20);

  tooltip
    .append('text')
    .text(lower)
    .style('font-weight', 'bold')
    .attr('x', measureRectX(x) + 10)
    .attr('y', measureRectY(y) + 40);

  return tooltip;
}
