import * as d3 from 'd3';
import React, {useEffect} from 'react';
import {getSvgWidth} from './common';
import './graphs.css';
import appendTooltip from './tooltips';

export default function BarChart(props) {
  const {data, id} = props;
  const {barColors, barData, maxValue, yAxislabels, yLabel, toolTipObjects} =
    data;
  const barValue = barData.map((b) => b.value);
  const barLabels = barData.map((b) => b.label);
  const height = window.screen.height;
  const svgHeight = height * 0.42;
  const individualWidth = 85 / barValue.length;
  const labelWidth = 85 / barLabels.length;
  const marginLeft = 10;

  const createLine = (svg, yAxis, value) => {
    const strokeWidth = yAxis === 0 || yAxis === height ? 1 : 0.4;
    svg
      .append('line')
      .attr('x1', marginLeft - 2 + '%')
      .attr('x2', '95%')
      .attr('y1', yAxis)
      .attr('y2', yAxis)
      .attr('stroke-width', strokeWidth)
      .attr('stroke', 'black')
      .attr('shape-rendering', 'crispEdges');

    svg
      .append('text')
      .text(maxValue - value)
      .attr('x', (marginLeft - 2) / 2 + '%')
      .attr('y', yAxis + 2)
      .style('font-size', 'small')
      .style('text-align', 'flex-end');
  };

  const createYAxislabel = (svg) => {
    svg
      .append('text')
      .text(yLabel)
      .attr('x', '2%')
      .attr('y', (height * 0.42) / 2 - yLabel.length * 2)
      .style('text-orientation', 'mixed')
      .style('writing-mode', 'vertical-rl')
      .style('font-weight', 'bold');
  };

  const createLabel = (svg, barValue, i) => {
    svg
      .append('text')
      .text(barValue)
      .attr('x', () => {
        if (i === 0) {
          return marginLeft + '%';
        }
        return i * labelWidth + marginLeft + '%';
      })
      .attr('y', svgHeight - 2);
  };

  const getScaleXValue = (width, data) => {
    const x = d3.scaleLinear().domain([0, 100]).range([0, width]);
    return x(data);
  };

  const y = d3
    .scaleLinear()
    .domain([0, maxValue])
    .range([height * 0.4, height * 0.02]);

  const drawChart = (onInit) => {
    let h = height * 0.4;
    // creating the svg
    d3.select('#bar-svg' + (id ? id : '')).remove();

    const svg = d3
      .select('#chart_' + (id ? id : ''))
      .append('svg')
      .attr('id', 'bar-svg' + (id ? id : ''))
      .attr('width', '100%')
      .attr('height', svgHeight);

    // creating lines parallel to x-axis

    yAxislabels.forEach((label) => {
      const {value, yAxisPercentage} = label;
      createLine(svg, h * yAxisPercentage, value);
    });

    if (yLabel) {
      createYAxislabel(svg);
    }

    // creating lineargradients of bar
    const grad = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'grad')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    grad
      .selectAll('stop')
      .data(barColors)
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (barColors.length - 1)) + '%';
      });

    // creating the bar
    svg
      .selectAll('rect')
      .data(barData)
      .enter()
      .append('rect')
      .on('mouseover', (e, data) => {
        tooltip
          .filter((c, i) => {
            return Array.from(barData).indexOf(data) === i;
          })
          .style('display', 'block');
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none');
      })
      .attr('x', (d, i) =>
        getScaleXValue(getSvgWidth(svg), i * individualWidth + marginLeft)
      )
      .attr('y', (d, i) => (onInit ? y(0) : y(d.value)))
      .attr('width', (d, i) =>
        getScaleXValue(getSvgWidth(svg), individualWidth * 0.6)
      )
      .attr('height', (d, i) => (onInit ? 0 : h - y(d.value)))
      .attr('rx', 4)
      .style('fill', 'url(#grad)')
      .style('cursor', 'pointer');

    //Animation
    svg
      .selectAll('rect')
      .transition()
      .duration(800)
      .attr('y', (d, i) => y(d.value))
      .attr('height', (d, i) => h - y(d.value))
      .delay(function (d, i) {
        return (i * 1000) / barData.length;
      });

    let toolTipCordinates = [];

    barData.forEach((d, i) => {
      toolTipCordinates.push({
        x: getScaleXValue(
          getSvgWidth(svg),
          i * individualWidth + marginLeft + (individualWidth * 0.6) / 2
        ),
        y: y(d.value),
      });
    });

    const tooltip = appendTooltip({
      svg: svg,
      toolTipObjects: toolTipObjects,
      cordinates: toolTipCordinates,
    });

    // yAxislabels of bar
    barLabels.forEach((d, i) => {
      createLabel(svg, d, i);
    });
  };

  useEffect(() => {
    drawChart(true);
    if (!d3.select('#bar-svg').empty()) {
      d3.select(window).on('resize.updateSvg', () => drawChart(false));
    }
  }, [drawChart]);
  return (
    <>
      <div id={'chart_' + (id ? id : '')} style={{width: '98%'}}></div>
    </>
  );
}
