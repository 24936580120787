import * as d3 from 'd3';
import {MEAL_TYPE} from '../common/config';
import {
  fastingBarData,
  mealBarAfter,
  mealBarWithin,
} from '../components/settings/vital-config';
import {getDataInRange, getFormattedHour} from './common';

export const complexScatterY = d3
  .scaleLinear()
  .domain([60, 190])
  .range([30, 200]);

export const multilineY = d3.scaleLinear().domain([0, 200]).range([30, 200]);

const USERSTATE = {
  FAST: 'FAST',
  MEALSWITHIN: 'MEALSWITHIN',
  MEALSAFTER: 'MEALSAFTER',
};

export const getSingleDayData = (date, inc) => {
  const breakFastTime = getDataInRange(7, 9);
  const lunchTime = getDataInRange(13, 15);
  let eachDayData = [];
  let standardLine = [];
  const isToday = date.toDateString() === new Date().toDateString();
  const lastHour = isToday ? date.getHours() : 24;
  const lastMinute = isToday ? date.getMinutes() : 0;
  const end = lastHour + lastMinute / 60;

  const meals = [
    {
      type: MEAL_TYPE.LIGHT,
      time: breakFastTime,
      yValue: 10,
    },
    {
      type: MEAL_TYPE.HEAVY,
      time: lunchTime,
      yValue: 10,
    },
  ];
  const activity = {
    start: getDataInRange(5, 7),
    end: getDataInRange(10, 12),
    yValue: 29,
    height: 20,
  };

  const fastingLine = {
    end: breakFastTime,
    yValue: 14,
    height: 5,
  };

  const getUserState = (hour) => {
    let state = USERSTATE.MEALSAFTER;
    if (hour < breakFastTime) {
      state = USERSTATE.FAST;
    }
    meals.forEach((meal) => {
      if (hour >= Number(meal.time) && hour <= Number(meal.time) + 2)
        state = USERSTATE.MEALSWITHIN;
    });

    return state;
  };

  const getVitalConf = (userstate) => {
    switch (userstate) {
      case USERSTATE.FAST:
        return fastingBarData;
      case USERSTATE.MEALSWITHIN:
        return mealBarWithin;
      case USERSTATE.MEALSAFTER:
        return mealBarAfter;
      default:
        return [];
    }
  };
  const getColorAndRange = (value, userState) => {
    let color = '';
    let stroke = '';
    const conf = getVitalConf(userState);
    conf.forEach((data) => {
      const {range} = data;
      if (range[0] <= value && range[1] > value) {
        color = data.color;
        stroke = data.borderColor;
      }
    });

    return {
      color: color,
      stroke: stroke,
    };
  };

  // const getRangeRespectToState = (userState) => {
  //     switch (userState) {
  //         case USERSTATE.FAST:
  //             return [2, 7]
  //         case USERSTATE.MEALSWITHIN:
  //             return [13, 45];
  //         case USERSTATE.MEALSAFTER:
  //             return [10, 60]
  //         default:
  //             break;
  //     }

  // }

  const getRangeRespectToState = (userState) => {
    switch (userState) {
      case USERSTATE.FAST:
        return [85, 90];
      case USERSTATE.MEALSWITHIN:
        return [90, 110];
      case USERSTATE.MEALSAFTER:
        return [90, 140];
      default:
        break;
    }
  };

  let preVal = Math.floor(0);
  let getLineValue = getDataInRange(80, 95);
  for (let i = 0; i <= end; i += inc) {
    const userState = getUserState(Math.floor(i));
    // const conf = getVitalConf(userState);
    const rangeOfScatter = getRangeRespectToState(userState);
    // const getRangeForLine = conf.filter(c => c.color === color.green)[0].range;
    if (preVal !== Math.floor(i)) {
      preVal = Math.floor(i);
      getLineValue = getDataInRange(rangeOfScatter[0], rangeOfScatter[1]);
    }
    const value =
      getDataInRange(0, 1) === 0
        ? getLineValue + getDataInRange(5, 10)
        : getLineValue - getDataInRange(2, 5);
    const colorConf = getColorAndRange(value, userState);

    eachDayData.push({
      x: i,
      y: value,
      color: colorConf.color,
      stroke: colorConf.stroke,
    });
    standardLine.push({
      x: i,
      y: getLineValue,
    });
  }

  return {
    date: new Date(date),
    activity: activity,
    meals: meals,
    fastingLine: fastingLine,
    eachDayData: eachDayData,
    standardLine: standardLine,
  };
};

export const generateComplexGlucoseDayData = (dateOfTheDay) => {
  const requestedDate = new Date(dateOfTheDay);
  const isToday = requestedDate.toDateString() === new Date().toDateString();
  let lastHour = isToday ? requestedDate.getHours() : 24;
  // let lastMinute = (isToday ? requestedDate.getMinutes() : 0);
  let xLabels = [];
  const singleDayData = getSingleDayData(requestedDate, 10 / 60);
  const {date, activity, meals, fastingLine, eachDayData, standardLine} =
    singleDayData;
  const grapghUpperLines = [
    {
      yValue: 70,
      label: 70,
      isDotted: true,
    },
    {
      yValue: 125,
      label: 125,
      isDotted: false,
    },
    {
      yValue: 180,
      label: 180,
      isDotted: true,
    },
  ];

  const graphLowerLines = [
    {
      yValue: 0,
      label: 'Meals',
      isDotted: false,
    },
    {
      yValue: 10,
      label: 'Fasting',
      isDotted: false,
    },
    {
      yValue: 15,
      label: 'Activity',
      isDotted: false,
    },
    {
      yValue: 30,
      label: '',
      isDotted: false,
    },
  ];

  const lastIndex = lastHour === 24 ? 24 : lastHour + 1;
  for (let i = 0; i <= lastIndex; i++) {
    if ((i === 0 || meals.map((m) => m.time).includes(i)) && i < lastHour - 2) {
      xLabels.push(getFormattedHour(i));
    } else if (i === lastIndex) {
      xLabels.push('<' + getFormattedHour(i));
    } else {
      xLabels.push('');
    }
  }

  return {
    requestedDate: date,
    yLabel: 'mg/dL',
    xLabels: xLabels,
    meals: meals,
    scatterData: eachDayData,
    standardLine: standardLine,
    graphUpperLines: grapghUpperLines,
    graphLowerLines: graphLowerLines,
    fastingLine: fastingLine,
    activity: activity,
    scatterLow: 70,
    scatterHigh: 180,
  };
};
