import {UPDATE_DOT} from '../actionTypes';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DOT:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
}
