import {HeightDataType} from '../../common/config';

export const HEIGHT_TRESHOLD = 3.0; // max, which may be set by imperial units
/**
 * Return empty string if everything is OK.
 *
 */
export const heightMeterValidator = (heightM, heightType) => {
  const heightNum = Number(heightM);
  if (heightType == HeightDataType.METER) {
    if (heightNum <= 0 || heightNum > HEIGHT_TRESHOLD) {
      return {
        errorMessage: 'Meter must be between 0 and 3.',
        valid: false,
      };
    }
  }

  return {
    valid: true,
  };
};

export const isHeightValid = (heightM, heightType, heightFT) => {
  const {valid: validMetricHeight} = heightMeterValidator(heightM, heightType);
  return heightType === HeightDataType.METER ? validMetricHeight : heightFT > 0;
};
