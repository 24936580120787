import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Spinner from 'react-spinner-material';
import Calender from '../../assets/icons/common/calendar.svg';
import Trash from '../../assets/icons/common/trash-blue.svg';
import HealthIcon from '../../assets/icons/health/health-symptoms_icon.svg';
import {months} from '../../common/config';
import {
  checkIfTokenPresent,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {deleteSymptom, listSymptom} from '../../rest-api/home-api/health';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import CardWithHeader from '../common/card-with-header/card-with-header';
import DropDown from '../common/drop-downs/dropdown';
import Modal, {modalType} from '../common/modal/modal';
import SuccessMessageSnackbar from '../common/success/success';
import {PlainTextBox} from '../common/textboxes/plain-textbox/plain-textbox';
import DashLayout from '../dash-layout/dash-layout';
import Drawer from '../drawer/drawer';
import './health-symptoms.css';

export default function HealthSymptoms() {
  const history = useHistory();
  const [dateValue, setDateValue] = useState(new Date());
  const [isDatePickerOpen, openDatePicker] = useState(false);
  const [isAddHealthOpened, openAddHealth] = useState(false);
  const [successMessage, setSuccessful] = useState('');
  const [symptom, setSymptom] = useState('');
  const [isEditOpen, openEdit] = useState(false);
  const [editObject, setEditObject] = useState({});
  const [symptomsInAction, setSymptomsInAction] = useState([]);
  const userId = getLocalStorage(localStorageKey.USERID);
  const [isConfirmDeleteOpened, openConfirmDelete] = useState(false);
  const [symptomIdToDelete, setSymptomIdToDelete] = useState('');

  const setTodayAsDate = () => {
    getAllSymptom(new Date());
  };

  const updateDateToCurrentTime = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    dateValue.setHours(currentHour, currentMinute);
  };

  const Loader = () => (
    <div className="meal-loader">
      <Spinner color="#1a74d4" stroke={4} />
    </div>
  );

  const NoHealthSymptoms = () => (
    <div className="no-meal-found">
      There are no symptoms to show for this day
    </div>
  );

  const Content = () => {
    return (
      <>
        {symptom === '' ? (
          <Loader />
        ) : (
          <>
            {symptom.length > 0 ? (
              <>
                {symptom.map((obj, i) => (
                  <SymptomList obj={obj} key={i} index={i} />
                ))}
              </>
            ) : (
              <NoHealthSymptoms />
            )}
          </>
        )}
      </>
    );
  };
  const Header = () => {
    return (
      <>
        <div className="meal-header-item">
          <div style={{display: 'flex', width: '50%'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableFuture
                open={isDatePickerOpen}
                value={dateValue}
                onChange={(val) => getAllSymptom(val)}
                onClose={() => openDatePicker(false)}
                animateYearScrolling
                TextFieldComponent={() => (
                  <img
                    className="c-pointer"
                    src={Calender}
                    alt="calendar"
                    onClick={() => openDatePicker(!isDatePickerOpen)}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
            <div style={{marginLeft: '5%'}}>
              {months[dateValue.getMonth()]} {dateValue.getDate()}
            </div>
          </div>
          <div
            className="meal-underline c-pointer"
            onClick={() => setTodayAsDate()}
          >
            Today
          </div>
        </div>
      </>
    );
  };

  const getAllSymptom = (val) => {
    setDateValue(val);
    const timestamp = new Date(val);
    const body = {
      userId: userId,
      timestamp: timestamp.setHours(0, 0, 0, 0),
    };
    setSymptom('');
    listSymptom(body)
      .then((res) => {
        if (res.data.symptomsList.statusCode === 200) {
          setSymptom(Array.from(res.data.symptomsList.body.data));
        }
      })
      .catch((e) => console.error(e));
  };

  const getTimeFormat = (timestamp) => {
    const date = new Date(Number(timestamp));
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (
      ('0' + (hours > 12 ? hours - 12 : hours)).slice(-2) +
      ' : ' +
      ('0' + minutes).slice(-2) +
      (hours > 12 ? ' pm' : ' am')
    );
  };

  const deleteConfirm = (symptomId) => {
    setSymptomIdToDelete(symptomId);
    openConfirmDelete(true);
  };

  const deleteItem = () => {
    let temp = Array.from(symptomsInAction);
    temp.push(symptomIdToDelete);
    setSuccessful('');
    setSymptomsInAction(Array.from(temp));
    const body = {
      userId: userId,
      symptomsId: symptomIdToDelete,
    };
    deleteSymptom(body).then((res) => {
      const {message} = JSON.parse(res.data.deleteSymptoms.body);
      if (res.data.deleteSymptoms.statusCode === 200) {
        setSuccessful(message);
        setSymptom(symptom.filter((m) => m.symptomsId !== symptomIdToDelete));
      }
      setSymptomsInAction(temp.filter((t) => t !== symptomIdToDelete));
    });
  };

  const SymptomList = (props) => {
    const {obj, index} = props;
    const {timestamp, details, symptomsTypeId, symptomsId} = obj;

    return (
      <>
        <div
          className="meal-list-container"
          style={index === symptom.length - 1 ? {border: 0} : {}}
        >
          <>
            {symptomsInAction.includes(symptomsId) ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <div className="meal-underline">{getTimeFormat(timestamp)}</div>
                <div className="meal-list-body">
                  <DropDown
                    isArrowHidden={true}
                    dataType="getSymptomsType"
                    changeValue={() => {}}
                    messageClass="meal-text-box"
                    classes="meal-text-box health-dropdown-box"
                    value={symptomsTypeId}
                  />
                  <PlainTextBox
                    value={details}
                    changeValue={() => {}}
                    label="No notes for this symptom."
                    classes="meal-text-box"
                  />
                </div>
                <div className="d-flex-space-between">
                  <i
                    className="fa fa-pencil edit-meal"
                    onClick={() => {
                      setEditObject(obj);
                      openEdit(true);
                    }}
                  />
                  <img
                    src={Trash}
                    style={{height: 28}}
                    alt="trash-blue"
                    className="c-pointer"
                    onClick={() => deleteConfirm(symptomsId)}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </>
    );
  };

  const alertConfirmDetails = {
    text: 'Are you sure you want to delete this symptom permanently?',
    yes: 'Yes',
    no: 'No',
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    getAllSymptom(new Date());
  }, []);

  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      <Modal
        details={alertConfirmDetails}
        type={modalType.ALERT_CONFIRM}
        isOpened={isConfirmDeleteOpened}
        close={() => openConfirmDelete(false)}
        afterSubmitMethod={() => deleteItem()}
      />

      <Modal
        afterSubmitMethod={(value) => {
          setSuccessful(value);
          getAllSymptom(dateValue);
        }}
        type={modalType.ADD_HEALTH}
        params={{selectedDate: dateValue}}
        close={() => openAddHealth(false)}
        isOpened={isAddHealthOpened}
      />

      <Modal
        afterSubmitMethod={(value) => {
          setSuccessful(value);
          getAllSymptom(dateValue);
        }}
        type={modalType.EDIT_HEALTH}
        params={{object: editObject}}
        close={() => openEdit(false)}
        isOpened={isEditOpen}
      />
      <DashLayout
        bodyClass="drawer-body"
        loggedIn={true}
        children={
          <Drawer
            children={
              <>
                <div className="meal-container">
                  <div className="meal-header">
                    <div className="health-header-label">
                      <img
                        src={HealthIcon}
                        alt="health-icon"
                        className="meal-icon"
                      />
                      <span style={{marginTop: '2%'}}>Health Symptoms</span>
                    </div>
                    <PrimaryButton
                      classes="add-health-button"
                      label="Add a symptom"
                      click={() => {
                        updateDateToCurrentTime();
                        openAddHealth(true);
                      }}
                    />
                  </div>
                  <CardWithHeader Header={Header} Content={Content} />
                </div>
              </>
            }
          />
        }
      />
    </>
  );
}
