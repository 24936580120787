import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {masterDataType} from '../../common/config';
import {
  errorMessageOfDay,
  errorMessageOfMonth,
  errorMessageOfYear,
} from '../../common/dateHandler';
import {
  localStorageKey,
  setLocalStorage,
  setSettingsDataIntoLocalstorage,
} from '../../common/localStorageHandler';
import {addOtherInfo} from '../../rest-api/user-api/sign-up';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import DropDown from '../common/drop-downs/dropdown';
import SearchableDropdown from '../common/drop-downs/searchableDropdown/searchableDropdown';
import ErrorMessage from '../common/errors/error-message';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import {PlainTextBox} from '../common/textboxes/plain-textbox/plain-textbox';
import './personal-info.css';
import {formatToFeetDotInches} from '../../common/metricsTool';
import HeightPicker from '../height-picker/height-picker';
import {isHeightValid} from '../height-picker/height-validator';
import SkinTonePicker, {
  isValidSkinToneId,
  SKIN_TONE_DUMMY_ID,
} from '../common/skintone-picker/skin-tone-picker';
import WeightPicker from '../weight-picker/weight-picker';
import {IMPERIAL_SYSTEM, useMeasurement} from '../../hooks/measurement';
import {useWeight} from '../weight-picker/hooks';
export default function PersonalInfo(props) {
  const state = props.location.state;
  const history = useHistory();
  const signInData = useSelector((state) => state.signIn);
  const [dayOfDOB, setDayOfDOB] = useState('');
  const [monthOfDOB, setMonthOfDOB] = useState('');
  const [yearOfDOB, setYearOfDOB] = useState('');
  const [enthnicity, setEthnicity] = useState('');
  const [gender, setGender] = useState('');
  const [heightFT, setHeightFT] = useState(0);
  const [heightIN, setHeightIN] = useState(0);
  const [heightM, setHeightM] = useState('');
  const {
    heightType,
    setHeightType,
    weightType,
    setWeightType,
    unitsOfMeasurement,
  } = useMeasurement(IMPERIAL_SYSTEM);
  const weightParams = useWeight('', weightType);

  const [errorMessage, setErrorMessage] = useState('');
  const [country, setCountry] = useState({});

  const [isSubmiting, setSubmiting] = useState(false);
  const [skinTone, setSkinTone] = useState(SKIN_TONE_DUMMY_ID);

  const formatBirthDay = () => {
    return yearOfDOB + '-' + monthOfDOB + '-' + dayOfDOB;
  };

  const setLocalStorageData = () => {
    const {
      jwtToken,
      apiKeyGpl,
      apiKeyEureka,
      userId,
      firstName,
      lastName,
      profileUrl,
      fuseBillCustomerToken,
    } = signInData;
    setLocalStorage(localStorageKey.JWTTOKEN, jwtToken);
    setLocalStorage(localStorageKey.APIKEYGPL, apiKeyGpl);
    setLocalStorage(localStorageKey.APIKEYEUREKA, apiKeyEureka);
    setLocalStorage(localStorageKey.USERID, userId);
    setLocalStorage(localStorageKey.FIRST_NAME, firstName);
    setLocalStorage(localStorageKey.LAST_NAME, lastName);
    setLocalStorage(localStorageKey.PROFILE_PIC_URL, profileUrl);
    setLocalStorage(
      localStorageKey.FUSEBILLCUSTOMERTOKEN,
      fuseBillCustomerToken
    );
    setSettingsDataIntoLocalstorage();
  };

  const addPersonalInfo = (e) => {
    e.preventDefault();

    setSubmiting(true);
    setErrorMessage('');
    const {userId, apiKeyEureka, jwtToken} = signInData;
    console.log('State: ', state);
    const object = {
      userId: userId,
      birthDay: formatBirthDay(),
      gender: gender,
      height: formatToFeetDotInches(heightM, heightFT, heightIN, heightType),
      weight: Number(weightParams.resultWeight),
      ethnicityId: Number(enthnicity),
      countryCode: country.code,
      unitOfMeasurement: unitsOfMeasurement,
      skinToneId: skinTone,
    };
    addOtherInfo(object, apiKeyEureka, jwtToken)
      .then((res) => {
        if (res.data.addUserInfo.statusCode === 200) {
          setLocalStorageData();
          history.push(routesPath.HOME_PAGE);
        } else {
          const {message} = JSON.parse(res.data.addUserInfo.body);
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const updateNumberValue = (value, maxLength, handler) => {
    if (value.length <= maxLength && /^\d*$/.test(value)) handler(value);
  };

  const checkIfSubmitDisabled = () => {
    console.log('Country: ', country);

    return !(
      dayOfDOB.trim().length &&
      monthOfDOB.trim().length &&
      yearOfDOB.trim().length &&
      enthnicity.trim().length &&
      isValidSkinToneId(skinTone) &&
      gender.trim().length &&
      isHeightValid(heightM, heightType, heightFT) &&
      weightParams?.resultWeight?.trim().length &&
      !errorMessageOfDay(dayOfDOB, monthOfDOB, yearOfDOB).length &&
      !errorMessageOfMonth(monthOfDOB, yearOfDOB).length &&
      !errorMessageOfYear(yearOfDOB).length &&
      country !== null &&
      country.code
    );
  };

  const ErrorText = (props) => {
    return (
      <div className="invalid-message">
        {props.message.length > 0 && (
          <i
            className="fa fa-exclamation-triangle"
            style={{marginRight: '2%'}}
          ></i>
        )}
        {props.message}
      </div>
    );
  };

  const dobFieldsObject = [
    {
      label: 'dd',
      value: dayOfDOB,
      handler: setDayOfDOB,
      maxLength: 2,
      errorMessage: errorMessageOfDay(dayOfDOB, monthOfDOB, yearOfDOB),
    },
    {
      label: 'mm',
      value: monthOfDOB,
      handler: setMonthOfDOB,
      maxLength: 2,
      errorMessage: errorMessageOfMonth(monthOfDOB, yearOfDOB),
    },
    {
      label: 'yyyy',
      value: yearOfDOB,
      handler: setYearOfDOB,
      maxLength: 4,
      errorMessage: errorMessageOfYear(yearOfDOB),
    },
  ];

  useEffect(() => {
    // check if the component is allowed for rendering or not
    if (!state || !state.allow || signInData.userId === '') {
      history.push(routesPath.SIGN_IN);
    }
  }, [state, history]);

  return (
    <PreAuthContainer>
      <div className="pre-authentication-header">
        Add your personal information
      </div>
      <div className="personal-sub-text">
        This information helps the product measure more accurately based on who
        you are.
      </div>
      {isSubmiting && (
        <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
      )}
      <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
        <form onSubmit={addPersonalInfo} style={{marginBottom: '2%'}}>
          <div className="pre-authentication-textbox-container">
            <div>
              <b> Date of birth</b>
            </div>
            <div className="multi-textboxes">
              {dobFieldsObject.map((obj, i) => (
                <PlainTextBox
                  inputId={'text_' + obj.label}
                  classes="dob-sub-textfield"
                  label={obj.label}
                  value={obj.value}
                  errorMessage={obj.errorMessage}
                  changeValue={(value) =>
                    updateNumberValue(value, obj.maxLength, obj.handler)
                  }
                  type="number"
                  maxLength={obj.maxLength}
                />
              ))}
            </div>
            <div className="multi-textboxes">
              {dobFieldsObject.map((obj, i) => (
                <ErrorText message={obj.errorMessage} />
              ))}
            </div>
          </div>
          <div className="pre-authentication-textbox-container">
            <div>
              <b>Ethnicity</b>
            </div>
            <DropDown
              inputId="select_ethinicity"
              value={enthnicity}
              dataType={masterDataType.ETHNICITYLIST}
              changeValue={setEthnicity}
            />
          </div>
          <div className="pre-authentication-textbox-container">
            <div>
              <b>Skin tone</b>
            </div>
            <SkinTonePicker selectedId={skinTone} setSkinTone={setSkinTone} />
          </div>
          <div className="pre-authentication-textbox-container">
            <div>
              <b>Gender</b>
            </div>
            <DropDown
              inputId="select_gender"
              value={gender}
              dataType={masterDataType.GENDERLIST}
              changeValue={setGender}
            />
          </div>
          <HeightPicker
            {...{
              heightType,
              setHeightType,
              heightFT,
              setHeightFT,
              heightIN,
              setHeightIN,
              heightM,
              setHeightM,
              containerClassName: 'pre-authentication-textbox-container',
              dropdownContainerClass: 'multi-textboxes-profile',
              dropdownItemClass: 'multi-dropdown-item-profile',
              metricInputClass: '',
              metricInputErrorClass: 'lower-item-error-text-box-profile',
            }}
          />

          <div className="pre-authentication-textbox-container">
            <div>
              <b>Weight</b>
            </div>
            <WeightPicker {...{weightType, setWeightType, ...weightParams}} />
          </div>
          <div className="pre-authentication-textbox-container">
            <div>
              <b>Country</b>
            </div>
            <SearchableDropdown value={country} changeValue={setCountry} />
          </div>
          <div className="pre-authentication-button-container">
            <PrimaryButton
              inputId="btn_continue"
              label="Continue"
              labelInAction="Submiting..."
              isInAction={isSubmiting}
              disable={checkIfSubmitDisabled()}
              click={addPersonalInfo}
            />
          </div>
        </form>
      </div>
      {errorMessage.length > 0 && <ErrorMessage message={errorMessage} />}
    </PreAuthContainer>
  );
}
