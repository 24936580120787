import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {getWatchSettings} from '../../rest-api/home-api/settings';
import store from '../store';
import {selectWatchSettingsData} from './selectors';
import {saveWatchSettings} from './reducer';
import {getBloodGlucoseLabel} from '../../utils/glucose';

export const getGlucoseUnit = async () => {
  let data = selectWatchSettingsData(store.getState());
  if (!data) {
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId,
    };
    const watchSettings = await getWatchSettings(body);
    data = watchSettings?.data?.getSettings?.body?.data;
    store.dispatch(saveWatchSettings(data));
  }

  return {
    unitLabel: getBloodGlucoseLabel(data?.cgmUnit),
    unit: data?.cgmUnit,
  };
};
