import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import LinearProgress from '@material/react-linear-progress';
import React, {useState} from 'react';
import MealHeavy from '../../assets/icons/meal/meal-heavy-black.svg';
import MealLight from '../../assets/icons/meal/meal-light-black.svg';
import MealModerate from '../../assets/icons/meal/meal-moderate-black.svg';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {addMeal} from '../../rest-api/home-api/meal';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import ErrorMessage from '../common/errors/error-message';
import TextArea from '../common/textarea/textarea';
import PlainTextBoxWithLabel from '../common/textboxes/plain-textbox/plain-textbox';

const mealSizeOptions = [
  {
    icon: MealLight,
    label: 'Light',
    size: 1,
  },
  {
    icon: MealModerate,
    label: 'Moderate',
    size: 2,
  },
  {
    icon: MealHeavy,
    label: 'Heavy',
    size: 3,
  },
];

export default function AddMeal(props) {
  const {close, afterSubmitMethod, params} = props;
  const [isSubmiting, setSubmiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTimePickerOpened, openTimePicker] = useState(false);
  console.log('date: ', params.selectedDate);
  const [selectedTime, setTime] = useState(params.selectedDate);
  const [mealSize, setMealSize] = useState('');
  const [details, setDetails] = useState('');

  const MealRadioButtons = (props) => {
    const {icon, size, label} = props;
    return (
      <div className="add-meal-radio">
        <input
          checked={size === mealSize}
          onChange={() => setMealSize(size)}
          className="radio-button"
          name="radio"
          type="radio"
        />
        <img className="ml-1" src={icon} alt="radio-icon" />
        <span className="ml-1">{label}</span>
      </div>
    );
  };

  const addNewMeal = (e) => {
    e.preventDefault();
    setSubmiting(true);
    setErrorMessage('');
    afterSubmitMethod('');
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId: userId,
      size: mealSize,
      timestamp: selectedTime.getTime(),
      details: details,
    };

    addMeal(body)
      .then((res) => {
        console.log('got response ', res);
        const {message} = JSON.parse(res.data.addMeals.body);
        if (res.data.addMeals.statusCode === 200) {
          close();
          afterSubmitMethod(message);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const getTime = () => {
    const hour = selectedTime.getHours();
    const minute = selectedTime.getMinutes();
    if (hour >= 12) {
      return (
        ('0' + (hour - (hour !== 12 ? 12 : 0))).slice(-2) +
        ' : ' +
        ('0' + minute).slice(-2) +
        ' PM'
      );
    } else {
      return ('0' + hour).slice(-2) + ' : ' + (' 0' + minute).slice(-2) + ' AM';
    }
  };

  const changeTime = (value) => {
    setErrorMessage('');
    const currentTimeStamp = new Date().getTime();
    if (currentTimeStamp < value.getTime()) {
      setErrorMessage("You can't enter future time");
    } else {
      setTime(value);
    }
  };

  return (
    <>
      <div className="add-contact-container">
        <div className="add-contact-header">
          <div className="add-contact-header-text">Add Meal</div>
          <i
            className="fa fa-close"
            onClick={close}
            style={{color: 'dodgerblue', cursor: 'pointer'}}
          />
        </div>
        {isSubmiting && <LinearProgress indeterminate={true} />}
        <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
          <form onSubmit={addNewMeal}>
            <div className="add-contact-body">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  label="12 hours"
                  emptyLabel="some"
                  value={selectedTime}
                  open={isTimePickerOpened}
                  onClose={() => openTimePicker(false)}
                  onChange={changeTime}
                  TextFieldComponent={() => (
                    <div className="mt-1">
                      <PlainTextBoxWithLabel
                        classes="add-meal-textbox"
                        value={getTime()}
                        label="Time"
                        click={() => openTimePicker(true)}
                      />
                    </div>
                  )}
                />
              </MuiPickersUtilsProvider>

              <div className="add-meal-size-option mt-1">
                <span className="textarea-label" style={{marginLeft: 0}}>
                  Size of Meal
                </span>
                {mealSizeOptions.map((obj, i) => (
                  <MealRadioButtons
                    key={i}
                    label={obj.label}
                    icon={obj.icon}
                    size={obj.size}
                  />
                ))}
              </div>
              <div className="textarea-label">Details</div>
              <div style={{width: '97%'}}>
                <TextArea
                  value={details}
                  changeValue={setDetails}
                  placeholder="Add notes"
                />
              </div>
              <div className="add-contact-text-box">
                <PrimaryButton
                  disable={mealSize === ''}
                  labelInAction="Adding ..."
                  isInAction={isSubmiting}
                  label="Add"
                  classes="add-contact-downdown"
                  click={addNewMeal}
                />
              </div>
              {errorMessage.length > 0 && (
                <ErrorMessage
                  classes="add-contact-text-box-input"
                  message={errorMessage}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
