import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAddBloodGroupBody,
  getAddKnownDiseaseBody,
  getBloodGroupBody,
  getEditAccountInfoBody,
  getKnownDiseaseBody,
  getProfileInfoBody,
  getProfilePicBody,
  getUpdatePasswordBody,
  getUpdatePersonalInfoBody,
  getUpdateStepGoalBody,
} from '../body/profile';
import {
  getHeadersWithKeyAndToken,
  getHeadersWithToken,
  headers,
  URL,
} from '../config';
import {httpPostRequest} from '../httpHandler';

export const getProfileInfo = () => {
  const userId = getLocalStorage(localStorageKey.USERID);
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getProfileInfoBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const uploadProfilePic = (object) => {
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getProfilePicBody(object);
  return httpPostRequest(
    URL[URL.currentENV].uploadProfilePic,
    getHeadersWithToken(token),
    body
  );
};

export const updateAccountInfo = (object) => {
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getEditAccountInfoBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};

export const updatePassword = (object) => {
  const apikeyGpl = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getUpdatePasswordBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikeyGpl, token),
    body
  );
};

export const updateStepGoal = (stepGoal) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = getUpdateStepGoalBody({
    userId: userId,
    stepGoal: stepGoal,
  });
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getBloodGroup = () => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = getBloodGroupBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getKnownDisease = () => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = getKnownDiseaseBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const updateBloodGroup = (bloodGroup) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = getAddBloodGroupBody({
    userId: userId,
    bloodGroup: bloodGroup,
  });
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const updateKnownDisease = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAddKnownDiseaseBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const updatePersonalInfo = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYGPL);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getUpdatePersonalInfoBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQlAdmin,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
