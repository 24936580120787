import React from 'react';

export default function SubscriptionInfo(props) {
  const monthList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const getTimeFormat = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = monthList[date.getMonth() + 1];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let time =
      ('0' + (hours > 12 ? hours - 12 : hours)).slice(-2) +
      ':' +
      ('0' + minutes).slice(-2) +
      (hours > 12 ? ' pm' : ' am');
    return month + ' ' + day + ', ' + year + ' ' + time;
  };
  let subscriptionStatus = props.subscriptionStatus;
  let subscription,
    customer,
    card,
    planType,
    planAmount,
    chargeType,
    chargeAmount;
  if (subscriptionStatus) {
    subscription = props.subscriptionInfo.subscription;
    customer = props.subscriptionInfo.customer;
    card = props.subscriptionInfo.card;
    for (let i = 0; i < subscription.subscription_items.length; i++) {
      if (subscription.subscription_items[i].item_type == 'plan') {
        planType =
          subscription.subscription_items[i].item_price_id.split('-')[0];
        planAmount = (subscription.subscription_items[i].amount / 100).toFixed(
          2
        );
      } else if (subscription.subscription_items[i].item_type == 'charge') {
        chargeType =
          subscription.subscription_items[i].item_price_id.split('-')[0];
        chargeAmount = (
          subscription.subscription_items[i].amount / 100
        ).toFixed(2);
      }
    }
  }
  return (
    <>
      <div className="sub-page">
        {subscriptionStatus ? (
          <table>
            <tr>
              <th>Plan: </th>
              <td>{planType}</td>
            </tr>
            <tr>
              <th>Price: </th>
              <td>${planAmount}</td>
            </tr>
            <tr>
              <th>Started on: </th>
              <td>{getTimeFormat(subscription.current_term_start)}</td>
            </tr>
            <tr>
              <th>Next Billing at: </th>
              <td>{getTimeFormat(subscription.next_billing_at)}</td>
            </tr>
            {chargeType ? (
              <tr>
                <th>Charge: </th>
                <td>{chargeType}</td>
              </tr>
            ) : (
              ''
            )}
            {chargeType ? (
              <tr>
                <th>Price: </th>
                <td>${chargeAmount}</td>
              </tr>
            ) : (
              ''
            )}
            <tr>
              <th>Card Type: </th>
              <td>{card.funding_type} card</td>
            </tr>
            <tr>
              <th>Card Number: </th>
              <td>{card.masked_number}</td>
            </tr>
            <tr>
              <th>Brand: </th>
              <td>{card.card_type}</td>
            </tr>
            <tr>
              <th>Expiry: </th>
              <td>
                {card.expiry_month}/{card.expiry_year}
              </td>
            </tr>
            <tr>
              <th>Auto Collection: </th>
              <td>{customer.auto_collection}</td>
            </tr>
          </table>
        ) : (
          <div>
            <div>You don't have any Subscription.</div>
            <b>
              <a href="/plan-registration">Subscribe Now</a>
            </b>{' '}
            to enjoy continue services.
          </div>
        )}
      </div>
    </>
  );
}
