import * as d3 from 'd3';
import React, {useEffect} from 'react';
import {stepsAreaColors} from '../../fake-data-generator/common';
import {generateStepsAreaData} from '../../fake-data-generator/steps-walked';
import {getSvgWidth} from './common';
import appendTooltip from './tooltips';

export default function AreaChart(props) {
  const {data} = props;
  const height = window.screen.height;
  const {areaGraphData, yAxislabels, yLabels, toolTipObjects, maxValue} =
    generateStepsAreaData(data);
  const svgHeight = height * 0.42;
  const labelWidth = 85 / yLabels.length;
  const marginLeft = 10;
  const max = maxValue;

  const createLabel = (svg, barValue, i) => {
    svg
      .append('text')
      .text(barValue)
      .attr('x', () => {
        if (i === 0) {
          return marginLeft + '%';
        }
        return i * labelWidth + marginLeft + '%';
      })
      .attr('y', svgHeight - 2);
  };

  const createLine = (svg, yAxis, value) => {
    const strokeWidth = yAxis === 0 || yAxis === height ? 1 : 0.4;
    svg
      .append('line')
      .attr('x1', marginLeft - 2 + '%')
      .attr('x2', '95%')
      .attr('y1', y(value))
      .attr('y2', y(value))
      .attr('stroke-width', strokeWidth)
      .attr('stroke', 'black')
      .attr('shape-rendering', 'crispEdges');

    svg
      .append('text')
      .text(value)
      .attr('x', (marginLeft - 2) / 2 + '%')
      .attr('y', y(value) + 2)
      .style('font-size', 'small')
      .style('text-align', 'flex-end');
  };

  const getScaleXValueRev = (width, data) => {
    const x = d3.scaleLinear().domain([0, 100]).range([0, width]);
    return x.invert(data);
  };

  const getScaleXValue = (width, data) => {
    const x = d3.scaleLinear().domain([0, 100]).range([0, width]);
    return x(data);
  };

  const y = d3
    .scaleLinear()
    .domain([0, max])
    .range([height * 0.4, height * 0.02]);

  const valueline = (value, width, tooltip) => {
    console.log('width: ', width);
    const line = d3
      .line()
      .x(function (d) {
        return getScaleXValue(width, d.x);
      })
      .y(function (d) {
        return y(d.y);
      });

    return line(value);
  };

  const filledArea = (value, width) => {
    const area = d3
      .area()
      .x(function (d) {
        return getScaleXValue(width, d.x);
      })
      .y0(y(0))
      .y1(function (d) {
        return y(d.y);
      });
    return area(value);
  };
  // .y0(200)                            // Y position of bottom line breaks (200 = bottom of svg area)

  const findTheClosest = (value) => {
    const xValues = areaGraphData.map((a) => a.x);
    const closest = xValues.reduce(function (prev, curr) {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });

    console.log('closet: ', closest);
    return Math.floor(closest);
  };
  const drawAreaChart = (onInit) => {
    let h = height * 0.4;

    d3.select('#area-svg').remove();

    const svg = d3
      .select('#area-chart')
      .append('svg')
      .attr('id', 'area-svg')
      .attr('width', '100%')
      .attr('height', svgHeight);

    const areaGrad = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'grad')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%')
      .attr('id', 'areaGrad');

    areaGrad
      .selectAll('stop')
      .data(stepsAreaColors)
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (stepsAreaColors.length - 1)) + '%';
      });

    yAxislabels.forEach((label) => {
      const {value, yAxisPercentage} = label;
      createLine(svg, h * yAxisPercentage, value);
    });

    const pathArea = svg
      .append('path')
      .datum(areaGraphData)
      .style('fill', 'url(#areaGrad)')
      .style('opacity', 0.2)
      .attr('d', filledArea(areaGraphData, getSvgWidth(svg)));

    const pathLine = svg
      .append('path')
      .datum(areaGraphData)
      .attr('stroke', stepsAreaColors[0])
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('d', valueline(areaGraphData, getSvgWidth(svg)));

    let toolTipCordinates = [];

    areaGraphData.forEach((d) => {
      toolTipCordinates.push({
        x: getScaleXValue(getSvgWidth(svg), d.x),
        y: y(d.y),
      });
    });

    const tooltip = appendTooltip({
      svg: svg,
      toolTipObjects: toolTipObjects,
      cordinates: toolTipCordinates,
    });

    if (pathLine.node()) {
      var totalLength = pathLine.node().getTotalLength();
    }
    if (onInit) {
      pathLine
        .attr('stroke-dasharray', totalLength + ' ' + totalLength)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(1000)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    }

    pathLine
      .on('mouseover', (e, data) => {
        const xValue = findTheClosest(
          getScaleXValueRev(getSvgWidth(svg), d3.pointer(e)[0])
        );
        tooltip
          .filter((c) => {
            return (
              Math.floor(getScaleXValueRev(getSvgWidth(svg), c.x)) === xValue
            );
          })
          .style('display', 'block');
      })
      .on('mouseout', (e, data) => {
        tooltip.style('display', 'none');
      });
    yLabels.forEach((d, i) => {
      createLabel(svg, d, i);
    });
  };
  useEffect(() => {
    drawAreaChart(true);
    if (!d3.select('#area-svg').empty()) {
      d3.select(window).on('resize', () => drawAreaChart(false));
    }
  }, [drawAreaChart]);
  return <div id="area-chart" style={{width: '98%'}}></div>;
}
