import {Divider} from '@material-ui/core';
import INFOICON from '../../assets/icons/common/info.svg';
import OKICON from '../../assets/icons/common/ok.svg';
import {HealthParameterType} from '../../common/config';
import {generateHealthBarYearData} from '../../health-data-generator/health-data-year';
import BarChart from '../graphs/barchart';
import {DotDetailsContainerWithHighLow} from '../graphs/common';
import MultiLineAreaChart from '../graphs/multiLineWithAreaChart';
import {Loader} from './health-common';
import {useEffect, useState} from 'react';

const YearOkContainer = (props) => {
  const {inRangeDays, timeType} = props;

  return (
    <div className="graph-lower-container blood-glucose-time-in-container">
      <img src={OKICON} alt="ok-icon" width={24} height={24} />
      <span style={{marginLeft: '1%', marginRight: '1%'}}>
        {inRangeDays} month(s){' '}
      </span>
      <span style={{fontWeight: '500'}}>
        {' '}
        above 70% benchmark, 1 more than last {String(
          timeType
        ).toLowerCase()}{' '}
      </span>
    </div>
  );
};
export const YearSummary = (props) => {
  const [{barData, barDataSys, barDataDia}, setData] = useState({});

  const {data, healthType, dailyAverage, setOpenTimeRangeBound, timeType} =
    props;
  const type = String(timeType).toLowerCase();
  //   const [dailyAverage, setDailyAverage] = useState("");
  const menuItem = [
    {
      value: 0,
      label: `Previous 4 ${type}s`,
    },
    {
      value: 1,
      label: `Previous 3 ${type}s`,
    },
    {
      value: 2,
      label: `Previous 2 ${type}s`,
    },
    {
      value: 3,
      label: `Previous ${type}`,
    },
  ];
  useEffect(async () => {
    const barData = await generateHealthBarYearData(data, healthType);
    const barDataSys = await generateHealthBarYearData(data, healthType, 0);
    const barDataDia = await generateHealthBarYearData(data, healthType, 1);
    setData({barData, barDataSys, barDataDia});
  }, []);

  if (!barData) {
    return null;
  }

  return (
    <>
      {dailyAverage !== '' ? (
        <>
          {/* {countData.length > 0 ?
                        <> */}
          <MultiLineAreaChart
            isSingleLine={true}
            id="_single"
            timeType={type}
            data={dailyAverage}
            menuItem={menuItem}
          />
          <DotDetailsContainerWithHighLow
            isSingleLine={true}
            type={healthType}
          />
          <Divider className="thick-black-divider" />
          {healthType === HealthParameterType.BLOOD_PRESSURE ? (
            <>
              <div className="time-in-range-week">
                <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                  {' '}
                  Systolic
                </span>
              </div>
              <YearOkContainer
                inRangeDays={barDataSys.inRange}
                timeType={timeType}
              />
              <BarChart data={barDataSys} id="sys" />
              <Divider className="thick-black-divider" />
              <div className="time-in-range-week">
                <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                  {' '}
                  Diastolic
                </span>
              </div>
              <YearOkContainer
                inRangeDays={barDataDia.inRange}
                timeType={timeType}
              />
              <BarChart data={barDataDia} id="dia" />
            </>
          ) : (
            <>
              <div className="time-in-range-week">
                <span style={{fontWeight: 'bold', marginRight: '1%'}}>
                  {' '}
                  % Time in range
                </span>
                {healthType === HealthParameterType.BLOOD_GLUCOSE && (
                  <img
                    className="c-pointer"
                    onClick={() => setOpenTimeRangeBound(true)}
                    src={INFOICON}
                    alt="info-icon"
                    width={24}
                    height={24}
                  />
                )}
              </div>
              <YearOkContainer
                inRangeDays={barData.inRange}
                timeType={timeType}
              />
              <BarChart data={barData} />
            </>
          )}
          <Divider className="thick-black-divider" />
          <div style={{fontWeight: 'bold', margin: '1%'}}>
            Daily averages by hour
          </div>
          <MultiLineAreaChart
            id="_multi"
            timeType={type}
            data={dailyAverage}
            menuItem={menuItem}
          />
          <DotDetailsContainerWithHighLow type={healthType} />
          {/* </> :
                        <NoDataContainer />} */}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
