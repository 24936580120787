import {
  getAddOtherInfoBody,
  getSignUpBody,
  getTermsOfServiceBody,
} from '../body/userModule';
import {
  getHeadersWithKey,
  getHeadersWithKeyAndToken,
  headers,
  URL,
} from '../config';
import {httpGetRequest, httpPostRequest} from '../httpHandler';

export const doCreateAccount = (email, firstName, lastName, password) => {
  const body = getSignUpBody(email, firstName, lastName, password);
  return httpGetRequest(URL[URL.currentENV].getApiKey, {})
    .then((res) => {
      if (res.statusCode === 200) {
        return getHeadersWithKey(res.data.apiKeyEureka);
      } else {
        throw new Error('Key not found');
      }
    })
    .then((header) =>
      httpPostRequest(URL[URL.currentENV].graphQl, header, body)
    );
};

export const acceptTermsOfService = (userId, apiKey, token) => {
  const body = getTermsOfServiceBody(userId);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apiKey, token),
    body
  );
};

export const addOtherInfo = (obj, apiKey, token) => {
  const body = getAddOtherInfoBody(obj);

  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apiKey, token),
    body
  );
};
