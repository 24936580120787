import {Divider} from '@material-ui/core';
import React from 'react';
import './plain-switch.css';

export default function PlainSwitch(props) {
  const {value, switchId, changeValue, classes, switches} = props;

  const checkIfActive = (label) => {
    if (label === value) {
      return true;
    }
    return false;
  };

  const toogleSwitch = (label) => {
    if (label !== value) {
      changeValue(label);
    }
  };

  const getClass = (label) => {
    return 'switch ' + (checkIfActive(label) ? 'switch-active' : '');
  };
  const Switch = (props) => {
    const {label, hideDivider} = props;
    return (
      <>
        <div
          key="switch-plain"
          className={getClass(label)}
          id={'div_' + label}
          onClick={() => toogleSwitch(label)}
        >
          {label}
        </div>
        {!(checkIfActive(label) || hideDivider) && (
          <Divider orientation="vertical" flexItem key="switch-divider" />
        )}
      </>
    );
  };
  return (
    <>
      <div
        key={switchId}
        id={switchId}
        className={(classes ? classes : '') + ' switch-container'}
      >
        {switches.map((value, i) => (
          <Switch
            label={value}
            key={`switch-${value}`}
            hideDivider={i === switches.length - 1}
          />
        ))}
      </div>
    </>
  );
}
