import * as d3 from 'd3';
import {HealthParameterType} from '../common/config';
import {getLocalStorage, localStorageKey} from '../common/localStorageHandler';
import {
  fastingBarData,
  heartRateConf,
  mealBarAfter,
  mealBarWithin,
  oxygenSaturationConf,
  respirationRateConf,
  systolicBloodPressureConf,
  diastolicBloodPressureConf,
} from '../components/settings/vital-config';
import {generateComplexGlucoseDayData} from '../fake-data-generator/bloodGlucose';
import {getDataInRange, getFormattedHour} from '../fake-data-generator/common';
import {listMeal} from '../rest-api/home-api/meal';
import {getAllHealthValues} from '../rest-api/home-api/overview';
import {getHealthConfig, getHealthRange} from './health-common';
import {getGraphLowerLines, getGraphUpperLines} from './utils';

export const complexScatterY = (range, diff) => {
  const scale = d3
    .scaleLinear()
    .domain([range[0] - diff, range[1] + diff])
    .range([30, 200]);
  return scale;
};

export const USERSTATE = {
  FAST: 'FAST',
  MEALSWITHIN: 'MEALSWITHIN',
  MEALSAFTER: 'MEALSAFTER',
};

export const getSingleDayData = (date, inc, param, type) => {
  let eachDayData = [];
  let standardLine = [];
  const isToday = false;
  // const isToday = (date.toDateString() === new Date().toDateString());
  const lastHour = isToday ? date.getHours() : 24;
  const lastMinute = isToday ? date.getMinutes() : 0;
  const end = lastHour + lastMinute / 60;
  const startTime = new Date(date);
  startTime.setHours(0, 0, 0, 0);
  const endTime = new Date(date);
  endTime.setHours(23, 59, 0, 0);
  const userId = getLocalStorage(localStorageKey.USERID);
  const body = {
    userId: userId,
    startTS: startTime.getTime(),
    endTS: endTime.getTime(),
  };
  let meals = [];
  const activity = {
    start: getDataInRange(5, 7),
    end: getDataInRange(10, 12),
    yValue: 29,
    height: 20,
  };

  let fastingLine = [];

  const getUserState = (hour) => {
    let state = USERSTATE.MEALSAFTER;
    if (meals[0] && hour < meals[0].time) {
      state = USERSTATE.FAST;
    }
    meals.forEach((meal) => {
      if (hour >= Number(meal.time) && hour <= Number(meal.time) + 2)
        state = USERSTATE.MEALSWITHIN;
    });
    return state;
  };

  const getVitalConf = (userstate, type) => {
    if (type === HealthParameterType.BLOOD_GLUCOSE) {
      switch (userstate) {
        case USERSTATE.FAST:
          return fastingBarData;
        case USERSTATE.MEALSWITHIN:
          return mealBarWithin;
        case USERSTATE.MEALSAFTER:
          return mealBarAfter;
        default:
          break;
      }
    }
    switch (type) {
      case HealthParameterType.HEART_RATE:
        return heartRateConf;
      case HealthParameterType.OXYGEN_SATURATION:
        return oxygenSaturationConf;
      case HealthParameterType.RESPIRATION_RATE:
        return respirationRateConf;
      case HealthParameterType.BLOOD_PRESSURE:
        return [systolicBloodPressureConf, diastolicBloodPressureConf];
      default:
        return [];
    }
  };

  const getColorAndRange = (value, userState) => {
    let color = '';
    let stroke = '';
    const conf = getVitalConf(userState, type);
    if (type !== HealthParameterType.BLOOD_PRESSURE) {
      conf.forEach((data) => {
        const {range} = data;
        if (range[0] <= value && range[1] >= value) {
          color = data.color;
          stroke = data.borderColor;
        }
      });
    } else {
      color = [];
      stroke = [];
      conf[0].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[0] && range[1] >= value[0]) {
          color[0] = data.color;
          stroke[0] = data.borderColor;
        }
      });
      conf[1].forEach((data) => {
        const {range} = data;
        if (range[0] <= value[1] && range[1] >= value[1]) {
          color[1] = data.color;
          stroke[1] = data.borderColor;
        }
      });
    }

    return {
      color: color,
      stroke: stroke,
    };
  };

  const getRangeRespectToState = (userState) => {
    switch (userState) {
      case USERSTATE.FAST:
        return [85, 90];
      case USERSTATE.MEALSWITHIN:
        return [90, 110];
      case USERSTATE.MEALSAFTER:
        return [90, 140];
      default:
        break;
    }
  };

  let preVal = Math.floor(0);
  const range =
    type === HealthParameterType.BLOOD_GLUCOSE
      ? getRangeRespectToState(USERSTATE.FAST)
      : getHealthRange(type);
  let getLineValue = getDataInRange(range[0], range[1]);
  for (let i = 0; i <= end; i += inc) {
    const userState = getUserState(Math.floor(i));
    const rangeOfScatter =
      type === HealthParameterType.BLOOD_GLUCOSE
        ? getRangeRespectToState(userState)
        : getHealthRange(type);
    if (preVal !== Math.floor(i)) {
      preVal = Math.floor(i);
      getLineValue = getDataInRange(rangeOfScatter[0], rangeOfScatter[1]);
    }
    standardLine.push({
      x: i,
      y: getLineValue,
    });
  }

  return Promise.resolve()
    .then(() => {
      if (type !== HealthParameterType.BLOOD_GLUCOSE) {
        throw new Error('SKIP_MEAL');
      }
    })
    .then(() => listMeal({userId: userId, timestamp: startTime.getTime()}))
    .then((res) => {
      if (res.data.mealList.statusCode === 200) {
        Array.from(res.data.mealList.body.data).forEach((meal, i) => {
          let date = new Date(Number(meal.timestamp));
          meals.push({
            type: String(meal.mealSize).toUpperCase(),
            x: date.getHours() + date.getMinutes() / 60,
            date: date,
            yValue: 10,
          });
          if (i === 0) {
            fastingLine = {
              end: date.getHours() + date.getMinutes() / 60,
              yValue: 14,
              height: 5,
            };
          }
        });
      }
    })
    .catch((e) => {
      if (e.message !== 'SKIP_MEAL') {
        throw new Error(e.message);
      }
    })
    .then(() => getAllHealthValues(body))
    .then((res) => {
      const {fetchData} = res.data;
      if (fetchData.statusCode === 200) {
        const allData = fetchData.body.data;
        allData.forEach((response) => {
          const data = JSON.parse(response.data);
          const y =
            type === HealthParameterType.BLOOD_PRESSURE
              ? [data[param[0]], data[param[1]]]
              : data[param];

          const date = new Date(Number(response.ts));
          const x = date.getHours() + date.getMinutes() / 60;
          const userState = getUserState(Math.floor(x));
          const colorConf = getColorAndRange(y, userState);

          if (typeof y === 'number' && !isNaN(y)) {
            eachDayData.push({
              x: x,
              date: date,
              y: y,
              color: colorConf.color,
              stroke: colorConf.stroke,
            });
          } else if (Array.isArray(y)) {
            if (y[0] && y[1]) {
              eachDayData.push({
                x: x,
                date: date,
                y: y,
                color: colorConf.color,
                stroke: colorConf.stroke,
              });
            }
          }
        });
      }
    })
    .then(() => {
      return {
        date: new Date(date),
        activity: activity,
        meals: meals,
        fastingLine: fastingLine,
        eachDayData: eachDayData,
        standardLine: standardLine,
      };
    });
};

export const generateComplexHealthDayData = async (
  dateOfTheDay,
  type,
  isFake
) => {
  if (isFake) {
    return Promise.resolve(generateComplexGlucoseDayData(dateOfTheDay));
  }

  const {param, range, diff, unitLabel, unit, converter} =
    await getHealthConfig(type);

  return getSingleDayData(dateOfTheDay, 10 / 60, param, type).then(
    (singleDayData) => {
      console.log('Single day data response: ', singleDayData);
      const requestedDate = new Date(dateOfTheDay);
      const isToday =
        requestedDate.toDateString() === new Date().toDateString();
      let lastHour = isToday ? requestedDate.getHours() : 24;
      let xLabels = [];
      const {eachDayData, meals, activity, standardLine, fastingLine} =
        singleDayData;
      const graphUpperLines = getGraphUpperLines(range, unit, converter);
      const graphLowerLines = getGraphLowerLines();

      const lastIndex = lastHour === 24 ? 24 : lastHour + 1;
      for (let i = 0; i <= lastIndex; i++) {
        if (
          (i === 0 || meals.map((m) => m.time).includes(i)) &&
          i < lastHour - 2
        ) {
          xLabels.push(getFormattedHour(i));
        } else if (i === lastIndex) {
          xLabels.push('<' + getFormattedHour(i));
        } else {
          xLabels.push('');
        }
      }

      const res = {
        requestedDate: dateOfTheDay,
        yLabel: unitLabel,
        xLabels: xLabels,
        meals: meals,
        scatterData: eachDayData,
        standardLine: standardLine,
        graphUpperLines: graphUpperLines,
        graphLowerLines: graphLowerLines,
        fastingLine: fastingLine,
        activity: activity,
        scatterLow: range[0],
        scatterHigh: range[1],
        diffToAxis: diff,
        type: type,
        complexScatterY: complexScatterY(range, diff),
      };

      return res;
    }
  );
};
