import {HeightDataType} from '../common/config';
import {useCallback, useMemo, useState} from 'react';

export const KG = 'kg';
export const POUND = 'lb';
export const IMPERIAL_SYSTEM = 'FPS';
export const METRIC_SYSTEM = 'MKS';

export const useMeasurement = (initialUnitsOfMeasurement = IMPERIAL_SYSTEM) => {
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState(
    initialUnitsOfMeasurement
  );
  //heightType, setHeightType

  const setHeightType = useCallback(
    (newHeightType) => {
      setUnitsOfMeasurement(
        newHeightType == HeightDataType.FEET ? IMPERIAL_SYSTEM : METRIC_SYSTEM
      );
    },
    [setUnitsOfMeasurement]
  );

  const setWeightType = useCallback(
    (newWeightType) => {
      setUnitsOfMeasurement(
        newWeightType == POUND ? IMPERIAL_SYSTEM : METRIC_SYSTEM
      );
    },
    [setUnitsOfMeasurement]
  );

  return useMemo(
    () => ({
      heightType:
        unitsOfMeasurement == IMPERIAL_SYSTEM
          ? HeightDataType.FEET
          : HeightDataType.METER,
      setHeightType,
      weightType: unitsOfMeasurement == IMPERIAL_SYSTEM ? POUND : KG,
      setWeightType,
      unitsOfMeasurement,
      setUnitsOfMeasurement,
    }),
    [
      unitsOfMeasurement,
      setHeightType,
      setWeightType,
      unitsOfMeasurement,
      setUnitsOfMeasurement,
    ]
  );
};
