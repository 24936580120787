import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import './verify-account.css';

export default function VerifyAccount(props) {
  const history = useHistory();

  useEffect(() => {
    // check if the component is allowed for rendering or not
    if (!props.location.state || !props.location.state.allow) {
      history.push(routesPath.SIGN_IN);
    }
  }, [props.location.state, history]);

  return (
    <PreAuthContainer
      children={
        <>
          <div className="pre-authentication-header">
            Check your email to verify your account
          </div>
          <div className="verify-text font-rajdhani">
            We have sent you a link.
          </div>
          <div className="verify-continue-container">
            <Link to="/sign-in" className="no-underline">
              <PrimaryButton label="Continue" disable={false} classes="" />
            </Link>
          </div>
        </>
      }
    />
  );
}
