import {getHeadersWithKeyAndToken, URL} from '../config';
import {getStepsCountBody} from '../body/stepsWalked';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {httpPostRequest} from '../httpHandler';

export const getStepsCount = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getStepsCountBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
