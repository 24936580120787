export const errorMessageOfDay = (day, month, year) => {
  if ((day.length && day < 1) || day > 31) {
    return 'Day must be between 1 and 31';
  } else if (month.length && !isValid(day, month, year)) {
    return 'Date is not valid for this month';
  } else if (
    Number(year) === new Date().getFullYear() &&
    Number(month) === new Date().getMonth() + 1 &&
    Number(day) > new Date().getDate()
  ) {
    return 'Future date is not allowed';
  } else {
    return '';
  }
};

export const errorMessageOfMonth = (month, year) => {
  const currentYear = new Date().getFullYear();
  if ((month < 1 || month > 12) && month.length !== 0) {
    return 'Month must be between 1 and 12';
  } else if (
    currentYear === Number(year) &&
    Number(month) > new Date().getMonth() + 1
  ) {
    return 'Future month is not allowed';
  } else {
    return '';
  }
};

export const errorMessageOfYear = (year) => {
  const currentYear = new Date().getFullYear();
  if ((year >= 1900 && year <= currentYear) || year.length === 0) {
    return '';
  } else {
    return 'Year must be between 1900 and ' + currentYear;
  }
};

function daysInMonth(m, y) {
  // m is 0 indexed: 0-11
  switch (m - 1) {
    case 1:
      if (y.length > 0) {
        return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
      } else {
        return 29;
      }
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}

function isValid(d, m, y) {
  return d <= daysInMonth(m, y);
}
