import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAllHealthValuesBody,
  getLatestHealthValuesBody,
  getLatestStepValueBody,
} from '../body/overview';
import {getHeadersWithKeyAndToken, URL} from '../config';
import {httpPostRequest} from '../httpHandler';

export const getAllHealthValues = (object) => {
  console.log('called health');
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAllHealthValuesBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getLatestHealthValues = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getLatestHealthValuesBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getLatestStepValue = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getLatestStepValueBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
