import React from 'react';
import './plain-textbox.css';

export default function PlainTextBoxWithLabel(props) {
  const {label, inputId, click, value, changeValue, classes, isOptional} =
    props;
  const id = 'textbox_' + String(label).split(' ').join('_').toLowerCase();

  const getClass = () => {
    return (classes ? classes : '') + ' text-box-container';
  };

  return (
    <>
      <div>
        <b>{label}</b> {isOptional ? ' (optional)' : ''}
      </div>
      <input
        id={inputId ? inputId : id}
        onClick={() => {
          if (click) click();
        }}
        className={getClass()}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
        placeholder={label}
      />
    </>
  );
}

export function PlainTextBox(props) {
  const {label, inputId, value, changeValue, classes} = props;
  const id = 'textbox_' + String(label).split(' ').join('_').toLowerCase();

  const getClass = () => {
    return (classes ? classes : '') + ' text-box-container p-6';
  };

  return (
    <>
      <input
        id={inputId ? inputId : id}
        className={getClass()}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
        placeholder={label}
      />
    </>
  );
}
