import React, {useState} from 'react';
import './check-box.css';
import checkedIcon from '../../../assets/icons/common/checked.svg';
import uncheckedIcon from '../../../assets/icons/common/unchecked.svg';

export default function CheckBoxWithLabel(props) {
  const {label, checked, labelClass, value, click} = props;
  const id = String(label).split(' ').join('_').toLowerCase();

  return (
    <>
      <div className="check-box-container">
        <img
          id={'checkbox_' + id}
          src={checked ? checkedIcon : uncheckedIcon}
          alt="check"
          style={{cursor: 'pointer'}}
          onClick={() => click(value)}
        />
        <div className={(labelClass ? labelClass : '') + ' check-box-label'}>
          {label}
        </div>
      </div>
    </>
  );
}
