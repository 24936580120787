import LinearProgress from '@material/react-linear-progress';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {isValidEmail} from '../../common/emailHandler';
import {
  deleteAllLocalStorage,
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {forgetPassword} from '../../rest-api/user-api/sign-in';
import {routesPath} from '../../router';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import ErrorMessage from '../common/errors/error-message';
import PreAuthContainer from '../common/pre-authentication-container/pre-authentication-container';
import SuccessMessageSnackbar from '../common/success/success';
import EmailTextBoxWithLabel from '../common/textboxes/email-textbox/email-textbox';
import './forget-password.css';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isChanging, setChanging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessful] = useState('');
  const history = useHistory();

  const checkIfSubmitDisable = () => {
    if (email.trim().length && isValidEmail(email)) {
      return false;
    }
    return true;
  };

  const requestToChangePassword = (e) => {
    e.preventDefault();
    setChanging(true);
    forgetPassword(email.trim())
      .then((res) => {
        console.log('Response: ', res);
        const {message} = JSON.parse(res.data.forgetPassword.body);
        if (res.data.forgetPassword.statusCode === 200) {
          setSuccessful(message);
          setEmail('');
          setTimeout(() => {
            history.push(routesPath.SIGN_IN);
          }, 2000);
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setChanging(false));
  };

  useEffect(() => {
    const getUserState = getLocalStorage(localStorageKey.USERSTATE);
    if (Number(getUserState) === 3) {
      history.push(routesPath.HOME_PAGE);
    } else {
      deleteAllLocalStorage();
    }
  }, [history]);
  return (
    <>
      {successMessage.length > 0 && (
        <SuccessMessageSnackbar message={successMessage} />
      )}
      <PreAuthContainer
        children={
          <>
            <Link
              to={routesPath.SIGN_IN}
              className="no-underline"
              id="btn_back"
            >
              <div className="back-button font-rajdhani">
                <i className="fa fa-arrow-left" />{' '}
                <span style={{marginLeft: '1vw'}}>Back</span>
              </div>
            </Link>
            <div className="pre-authentication-header">
              Forgot your password?
            </div>
            <div className="forget-text font-rajdhani">
              We'll send an email to reset it.
            </div>
            {isChanging && (
              <LinearProgress style={{marginTop: '2%'}} indeterminate={true} />
            )}
            <div className={isChanging ? 'pre-authentication-disable-div' : ''}>
              <form onSubmit={requestToChangePassword}>
                <div className="pre-authentication-textbox-container">
                  <EmailTextBoxWithLabel
                    inputId="text_email"
                    label="Email"
                    value={email}
                    changeValue={setEmail}
                  />
                </div>
                <div className="forget-password-submit-container">
                  <PrimaryButton
                    inputId="btn_continue"
                    label="Continue"
                    labelInAction="Sending mail..."
                    isInAction={isChanging}
                    disable={checkIfSubmitDisable()}
                    click={requestToChangePassword}
                    classes=""
                  />
                </div>
              </form>
              {errorMessage.length > 0 && (
                <ErrorMessage message={errorMessage} />
              )}
            </div>
          </>
        }
      />
    </>
  );
}
