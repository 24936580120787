import React, {useState} from 'react';
import PlainSwitch from '../common/switches/plain-switch';
import {
  bloodGlucose,
  bloodPressure,
  heartRate,
  oxygenSaturation,
  respirationRate,
} from './vital-config';
import {convertToMmOl} from '../../utils/glucose';

export const VITAL_PARAMS_FIELD = {
  BLOOD_GLUCOSE: 'BLOOD_GLUCOSE',
  BLOOD_PRESSURE: 'BLOOD_PRESSURE',
  HEART_RATE: 'HEART_RATE',
  RESPIRATION_RATE: 'RESPIRATION_RATE',
  OXYGEN_SATURATION: 'OXYGEN_SATURATION',
};

export default function VitalParameter(props) {
  const {field} = props;

  const [selectedBloodGlucoseUnit, setBloodGlucoseUnit] = useState('mg/dL');

  const VitalBarItem = (props) => {
    const {data, isFirst, isLast, isConvertable, textAlign} = props;
    const {width, color, value, borderColor} = data;
    return (
      <div className="vital-param-bar-item" style={{width: width}}>
        <div
          className={
            (isFirst ? 'vital-param-first-item' : '') +
            (isLast ? 'vital-param-last-item' : '') +
            ' vital-param-bar'
          }
          style={{backgroundColor: color, borderColor: borderColor}}
        ></div>
        <div style={{textAlign: textAlign}}>
          {value !== 0 &&
            (selectedBloodGlucoseUnit === 'mmol/l' && isConvertable
              ? convertToMmOl(value)
              : value)}
        </div>
      </div>
    );
  };
  const VitalParamBar = (props) => {
    const {label, data, isLast, isConvertable, textAlign} = props;
    return (
      <>
        <div
          className="vital-param-bar-container"
          style={isLast ? {border: 0} : {}}
        >
          <span>{label}</span>
          <div className="vital-bar-container">
            {data.map((obj, i) => (
              <VitalBarItem
                isFirst={i === 0}
                isLast={i === data.length - 1}
                data={obj}
                key={i}
                isConvertable={isConvertable}
                textAlign={textAlign}
              />
            ))}
          </div>
        </div>
      </>
    );
  };
  const VitalParameterBarWithLabel = (props) => {
    const {config, isConvertable, textAlign} = props;
    return (
      <>
        {config.map((obj, i) => (
          <VitalParamBar
            label={obj.label}
            data={obj.data}
            isLast={i === config.length - 1}
            isConvertable={isConvertable}
            textAlign={textAlign}
          />
        ))}
      </>
    );
  };

  const BloodGlucose = () => {
    return (
      <div className="vital-parameter-item">
        <div className="vital-header">Blood Glucose</div>
        <PlainSwitch
          classes="vital-switch"
          switches={['mg/dL', 'mmol/l']}
          value={selectedBloodGlucoseUnit}
          changeValue={setBloodGlucoseUnit}
        />
        <VitalParameterBarWithLabel
          config={bloodGlucose}
          isConvertable={true}
          textAlign="end"
        />
      </div>
    );
  };

  const BloodPressure = () => {
    return (
      <div className="vital-parameter-item">
        <div className="vital-header">Blood Pressure</div>
        <div className="vital-label">mmHg</div>
        <VitalParameterBarWithLabel
          config={bloodPressure}
          isConvertable={false}
          textAlign="end"
        />
      </div>
    );
  };

  const HeartRate = () => {
    return (
      <div className="vital-parameter-item">
        <div className="vital-header">Heart Rate</div>
        <div className="vital-label">bpm</div>
        <VitalParameterBarWithLabel
          config={heartRate}
          isConvertable={false}
          textAlign="end"
        />
      </div>
    );
  };

  const RespirationRate = () => {
    return (
      <div className="vital-parameter-item">
        <div className="vital-header">Respiration Rate</div>
        <div className="vital-label">brpm</div>
        <VitalParameterBarWithLabel
          config={respirationRate}
          isConvertable={false}
          textAlign="end"
        />
      </div>
    );
  };

  const OxygenSaturation = () => {
    return (
      <div className="vital-parameter-item">
        <div className="vital-header">Oxygen Saturation</div>
        <div className="vital-label">%</div>
        <VitalParameterBarWithLabel
          config={oxygenSaturation}
          isConvertable={false}
          textAlign="start"
        />
      </div>
    );
  };

  const getSelectedVitalContainer = () => {
    switch (field) {
      case VITAL_PARAMS_FIELD.BLOOD_GLUCOSE:
        return <BloodGlucose />;
      case VITAL_PARAMS_FIELD.BLOOD_PRESSURE:
        return <BloodPressure />;
      case VITAL_PARAMS_FIELD.HEART_RATE:
        return <HeartRate />;
      case VITAL_PARAMS_FIELD.RESPIRATION_RATE:
        return <RespirationRate />;
      case VITAL_PARAMS_FIELD.OXYGEN_SATURATION:
        return <OxygenSaturation />;
      default:
        break;
    }
  };

  return <>{getSelectedVitalContainer()}</>;
}
