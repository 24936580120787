import React from 'react';
import './textarea.css';

export default function TextArea(props) {
  const {value, changeValue, placeholder} = props;
  return (
    <>
      <textarea
        className="text-area"
        placeholder={placeholder}
        rows={6}
        onChange={(e) => changeValue(e.target.value)}
      >
        {value}
      </textarea>
    </>
  );
}
