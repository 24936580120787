import LinearProgress from '@material/react-linear-progress';
import React, {useState} from 'react';
import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {addFeedback} from '../../rest-api/home-api/settings';
import PrimaryButton from '../common/buttons/button-primary/button-primary';
import ErrorMessage from '../common/errors/error-message';
import TextArea from '../common/textarea/textarea';
import './add-feedback.css';

export default function AddFeedback(props) {
  const {close, afterSubmitMethod} = props;
  const [message, setMessage] = useState('');
  const [isSubmiting, setSubmiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const sendFeedback = () => {
    setSubmiting(true);
    afterSubmitMethod('');
    setErrorMessage('');
    const userId = getLocalStorage(localStorageKey.USERID);
    const body = {
      userId: Number(userId),
      message: message,
    };
    addFeedback(body)
      .then((res) => {
        const {message} = JSON.parse(res.data.addFeedback.body);
        if (res.data.addFeedback.statusCode === 200) {
          close();
          afterSubmitMethod(message);
          // use to update contact list
        } else {
          throw new Error(message);
        }
      })
      .catch((e) => setErrorMessage(e.message))
      .then(() => setSubmiting(false));
  };

  const isSubmitDisable = () => {
    return !message.trim().length;
  };
  return (
    <>
      <div className="add-contact-container">
        <div className="add-contact-header">
          <div className="add-contact-header-text">Feedback</div>
          <i
            className="fa fa-close"
            onClick={close}
            style={{color: 'dodgerblue', cursor: 'pointer'}}
          />
        </div>
        {isSubmiting && <LinearProgress indeterminate={true} />}
        <div className={isSubmiting ? 'pre-authentication-disable-div' : ''}>
          <div className="add-contact-body">
            <div className="add-feedback-header-text">
              Let us know what you think
            </div>
            <div className="mt-1">
              We value your feedback in helping us deliver the best product
              possible.
            </div>
            <div style={{marginTop: '1%', fontWeight: 'bold'}}>Message</div>
            <div className="add-feedback-message-box">
              <TextArea
                placeholder="Add notes"
                value={message}
                changeValue={setMessage}
              />
            </div>
            <div className="add-contact-text-box">
              <PrimaryButton
                disable={isSubmitDisable()}
                labelInAction="Adding feedback ..."
                isInAction={isSubmiting}
                label="Add feedback"
                classes="add-feedback-button"
                click={sendFeedback}
              />
            </div>
            {errorMessage.length > 0 && (
              <ErrorMessage
                classes="add-contact-text-box-input"
                message={errorMessage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
