import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAddFeedbackBody,
  getWatchSettingsBody,
  saveWatchSettingsBody,
} from '../body/settings';
import {getHeadersWithKeyAndToken, URL} from '../config';
import {httpPostRequest} from '../httpHandler';

export const addFeedback = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAddFeedbackBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const getWatchSettings = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getWatchSettingsBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const saveWatchSettings = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = saveWatchSettingsBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
