export default function appendTooltip(obj) {
  const {svg, toolTipObjects, cordinates} = obj;

  const rectOffset = {
    x: 50,
    y: 90,
  };

  const rectHeight = 50;
  const rectWidth = 120;

  const measureRectX = (xValue) => {
    const svgWidth = Number(svg.style('width').replace('px', ''));
    if (xValue < rectHeight) {
      return Number(xValue) - rectOffset.x / 2;
    }
    if (Number(xValue) + rectWidth / 2 < svgWidth) {
      return Number(xValue) - rectOffset.x - 10;
    } else {
      return Number(xValue) - rectOffset.x * 2;
    }
  };

  const measureRectY = (yValue) => {
    if (Number(yValue - rectOffset.y) < 0) {
      return yValue + rectOffset.y / 4;
    } else {
      return yValue - rectOffset.y;
    }
  };

  console.log('cordinates: ', cordinates);

  // tooltip
  const tooltip = svg
    .selectAll('circle')
    .data(cordinates)
    .enter()
    .append('g')
    .style('z-index', 1)
    .style('display', 'none');

  //outer circle
  tooltip
    .append('circle')
    .attr('cx', (d, i) => d.x)
    .attr('cy', (d, i) => d.y)
    .style('stroke', '#1a74d4')
    .style('fill', 'white')
    .attr('r', 6);

  //inner circle
  tooltip
    .append('circle')
    .attr('cx', (d, i) => d.x)
    .attr('cy', (d, i) => d.y)
    .style('fill', '#1a74d4')
    .attr('r', 3);

  tooltip
    .append('rect')
    .attr('rx', 4)
    .attr('ry', 4)
    .style('z-index', 1)
    .attr('stroke', 'grey')
    .attr('fill', 'white')
    .style('height', rectHeight)
    .style('width', rectWidth)
    .attr('x', (d, i) => measureRectX(d.x))
    .attr('y', (d, i) => measureRectY(d.y));

  tooltip
    .append('text')
    .attr('class', 'tooltip-value')
    .text((d, i) => toolTipObjects[i].value)
    .attr('x', (d, i) => measureRectX(d.x) + 10)
    .attr('y', (d, i) => measureRectY(d.y) + 20);

  tooltip
    .append('text')
    .text((d, i) => toolTipObjects[i].upper)
    .style('font-size', 'small')
    .attr('x', (d, i) => {
      return (
        measureRectX(d.x) + 10 + String(toolTipObjects[i].value).length * 10
      );
    })
    .attr('y', (d, i) => measureRectY(d.y) + 20);

  tooltip
    .append('text')
    .text((d, i) => toolTipObjects[i].lower)
    .attr('x', (d, i) => measureRectX(d.x) + 10)
    .attr('y', (d, i) => measureRectY(d.y) + 40);

  return tooltip;
}
