import React from 'react';
import './email-textbox.css';
import {isValidEmail} from '../../../../common/emailHandler';
import {
  getLocalStorage,
  localStorageKey,
} from '../../../../common/localStorageHandler';

export default function EmailTextBoxWithLabel(props) {
  const {label, value, inputId, changeValue, classes, disable, purpose} = props;
  const id = 'textbox_' + String(label).split(' ').join('_').toLowerCase();

  const InvalidEmailMessage = () => {
    if (purpose && purpose == 'contact') {
      return (
        <div>
          <small>
            Please enter an email address with the format example@email.com and
            any other address apart from the registered user address
          </small>
        </div>
      );
    } else {
      return (
        <div>
          <small>
            Please enter an email address with the format example@email.com
          </small>
        </div>
      );
    }
  };

  const isInvalidEmail = () => {
    const emailId = getLocalStorage(localStorageKey.EMAIL_ID);
    if (purpose && purpose == 'contact' && emailId == value) {
      return true;
    } else {
      return !isValidEmail(value) && value.trim().length !== 0;
    }
  };

  const getContainerClass = () => {
    return 'email-container' + (isInvalidEmail() ? ' red-text' : '');
  };

  const getInputClass = () => {
    return (
      (isInvalidEmail() ? 'invalid-email ' : '') +
      (classes ? classes : '') +
      ' text-box-container'
    );
  };

  return (
    <>
      <div className={getContainerClass()}>
        <div>
          <b>{label}</b>
        </div>
        <input
          id={inputId ? inputId : id}
          disabled={disable}
          className={getInputClass()}
          value={value}
          onChange={(e) => {
            changeValue(e.target.value);
          }}
          placeholder={label}
        />
        {isInvalidEmail() && <InvalidEmailMessage />}
      </div>
    </>
  );
}
