import Checkbox from '@material-ui/core/Checkbox';
import * as d3 from 'd3';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import StepsIcon from '../../assets/icons/blood-glucose/steps.svg';
import {HealthParameterType} from '../../common/config';
import {UPDATE_DOT} from '../../redux/actionTypes';
import {getMealIcon, getSvgWidth} from './common';

export default function ComplexScatter(props) {
  const height = window.screen.height;
  const {data} = props;
  const {
    xLabels,
    scatterData,
    complexScatterY,
    scatterLow,
    scatterHigh,
    graphUpperLines,
    standardLine,
    activity,
    fastingLine,
    graphLowerLines,
    meals,
    yLabel,
    requestedDate,
    diffToAxis,
    type,
  } = data;
  const isToday =
    new Date(requestedDate).toDateString() === new Date().toDateString();
  const selectedData = scatterData[scatterData.length - 1];
  const svgHeight = height * 0.38;
  const labelWidth = 1;
  const marginLeft = 10;
  const max = xLabels.length;
  const y = d3
    .scaleLinear()
    .domain([0, 200])
    .range([height * 0.36, 0]);

  const dispatch = useDispatch();

  const createLabel = (svg, value, i) => {
    svg
      .append('text')
      .text(value)
      .attr(
        'x',
        getScaleXValue(getSvgWidth(svg), i) -
          (i === xLabels.length - 1 && !isToday ? 15 : 0)
      )
      .attr('y', svgHeight - 2)
      .style('font-size', 'small');
  };
  const appendImage = (svg, src, xCord, yCord) => {
    svg
      .append('image')
      .attr('xlink:href', src)
      .attr('x', getScaleXValue(getSvgWidth(svg), xCord))
      .attr('y', y(yCord));
  };

  const createFastingDashLine = (svg) => {
    for (let i = 0; i < fastingLine.end - 0.1; i = i + 0.1) {
      svg
        .append('line')
        .attr('x1', getScaleXValue(getSvgWidth(svg), i))
        .attr('x2', getScaleXValue(getSvgWidth(svg), i + 0.1))
        .attr('y1', y(fastingLine.yValue - fastingLine.height + 1))
        .attr('y2', y(fastingLine.yValue))
        .attr('stroke-width', 1)
        .attr('stroke', 'grey');
    }
  };

  const createMarginTopLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createMarginBottomLine = (svg) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', svgHeight)
      .attr('y2', svgHeight)
      .attr('stroke-width', 0.5)
      .attr('stroke', 'lightgrey');
  };

  const createFastingLine = (svg) => {
    const x1 = getScaleXValue(getSvgWidth(svg), 0);
    const x2 = getScaleXValue(getSvgWidth(svg), fastingLine.end);
    svg
      .append('rect')
      .attr('x', x1)
      .attr('y', y(fastingLine.yValue))
      .attr('fill', '#d0e7ff')
      .style('width', x2 - x1)
      .style('height', fastingLine.height);
    createFastingDashLine(svg);
  };

  const appendVerticalLine = (svg, x, y1, y2, xOffset, color) => {
    svg
      .append('line')
      .attr('x1', Number(getScaleXValue(getSvgWidth(svg), x)) + xOffset)
      .attr('x2', Number(getScaleXValue(getSvgWidth(svg), x)) + xOffset)
      .attr('y1', y(y1))
      .attr('y2', y(y2))
      .attr('stroke-width', 0.5)
      .style('stroke', color)
      .attr('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '5,5');
  };

  const appendMeal = (svg, obj) => {
    const {x, type, yValue} = obj;
    const lastHour = requestedDate.getHours();
    if (x !== lastHour + 1) {
      const src = getMealIcon(type);
      appendImage(svg, src, x * labelWidth, yValue);
      appendVerticalLine(svg, x, 8, 200, 8, 'grey');
    }
  };

  const appendActivityDiv = (svg, xValue, width) => {
    svg
      .append('rect')
      .attr('x', getScaleXValue(getSvgWidth(svg), xValue))
      .attr('y', y(activity.yValue))
      .attr('fill', '#d0e7ff')
      .attr('rx', activity.height / 2)
      .style('width', width)
      .style('height', activity.height);
  };
  const appendActivityImage = (svg, xCord, yCord, width) => {
    svg
      .append('image')
      .attr('xlink:href', StepsIcon)
      .attr('x', Number(getScaleXValue(getSvgWidth(svg), xCord)) + width / 2)
      .attr('y', y(yCord) + 2);
  };

  const createAlertBar = (svg, y1, y2, x, up) => {
    console.log('Alert bar creating: ', y2 - y1);
    svg
      .append('rect')
      .attr('x', getScaleXValue(getSvgWidth(svg), x))
      .attr('y', y1)
      .attr('rx', 2)
      .attr('ry', 2)
      .style('height', y2 - y1)
      .style('width', 5)
      .style('fill', up ? 'url(#gradUpper)' : 'url(#gradLower)');
  };

  const appendActivity = (svg) => {
    appendActivityDiv(svg, activity.start * labelWidth, 48);
    appendActivityDiv(svg, activity.end * labelWidth, 32);
    appendActivityImage(svg, activity.start * labelWidth, activity.yValue, 32);
    appendActivityImage(svg, activity.end * labelWidth, activity.yValue, 16);
  };
  const createGraphLineParrelToX = (svg, yValue, isDotted) => {
    svg
      .append('line')
      .attr('x1', getScaleXValue(getSvgWidth(svg), 0))
      .attr('x2', getScaleXValue(getSvgWidth(svg), max - 0.2))
      .attr('y1', y(yValue))
      .attr('y2', y(yValue))
      .attr('stroke-width', 0.4)
      .style('stroke-dasharray', isDotted ? '5,5' : '')
      .style('stroke', isDotted ? '#f86363' : 'black')
      .attr('shape-rendering', 'crispEdges');
  };

  const createLineText = (svg, yValue, label, xOffset) => {
    svg
      .append('text')
      .text(label)
      .attr('x', getScaleXValue(getSvgWidth(svg), 0) - xOffset)
      .attr('y', y(yValue) + 2)
      .style('font-size', 'small')
      .style('text-align', 'flex-end');
  };

  const createGraphUpperLine = (svg, obj) => {
    const {yValue, label, isDotted} = obj;
    createGraphLineParrelToX(svg, complexScatterY(yValue), isDotted);
    createLineText(svg, complexScatterY(yValue), label, marginLeft * 2);
  };

  const createGraphLowerLine = (svg, obj, i) => {
    const {yValue, label, isDotted} = obj;
    let yOffset = 2;
    if (i !== graphLowerLines.length - 1 && i !== 0)
      yOffset = Math.floor(
        (graphLowerLines[i].yValue + graphLowerLines[i + 1].yValue) / 2
      );
    createGraphLineParrelToX(svg, yValue, isDotted);
    createLineText(svg, yOffset, label, marginLeft * 5);
  };

  const getScaleXValue = (width, data) => {
    const x = d3
      .scaleLinear()
      .domain([0, max])
      .range([width * 0.1, width]);
    return x(data);
  };

  const valueline = (value, width) => {
    const getXDistance = 100 / standardLine.length;
    const line = d3
      .line()
      .x(function (d) {
        return getScaleXValue(width, d.x);
      })
      .y(function (d) {
        return y(complexScatterY(d.y));
      });

    return line(value);
  };

  const createStandardLine = (svg) => {
    svg
      .append('path')
      .datum(standardLine)
      .attr('id', 'complex-scatter-line')
      .attr('stroke', 'grey')
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .style('stroke-dasharray', '5,5')
      .attr('d', valueline(standardLine, getSvgWidth(svg)));
  };

  const createScatterPlotMultiple = (svg, onInit) => {
    const sec = 1000 / scatterData.length;
    console.log('scatterData y: ', scatterData);
    const g = svg
      .selectAll('circle')
      .attr('id', '#complex-scatter-tooltip')
      .data(scatterData)
      .enter()
      .append('g')
      .style('z-index', 1);
    let tooltipCircle;
    let tooltipLine;

    tooltipLine = g
      .append('line')
      .attr('x1', (data, i) => Number(getScaleXValue(getSvgWidth(svg), data.x)))
      .attr('x2', (data, i) => Number(getScaleXValue(getSvgWidth(svg), data.x)))
      .attr('y1', y(30))
      .attr('y2', y(200))
      .attr('stroke-width', 2)
      .style('stroke', '#1a74d4')
      .style('z-index', -1)
      .attr('shape-rendering', 'crispEdges')
      .style('display', (d, i) =>
        scatterLow >= d.y[0] ||
        scatterHigh <= d.y[0] ||
        scatterLow >= d.y[1] ||
        scatterHigh <= d.y[1] ||
        d !== selectedData
          ? 'none'
          : ''
      );

    tooltipCircle = g
      .append('g')
      .style('display', (d, i) =>
        scatterLow >= d.y[0] ||
        scatterHigh <= d.y[0] ||
        scatterLow >= d.y[1] ||
        scatterHigh <= d.y[1] ||
        d !== selectedData
          ? 'none'
          : ''
      );

    tooltipCircle
      .append('circle')
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .attr('display', (d, i) => (d.y[0] > scatterHigh ? 'none' : 'block'));

    tooltipCircle
      .append('circle')
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));

    g.append('circle')
      .on('mouseover', (e, data) => {
        tooltipCircle.style('display', 'none');
        tooltipLine.style('display', 'none');
        dispatch({
          type: UPDATE_DOT,
          payload: data,
        });
        console.log('Data in : ', data);
        tooltipCircle.filter((d, i) => d === data).style('display', '');
        tooltipLine.filter((d, i) => d === data).style('display', '');
      })
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y[0])))
      .style('fill', (d, i) => d.color[0])
      .style('stroke', (d, i) => d.stroke[0])
      .attr('r', 4)
      .style('cursor', 'pointer')
      .style('opacity', 0)
      .transition()
      .delay((d, i) => (onInit ? i * sec : 0))
      .style('opacity', 1)
      .style('display', (d, i) =>
        scatterLow >= d.y[0] || scatterHigh <= d.y[0] ? 'none' : ''
      );

    g.append('circle')
      .on('mouseover', (e, data) => {
        tooltipCircle.style('display', 'none');
        tooltipLine.style('display', 'none');
        dispatch({
          type: UPDATE_DOT,
          payload: data,
        });
        console.log(
          'Data in : ',
          tooltipCircle.filter((d, i) => d.x === data.x)
        );
        tooltipCircle.filter((d, i) => d === data).style('display', '');
        tooltipLine.filter((d, i) => d === data).style('display', '');
      })
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y[1])))
      .style('fill', (d, i) => d.color[1])
      .style('stroke', (d, i) => d.stroke[1])
      .attr('r', 4)
      .style('cursor', 'pointer')
      .style('opacity', 0)
      .transition()
      .delay((d, i) => (onInit ? i * sec : 0))
      .style('opacity', 1)
      .style('display', (d, i) =>
        scatterLow >= d.y[1] || scatterHigh <= d.y[1] ? 'none' : ''
      );

    tooltipCircle
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .style('z-index', 1)
      .attr('stroke', 'grey')
      .attr('fill', 'white')
      .style('height', 20)
      .style('width', 40)
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 20)
      .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 40)
      .attr('display', (d, i) => (d.y[0] > scatterHigh ? 'none' : 'block'));

    tooltipCircle
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .style('z-index', 1)
      .attr('stroke', 'grey')
      .attr('fill', 'white')
      .style('height', 20)
      .style('width', 40)
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 20)
      .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 20)
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));

    tooltipCircle
      .append('text')
      .text('SYS')
      .style('font-weight', 'bold')
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 12)
      .attr('y', (d, i) => y(complexScatterY(d.y[0])) - 25)
      .attr('display', (d, i) => (d.y[0] > scatterHigh ? 'none' : 'block'));

    tooltipCircle
      .append('text')
      .text('DIA')
      .style('font-weight', 'bold')
      .attr('x', (d, i) => getScaleXValue(getSvgWidth(svg), d.x) - 12)
      .attr('y', (d, i) => y(complexScatterY(d.y[1])) + 35)
      .attr('display', (d, i) => (d.y[1] < scatterLow ? 'none' : 'block'));
  };

  const createScatterPlot = (svg, onInit) => {
    const sec = 1000 / scatterData.length;
    console.log('scatterData y: ', scatterData);
    const g = svg
      .selectAll('circle')
      .attr('id', '#complex-scatter-tooltip')
      .data(scatterData)
      .enter()
      .append('g')
      .style('z-index', 1);
    let tooltipCircle;
    let tooltipLine;
    tooltipCircle = g
      .append('circle')
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y)))
      .style('fill', 'none')
      .style('stroke', '#1a74d4')
      .style('stroke-width', 2)
      .attr('r', 8)
      .style('display', (d, i) =>
        scatterLow >= d.y || scatterHigh <= d.y || d !== selectedData
          ? 'none'
          : ''
      );

    tooltipLine = g
      .append('line')
      .attr('x1', (data, i) => Number(getScaleXValue(getSvgWidth(svg), data.x)))
      .attr('x2', (data, i) => Number(getScaleXValue(getSvgWidth(svg), data.x)))
      .attr('y1', y(30))
      .attr('y2', y(200))
      .attr('stroke-width', 2)
      .style('stroke', '#1a74d4')
      .style('z-index', -1)
      .attr('shape-rendering', 'crispEdges')
      .style('display', (d, i) =>
        scatterLow >= d.y || scatterHigh <= d.y || d !== selectedData
          ? 'none'
          : ''
      );

    g.append('circle')
      .on('mouseover', (e, data) => {
        tooltipCircle.style('display', 'none');
        tooltipLine.style('display', 'none');
        dispatch({
          type: UPDATE_DOT,
          payload: data,
        });
        tooltipCircle.filter((d, i) => d === data).style('display', '');
        tooltipLine.filter((d, i) => d === data).style('display', '');
      })
      .attr('cx', (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
      .attr('cy', (d, i) => y(complexScatterY(d.y)))
      .style('fill', (d, i) => d.color)
      .style('stroke', (d, i) => d.stroke)
      .attr('r', 4)
      .style('cursor', 'pointer')
      .style('opacity', 0)
      .transition()
      .delay((d, i) => (onInit ? i * sec : 0))
      .style('opacity', 1)
      .style('display', (d, i) =>
        scatterLow >= d.y || scatterHigh <= d.y ? 'none' : ''
      );

    // g.append("circle")
    //     .on("mouseover", (e, data) => {
    //         tooltipCircle.style("display", "none");
    //         tooltipLine.style("display", "none");
    //         dispatch({
    //             type: UPDATE_DOT,
    //             payload: data
    //         });
    //         tooltipCircle.filter((d, i) => d === data)
    //             .style("display", "")
    //         tooltipLine.filter((d, i) => d === data)
    //             .style("display", "")
    //     })
    //     .attr("cx", (d, i) => getScaleXValue(getSvgWidth(svg), d.x))
    //     .attr("cy", (d, i) => y(complexScatterY(d.y[1])))
    //     .style("fill", (d, i) => d.color[1])
    //     .style("stroke", (d, i) => d.stroke[1])
    //     .attr("r", 4)
    //     .style("cursor", "pointer")
    //     .style("opacity", 0)
    //     .transition()
    //     .delay((d, i) => (onInit ? i * sec : 0))
    //     .style("opacity", 1)
    //     .style("display", (d, i) => ((scatterLow >= d.y[1] || scatterHigh <= d.y[1]) ? "none" : ""))
  };

  const drawComplexScatterChart = (onInit) => {
    d3.select('#complex-scatter-svg').remove();

    const svg = d3
      .select('#complex-scatter-chart')
      .append('svg')
      .attr('id', 'complex-scatter-svg')
      .attr('width', '100%')
      .attr('height', svgHeight);

    createMarginTopLine(svg);
    createMarginBottomLine(svg);

    const gradLower = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradLower')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    const gradUpper = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradUpper')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradLower
      .selectAll('stop')
      .data(['#f86363', '#ffe6e6'])
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (2 - 1)) + '%';
      });

    gradUpper
      .selectAll('stop')
      .data(['#ffe6e6', '#f86363'])
      .enter()
      .append('stop')
      .style('stop-color', function (d) {
        return d;
      })
      .attr('offset', function (d, i) {
        return 100 * (i / (2 - 1)) + '%';
      });
    xLabels.forEach((d, i) => {
      createLabel(svg, d, i);
    });

    graphLowerLines.forEach((obj, i) => {
      createGraphLowerLine(svg, obj, i);
    });

    graphUpperLines.forEach((obj) => {
      createGraphUpperLine(svg, obj);
    });

    meals.forEach((obj) => {
      appendMeal(svg, obj);
    });
    svg
      .append('text')
      .text(yLabel)
      .attr('x', '2%')
      .attr('y', y(120))
      .style('writing-mode', 'vertical-rl')
      .style('font-weight', 'bold');

    createFastingLine(svg);
    appendActivity(svg);
    createStandardLine(svg);
    if (type !== HealthParameterType.BLOOD_PRESSURE) {
      createScatterPlot(svg, onInit);
    } else {
      createScatterPlotMultiple(svg, onInit);
    }

    scatterData.forEach((data) => {
      if (data.y < scatterLow) {
        createAlertBar(
          svg,
          y(complexScatterY(scatterLow)),
          y(complexScatterY(scatterLow - diffToAxis)),
          Math.floor(data.x),
          false
        );
      }
      if (data.y > scatterHigh) {
        createAlertBar(
          svg,
          y(complexScatterY(scatterHigh + diffToAxis)),
          y(complexScatterY(scatterHigh)),
          Math.floor(data.x),
          true
        );
      }
    });
  };

  useEffect(() => {
    drawComplexScatterChart(true);
    if (!d3.select('#complex-scatter-svg').empty()) {
      d3.select(window).on('resize', () => drawComplexScatterChart(false));
    }
    dispatch({
      type: UPDATE_DOT,
      payload: selectedData,
    });
  }, []);

  const hideStandardLine = () => {
    const isShown = d3.select('#complex-scatter-line').style('display');
    if (isShown === 'none') {
      d3.select('#complex-scatter-line').style('display', 'inline');
    } else {
      d3.select('#complex-scatter-line').style('display', 'none');
    }
  };

  const CompareWithStandard = () => {
    return (
      <div className="compare-standard-container">
        <Checkbox
          defaultChecked
          size="small"
          color="primary"
          style={{padding: 0}}
          onChange={hideStandardLine}
        />
        <div className="three-dashed-line"></div>
        Compare to reference population
      </div>
    );
  };

  return (
    <>
      <CompareWithStandard />
      <div
        id="complex-scatter-chart"
        style={{width: '100%', marginTop: '0.5%'}}
      ></div>
    </>
  );
}
