export const getLatestHealthValuesBody = (object) => {
  const {userId} = object;

  const body = {
    query:
      'query MyQuery($userId: Int) {fetchLatestData(userId: $userId) {statusCode body {message success data {data ts}}}}',
    variables: {
      userId: userId,
    },
  };
  return JSON.stringify(body);
};

export const getLatestStepValueBody = (object) => {
  const {userId} = object;

  const body = {
    query:
      'query MyQuery($userId: Int) {getLatestStepCount(userId: $userId) {statusCode body {message success data {count ts}}}}',
    variables: {
      userId: userId,
    },
  };
  return JSON.stringify(body);
};

export const getAllHealthValuesBody = (object) => {
  const {startTS, endTS, userId} = object;

  const body = {
    query:
      'query fetch($userId:Int,$startTs:String,$endTs:String){fetchData(userId:$userId,startTs:$startTs,endTs:$endTs){statusCode body{success message data{data ts}}}}',
    variables: {
      userId: userId,
      startTs: startTS,
      endTs: endTS,
    },
  };
  return JSON.stringify(body);
};
