import React from 'react';
import './error-message.css';

export default function ErrorMessage(props) {
  const {message, classes} = props;
  return (
    <div className={'error-message ' + (classes ? classes : '')}>
      <i className="fa fa-exclamation-triangle" style={{marginRight: '2%'}}></i>
      {message}
    </div>
  );
}

export const InvalidPasswordMessage = () => (
  <div className="invalid-password">
    <small>
      New password must contain at least 8 characters with at least one capital
      letter, one small letter, one number and one special character.
    </small>
  </div>
);
