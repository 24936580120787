import React from 'react';

export default function Subscription(props) {
  const monthList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const getTimeFormat = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = monthList[date.getMonth() + 1];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let time =
      ('0' + (hours > 12 ? hours - 12 : hours)).slice(-2) +
      ':' +
      ('0' + minutes).slice(-2) +
      (hours > 12 ? ' pm' : ' am');
    return month + ' ' + day + ', ' + year + ' ' + time;
  };
  let subscription = props.subscriptionDetails.subscription;
  let customer = props.subscriptionDetails.customer;
  let card = props.subscriptionDetails.card;
  let address = subscription.shipping_address;
  let planType, planAmount, chargeType, chargeAmount;
  for (let i = 0; i < subscription.subscription_items.length; i++) {
    if (subscription.subscription_items[i].item_type == 'plan') {
      planType = subscription.subscription_items[i].item_price_id.split('-')[0];
      planAmount = (subscription.subscription_items[i].amount / 100).toFixed(2);
    } else if (subscription.subscription_items[i].item_type == 'charge') {
      chargeType =
        subscription.subscription_items[i].item_price_id.split('-')[0];
      chargeAmount = (subscription.subscription_items[i].amount / 100).toFixed(
        2
      );
    }
  }
  return (
    <>
      <div className="sub-card">
        <div className="sub-card-header">
          <h2>Subscription</h2>
        </div>
        <div className="sub-card-body">
          <table>
            <tr>
              <th>Plan: </th>
              <td>{planType}</td>
            </tr>
            <tr>
              <th>Price: </th>
              <td>${planAmount}</td>
            </tr>
            <tr>
              <th>Status: </th>
              <td>{subscription.status}</td>
            </tr>
            <tr>
              <th>Started on: </th>
              <td>{getTimeFormat(subscription.current_term_start)}</td>
            </tr>
            <tr>
              <th>End on: </th>
              <td>{getTimeFormat(subscription.current_term_end)}</td>
            </tr>
            <tr>
              <th>Next Billing at: </th>
              <td>{getTimeFormat(subscription.next_billing_at)}</td>
            </tr>
            <tr>
              <th>currency: </th>
              <td>{subscription.currency_code}</td>
            </tr>
          </table>
        </div>
      </div>
      {chargeType ? (
        <div className="sub-card">
          <div className="sub-card-header">
            <h2>Charges</h2>
          </div>
          <div className="sub-card-body">
            <table>
              <tr>
                <th>Charge: </th>
                <td>{chargeType}</td>
              </tr>
              <tr>
                <th>Price: </th>
                <td>${chargeAmount}</td>
              </tr>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="sub-card">
        <div className="sub-card-header">
          <h2>Address</h2>
        </div>
        <div className="sub-card-body">
          <table>
            <tr>
              <th>Type: </th>
              <td>Shipping Address</td>
            </tr>
            <tr>
              <th>First Name: </th>
              <td>{address.first_name}</td>
            </tr>
            <tr>
              <th>Last Name: </th>
              <td>{address.last_name}</td>
            </tr>
            <tr>
              <th>Address: </th>
              <td>
                {address.line1} {address.line2}
              </td>
            </tr>
            <tr>
              <th>City: </th>
              <td>{address.city}</td>
            </tr>
            <tr>
              <th>State: </th>
              <td>{address.state}</td>
            </tr>
            <tr>
              <th>Country: </th>
              <td>{address.country}</td>
            </tr>
            <tr>
              <th>Zip: </th>
              <td>{address.zip}</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="sub-card">
        <div className="sub-card-header">
          <h2>Card Details</h2>
        </div>
        <div className="sub-card-body">
          <table>
            <tr>
              <th>Type: </th>
              <td>{card.funding_type} card</td>
            </tr>
            <tr>
              <th>Status: </th>
              <td>{card.status}</td>
            </tr>
            <tr>
              <th>Card Number: </th>
              <td>{card.masked_number}</td>
            </tr>
            <tr>
              <th>Brand: </th>
              <td>{card.card_type}</td>
            </tr>
            <tr>
              <th>Expiry: </th>
              <td>
                {card.expiry_month}/{card.expiry_year}
              </td>
            </tr>
            <tr>
              <th>Auto Collection: </th>
              <td>{customer.auto_collection}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}
