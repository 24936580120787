export const localStorageKey = {
  JWTTOKEN: 'jwt-token',
  APIKEYGPL: 'api-key-gpl',
  APIKEYEUREKA: 'api-key-eureka',
  USERID: 'user-id',
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  EMAIL_ID: 'email-id',
  PROFILE_PIC_URL: 'profile-pic-url',
  DEVICE_MSN: 'device-msn',
  SETTINGS: {
    AUTO_MEASURE: 'auto-measure',
    TRACK_WHILE_SLEEPING: 'track-while-sleeping',
    ADAPTIVE_POWER_SAVING: 'adaptive-power-saving',
    AUTO_MEASURE_INTERVAL: 'auto-measure-interval',
  },
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const deleteAllLocalStorage = () => {
  localStorage.clear();
};

export const checkIfTokenPresent = () => {
  if (localStorage.getItem(localStorageKey.JWTTOKEN)) {
    return true;
  }
  return false;
};

export const setSettingsDataIntoLocalstorage = () => {
  setLocalStorage(localStorageKey.SETTINGS.AUTO_MEASURE, true);
  setLocalStorage(localStorageKey.SETTINGS.TRACK_WHILE_SLEEPING, true);
  setLocalStorage(localStorageKey.SETTINGS.ADAPTIVE_POWER_SAVING, true);
  setLocalStorage(localStorageKey.SETTINGS.AUTO_MEASURE_INTERVAL, 10);
};
