import {combineReducers} from 'redux';
import signIn from './sign-in';
import selectedDot from './selected-dot';
import selectedDotWithHighLow from './selected-dot-with-high-low';
import selectedDate from './selected-date';
import selectedDotSingleLineWithHighLow from './selected-dot-singleline-high-low';
import watchSettings from '../watchSettings/reducer';

export default combineReducers({
  signIn,
  selectedDot,
  selectedDate,
  selectedDotWithHighLow,
  selectedDotSingleLineWithHighLow,
  watchSettings,
});
