import {
  getLocalStorage,
  localStorageKey,
} from '../../common/localStorageHandler';
import {
  getAddSymptomBody,
  getDeleteSymptomBody,
  getEditSymptomBody,
  getListSymptomBody,
} from '../body/health';
import {getHeadersWithKeyAndToken, URL} from '../config';
import {httpPostRequest} from '../httpHandler';

export const addSymptom = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getAddSymptomBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const listSymptom = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getListSymptomBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const editSymptom = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getEditSymptomBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};

export const deleteSymptom = (object) => {
  const apikey = getLocalStorage(localStorageKey.APIKEYEUREKA);
  const token = getLocalStorage(localStorageKey.JWTTOKEN);
  const body = getDeleteSymptomBody(object);
  return httpPostRequest(
    URL[URL.currentENV].graphQl,
    getHeadersWithKeyAndToken(apikey, token),
    body
  );
};
