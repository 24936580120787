import {fade} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import REDICON from '../../assets/icons/alert/bell-red.svg';
import ORANGEICON from '../../assets/icons/alert/orange.svg';
import YELLOWICON from '../../assets/icons/alert/yellow.svg';
import MEALTABLE from '../../assets/icons/meal/meal-table.svg';
import {HealthParameterType, months} from '../../common/config';
import {checkIfTokenPresent} from '../../common/localStorageHandler';
import {generateComplexGlucoseDayData} from '../../fake-data-generator/bloodGlucose';
import {
  getHourAndMinuteFromTime,
  TIME_TYPE,
} from '../../fake-data-generator/common';
import {useHealthConfig} from '../../health-data-generator/health-common';
import {generateComplexHealthDayData} from '../../health-data-generator/health-day-data';
import {
  generateComplexHealthLineData,
  generateHealthAverageByHourData,
} from '../../health-data-generator/health-multiday-data';
import {routesPath} from '../../router';
import OutlinePrimaryButton from '../common/buttons/outline-primary/outline-primary';
import CardWithHeader from '../common/card-with-header/card-with-header';
import Modal, {modalType} from '../common/modal/modal';
import PlainSwitch from '../common/switches/plain-switch';
import DashLayout from '../dash-layout/dash-layout';
import Drawer from '../drawer/drawer';
import {getIconWithColor} from '../home-page/icon-handler';
import {color} from '../settings/vital-config';
import './blood-glucose.css';
import {getTitle, Loader, NoDataContainer} from './health-common';
import {DaySummary} from './health-component-day';
import {OtherSummary} from './health-component-other';
import {YearSummary} from './health-component-year';

const timeTypeList = ['Day', 'Week', 'Month', 'Year'];
const DAY_INDEX = 0;
const WEEK_INDEX = 1;
const MONTH_INDEX = 2;
const YEAR_INDEX = 3;

export default function HealthComponent(props) {
  const {type} = props;
  const history = useHistory();
  const [timeType, setTimeType] = useState(timeTypeList[DAY_INDEX]);

  const [isTabSummary, setOpenTabSummary] = useState(true);
  const [isBloodGlucoseOpened, setOpenBloodGlucoseBound] = useState(false);
  const [isTimeRangeOpened, setOpenTimeRangeBound] = useState(false);
  const [dailyAverage, setDailyAverage] = useState('');
  const [extractedDetails, setExtractedDetails] = useState([]);

  const [topHeader, setTopHeader] = useState('');
  const [extractedDay, setExtractedDay] = useState([]);
  const [extractedMonth, setExtractedMonth] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [latestValue, setLatestValue] = useState('');
  const [formData, setformData] = useState({
    detailsData: [],
    outOfRange: 0,
    allDetailsDataCount: 0,
    selectedDetailTab: '',
    generatedData: '',
    dailyAverage: '',
  });

  console.log('detailsData coming from state ', formData.detailsData);
  const {range, unitLabel, unit, converter} = useHealthConfig(type);

  const changeTimeAndUpdate = (back) => {
    let numberOfDaysToGoBack = 0;
    switch (timeType) {
      case timeTypeList[DAY_INDEX]:
        numberOfDaysToGoBack = 1;
        break;
      case timeTypeList[WEEK_INDEX]:
        numberOfDaysToGoBack = 7;
        break;
      case timeTypeList[MONTH_INDEX]:
        numberOfDaysToGoBack = 30;
        break;
      case timeTypeList[YEAR_INDEX]:
        numberOfDaysToGoBack = 365;
        break;
      default:
        break;
    }
    if (back) {
      endDate.setDate(new Date(endDate).getDate() - numberOfDaysToGoBack);
    } else {
      endDate.setDate(new Date(endDate).getDate() + numberOfDaysToGoBack);
    }
    getBarDataBySelection(timeType);
  };

  const getNotInRangeNumber = (data) => {
    let outOfRange = 0;
    data.forEach((s) => {
      let value = s.y;
      if (value < range[0] || value > range[1]) {
        outOfRange++;
      }
    });
    return outOfRange;
  };

  const getDayData = () => {
    let data = [];
    startDate.setDate(new Date().getDate());
    setTopHeader('All readings');
    generateComplexHealthDayData(endDate, type, false)
      .then((res) => {
        data = res;
      })
      .then(() => {
        if (data.scatterData.length > 0) {
          const latest =
            type === HealthParameterType.BLOOD_PRESSURE
              ? data.scatterData[data.scatterData.length - 1].y[0] +
                '/' +
                data.scatterData[data.scatterData.length - 1].y[1]
              : data.scatterData[data.scatterData.length - 1].y;
          setLatestValue(latest);
        } else {
          setLatestValue(-1);
        }
        console.log('&&&&&&&&&&&&&&&&&&&&&&&&');
        setformData({
          generatedData: data,
          detailsData: [...getDetailsData(data.scatterData, data.meals)],
          outOfRange: getNumberOfData(getNotInRangeNumber(data.scatterData)),
          allDetailsDataCount: getNumberOfData(data.scatterData.length),
          selectedDetailTab: `All (${getNumberOfData(
            data.scatterData.length
          )})`,
        });
      });
  };

  const getMultiDayData = (timeType) => {
    let data = [];

    generateComplexHealthLineData(endDate, timeType, type, false)
      .then((res) => {
        data = res;
      })
      .then(() => {
        console.log('resp', data);
        if (timeType === TIME_TYPE.YEAR) {
          let allData = 0;
          let notInRange = 0;
          let detailsData = [];
          const {multiDaysData} = data;
          const startDate =
            multiDaysData.length > 0
              ? new Date(multiDaysData[0].date)
              : new Date();
          const endDate =
            multiDaysData.length > 0
              ? new Date(multiDaysData[multiDaysData.length - 1].date)
              : new Date();
          let dataOfThisMonth = [];
          for (
            let start = new Date(startDate);
            start <= endDate ||
            (start.getMonth() <= endDate.getMonth() &&
              start.getFullYear() <= endDate.getFullYear());
            start.setMonth(start.getMonth() + 1)
          ) {
            dataOfThisMonth = multiDaysData.filter(
              (m) =>
                new Date(m.date).getMonth() === start.getMonth() &&
                new Date(m.date).getFullYear() === start.getFullYear()
            );
            const monthStart = new Date(dataOfThisMonth[0].date);
            const monthEnd = new Date(
              dataOfThisMonth[dataOfThisMonth.length - 1].date
            );
            let details = [];
            dataOfThisMonth.forEach((data) => {
              let detail = getDetailsData(data.eachDayData, data.meals);
              allData += data.eachDayData.length;
              notInRange += getNotInRangeNumber(data.eachDayData);
              details.push({
                date: data.date,
                detail: detail,
              });
            });
            detailsData.push({
              start: monthStart,
              end: monthEnd,
              multiDaysData: details,
            });
          }
          if (allData === 0) {
            setLatestValue(-1);
          }
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  1');
          setformData((value) => ({
            ...value,
            detailsData: [...detailsData],
            outOfRange: getNumberOfData(notInRange),
            allDetailsDataCount: getNumberOfData(allData),
            selectedDetailTab: `All (${getNumberOfData(allData)})`,
            generatedData: data,
          }));
          //   setDetailsData([...detailsData]);
          //   setOutOfRange(getNumberOfData(notInRange));
          //   setAllDetailsDataCount(getNumberOfData(allData));
          //   setSelectedDetailTab(`All (${getNumberOfData(allData)})`);
        } else {
          let allData = 0;
          let notInRange = 0;
          let details = [];
          data.multiDaysData.forEach((data) => {
            let detail = getDetailsData(data.eachDayData, data.meals);
            allData += data.eachDayData.length;
            notInRange += getNotInRangeNumber(data.eachDayData);
            details.push({
              date: data.date,
              detail: detail,
            });
          });
          details.reverse();
          if (allData === 0) {
            setLatestValue(-1);
          }
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  2');
          setformData((value) => ({
            ...value,
            detailsData: [...details],
            outOfRange: getNumberOfData(notInRange),
            allDetailsDataCount: getNumberOfData(allData),
            selectedDetailTab: `All (${getNumberOfData(allData)})`,
            generatedData: data,
          }));
          //   setDetailsData([...details]);
          //   setOutOfRange(getNumberOfData(notInRange));
          //   setAllDetailsDataCount(getNumberOfData(allData));
          //   setSelectedDetailTab(`All (${getNumberOfData(allData)})`);
        }
      });
  };

  const getBarDataBySelection = (tab) => {
    // setGeneratedData("");
    setformData((value) => ({...value, generatedData: ''}));
    setLatestValue('');
    switch (tab) {
      case timeTypeList[DAY_INDEX]:
        setStartDate(new Date(endDate));
        getDayData();
        break;
      case timeTypeList[WEEK_INDEX]:
        startDate.setDate(new Date(endDate).getDate() - 6);
        setStartDate(new Date(startDate));
        getMultiDayData(TIME_TYPE.WEEK);
        setTopHeader('All readings this week');
        break;
      case timeTypeList[MONTH_INDEX]:
        startDate.setDate(new Date(endDate).getDate() - 29);
        setStartDate(new Date(startDate));
        getMultiDayData(TIME_TYPE.MONTH);
        setTopHeader('All readings this month');
        break;
      case timeTypeList[YEAR_INDEX]:
        startDate.setDate(new Date(endDate).getDate() - 365);
        setStartDate(new Date(startDate));
        getMultiDayData(TIME_TYPE.YEAR);
        setTopHeader('Average reading for every hour this year');
        break;
      default:
        setformData((value) => ({
          ...value,
          generatedData: generateComplexGlucoseDayData(new Date()),
        }));
        // setGeneratedData(generateComplexGlucoseDayData(new Date()));
        break;
    }
    setOpenTabSummary(true);
  };

  const getNumberOfData = (data) => {
    return data < 1000 ? data : '+1000';
  };

  const whichTabIsSelected = () => {
    switch (timeType) {
      case timeTypeList[DAY_INDEX]:
        return (
          <DaySummary
            data={formData.generatedData}
            setOpenTimeRangeBound={setOpenTimeRangeBound}
            healthType={type}
          />
        );
      case timeTypeList[WEEK_INDEX]:
        return (
          <OtherSummary
            data={formData.generatedData}
            setOpenTimeRangeBound={setOpenTimeRangeBound}
            timeType={TIME_TYPE.WEEK}
            currentDate={endDate}
            healthType={type}
          />
        );
      case timeTypeList[MONTH_INDEX]:
        return (
          <OtherSummary
            data={formData.generatedData}
            setOpenTimeRangeBound={setOpenTimeRangeBound}
            timeType={TIME_TYPE.MONTH}
            currentDate={endDate}
            healthType={type}
          />
        );
      case timeTypeList[YEAR_INDEX]:
        return (
          <YearSummary
            data={formData.generatedData}
            setOpenTimeRangeBound={setOpenTimeRangeBound}
            timeType={TIME_TYPE.YEAR}
            currentDate={endDate}
            healthType={type}
            dailyAverage={dailyAverage}
          />
        );
      default:
        return (
          <OtherSummary
            data={formData.generatedData}
            setOpenTimeRangeBound={setOpenTimeRangeBound}
            timeType={TIME_TYPE.MONTH}
            currentDate={endDate}
          />
        );
    }
  };

  const SummaryTab = () => {
    return (
      <>
        {formData.generatedData !== '' ||
        timeType === timeTypeList[YEAR_INDEX] ? (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className="blood-glucose-content-header">
              <div className="blood-glucose-content-text">{topHeader}</div>
              <OutlinePrimaryButton
                click={() => setOpenBloodGlucoseBound(true)}
                inputId="btn_glucose_bound"
                classes="blood-glucose-bound-button"
                label={getTitle(type) + ' bounds'}
              />
            </div>
            {whichTabIsSelected()}
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  };

  const DetailCol = (props) => {
    const {value} = props;

    return <div className="detail-column">{value}</div>;
  };

  const getValueOfGlucose = (val, unit) => {
    if (val === 'NA') {
      return ' ';
    }
    const v = val;
    if (type === HealthParameterType.BLOOD_PRESSURE) {
      if (v[0] < range[0]) {
        return '<' + converter(range[0], unit);
      } else if (v[1] > range[1]) {
        return '>' + converter(range[1], unit);
      } else {
        return converter(v[0], unit) + '/' + converter(v[1], unit);
      }
    }
    if (v < range[0]) {
      return '<' + converter(range[0], unit);
    } else if (v > range[1]) {
      return '>' + converter(range[1], unit);
    } else {
      return converter(v, unit);
    }
  };

  const setOrUnsetObj = (obj, extractedItems, setExtractedItems) => {
    let arr = Array.from(extractedItems);
    const contains = arr.some((a) => JSON.stringify(a) === JSON.stringify(obj));
    if (contains) {
      arr = extractedItems.filter(
        (a) => JSON.stringify(a) !== JSON.stringify(obj)
      );
    } else {
      arr.push(obj);
    }
    setExtractedItems([...arr]);
  };

  const isExpandAble = (obj) => {
    const contains = extractedDetails.some(
      (a) => JSON.stringify(a) === JSON.stringify(obj)
    );
    if (contains) {
      if (obj.type || obj.date) {
        return true;
      }
      if (
        type !== HealthParameterType.BLOOD_PRESSURE &&
        obj.color !== color.green
      ) {
        return true;
      } else {
        if (obj.color[0] !== color.green) {
          return true;
        }
      }
    }
    return false;
  };

  const getTheAlertIcon = (c, y) => {
    if (y === 'NA') {
      return MEALTABLE;
    }
    switch (c) {
      case color.orange:
        return ORANGEICON;
      case color.red:
        return REDICON;
      case color.yellow:
        return YELLOWICON;
      default:
        return REDICON;
    }
  };

  const getBorderColor = (c, y) => {
    if (y === 'NA') {
      return '#1a74d4';
    }
    return c ? c : color.red;
  };

  const checkIfIconPresent = (c) => {
    if (c !== color.green) {
      return true;
    }
    return false;
  };

  const DetailRow = (props) => {
    const {parentObj, yearObj, obj, isSubItem, isChildSubItem} = props;
    const {y, date, type, color} = obj;
    const contains = extractedDetails.some(
      (a) => JSON.stringify(a) === JSON.stringify(obj)
    );
    return (
      <>
        <div
          className={(contains ? 'text-bold' : '') + ' detail-row c-pointer'}
          style={
            isExpandAble(obj) &&
            checkIfIconPresent(Array.isArray(color) ? color[0] : color)
              ? {
                  border:
                    '2px solid ' +
                    getBorderColor(Array.isArray(color) ? color[0] : color, y),
                  marginTop: '1px',
                  backgroundColor: fade(
                    getBorderColor(Array.isArray(color) ? color[0] : color, y),
                    0.2
                  ),
                }
              : {}
          }
        >
          <div className="d-flex-col w-100">
            <div style={{display: 'flex'}}>
              {isExpandAble(obj) &&
                checkIfIconPresent(Array.isArray(color) ? color[0] : color) && (
                  <img
                    src={getTheAlertIcon(
                      Array.isArray(color) ? color[0] : color,
                      y
                    )}
                    alt="yellow-bell"
                    className="details-icon"
                  />
                )}
              <div
                className={
                  (!isExpandAble(obj) && isChildSubItem
                    ? 'ml-2'
                    : !isExpandAble(obj) && isSubItem
                    ? 'ml-1'
                    : '') + ' d-flex-space-between w-100'
                }
                onClick={() =>
                  setOrUnsetObj(obj, extractedDetails, setExtractedDetails)
                }
                style={
                  isExpandAble(obj) &&
                  checkIfIconPresent(Array.isArray(color) ? color[0] : color)
                    ? {marginLeft: '-22px'}
                    : {}
                }
              >
                <DetailCol
                  value={
                    getHourAndMinuteFromTime(date) + (type ? ' | ' + type : '')
                  }
                />
                <DetailCol value={getValueOfGlucose(y, unit)} />
                <div className="detail-column">
                  <i className={'fa ' + (contains ? 'fa-minus' : 'fa-plus')} />
                </div>
              </div>
            </div>
            <DetailsText
              yearObj={yearObj}
              parentObj={parentObj}
              obj={obj}
              isExpandAble={contains}
            />
          </div>
        </div>
      </>
    );
  };

  const setTextToDetail = (text, parentObj, obj, yearObj) => {
    let details = [...formData.detailsData];
    switch (timeType) {
      case timeTypeList[DAY_INDEX]:
        details.filter((o) => JSON.stringify(o) === JSON.stringify(obj))[0][
          'text'
        ] = text;
        break;
      case timeTypeList[WEEK_INDEX]:
      case timeTypeList[MONTH_INDEX]:
        details
          .filter((o) => JSON.stringify(o) === JSON.stringify(parentObj))[0]
          .detail.filter((o) => JSON.stringify(o) === JSON.stringify(obj))[0][
          'text'
        ] = text;
        break;
      case timeTypeList[YEAR_INDEX]:
        details
          .filter((o) => o.start === yearObj.start)[0]
          .multiDaysData.filter(
            (o) => JSON.stringify(o) === JSON.stringify(parentObj)
          )[0]
          .detail.filter((o) => JSON.stringify(o) === JSON.stringify(obj))[0][
          'text'
        ] = text;
        break;
      default:
        break;
    }
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  3');
    setformData((value) => ({...value, detailsData: [...details]}));
    // setDetailsData([...details]);
  };

  const getHeaderDate = () => {
    if (timeType === timeTypeList[DAY_INDEX]) {
      const date = new Date(endDate);
      date.setDate(endDate.getDate() + 1);
      if (endDate.toDateString() === new Date().toDateString()) {
        return 'Today';
      } else if (date.toDateString() === new Date().toDateString()) {
        return 'Yesterday';
      }
      return (
        months[endDate.getMonth()].substr(0, 3) +
        ' ' +
        endDate.getDate() +
        ', ' +
        endDate.getFullYear()
      );
    }
    return (
      months[startDate.getMonth()].substr(0, 3) +
      ' ' +
      startDate.getDate() +
      ', ' +
      startDate.getFullYear() +
      ' - ' +
      months[endDate.getMonth()].substr(0, 3) +
      ' ' +
      endDate.getDate() +
      ', ' +
      endDate.getFullYear()
    );
  };

  const DetailsText = (props) => {
    const {parentObj, obj, isExpandAble, yearObj} = props;
    let val = obj.text;
    return (
      <div
        style={isExpandAble ? {display: 'flex'} : {display: 'none'}}
        className="detail-text-box-container"
      >
        <input
          defaultValue={obj.text}
          placeholder="Write here"
          onChange={(e) => (val = e.target.value)}
          className="details-text-box"
        />
        <div className="details-text-icon-container">
          <i
            className="detail-button fa fa-save"
            onClick={() => setTextToDetail(val, parentObj, obj, yearObj)}
            style={{color: '#1a74d4'}}
          />
        </div>
        <div className="details-text-icon-container">
          <i
            className="detail-button fa fa-trash"
            onClick={() => setTextToDetail('', parentObj, obj, yearObj)}
            style={{color: 'crimson'}}
          />
        </div>
      </div>
    );
  };

  const getDetailsData = (hourData, meals) => {
    let sctD = hourData;
    let m = [];
    meals.forEach((meal) => {
      // if (sctD[sctD.length - 1].x > meal.time) {
      m.push({
        date: meal.date,
        y: 'NA',
        type: meal.type,
      });
      // }
    });
    let data = [];
    data = sctD.concat(m);
    data = data.sort((a, b) => a.x - b.x);
    data.forEach((obj) => {
      obj['text'] = '';
    });
    data.reverse();
    return data;
  };

  const switchToOtherTab = (value) => {
    setExtractedDay([]);
    setExtractedDetails([]);
    setExtractedMonth([]);
    setformData((rest) => ({
      ...rest,
      selectedDetailTab: value,
    }));
    // setSelectedDetailTab(value);
  };

  const getFilteredData = (detailsData) => {
    if (formData.selectedDetailTab.startsWith('All')) {
      return detailsData;
    } else {
      return detailsData.filter(
        (s) => (!s.type && s.y < range[0]) || s.y > range[1]
      );
    }
  };

  const DetailDayRowWithData = (props) => {
    const {data, parentObj, isSubItem} = props;
    const {date, detail} = data;
    const dataCount = getFilteredData(detail).filter((d) => d.y != 'NA').length;
    let dateText = String(date).substr(0, 10).split(' ');
    dateText = dateText[0] + ', ' + dateText[1] + ' ' + dateText[2];
    const contains = extractedDay.some(
      (a) => JSON.stringify(a) === JSON.stringify(data)
    );
    return (
      <>
        {dataCount !== 0 && (
          <>
            <div
              style={
                contains
                  ? {
                      fontWeight: 'bold',
                      backgroundColor: '#f3fbff',
                      borderBottomColor: 'black',
                    }
                  : {}
              }
              className={' detail-row c-pointer d-flex-space-between'}
              onClick={() => setOrUnsetObj(data, extractedDay, setExtractedDay)}
            >
              <div className={(isSubItem ? 'ml-3' : '') + ' detail-column'}>
                {dateText + ' (' + dataCount + ')'}
              </div>
              <div className="detail-column">
                <i
                  className={
                    'fa ' + (contains ? 'fa-angle-up' : 'fa-angle-down')
                  }
                />
              </div>
            </div>
            {contains &&
              getFilteredData(detail).map((value, i) => (
                <>
                  <DetailRow
                    parentObj={data}
                    yearObj={parentObj}
                    obj={value}
                    isSubItem={true}
                    isChildSubItem={isSubItem ? isSubItem : false}
                  />
                </>
              ))}
          </>
        )}
      </>
    );
  };

  const DetailYearRowWithData = (props) => {
    const {data} = props;
    const {end, start, multiDaysData} = data;
    let dataCount = 0;
    multiDaysData.forEach((data) => {
      dataCount += getFilteredData(data.detail).filter(
        (d) => d.y != 'NA'
      ).length;
    });
    let startText = String(start).substr(4, 11).split(' ');
    startText = startText[0] + ' ' + startText[1] + ', ' + startText[2];
    let endText = String(end).substr(4, 11).split(' ');
    endText = endText[0] + ' ' + endText[1] + ', ' + endText[2];
    const dateText = startText + ' - ' + endText;
    const contains = extractedMonth.some(
      (a) => JSON.stringify(a) === JSON.stringify(data)
    );
    return (
      <>
        {dataCount !== 0 && (
          <>
            <div
              style={
                contains
                  ? {
                      fontWeight: 'bold',
                      backgroundColor: '#f3fbff',
                      borderBottomColor: 'black',
                    }
                  : {}
              }
              className="detail-row c-pointer d-flex-space-between"
              onClick={() =>
                setOrUnsetObj(data, extractedMonth, setExtractedMonth)
              }
            >
              <DetailCol value={dateText + ' (' + dataCount + ')'} />
              <div className="detail-column">
                <i
                  className={
                    'fa ' + (contains ? 'fa-angle-up' : 'fa-angle-down')
                  }
                />
              </div>
            </div>
            {contains &&
              multiDaysData.map((d) => {
                return (
                  <DetailDayRowWithData
                    parentObj={data}
                    data={d}
                    isSubItem={true}
                  />
                );
              })}
          </>
        )}
      </>
    );
  };

  const getFilteredDataLengthForMultipleDay = () => {
    let dataCount = 0;
    formData.detailsData.forEach((d) => {
      dataCount += getFilteredData(d.detail).filter((d) => d.y !== 'NA').length;
    });
    return dataCount;
  };

  const getFilteredDataLenghtForYear = () => {
    let dataCount = 0;
    formData.detailsData.forEach((monthData) => {
      monthData.multiDaysData.forEach((data) => {
        dataCount += getFilteredData(data.detail).filter(
          (d) => d.y != 'NA'
        ).length;
      });
    });
    return dataCount;
  };

  const DetailsTab = () => {
    let filteredData;
    switch (timeType) {
      case timeTypeList[DAY_INDEX]:
        filteredData = getFilteredData(formData.detailsData);
        break;
      case timeTypeList[YEAR_INDEX]:
        filteredData = getFilteredDataLenghtForYear();
        break;
      default:
        filteredData = getFilteredDataLengthForMultipleDay();
        break;
    }

    return (
      <>
        <PlainSwitch
          classes="details-tab-switch"
          value={formData.selectedDetailTab}
          changeValue={switchToOtherTab}
          switches={[
            `Out of Range (${formData.outOfRange})`,
            `All (${formData.allDetailsDataCount})`,
          ]}
        />
        <div className="detail-row detail-header">
          <DetailCol value="Time of reading" />
          <DetailCol value={'Value (' + unitLabel + ')'} />
          <DetailCol value="" />
        </div>
        {formData.generatedData !== '' && (
          <>
            {timeType === timeTypeList[DAY_INDEX] ? (
              <>
                {filteredData.length > 0 ? (
                  filteredData.map((value, i) => <DetailRow obj={value} />)
                ) : (
                  <NoDataContainer />
                )}
              </>
            ) : (
              <>
                {timeType === timeTypeList[YEAR_INDEX] ? (
                  <>
                    {filteredData !== 0 ? (
                      formData.detailsData.map((data) => (
                        <DetailYearRowWithData data={data} />
                      ))
                    ) : (
                      <NoDataContainer />
                    )}
                  </>
                ) : (
                  <>
                    {filteredData !== 0 ? (
                      formData.detailsData.map((d) => {
                        return <DetailDayRowWithData data={d} />;
                      })
                    ) : (
                      <NoDataContainer />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const Content = () => {
    return <>{isTabSummary ? <SummaryTab /> : <DetailsTab />}</>;
  };

  const switchTimeTab = (value) => {
    setExtractedDay([]);
    setExtractedDetails([]);
    setExtractedMonth([]);
    endDate.setDate(new Date().getDate());
    startDate.setFullYear(new Date().getFullYear());
    startDate.setMonth(new Date().getMonth());
    startDate.setDate(new Date().getDate());
    setEndDate(new Date());
    setStartDate(new Date());
    setTimeType(value);
    getBarDataBySelection(value);
    if (value === 'Year') {
      generateHealthAverageByHourData(new Date(endDate), 365, type, false).then(
        (res) => {
          setDailyAverage(res);
        }
      );
    }
  };

  const Header = () => {
    return (
      <>
        <div className="meal-header-item steps-header-item">
          <div className="steps-walked-header">
            <div className="blood-glucose-header-left">
              <div>
                <i
                  className="fa fa-angle-left"
                  id="btn_back"
                  onClick={() => changeTimeAndUpdate(true)}
                />
                <span className="steps-header-text">{getHeaderDate()}</span>
                {new Date(endDate).toDateString() !==
                  new Date().toDateString() && (
                  <i
                    className="fa fa-angle-right"
                    id="btn_front"
                    onClick={() => changeTimeAndUpdate(false)}
                  />
                )}
              </div>
              <div className="blood-glucose-tab-container">
                <div
                  onClick={() => setOpenTabSummary(true)}
                  className={
                    (isTabSummary ? 'blood-glucose-tab-active' : '') +
                    ' blood-glucose-tab'
                  }
                >
                  Summary
                </div>
                <div
                  onClick={() => setOpenTabSummary(false)}
                  className={
                    (!isTabSummary ? 'blood-glucose-tab-active' : '') +
                    ' blood-glucose-tab'
                  }
                >
                  Details
                </div>
              </div>
            </div>
            <div className="blood-glucose-header-right">
              <PlainSwitch
                classes="steps-switch"
                value={timeType}
                changeValue={switchTimeTab}
                switches={timeTypeList}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!checkIfTokenPresent()) {
      history.push(routesPath.SIGN_IN);
    }
    getBarDataBySelection(timeTypeList[DAY_INDEX]);
  }, []);

  return (
    <>
      <Modal
        isOpened={isBloodGlucoseOpened}
        close={() => setOpenBloodGlucoseBound(false)}
        type={modalType.BLOOD_GLUCOSE_BOUND}
        params={{type: type}}
      />
      <Modal
        isOpened={isTimeRangeOpened}
        close={() => setOpenTimeRangeBound(false)}
        type={modalType.TIME_IN_RANGE}
      />
      <DashLayout
        bodyClass={(isBloodGlucoseOpened ? 'no-pointer' : '') + ' drawer-body'}
        loggedIn={true}
        children={
          <Drawer
            children={
              <>
                <div className="meal-container">
                  <div className="meal-header">
                    <div className="blood-glucose-header-label">
                      <img
                        src={getIconWithColor(type, latestValue)}
                        alt="glucose-icon"
                        className="glucose-header-icon"
                      />
                      <span style={{marginTop: '2%'}}>{getTitle(type)}</span>
                    </div>
                  </div>
                  <CardWithHeader
                    headerClass="blood-glucose-card-header"
                    Header={Header}
                    Content={Content}
                  />
                </div>
              </>
            }
          />
        }
      />
    </>
  );
}
