import React from 'react';
import VitalParameter, {VITAL_PARAMS_FIELD} from '../settings/vital-parameter';

export default function HealthBound(props) {
  const {close, params} = props;
  const {type} = params;
  return (
    <>
      <div className="d-flex-col" style={{width: '40%'}}>
        <i
          className="fa fa-close c-pointer"
          onClick={close}
          style={{color: 'dodgerblue', alignSelf: 'flex-end'}}
        />
        <VitalParameter field={VITAL_PARAMS_FIELD[type]} />
      </div>
    </>
  );
}
